import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ActionsSubject, State, Store, select } from '@ngrx/store';
import { DocumentListActionTypes, ShowDocumentSuccess } from 'src/app/actions/document-list.actions';
import { MessagesService } from '../messages.service';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { DocumentService } from 'src/services/document.service';
import { ofType } from '@ngrx/effects';
import { MessagesState } from '../messages.reducer';
import { selectMessagesList } from 'src/app/reducers';
import { AppConstants } from 'src/utils/app.constants';
import { LoadMessagesSuccess, MessagesActionTypes, ToggleOpenedState } from '../messages.actions';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cps-monograph-messages',
  templateUrl: './cps-monograph-messages.component.html',
  styleUrls: ['./cps-monograph-messages.component.scss']
})
export class CpsMonographMessagesComponent implements OnInit, OnDestroy {

  @Input() currentDocument: DocumentItem;

  data: any;

  messagePanelCollapsed = false;
  contactManufacturer = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService,
              private actionListener$: ActionsSubject,
              private store: Store<MessagesState>) {
    const thizz = this;

    // note: found bug w/ switching tabs only (not opening)
    // subscription fires before constructor somehow?
    // rearrange so after initialization, checks the ngrx store
    this.actionListener$.pipe(ofType(DocumentListActionTypes.ShowDocumentSuccess),
                              takeUntil(this.destroy$),
                              filter((action: ShowDocumentSuccess) =>
                              action.document.type === AppConstants.MONOGRAPH_TYPE && action.document.id === this.currentDocument.id))
        .subscribe((action: ShowDocumentSuccess) => {
          this.store.select(selectMessagesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe(messages => {
              if (!!this.currentDocument) {
                thizz.parseMessageData(messages);
              }
            });
      });
  }

  ngOnInit(): void {
    this.detectScreenSize();

    // after initialization, check if there's message data. If not check if the store has something
    this.store.pipe(select(selectMessagesList), take(1)).subscribe(messages => {
      if (!!this.currentDocument && this.data === undefined) {
        this.parseMessageData(messages);
      }
    });
  }

  parseMessageData(messages: any): void {
    this.data = messages[this.currentDocument.id];
    if (!!messages[this.currentDocument.id] 
        && !!messages[this.currentDocument.id].messagePanelCollapsed) {
      this.messagePanelCollapsed = messages[this.currentDocument.id].messagePanelCollapsed;
    }
    if (!!messages[this.currentDocument.id]
      && !!messages[this.currentDocument.id].messages.manufacturer){
      this.contactManufacturer = messages[this.currentDocument.id].messages.manufacturer;
    }
  }

  private detectScreenSize() {
    if (window.innerWidth < 420) {
      this.messagePanelCollapsed = true;
    } else {
      this.messagePanelCollapsed = false;
    }
  }

  toggleMessagePanel(): void {
    this.messagePanelCollapsed = !this.messagePanelCollapsed;

    this.store.dispatch(new ToggleOpenedState(this.currentDocument.id, this.messagePanelCollapsed));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
