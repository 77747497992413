import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { State } from 'src/app/reducers';
import { DatalayerService } from 'src/services/datalayer.service';
import { DocumentService } from 'src/services/document.service';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { DocumentItem } from '../document-models/document-item';
import { DocumentComponent } from '../document-models/documentComponent';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-cps-body-weight-calculator',
  templateUrl: './cps-body-weight-calculator.component.html',
  styleUrls: ['./cps-body-weight-calculator.component.scss']
})
export class CpsBodyWeightCalculatorComponent implements OnInit, DocumentComponent, OnDestroy {

  constructor(public translationService: TranslateService,
              public formBuilder: FormBuilder,
              private datalayerService: DatalayerService,
              private documentService: DocumentService) {

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
    });
  }

  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;
  public contentLoaded = false;
  BSAForm: FormGroup;
  weightField: FormControl;
  heightFieldCm: FormControl;
  heightFieldFeet: FormControl;
  heightFieldInches: FormControl;

  gender = 'male';
  selectedWeightUnit = 'kg';
  selectedHeightUnit = 'cm';
  heightUnitFlag = true;

  mostellerValue = 0;
  haycockValue = 0;
  ibwValue = 0;
  abwValue = 0;
  wtValue: number = null;
  cmValue: number = null;
  ftValue: number = null;
  inValue: number = null;
  heightValue: number = null;
  weightValue: number = null;
  hasUserInteractedWithTool: boolean;
  mobileView = false;

  contentLoadedSub: Subscription;
  weightFieldSub: Subscription;
  heightCmFieldSub: Subscription;
  heightFtFieldSub: Subscription;
  heightInchFieldSub: Subscription;
  fileName = '/static/Calculations_and_Dosing_Tools.pdf';

  protected readonly environment = environment;

  ngOnInit(): void {

    this.hasUserInteractedWithTool = false;

    this.weightField = new FormControl();
    this.heightFieldCm = new FormControl();
    this.heightFieldFeet = new FormControl();
    this.heightFieldInches = new FormControl();
    this.BSAForm = this.formBuilder.group(
      {
        'weightField': this.weightField,
        'heightFieldCm': this.heightFieldCm,
        'heightFieldFeet': this.heightFieldFeet,
        'heightFieldInches': this.heightFieldInches
      }
    );

    this.weightFieldSub = this.weightField.valueChanges.subscribe(data => {
      if (data === '') {
        this.wtValue = null;
      } else {
        this.wtValue = data;
      }
      this.calculateResults();
    })
    this.heightCmFieldSub = this.heightFieldCm.valueChanges.subscribe(data => {
      if (data === '') {
        this.cmValue = null;
      } else {
        this.cmValue = data;
      }
      this.calculateResults();
    })
    this.heightFtFieldSub = this.heightFieldFeet.valueChanges.subscribe(data => {
      if (data === '') {
        this.ftValue = null;
      } else {
        this.ftValue = data;
      }
      this.calculateResults();
    })
    this.heightInchFieldSub = this.heightFieldInches.valueChanges.subscribe(data => {
      if (data === '') {
        this.inValue = null;
      } else {
        this.inValue = data;
      }
      this.calculateResults();
    })

    setTimeout(() => {
      this.checkContentLoaded();
    }, 200);
  }

  selectGender(genderType: string): void {
    this.gender = genderType;
    this.calculateResults();
  }

  weightConversion(unitValue: number, convertTo: string): number {
    // 1 KG = 2.20462 LBS
    let requiredResult = null;
    if (convertTo === 'kg') {
      requiredResult = unitValue / 2.20462;
    } else {
      requiredResult = unitValue * 2.20462;
    }
    if (requiredResult % 1 !== 0) {
      return parseFloat((Math.floor(requiredResult * Math.pow(10, 3)) / Math.pow(10, 3)).toFixed(3));
    } else {
      return Math.trunc(requiredResult);
    }
  }

  selectWeightUnit(weightType: string): void {
    if(this.selectedWeightUnit !== weightType){
      this.onToolInteractionEvent();

      this.selectedWeightUnit = weightType;
      if (this.wtValue !== null) {
        this.BSAForm.get('weightField').setValue(this.weightConversion(this.wtValue, weightType));
      }
    }
  }

  heightConversion(inches: number, cm: number, convertTo: string) {
    // 1 INCH = 2.54 CM
    let requiredResult = null;
    if (convertTo === 'in') {
      return (cm / 2.54);
    } else {
      requiredResult = inches * 2.54;
      if (requiredResult % 1 !== 0) {
        return parseFloat((Math.floor(requiredResult * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
      } else {
        return Math.trunc(requiredResult);
      }
    }
  }

  selectHeightUnit(heightType: string): void {
    if (this.selectedHeightUnit !== heightType) {
      this.onToolInteractionEvent();

      this.selectedHeightUnit = heightType;
      if (heightType === 'in') { // CM to INCHES conversion
        this.heightUnitFlag = false;
        if (this.cmValue !== null) {
          let feet = null;
          let inches = this.heightConversion(null, this.cmValue, heightType);
          if (inches >= 12) {
            feet = Math.trunc(inches / 12);
            inches = inches % 12;
          }
          if (inches % 1 !== 0) {
            inches = parseFloat((Math.floor(inches * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
          } else {
            inches = Math.trunc(inches);
          }
          this.BSAForm.get('heightFieldCm').setValue('');

          if (feet != null) {
            this.BSAForm.get('heightFieldFeet').setValue(feet);
          } else {
            this.BSAForm.get('heightFieldFeet').setValue('');
          }
          this.BSAForm.get('heightFieldInches').setValue(inches);
        }
      } else { // INCHES to CM conversion
        this.heightUnitFlag = true;
        if (this.ftValue !== null || this.inValue !== null) {
          let heightInInches = this.inValue;
          if (this.ftValue !== null) {
            heightInInches = this.ftValue * 12 + this.inValue;
          }
          let cm = this.heightConversion(heightInInches, null, heightType);

          this.BSAForm.get('heightFieldFeet').setValue('');
          this.BSAForm.get('heightFieldInches').setValue('');

          this.BSAForm.get('heightFieldCm').setValue(cm);
        }
      }
    }
  }

  resetResult(): void {
    this.mostellerValue = 0;
    this.haycockValue = 0;
    this.ibwValue = 0;
    this.abwValue = 0;
    this.weightValue = null;
    this.heightValue = null;
  }

  calculateResults(): void {
    this.onToolInteractionEvent();
    if (this.wtValue != null && (this.cmValue != null || this.inValue != null || this.ftValue !== null)) {
      if (this.selectedWeightUnit === 'lb') {
        this.weightValue = this.wtValue / 2.20462;
      } else {
        this.weightValue = this.wtValue;
      }
      if (this.selectedHeightUnit === 'in') {
        this.heightValue = this.ftValue * 12 * 2.54 + this.inValue * 2.54;
      } else {
        this.heightValue = this.cmValue;
      }
      if (this.heightValue !== null && this.weightValue !== null) {
        this.mostellerValue = Math.round((Math.sqrt(this.weightValue * this.heightValue / 3600)) * 100) / 100;
        this.haycockValue = Math.round((0.024265 * Math.pow(this.heightValue, 0.3964) * Math.pow(this.weightValue, 0.5378)) * 100) / 100;
        if (this.gender === 'male') {
          this.ibwValue = Math.round(50 + 2.3 * ((this.heightValue / 2.54) - 60));
        }
        else {
          this.ibwValue = Math.round(45.5 + 2.3 * ((this.heightValue / 2.54) - 60));
        }
        this.abwValue = Math.round(this.ibwValue + 0.4 * (this.weightValue - this.ibwValue));
      }
    } else {
      this.resetResult();
    }
  }

  onToolInteractionEvent(): void {
    if (!this.hasUserInteractedWithTool) {
      this.datalayerService.toolInteractionEvent('bodysurface');
      this.hasUserInteractedWithTool = true;
    }
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.contentLoadedSub = this.documentService.getContentLoaded().subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    this.weightFieldSub.unsubscribe();
    this.heightCmFieldSub.unsubscribe();
    this.heightFtFieldSub.unsubscribe();
    this.heightInchFieldSub.unsubscribe();
    this.contentLoadedSub.unsubscribe();
  }

  getPdfItem(urlForPdf, event: MouseEvent): void {
    const element = event.target as HTMLElement;
    const rect = element.getBoundingClientRect();
    const position = rect.top + window.scrollY;
    const fileExtension = urlForPdf.split('.').pop(); 

    this.documentService.getPdfForDocumentItem(urlForPdf).subscribe(res => {
      const fileURL: string = window.URL.createObjectURL(res);
      const tab: Window = window.open();
      tab.location.href = fileURL;
      this.datalayerService.fileDownloadEvent(fileExtension, this.fileName, position);
    });
  }
}

