import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SetState } from 'src/app/actions/home-history.actions';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { selectActive, selectFavoritesList, selectRecentsList } from 'src/app/reducers';
import { UserProfileState } from 'src/app/reducers/user-profile.reducer';
import { AuthService } from 'src/services/auth-services/auth.service';
import { AppConstants } from 'src/utils/app.constants';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DatalayerService } from 'src/services/datalayer.service';

@Component({
  selector: 'app-cps-home-history',
  templateUrl: './cps-home-history.component.html',
  styleUrls: ['./cps-home-history.component.scss']
})
export class CpsHomeHistoryComponent implements OnInit, OnDestroy {

  public active: number;

  public favorites$: Observable<DocumentItem[]>;
  public recents$: Observable<DocumentItem[]>;
  
  private userId: string;

  public ComponentType: string[] = AppConstants.STATIC_COMPONENT_TYPE_LIST;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService, 
             
              private store: Store,
              private router: Router,
              private datalayerService: DatalayerService) {
    this.favorites$ = this.store.select(selectFavoritesList);
    this.recents$ = this.store.select(selectRecentsList);
    this.store.select(selectActive)
      .pipe(takeUntil(this.destroy$)).subscribe(number => this.active = number);

  }

  ngOnInit(): void {

    this.userId = AuthService.userProfileState.userProfile.userId;
  }

  public filter(arr: DocumentItem[]): DocumentItem[] {
    return arr.filter(doc => doc.lang == this.translationService.currentLang
                              && doc.userid == this.userId && doc.type !== AppConstants.NOT_FOUND_TYPE);
  }

  public setActive(act: number) {
    this.store.dispatch(new SetState(act));
  }

  public getPath(doc: DocumentItem): string {
    let path: string;
    
    if (doc.type == AppConstants.MONOGRAPH_TYPE || doc.type == AppConstants.CLININFO_TYPE 
        || doc.type == AppConstants.CONDITIONS_TYPE || doc.type == AppConstants.MA_CONDITIONS_TYPE
        || doc.type == AppConstants.IFP_TYPE || doc.type == AppConstants.PMA_TYPE) {
      path = "/document/" + DocumentItem.getPathFromType(doc.type) + "/" + doc.id;
    } else if (doc.type == AppConstants.MA_PI_TYPE) {
      path = "/document/" + DocumentItem.getPathFromType(doc.type)
        + "/" + doc.parentDocumentID + "/" + doc.id;
    } else if(doc.type == AppConstants.MENU_TYPE || doc.type == AppConstants.RESOURCE_TYPE
      || doc.type == AppConstants.CLININFO_TOOLS_TYPE || doc.type == AppConstants.WHATS_NEW
      || doc.type == AppConstants.TOOLS_TYPE || doc.type == AppConstants.GENERIC_TYPE
      || doc.type == AppConstants.DIRECTORY) {
      path = DocumentItem.getPathFromType(doc.type) + "/" + doc.id;
    } 
    return path;
  }

  public navigateToDoc(doc: DocumentItem) {
    this.router.navigate([this.getPath(doc)]);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  homePageClickEvent( subcategory: string): void {
    let category = this.active === 1 ? "favourites" : "recent"
    this.datalayerService.homePageClickEvent(category, subcategory);
  }
}
