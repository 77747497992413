<div class="home-alerts-container" *ngIf="!isClosed && isHome && homeAlerts$ | async as homeAlerts">
    <div class="home-alerts-panel">
        <div class="d-xl-block d-lg-block d-md-block d-none align-self-center attentionIcon">
            <span class="warning-icon"></span>
        </div>
        <div class="alertText" *ngFor="let alert of homeAlerts">
            <ul class="homeAlertList">
                <li [innerHTML]="alert.innerHTML | safe: 'html'" (click)="clickEvent(alert.innerHTML)"></li>
            </ul>
        </div>
    </div>
</div>

