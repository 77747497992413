import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from 'src/services/document.service';
import { DocumentComponent } from '../document-models/documentComponent';
import { FrenchAccentNormalizer } from '../../utility/Objects/frenchNormalizationMap';
import { DocumentItem } from '../document-models/document-item';
import { ViewportScroller } from '@angular/common';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { CpsAlphaBarComponent } from '../cps-alpha-bar/cps-alpha-bar.component';
import { Generic } from './genericList.interface';
import { Subscription, throwError, ReplaySubject } from 'rxjs';
import { SessionService } from 'src/services/session.service';
import { catchError } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-cps-generic-index',
  templateUrl: './cps-generic-index.component.html',
  styleUrls: ['./cps-generic-index.component.scss']
})
// tslint:disable: max-line-length
// tslint:disable: prefer-for-of
export class CpsGenericIndexComponent implements OnInit, OnDestroy, DocumentComponent, AfterViewChecked {

  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  @ViewChild('alphaBar') alphaBar: CpsAlphaBarComponent;
  public doc: DocumentItem;
  public document: Document;

  public genericMono;

  public showMono: string;

  public genericIdentifier: string;

  public fan: FrenchAccentNormalizer;

  public currentLetter = '';
  public currentLetterList = [];
  private alphabarSelectedChar = '';

  public maxListSize = 150;

  // Search variables
  public searchText = '';
  public hasSearchBeenMade: boolean;
  public currentFocusIndex: number;
  public totalMatchesNum: number;
  public highlightList = [];
  public titlesList = [];

  public genericList: Generic[];
  public filteredGenericList: Generic[];

  // eslint-disable-next-line no-control-regex
  private english = /[^\u0000-\u007f]/;

  private subMonos: Subscription;
  private subLoadList: Subscription;
  public contentLoaded = false;
  public docError: boolean;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public documentService: DocumentService, public viewportScroller: ViewportScroller, private store: Store<State>,
    private changeDetectorRef: ChangeDetectorRef, private sessionService: SessionService) {
    this.fan = new FrenchAccentNormalizer();
    this.genericIdentifier = 'generic';
    this.currentLetter = 'a';
    if (!!globalThis.selectedGenericAlpha && globalThis.selectedGenericAlpha !== '') {
      this.currentLetter = globalThis.selectedGenericAlpha;
    }
    this.currentLetterList = [this.currentLetter];
  }

  ngOnDestroy(): void {
    if (this.subMonos) this.subMonos.unsubscribe();
    if (this.subLoadList) this.subLoadList.unsubscribe();
    this.doc.searchText = this.searchText;
  }

  ngOnInit(): void {

    this.onSearchTextChanged(this.doc.searchText);
    this.onSearchIndexChange(0);
    this.searchText = this.doc.searchText;

    this.subLoadList = this.documentService.loadGenericList()
      .pipe(takeUntil(this.destroy$)).subscribe((value) => {

      if (value.genericList) {
        this.genericList = value.genericList.results;
        this.updateFilteredList();

        for (const result in this.genericList) {
          if (this.titlesList.indexOf(this.genericList[result].title) < 0 ) {
            this.titlesList.push(this.genericList[result].title);
          }
        }
      }
    });

    setTimeout(() => {
      this.checkContentLoaded();
    }, 200);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  isANumber(letter: string): string {

    if (isNaN(Number(letter)) && !this.english.test(this.normalizeTitle(letter))) {
      return letter;
    }

    return '#';
  }

  normalizeTitle(title: string): string {

    return this.fan.frenchAccentNormalizer(title);

  }

  newHeading(letter: string): boolean {
    const l = this.isANumber(letter);

    if (this.alphabarSelectedChar.toLowerCase() === letter.toLowerCase()) {
      this.alphabarSelectedChar = '';
      return true;
    }

    if (this.currentLetter !== l) {

      this.currentLetter = l;
      return true;
    }
    return false;
  }

  getMonos(generic: string): void {
    this.subMonos = this.documentService.getGenericMono(generic)
      .pipe(takeUntil(this.destroy$),
            catchError((err) => {
              this.genericMono = '';
              this.docError = true;
              throw err;
            })
      )      
      .subscribe((value) => {
        this.genericMono = value.results;
        return this.genericMono;
      })
    this.genericMono = '';
  }

  showMonos(id: string): void {

    if (this.showMono === id) {
      this.showMono = ''; // Close the section
    } else {
      this.showMono = id;
    }
  }

  getPath(genericId: string): string {

    return "/document/monograph/" + genericId;
  }

  

  onSelectedAlphaChange(selectedLetter: string): void {
    this.currentLetter = selectedLetter;
    this.alphabarSelectedChar = selectedLetter;
    globalThis.selectedGenericAlpha = selectedLetter;
    this.currentLetterList = [selectedLetter.toLowerCase()];
    this.updateFilteredList();
  }

  updateFilteredList(): void {
    if (this.isANumber(this.currentLetter) === '#') {
      this.filteredGenericList = this.genericList.filter(g => !g.title.toLowerCase().match(/[a-z]/i));
    } else {
      this.filteredGenericList = this.genericList.filter(g => this.currentLetterList.indexOf(g.title[0].toLowerCase()) > -1);
      this.currentLetterList = [this.currentLetter.toLowerCase()];
      let nextLetter = '';
      while (this.filteredGenericList.length < this.maxListSize && nextLetter !== null) {
        nextLetter = this.nextChar(this.currentLetterList[this.currentLetterList.length - 1].toLowerCase());
        this.currentLetterList.push(nextLetter);
        this.filteredGenericList = this.genericList.filter(g => this.currentLetterList.indexOf(g.title[0].toLowerCase()) > -1);
      }
    }
    this.sessionService.isScrollLocked = true;
    window.scrollTo(0, 0);
  }

  clearSearch(): void {
    this.searchText = '';
    this.currentFocusIndex = -1;
    this.totalMatchesNum = 0;
    this.hasSearchBeenMade = false;
  }
  onSearchTextChanged = (text: string): number => {
    this.searchText = text;
    if (this.searchText !== null && this.searchText !== undefined && this.searchText !== '' && this.searchText.length > 2) {

      this.highlightList = [];
      this.hasSearchBeenMade = true;

      for (const index in this.titlesList) {

        if (index !== undefined) {
          const title = this.titlesList[index];

          if (title.toLowerCase().includes(this.searchText.toLowerCase())) {
            this.highlightList.push(title);
          }

        }

      }
      this.totalMatchesNum = this.highlightList.length;
      this.currentFocusIndex = 0;

    } else {
      this.highlightList = [];
      this.hasSearchBeenMade = false;
      this.clearSearch();
    }
    return this.totalMatchesNum;
  }
  
  onSearchIndexChange = (increment: number): number => {

    this.currentFocusIndex = increment;

    if (this.currentFocusIndex < 0) {
      this.currentFocusIndex = 0;
    }

    if (this.currentFocusIndex > this.highlightList.length - 1) {
      this.currentFocusIndex = 0;
    }

    if (this.highlightList.length > 0) {
      if (this.currentLetterList.indexOf(this.highlightList[this.currentFocusIndex][0].toLowerCase()) < 0) {      
        this.setSelectedLetter(this.highlightList[this.currentFocusIndex][0]);
        this.updateFilteredList();
        const thizz = this;
        setTimeout(() => {
          thizz.scrollToHighlightedText(thizz.doc.id + thizz.highlightList[thizz.currentFocusIndex]);
        }, 200);
      } else {
        this.scrollToHighlightedText(this.doc.id + this.highlightList[this.currentFocusIndex]);
      }
    }
    return this.currentFocusIndex;

  }

  setSelectedLetter(selectedLetter: string): void {
    this.currentLetter = selectedLetter;
    this.alphabarSelectedChar = selectedLetter;
    this.currentLetterList = [selectedLetter.toLowerCase()];
    this.alphaBar.alphaSelected(selectedLetter);
  }

  scrollToHighlightedText(id: string): void {
    this.viewportScroller.setOffset([0, 500]);
    this.viewportScroller.scrollToAnchor(id);
  }

  nextChar(c: string): string {
    return c === 'z' ? null : c === 'Z' ? null : String.fromCharCode(c.charCodeAt(0) + 1);
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded()
    .pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }
}
