import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PidService } from 'src/app/document/cps-pill-identifier-tool/pid.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { DocumentService } from 'src/services/document.service';

@Component({
  selector: 'app-cps-product-info-modal',
  templateUrl: './cps-product-info-modal.component.html',
  styleUrls: ['./cps-product-info-modal.component.scss']
})
export class CpsProductInfoModalComponent implements OnInit {

  public static monographHTML: HTMLElement;
  public html: HTMLElement;

  constructor(public translationService: TranslateService, public bsModalRef: BsModalRef,
              private datalayerService: DatalayerService, private pidService: PidService,
              private documentService: DocumentService) {
  }

  ngOnInit(): void {
    this.datalayerService.userEvent(
      { 'event': 'trackEvent-modalClick',
        'event_name': 'modal_click',
        'modal_type': 'product_image'
      }
    );

    this.html = CpsProductInfoModalComponent.monographHTML.getElementsByClassName('productInfoWrapper')[0] as HTMLElement;
  }

  ngAfterViewInit(): void {
    this.documentService.adjustTMsymbol(document);
  }
}
