<div class="drugShortageContainer">
    <div class="messageHeading">{{ 'MessageCenter.drugShortages.heading' | translate }}</div>
    <div class="label">{{ 'MessageCenter.drugShortages.label1' | translate }}<a href="{{drugShortageLink}}{{drugShortages[0].reportID}}"
                target="_blank" (click)="clickEvent(drugShortageLink)">drugshortagescanada.ca</a>
        {{ 'MessageCenter.drugShortages.label2' | translate }}</div>

    <div class="label" *ngIf="drugShortages && drugShortages.length">{{ 'MessageCenter.drugShortages.label3' | translate }}</div>
    <div class="alternativesList">
        <ul>
            <ng-container *ngFor="let alternativeDrugs of drugShortages">
                <li *ngIf="!!alternativeDrugs.name && alternativeDrugs.name !== ''">
                    <a [routerLink]="locatorToPath(alternativeDrugs.locator)"
                        (click)="navigateToMonograph(alternativeDrugs.docId, alternativeDrugs.locator)">{{alternativeDrugs.name}}</a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>