import { HeaderService } from 'src/services/header.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from 'src/app/search/search.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { HomeService } from 'src/services/home.service';
import { WhatsNew } from 'src/app/home/cps-home-sidebar/whatsnew.interface';
import { SessionService } from 'src/services/session.service';
import { Feature } from 'src/app/home/cps-home-feature/feature.interface';
import { AuthService } from 'src/services/auth-services/auth.service';

@Component({
  selector: 'app-cps-home-page',
  templateUrl: './cps-home-page.component.html',
  styleUrls: ['./cps-home-page.component.scss']
})
export class CpsHomePageComponent implements OnInit {

  public feature1: Feature;
  public feature2: Feature;
  public feature3: Feature;

  public whatsNew: WhatsNew[];

  constructor(public headerService: HeaderService,
              public translationService: TranslateService,
              private datalayerService: DatalayerService,
              public homeService: HomeService,
              public authService: AuthService) { }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.homeService.getFeature().subscribe((value) => {
       this.feature1 = value.features[0];
       this.feature2 = value.features[1];
       this.feature3 = value.features[2];
    });
    this.homeService.initializeWhatsNew();
    this.homeService.initializeWhatsNewTC();
    this.homeService.initializeHomeAlerts();

    this.datalayerService.pageLoadEvent('homepage', 'Home Page');
  }
}
