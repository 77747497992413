<header>
    <app-cps-header></app-cps-header>
    <app-cps-home-alerts></app-cps-home-alerts>
</header>
<main>  
    <router-outlet></router-outlet>
</main>
<footer>
    <app-cps-footer></app-cps-footer>
</footer>
<app-back-to-top *ngIf="isUserAuthenticated"></app-back-to-top>