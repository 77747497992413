import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { State } from '../app/reducers';
import { PopulateWhatsNew } from 'src/app/actions/whatsnew.actions';
import { WhatsNewState } from 'src/app/reducers/whatsnew.reducer';
import { WhatsNewTCState } from 'src/app/reducers/whatsnew-tc.reducer';
import { PopulateWhatsNewTC } from 'src/app/actions/whatsnew-tc.actions';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { AddRecent } from 'src/app/actions/home-history.actions';
import { PopulateHomeAlerts } from 'src/app/actions/home-alerts.action';
import { selectHomeAlert } from 'src/app/reducers/homeAlert.selector';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Features } from 'src/app/home/cps-home-feature/feature.interface';
import { DatalayerService } from './datalayer.service';
import { userProfile } from 'src/app/utility/Objects/userProfile';
import { AuthService } from './auth-services/auth.service';
import { CustomizerState } from 'src/app/reducers/customizer.reducer';
import { ClearCustomizer, PopulateCustomizer } from 'src/app/actions/customizer.actions';
import { AppConstants } from 'src/utils/app.constants';

@Injectable()
export class HomeService {

    public static customizerLoaded = false;

    constructor(private httpClient: HttpClient,
                private store: Store<State>,
                private translationService: TranslateService,
                private authService: AuthService,
                private datalayerService: DatalayerService) {
    }

    getCustomizer(lang: string, userprofile: userProfile): Observable<string> {

        return this.httpClient.get(environment.API_SERVICE_ENDPOINT + '/home/' + lang + '/' + userprofile.subscriberId + '/' + userprofile.active_Subscription + '/customizer', {responseType: 'text' });
    }

    getFeature(): Observable<Features> {

        if (environment.mockup) {
            return this.httpClient.get<Features>('assets/features/feature_1.json');
        }
        return this.httpClient.get<Features>(environment.API_SERVICE_ENDPOINT + '/home/' + this.translationService.currentLang + '/features');
    }

    getDrugUpdates(): Observable<string> {
        if (environment.mockup) {
            return this.httpClient.get('assets/mockData/WHATSNEW/drugupdates.xml', {responseType: 'text' }); // Demo
        }
        return this.httpClient.get(environment.API_SERVICE_ENDPOINT + "/documents/WHATSNEW/" + this.translationService.currentLang + "/whatsnew", {responseType: 'text' });
    }

    getTCUpdates(): Observable<string> {
        if (environment.mockup) {
            return this.httpClient.get('assets/mockData/WHATSNEW/whatsnewtc.xml', {responseType: 'text' }); // Demo
        }
        return this.httpClient.get(environment.API_SERVICE_ENDPOINT + "/documents/WHATSNEW/" + this.translationService.currentLang + "/whatsnewtc", {responseType: 'text' });
    }

    getWhatsNew(): Observable<WhatsNewState> {
        return this.store.select(state => state.whatsnewKey);
    }

    initializeCustomizer(lang: string, userProfile: userProfile): void {
        this.store.dispatch(new PopulateCustomizer(lang, userProfile)); 
    }

    clearCustomizer(): void {
        this.store.dispatch(new ClearCustomizer());
    }

    getStoredCustomizer(): Observable<CustomizerState> {
        return this.store.select(state => state.customizerKey);
    }

    initializeWhatsNew(): void {
        this.store.dispatch(new PopulateWhatsNew());
    }

    getWhatsNewTC(): Observable<WhatsNewTCState> {
        return this.store.select(state => state.whatsnewtcKey);
    }

    initializeWhatsNewTC(): void {
        this.store.dispatch(new PopulateWhatsNewTC());
    }

    updateRecents(doc: DocumentItem): void {
        this.store.dispatch(new AddRecent(doc));
    }

    getHomeAlerts(): Observable<string> { 
        // called from HomeAlerts effect to get data from API
        return this.httpClient.get(environment.API_SERVICE_ENDPOINT + '/home/' + this.translationService.currentLang + '/messages', {responseType: 'text' });
    }

    loadHomeAlerts(): Observable<Element[]> {
        // called from HomeAlerts component to retreive the observable reference in the NGRX store
        return this.store.select(selectHomeAlert);
    }

    initializeHomeAlerts(): void { 
        // called on initialization to trigger the HomeAlerts effect
        this.store.dispatch(new PopulateHomeAlerts()); 
    }

}
