import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { allowedNodeEnvironmentFlags } from 'process';
import { Observable } from 'rxjs';
import { Organizations } from 'src/app/document/cps-directory-document/directory.interface';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/utils/app.constants';

@Injectable()
export class DirectoryService {

    public static manufacturers: Organizations;
    public static healthorgs: Organizations;

    public static keywordSearchList = new Map();
    public static keywordSearchTerm = new Map();

    constructor(public httpClient: HttpClient, private translationService: TranslateService) {
        // Empty
    }

    // Health Orgs
    getHealthOrgs(): Observable<Organizations> {
        if (environment.mockup) {
            return this.httpClient.get<Organizations>('assets/mockData/ORGANIZATION/healthorgs.' + this.translationService.currentLang + '.json');
        }
        return this.httpClient.get<Organizations>(environment.API_SERVICE_ENDPOINT + '/documents/DIRECTORY/' + this.translationService.currentLang + '/DIR_HEALTH_ORG');
    }

    // MANUFACTURER
    getManufacturers(): Observable<Organizations> {
        if (environment.mockup) {
            return this.httpClient.get<Organizations>('assets/mockData/ORGANIZATION/manufacturers.' + this.translationService.currentLang + '.json');
        }
        return this.httpClient.get<Organizations>(environment.API_SERVICE_ENDPOINT + '/documents/DIRECTORY/' + this.translationService.currentLang + '/DIR_MANUFACTURER');
    }

    getDirectoryDetail(id: string, type: string): Observable<string> {
        if (environment.mockup) {
            switch (type) {
                case 'manufacturers':
                    return this.httpClient.get('assets/mockData/MANUFACTURER/' + id + '.' + this.translationService.currentLang + '.html', {responseType: 'text' });

                case 'healthorgs':
                    return this.httpClient.get('assets/mockData/HEALTHORG/' + id + '.' + this.translationService.currentLang + '.html', {responseType: 'text' });
            }
        }
        switch (type) {
            case 'manufacturers':
                return this.httpClient.get(environment.API_SERVICE_ENDPOINT + '/documents/DIRECTORY/' + this.translationService.currentLang + '/DIR_MANUFACTURER/' + id , {responseType: 'text' });

            case 'healthorgs':
                return this.httpClient.get(environment.API_SERVICE_ENDPOINT + '/documents/DIRECTORY/' + this.translationService.currentLang + '/DIR_HEALTH_ORG/' + id, {responseType: 'text' });
        }
    }
}