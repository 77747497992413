import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpsModalComponent } from './cps-modal/cps-modal.component';
import { CpsManufacturerModalComponent } from './cps-manufacturer-modal/cps-manufacturer-modal.component';
import { CpsProductInfoModalComponent } from './cps-product-info-modal/cps-product-info-modal.component';
import { CpsErrorModalComponent } from './cps-error-modal/cps-error-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { CpsLostConnectionModalComponent } from './cps-lost-connection-modal/cps-lost-connection-modal.component';
import { CpsPermissionDeniedModalComponent } from './cps-permission-denied-modal/cps-permission-denied-modal.component';
import { CpsSessionExpiredModalComponent } from './cps-session-expired-modal/cps-session-expired-modal.component';
import { CpsLicenseTakenModalComponent } from './cps-license-taken-modal/cps-license-taken-modal.component';



@NgModule({
  declarations: [
  	CpsModalComponent, 
	CpsManufacturerModalComponent, 
	CpsProductInfoModalComponent, 
	CpsErrorModalComponent, 
	CpsLostConnectionModalComponent,  
	CpsPermissionDeniedModalComponent, 
	CpsSessionExpiredModalComponent, CpsLicenseTakenModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule
  ]
})
export class ModalsModule { }
