import { SearchModule } from './search/search.module';
import { NavigationModule } from './navigation/navigation.module';
import { PagesModule } from './pages/pages.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HeaderService } from 'src/services/header.service';
import { LocalStorage } from 'src/utils/local-storage';
import { JsonTranslationLoader } from '../translation-loader';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentService } from 'src/services/document.service';
import { CommonModule } from '@angular/common';
import { DocumentModule } from './document/document.module';
import { HomeService } from 'src/services/home.service';
import { SessionService } from 'src/services/session.service'
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { HomeModule } from './home/home.module';
import { SearchService } from './search/search.service';
import { MonographModule } from './monograph/monograph.module';
import { SearchResultEffects } from './effects/search-result.effects';
import { UserProfileEffects } from './effects/user-profile.effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { DatalayerService } from 'src/services/datalayer.service';
import { NgxStickyfillModule } from 'ngx-stickyfill';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { UserService } from 'src/services/user.service';
import { MessagesEffects } from './monograph/messages.effects';
import { ModalsModule } from './modals/modals.module';
import { PortalModule } from '@angular/cdk/portal';
import { NgxPrintModule } from 'ngx-print';
import { PrintingService } from 'src/services/printing.service';
import { WhatsNewEffects } from './effects/whatsnew.effects';
import { WhatsNewTCEffects } from './effects/whatsnew-tc.effects';
import { UserProfileType } from './actions/user-profile.actions';
import { DocumentDataEffects } from './effects/document-data.effects';
import { SessionInterceptor } from './interceptors/session-interceptor';
import { AuthInterceptor } from 'src/services/auth-services/auth.interceptor';
import { HomeAlertEffects } from './effects/home-alerts.effects';
import { DirectoryService } from 'src/services/directory.service';
import { SharedModule } from './shared/shared.module';
import { GenericListEffects } from './effects/generic-list.effects';
import { CustomizerEffects } from './effects/customizer.effects';
import { InsideViewportDirective } from './directives/InsideViewport.directive';
import { NonMarketedUtils } from 'src/services/nonMarketedUtils';

export function FSLoaderFactory(): JsonTranslationLoader {
  return new JsonTranslationLoader();
}

@NgModule({
  declarations: [
    AppComponent,
    BackToTopComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    HomeModule,
    MonographModule,
    NavigationModule,
    SearchModule,
    CommonModule,
    DocumentModule,
    PagesModule,
    PortalModule,
    NgxPrintModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FSLoaderFactory,
      }
    }),
    ModalModule.forRoot(),
    ModalsModule,
    NgbTypeaheadModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forRoot(
      reducers,
      { metaReducers,
        runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([
        SearchResultEffects,
        MessagesEffects,
        WhatsNewEffects,
        WhatsNewTCEffects,
        UserProfileEffects,
        DocumentDataEffects,
        HomeAlertEffects,
        GenericListEffects,
        CustomizerEffects
      ])
  ],
  exports: [
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    HeaderService,
    LocalStorage,
    DocumentService,
    DirectoryService,
    UserService,
    HomeService,
    SearchService,
    SessionService,
    DatalayerService,
    PrintingService,
    NonMarketedUtils,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
