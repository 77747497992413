import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CPSMessages } from './CPSMessages.interface';
import { MessageRequest } from './MessageRequest.interface';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private httpClient: HttpClient) { }

  // getMonographMessages(msgRequest: MessageRequest): Observable<CPSMessages> {
  //   let params = new HttpParams()
  //           .set("requestData", encodeURIComponent(JSON.stringify(msgRequest)));
  //   return this.httpClient.get<CPSMessages>('assets/mockData/messageCenter-' + msgRequest.request.monoid + '.json', {params: params});
  // }

  getMonographMessagesById(monographId: string, language: string): Observable<CPSMessages> {
    const url: string = environment.API_SERVICE_ENDPOINT + '/search/messagecenter?monographId=' + monographId + '&lang=' + language;

    if (environment.mockup) {
      return this.httpClient.get<CPSMessages>('assets/mockData/messageCenter-' + monographId + '.' + language + '.json', {});
    } else {
      const result = this.httpClient.get<CPSMessages>(url, {});
      return result;
    }
  }

}
