import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { userProfile } from "../app/utility/Objects/userProfile"
import { Router, RoutesRecognized } from "@angular/router";
import { catchError, filter, pairwise } from "rxjs/operators";
import { AuthService } from "./auth-services/auth.service";
import { DatalayerService } from "./datalayer.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { CpsLostConnectionModalComponent } from "src/app/modals/cps-lost-connection-modal/cps-lost-connection-modal.component";
import { JwtTokenService } from "./jwt-token.service";
import { Store } from "@ngrx/store";
import { State } from "src/app/reducers";
import { LoadUserProfile } from "src/app/actions/user-profile.actions";
import { AuthResponse } from "./auth-services/auth-response.interface";
import { EMPTY } from "rxjs";
import { Observable } from "rxjs-compat";

@Injectable()
export class SessionService {

    public userProfile : userProfile;
    public lastRoute: string;

    public isScrollLocked: boolean;

    constructor(public translationService: TranslateService, 
                private authService: AuthService, 
                private router: Router,
                private jwtTokenService: JwtTokenService,
                private dataLayerService: DatalayerService,
                private modalService: BsModalService,
                private store: Store<State>) {

        this.userProfile = new userProfile();
        this.loadUserProfileFromStore();
        setTimeout(()=> { this.checkConnectivity(); }, 50); // We have to delay slightly to avoid a race condition between interceptors 
        
        const currentHostname = document.location.hostname;
        // if the referrer wasn't from cps itself
        if (document.referrer.indexOf(currentHostname) === -1) {
            this.dataLayerService.setPageReferrer(document.referrer);
        }

        // route changes in a SPA can't be caught w/ document.referrer so here's a subscription to handle it
        this.router.events
            .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                if (events.length > 0 && !!events[0]) {
                    this.dataLayerService.setPageReferrer(events[0].urlAfterRedirects);
                }
            });
    }

    loadUserProfileFromStore(): void {
        
        const storedUserInfo = this.authService.getUserProfileDetails();
        storedUserInfo.subscribe(userInfo => {
            if (!!userInfo && !!userInfo.userProfile) {
                this.userProfile = userInfo.userProfile;
            }
        });
    }

    authenticate() : userProfile {

        if (!!this.userProfile) {
            (<any>window).dataLayer.push({
                'subscriber_id':      this.userProfile.subscriberId,
                'event': 'page_load_user_properties',
                'billing_id':         this.userProfile.billingid,
                'subscriber_type':    this.userProfile.membertype,
                'login_type':         this.userProfile.login_type,
                'profession_type':    this.userProfile.profession,
                'language':           this.translationService.currentLang,
                'industry':           this.userProfile.industry,
            	'package_code':       this.userProfile.authorities[0],
            });
        } else {
            this.userProfile = new userProfile();
            this.loadUserProfileFromStore();
        }

        return this.userProfile;
    }

    refreshProfile(res: AuthResponse): void {
      let user = new userProfile();

      if (!!res) {
        this.jwtTokenService.setToken(res.access_token);
        user = this.jwtTokenService.getUserInfo();

        user.access_token = res.access_token;
        user.refresh_token = res.refresh_token;

        user.authorities = this.userProfile.authorities;
        user.active_Subscription = this.userProfile.active_Subscription;

        this.authService.clearLoginErrorMessage();
        this.dataLayerService.loginSuccessEvent(200, this.userProfile);

        this.store.dispatch(new LoadUserProfile(user));
      }
    }

    async checkConnectivity(): Promise<void> {
        await this.authService.checkConnection().then(authVersion => {
            if (authVersion == null) {
                this.openLostConectionModal();
            } else {
                console.groupCollapsed("CPS Auth Service Info");
                console.table(authVersion);
                console.groupEnd();
                this.authService.checkAPIVersion().subscribe(apiVersion => {
                    console.groupCollapsed("CPS API Service Info");
                    console.table(apiVersion);
                    console.groupEnd();
                });
                this.authService.checkProxyVersion().subscribe(proxyVersion => {
                    console.groupCollapsed("CPS Proxy Service Info");
                    console.table(proxyVersion);
                    console.groupEnd();
                });
            }
        });
    }

    openLostConectionModal(): void {

        const lostConnectionModal = this.modalService.show(CpsLostConnectionModalComponent, {backdrop : 'static', keyboard : false});
        lostConnectionModal.content.detectChanges();
    }

    setAutoLoginByWelcomeAction(rParam: string): void {

        this.authService.getRefreshToken(rParam).subscribe(
            (token: AuthResponse) => {
                
                let user = new userProfile();

                this.jwtTokenService.setToken(token.access_token);
                user = this.jwtTokenService.getUserInfo();
                user.access_token = token.access_token;
                user.refresh_token = token.refresh_token;
                user.authorities = this.jwtTokenService.getAuthorities();

                 if (user.authorities != null && user.authorities.length == 1) {
                    user.active_Subscription = user.authorities[0];
                } else {
                    user.active_Subscription = null;
                }
                
                this.authService.clearLoginErrorMessage();
                this.dataLayerService.loginSuccessEvent(200, this.userProfile);

                this.store.dispatch(new LoadUserProfile(user));
                this.authenticate();
                this.router.navigate(['/home'])
            },
            catchError(() => EMPTY )
        )            
    }

    checkAccessTokenValidity(): Observable<any> {
        this.loadUserProfileFromStore();
        return this.authService.checkAccessTokenValidity(this.userProfile.access_token);
    }
}
