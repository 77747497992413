import { Action } from '@ngrx/store';
import { DocumentItem } from '../document/document-models/document-item';

export enum HomeHistoryType {
  AddRecent = '[HomeHistory] Add Recent',
  AddFavorite = '[HomeHistory] Add Favorite',
  RemoveFavorite = '[HomeHistory] RemoveFavorite',
  // ChangeLanguage = '[HomeHistory] Change Language',
  SetState = '[HomeHistory] Set State'
}

export class AddRecent implements Action {
  readonly type = HomeHistoryType.AddRecent;
  constructor(public doc: DocumentItem) { }
}

export class AddFavorite implements Action {
  readonly type = HomeHistoryType.AddFavorite;
  constructor(public doc: DocumentItem) { }
}

export class RemoveFavorite implements Action {
  readonly type = HomeHistoryType.RemoveFavorite;
  constructor(public doc: DocumentItem) { }
}

// export class ChangeLanguage implements Action {
//   readonly type = HomeHistoryType.ChangeLanguage;
//   constructor(public lang: string) { }
// }

export class SetState implements Action {
  readonly type = HomeHistoryType.SetState;
  constructor(public active: number) { } // 1 = Favorites, 2 = Recents
}

export type HomeHistoryActions = AddRecent | AddFavorite | RemoveFavorite | SetState;
// export type HomeHistoryActions = AddRecent | AddFavorite | RemoveFavorite | ChangeLanguage | SetState;
