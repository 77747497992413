import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HomeService } from 'src/services/home.service';
import { DatalayerService } from 'src/services/datalayer.service';

@Component({
  selector: 'app-cps-notifications-panel',
  templateUrl: './cps-notifications-panel.component.html',
  styleUrls: ['./cps-notifications-panel.component.scss']
})
export class CpsNotificationsPanelComponent implements OnInit {

  @Input() cpsn: string;

  cpsNotificationURL = 'https://staging.cpsnotifications.ca/';

  constructor(public translationService: TranslateService,  private router: Router, public homeService: HomeService, private datalayerService: DatalayerService) {

  }

  ngOnInit(): void {
    this.datalayerService.bannerImpressionsEvent('CPS Notifications', 'monograph_message_centre');
  }

  clickEvent(linkUrl: string): void {
    this.datalayerService.bannerClickEvent('CPS Notifications', 'monograph_message_centre', linkUrl);
  }

}
