import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss']
})
export class BackToTopComponent implements OnInit, OnDestroy {

  showBackToTopTimeout: any;
  pageScrolled: boolean;
  constructor(@Inject(DOCUMENT) private document: Document) {
    fromEvent(window, 'scroll').pipe(debounceTime(100)).subscribe(event => {
      this.showBackToTopTimeout = setTimeout(() => {
        this.onPageScroll();
      }, 1000);
    });
  }

  ngOnInit(): void {
  }

  onPageScroll(): void {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.pageScrolled = true;
    }
    else if (this.pageScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.pageScrolled = false;
    }
  }

  isIE(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');
    return (msie > 0 || trident > 0);
  }
  
  backToTop() {
    window.scrollTo(0,0);
    // if (this.isIE()) {
    //   window.scrollTo(0,0);
    // } else {
    //   (function smoothscroll() {
    //     var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    //     if (scrollPosition > 0) {
    //       window.requestAnimationFrame(smoothscroll);
    //       window.scrollTo(0, scrollPosition - (scrollPosition / 2));
    //     }
    //   })();
    // }

  }

  ngOnDestroy(): void {
    clearTimeout(this.showBackToTopTimeout);
  }

}
