import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeAlertsFeatureKey, HomeAlertState } from './home-alerts.reducer';

const homeAlertFeatureState = createFeatureSelector<Partial<HomeAlertState>>(
    HomeAlertsFeatureKey
);

export const selectHomeAlert = createSelector(
    homeAlertFeatureState,
    state => state.homeAlert
);
