import { Action } from '@ngrx/store';
import { PIDResult, Result } from '../document/cps-pill-identifier-tool/PIDResult.interface';

export enum PillIdResultsActionTypes {
    LoadSearchResults = '[PillIdResult] Load SearchResults',
    SetResults = '[PillIdResult] Set Results',
    AddResults = '[PillIdResult] Add PID Results',
    ClearResults = '[PillIdResult] Clear all results'
}

export class LoadSearchResults implements Action {
    readonly type = PillIdResultsActionTypes.LoadSearchResults;
    constructor() { }
}

export class SetResults implements Action {
    readonly type = PillIdResultsActionTypes.SetResults;
    constructor(public pidResult: PIDResult, public brand: string, public imprint: string, public selectedFormType: any,
                public shapeSelected: any, public selectedColorsArray: string[], public scoredOrNot: boolean,
                public limit: number, public start: number, public page: number) {}
}

export class AddResults implements Action {
    readonly type = PillIdResultsActionTypes.AddResults;
    constructor(public results: Result[], public limit: number, public start: number, public page: number) { }
}

export class ClearResults implements Action {
    readonly type = PillIdResultsActionTypes.ClearResults;
    constructor() {}
}

export type PillIdResultActions = LoadSearchResults | SetResults | AddResults | ClearResults;
