import { MessagesActions, MessagesActionTypes } from "./messages.actions";

export const MessagesFeatureKey = 'messages';

export interface MessagesState {
    messages: {};
}

export const initialState: MessagesState = {
    messages: {}
}

export function messagesReducer(state = initialState, action: MessagesActions): MessagesState {
    switch (action.type) {
        case MessagesActionTypes.LoadMessagesSuccess:
            state.messages[action.monographid] = action.messages;
            return {...state };
        case MessagesActionTypes.LoadMessagesFailure:
            state.messages[action.monographid] = null;
            return {...state};
        case MessagesActionTypes.ClearMessages:
            return initialState;
        case MessagesActionTypes.ToggleOpenedState:
            state.messages[action.monographid].messagePanelCollapsed = action.isCollapsed;
            return {...state};
        default:
            return state;
    }
}