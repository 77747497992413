import { Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LocalStorage } from 'src/utils/local-storage';
import { SessionService } from 'src/services/session.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { AuthService } from 'src/services/auth-services/auth.service';
import { selectRecentsList } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-cps-login-page',
  templateUrl: './cps-login-page.component.html',
  styleUrls: ['./cps-login-page.component.scss']
})
export class CpsLoginPageComponent implements OnInit {

  public loginForm: FormGroup;
  public loginErrorMessage$: BehaviorSubject<string>;

  public recents$: Observable<DocumentItem[]>;
  public userIp$: Observable<string>;

  public isButtonDisabled = false;
  
  constructor(public translationService: TranslateService, public localStorage: LocalStorage,
    private authService: AuthService,
    private fb: FormBuilder, public sessionService: SessionService,
    private datalayerService: DatalayerService,
    private renderer: Renderer2,
    private store: Store) {
    this.validate()
    this.recents$ = this.store.select(selectRecentsList);
  }

  validate(): void {
    this.loginForm = this.fb.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      rememberMe: [false]     
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.authService.requestCompletion$.subscribe(completed => {
      if (completed) {
        this.isButtonDisabled = false;
        this.authService.setRequestCompletionState(false);
      }
    });
    
    this.userIp$ = this.authService.getIpCheckMessage();

    this.datalayerService.pageLoadEvent('login', 'Login Page');
    const params = new Proxy(new URLSearchParams(window.location.search),
      { get: (searchParams, prop) => searchParams.get(prop as string), }
    );
    const pParam = (params as any).p;

    if (pParam !== null){
      this.authService.setAutoLoginByParam(pParam);
      // all this has to do is dispath a LoadIPAuthenticatedUser to the store and let the user profile 
      // effects take care of the rest.
    } else if (this.datalayerService.getLastLogEvent() !== "logout") {  //DIG-2965 This condition was added to avoid getting a new token by IP when doing 'logout'. 
      this.authService.setIpAuthenticatedUserDetails();
    }

    const urlParams = window.location.search;

    if (urlParams.includes('error')) {
      this.authService.setLoginErrorMessage('error.authentication.code.1');
    }

    this.loginErrorMessage$ = this.authService.getLoginErrorMessage();

  }

  rememberMe(): void {
    console.log('Remember Me');
  }

  get f() {
    return this.loginForm.controls;
  }

  tabUser(): void {
    this.renderer.selectRootElement('#password').focus();
  }

  login(): void {
    this.loginForm.markAllAsTouched();
    this.isButtonDisabled = true;
    
    if (this.loginForm.invalid) {
      this.authService.setLoginErrorMessage('error.authentication.code.1');
      this.loginErrorMessage$ = this.authService.getLoginErrorMessage();
      this.isButtonDisabled = false;
      return;
    }

    this.authService.setIsUserAuthenticated(this.isButtonDisabled);
    this.authService.setUserCredentials(this.f.username.value, this.f.password.value);
    this.loginErrorMessage$ = this.authService.getLoginErrorMessage();
  }
}