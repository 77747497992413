import { Component, Inject, OnInit } from '@angular/core';
import { DatalayerService } from 'src/services/datalayer.service';
import { DocumentService } from 'src/services/document.service';
import { DocumentItem } from '../document-models/document-item';
import { PrintoutData, PRINTOUT_DOCUMENT_DATA, PRINTOUT_MODALS } from '../printoutDocument.tokens';

@Component({
  templateUrl: './cps-print-document.component.html',
  styleUrls: ['./cps-print-document.component.scss']
})
export class CpsPrintDocumentComponent implements OnInit {

  htmlElement: Element;
  doc: DocumentItem;
  public menuItemsList: Array<any> = [];
  public docFiltered = false;

  public tocTopLinkElements: Array<Element>;
  public productImageElement: Element;

  public footnote: string;
  public today: number = Date.now();
  public printName = true;

  constructor(@Inject(PRINTOUT_DOCUMENT_DATA) public data: PrintoutData, private datalayerService: DatalayerService, private documentService: DocumentService) {
    this.htmlElement = data.htmlElement;
    this.doc = data.doc;
    if (data.doc.type === 'MONOGRAPH' || (data.doc.type === 'IFP' && this.checkIFPCount(data.htmlElement) > 1)) {
      this.prepareMenuItemsPanel(data.htmlElement);
    }
    if (data.doc.type === 'MENU' || data.doc.type === 'WHATSNEW' || data.doc.type === 'RESOURCE' || data.doc.type === 'GENERIC' || data.doc.type === 'TOOL' || data.doc.type === 'DIRECTORY') {
      this.printName = false;
    }
  }

  ngOnInit(): void {
    // Empty
  }

  checkIFPCount(currentDoc: Element): number {
    const noOfIFP = currentDoc.querySelectorAll(".infoForPatientBody");
    return noOfIFP.length;
  }

  prepareMenuItemsPanel(currentDoc: Element): void {
    const rootNode: any = currentDoc.querySelector("ul.navigator-root");
    if (rootNode) {
      const liLinks: any = currentDoc.querySelectorAll("ul.navigator-root>li>ul>li");
      liLinks.forEach(childNode => {
        const currentEl = {
          text: childNode.firstChild.innerHTML,
          href: childNode.firstChild.hash
        };
        this.menuItemsList.push(currentEl);
      })
    }
  }

  setCheckboxes(e): void {
    const checks = PRINTOUT_MODALS[this.doc.id + 'windowInstance'].document.querySelectorAll("input[type='checkbox']");
    for (let i = 0; i < checks.length; i++) {
      if ((e.target.checked && !checks[i].checked) || (!e.target.checked && checks[i].checked)) {
        checks[i].click();
      }
    }
  }

  onCheckboxChange(e) {
    let element = PRINTOUT_MODALS[this.doc.id + 'windowInstance'].document.getElementById(e.target.value.replace('#', ''));
    if (this.doc.type === 'IFP') {
      do {
        if (element !== null) {
          if (e.target.checked) {
            element.style.display = "";
            element.classList.remove('x-hidden');
            element.style.display = "";
          } else {
            element.setAttribute('style', 'display: none !important');
            element.classList.add('x-hidden');
          }
        }
        element = element.nextSibling;
      } while (element !== null && !element.classList.contains('shifp'));
    } else {
      if (e.target.checked) {
        element.style.display = "";
      } else {
        element.setAttribute('style', 'display: none !important');
      }
    }

    if (this.docFiltered == false) {
      this.docFiltered = true;
      this.datalayerService.uniquePrintPageFilterClickEvent(this.doc, this.htmlElement, this.docFiltered);
    }
  }

  printDocument(): void {
    this.datalayerService.uniquePrintPageEvent(this.doc, this.htmlElement, this.docFiltered);
    PRINTOUT_MODALS[this.doc.id + 'windowInstance'].print();
  }

}
