import { Action } from '@ngrx/store';

export enum SearchTermActionTypes {
  LoadSearchTerm = '[SearchTerm] Load SearchTerms',
  RemoveSearchTerm = '[SearchTerm] Remove SearchTerms'  
}

export class LoadSearchTerm implements Action {
  readonly type = SearchTermActionTypes.LoadSearchTerm;
  constructor(public searchterm: string) { }
}

export class RemoveSearchTerm implements Action {
  readonly type = SearchTermActionTypes.RemoveSearchTerm;
  constructor() { }
}


export type SearchTermActions = LoadSearchTerm | RemoveSearchTerm;
