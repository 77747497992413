import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DatalayerService } from 'src/services/datalayer.service';
import { DocumentService } from 'src/services/document.service';
import { AppConstants } from 'src/utils/app.constants';
import { DocumentListActionTypes, LoadDocumentFailed, ShowDocumentPreload, ShowDocumentSuccess } from '../actions/document-list.actions';
import DocumentUtils from '../document/document-utils';

@Injectable()
export class DocumentListEffects {
  showDocument = createEffect(
    () => this.actions$.pipe(
      ofType<ShowDocumentPreload>(DocumentListActionTypes.ShowDocumentPreload),
      mergeMap((action) =>
        this.documentService.getHtmlForDocumentItem(action.document.type, action.document.id, action.document.lang, action.document.parentDocumentID)
          .pipe(
            map((html) => {
              this.documentService.toggleLock();
              const newHtmlParser = new DOMParser();
              const htmlEl = newHtmlParser.parseFromString(html, 'text/html');
              let title = htmlEl.documentElement.querySelector('title');
              if (action.document.type === AppConstants.IFP_TYPE && htmlEl.documentElement.querySelector('h2.brandname') !== undefined && htmlEl.documentElement.querySelector('h2.brandname') !== null){
                title = htmlEl.documentElement.querySelector('h2.brandname');

              } else if (action.document.type === AppConstants.MA_PI_TYPE && htmlEl.documentElement.querySelector('.dita-navtitle') !== undefined && htmlEl.documentElement.querySelector('.dita-navtitle') !== null){
                title = htmlEl.documentElement.querySelector('.dita-navtitle');

              }
              const manufacturerEl = htmlEl.documentElement.querySelector('div.manufacturers span.manufacturer');
              if (!!manufacturerEl) {
                action.document.manufacturerName = manufacturerEl.textContent;
                const manuId = manufacturerEl.getAttribute('id');
                if (!!manuId) {
                  action.document.manufacturerId = manuId;
                }
              }

              // PMA body descriptor
              const bodySystemDescriptor = htmlEl.documentElement.querySelector('meta[name="bodySystemDescriptor_text"]');

              if (!!bodySystemDescriptor) {
                action.document.bodySystemDescriptor = bodySystemDescriptor.getAttribute('content');
              }
              
              var titleClone:HTMLElement=null;
              var tm: HTMLElement;
              if (title != null && title.querySelector("sup")){
              titleClone=title.cloneNode(true) as HTMLElement;
              tm = titleClone.querySelector("sup") as HTMLElement;
                if (tm.innerText == 'MC') { tm.innerText ='<sup class="innerTextTM">MC</sup>'}
                if (tm.innerText == 'MD') { tm.innerText ='<sup class="innerTextTM">MD</sup>'}
                if (tm.innerText == '™') { tm.innerText = '<sup class="innerTextTM">TM</sup>' }
              }
              action.document.name = title ? title.innerText : action.document.id;
              action.document.nameHTML = titleClone ? titleClone.innerText : action.document.name;
              action.document.isActive = action.activate;
              const docType = DocumentUtils.getDocumentComponentTypeFromString(action.document.type, action.document.id);
              this.documentService.activateComponentRef(action.document, docType, htmlEl.documentElement);
              action.document.isInitialized = true;
              return new ShowDocumentSuccess(action.document, null);
            }
          ),
          catchError(
            (error) => {
              this.documentService.toggleLock();
              if (action.document.type !== AppConstants.TOOLS_TYPE && action.document.type !== AppConstants.GENERIC_TYPE) {
                action.document.type = AppConstants.NOT_FOUND_TYPE;
                this.datalayerService.documentNotFoundEvent(action.document);
                return of(new LoadDocumentFailed(action.document, error));
              }
              let componentRef = this.documentService
                  .getComponentRef(action.document.type, action.document.id, action.document.lang);

              if (componentRef === null) {
                const docType = DocumentUtils.getDocumentComponentTypeFromString(action.document.type, action.document.id);
                componentRef = this.documentService.createComponentRefForDocument(action.document, docType);
                componentRef.instance.htmlElement = this.documentService.skeleton;
                componentRef.instance.doc = action.document;
              }
              return of(new ShowDocumentSuccess(action.document, null));
            }
          )
        )
      )
    ));

  constructor(private actions$: Actions, private documentService: DocumentService, private datalayerService: DatalayerService) {}

}
