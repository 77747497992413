import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './cps-page-not-found.component.html',
  styleUrls: ['./cps-page-not-found.component.scss']
})
export class CpsPageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
