<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="false" [isMyOwnSearch]="true" [onNavegate]="onSearchIndexChange" [onSearch]="onSearchTextChanged" [currentDocument]="doc" [hidePrintButton]="true"></app-cps-tool-bar>
    <div #docWrapper class="directory-wrapper doc-content-wrapper">

        <div *ngIf="!contentLoaded">
            <ngx-skeleton-loader [theme]="{width: '25%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '50%', 'border-radius': '10px', 'margin-bottom': '20px'}"
            style="display: grid;"></ngx-skeleton-loader>
            <ngx-skeleton-loader
            [theme]="{width: '100%', height: '40px', 'border-radius': '10px', 'margin-bottom': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '60%', height: '40px', 'border-radius': '10px', 'margin-bottom': '10px', 'margin-bottom': '30px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="circle" [theme]="{width: '40px', height: '40px', 'margin-bottom': '10px'}"
            style="display: grid;"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="10" [theme]="{width: '100%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="circle" [theme]="{width: '40px', height: '40px', 'margin-bottom': '10px'}"
            style="display: grid;"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="10" [theme]="{width: '100%'}"></ngx-skeleton-loader>
        </div>

        <div class="headerContainer">

            <div *ngIf="contentLoaded" class="heading">{{'Directories.'+identifier+'.header' | translate}}</div>

            <div *ngIf="contentLoaded" class="sumText">{{'Directories.'+identifier+'.summary' | translate}}</div>

            <app-cps-alpha-bar *ngIf="contentLoaded" [identifier]="identifier" [itemList]="list" [document]="document" (selectedAlphaEvent)="onSelectedAlphaChange($event)" #alphaBar></app-cps-alpha-bar>
        </div>

        <div class="directoryList" *ngIf="contentLoaded">
            <div *ngFor="let item of list" class="letterHeadingTarget">
                <h3 *ngIf="newHeading(normalizeTitle(item.title).charAt(0))" class="letterHeading" id="{{identifier + isANumber(item.title.charAt(0))}}">
                    {{isANumber(item.title.charAt(0))}}
                </h3>

                <div *ngIf="!!item.participating==null || item.participating!=='no' || item.participating.length==0;" class="directoryItem" [ngClass]="{'rowHighlight' : this.highlightList[currentFocusIndex] == item.title}">
                   <a class="itemAnchor" (click)="showItem(item.id); getDirectoryDetail(item.id)" id="{{item.title}}">
                        <span [class]="showID==item.id ? 'collapse-icon' : 'expand-icon'"></span>
                     <span class="itemSpan" [ngClass]="{'highlight' :this.highlightList.indexOf(item.title) >= 0 }">{{item.title}}</span>
                   </a>
               </div>

                <div class="directoryInfo" *ngIf="showID==item.id" [innerHTML]="directoryDetails | safe: 'html'"></div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="loading" *ngIf="contentLoaded">
        <div class="box">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
                <div class="bounce4"></div>
                <div class="bounce5"></div>
            </div>
        </div>
    </div>
</ng-template>
