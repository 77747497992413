import { Action } from '@ngrx/store';
import { userProfile } from '../utility/Objects/userProfile';

export enum CustomizerActionTypes {
    PopulateCustomizer = '[Customizer] Populate Customizer',
    LoadCustomizer = '[Customizer] Load Customizer',
    ClearCustomizer = '[Customizer] Clear Customizer'
}

export class PopulateCustomizer implements Action {
    readonly type = CustomizerActionTypes.PopulateCustomizer;

    constructor(public lang: string, public userProfile: userProfile) {
        // Empty
    }
}

export class LoadCustomizer implements Action {
    readonly type = CustomizerActionTypes.LoadCustomizer;

    constructor(public customizer: string) {
        // Empty
    }
}

export class ClearCustomizer implements Action {
    readonly type = CustomizerActionTypes.ClearCustomizer;

    constructor() {
        // Empty
    }
}

export type CustomizerActions = PopulateCustomizer | LoadCustomizer | ClearCustomizer;