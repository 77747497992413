import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class NonMarketedUtils {
    private renderer: Renderer2;
    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public addNonmarketedMsg(): void {
        const intersectionObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {    
            const nonMarketedPanel = entry.target as HTMLElement
                const collapsiblePanel = nonMarketedPanel.querySelector(".nonmarketed-expandable") as HTMLElement || nonMarketedPanel.querySelector(".nonmarketed-collapsible") as HTMLElement;
                if (collapsiblePanel && !collapsiblePanel.classList.contains('activated')) {
                    this.collapseNonMarketedBody(collapsiblePanel);
                }
                if (!collapsiblePanel.dataset.listenerAdded) {
                this.renderer.listen(collapsiblePanel, 'click', () => {
                    this.toggleNonMarketedBody(collapsiblePanel);
                });
                    collapsiblePanel.dataset.listenerAdded = 'true'; // Mark as listener added
                }
            });
        });
        const observeNonMarketedMsg = () => {
            document.querySelectorAll('.nonmarketed-msg').forEach((nonMarketedPanel) => {
                intersectionObserver.observe(nonMarketedPanel);
            });
        };
        observeNonMarketedMsg();

        const mutationObserver = new MutationObserver(() => {
            observeNonMarketedMsg(); // Re-observe if mutations occur
        });

        mutationObserver.observe(document.body, { childList: true, subtree: true });
    }

    private toggleNonMarketedBody(e: HTMLElement): void {
        if (e.classList.contains('nonmarketed-collapsible')) {
            this.collapseNonMarketedBody(e);
        } else {
            this.expandNonMarketedBody(e);
        }
    }

    private collapseNonMarketedBody(e: HTMLElement): void {
        const nonmarketedPanel = e.parentNode as HTMLElement;
        const hideableElements = nonmarketedPanel.getElementsByClassName('dita-p');
        Array.from(hideableElements).forEach((element: HTMLElement) => {
            element.style.display = 'none';
        });

        const nonMarketedDisclaimerPanel = nonmarketedPanel.querySelector('.marketed-image')?.nextElementSibling as HTMLElement;
        if (nonMarketedDisclaimerPanel && nonMarketedDisclaimerPanel.classList.contains('marketed-brandname')) {
            nonMarketedDisclaimerPanel.style.display = 'list-item';
        } else {
            nonMarketedDisclaimerPanel.style.display = 'block';
        }
        if (!e.classList.contains('activated'))
            this.updateElementClass(e, 'activated', 'nonmarketed-collapsible', 'nonmarketed-expandable');
        this.updateElementClass(e, 'activated', 'nonmarketed-collapsible', 'nonmarketed-expandable');
    }

    private expandNonMarketedBody(e: HTMLElement): void {
        const nonmarketedPanel = e.parentNode as HTMLElement;
        const hideableElements = nonmarketedPanel.getElementsByClassName('dita-p');
        Array.from(hideableElements).forEach((element: HTMLElement, i: number) => {
            element.style.display = element.classList.contains('marketed-brandname') ? 'list-item' : 'block';
            if (i > 0 && !hideableElements[i - 1].classList.contains('marketed-brandname')) {
                element.style.margin = '0 30px';
            }
            if (i > 0 && hideableElements[i - 1].classList.contains('marketed-brandname')) {
                element.style.margin = '0 0 0 35px';
                element.style.padding = '0';
                element.style.display = 'inline';
            }
            else if (element.classList.contains('marketed-strength')) {
                element.style.margin = '0';
                element.style.padding = '0';
                element.style.display = 'inline'
            }
        });

        this.updateElementClass(e, 'activated', 'nonmarketed-expandable', 'nonmarketed-collapsible');
    }

    private updateElementClass(element: HTMLElement, ...classes: string[]): void {
        classes.forEach(className => {
            if (element.classList.contains(className)) {
                element.classList.remove(className);
            } else {
                element.classList.add(className);
            }
        });
    }
}