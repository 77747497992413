import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenService {

    jwtToken: string;
    userName: string;
    jsonObj: any;

  constructor() {
    // Empty
  }

  setToken(token: string) : void {
    this.jwtToken = JSON.stringify(jwt_decode(token));
  }

  decodeToken(token: string): any {
    this.jwtToken = JSON.stringify(jwt_decode(token));
    return this.jwtToken;
  }

  getJsonObj(): any {
    return JSON.parse(this.jwtToken);
  }

  getUserName(): string {
    this.userName = this.jwtToken;
    return this.getJsonObj().user_name;
  }

  getUserInfo(): any {
      return this.getJsonObj().UserInfo;
  }

  getAuthorities(): any {
    return this.getJsonObj().authorities;
  }

  getActiveSubscription(): any {
    return this.getJsonObj().active_Subscription;
  }

  isExpired(accessToken: string) : boolean {

    if (accessToken) {

      this.setToken(accessToken);
      // Note that the token exp (expiry date) field uses seconds since Unix Epoch, not milliseconds
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);

//      console.log("current token is good until " + new Date(parseInt(this.getJsonObj().exp) * 1000));
      if (parseInt(this.getJsonObj().exp) <= currentTimeInSeconds) {
//        console.log('Access token has expired.');
        return true;
      }
    }

    // If access token is undefined we shouldn't be getting to a point where we're checking the expiry date. 
    return false;
  }

}
