import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/services/auth-services/auth.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/utils/app.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cps-global-nav',
  templateUrl: './cps-global-nav.component.html',
  styleUrls: ['./cps-global-nav.component.scss']
})
export class CpsGlobalNavComponent implements OnInit, OnDestroy {

  public drugsMenu = [
    { "label" : "global-nav.whatnew", "link" : "whatsNew"},
    { "label" : "global-nav.monoUpdate", "link" : "monoUpdates"},
    { "label" : "global-nav.cpsMono", "link" : "monographs"},
    { "label" : "global-nav.medDevices", "link" : "medicalDevices"},
    { "label" : "global-nav.genericIndex", "link" : "genericIndex"},
    { "label" : "global-nav.pma", "link" : "productsMinorAils"},
    { "label" : "global-nav.clinInfo", "link" : "clinInfo"},
    { "label" : "global-nav.tc", "link" : "tc"},
    { "label" : "global-nav.tcma", "link" : "minorAils"},
    { "label" : "global-nav.ifp", "link" : "patientMedInfo"},
    { "label" : "global-nav.ma_pi", "link" : "patientInfoMA"}
  ];
  
  public drugsMenuLimited = [
    { "label" : "global-nav.whatnew", "link" : "whatsNew"},
    { "label" : "global-nav.monoUpdate", "link" : "monoUpdates"},
    { "label" : "global-nav.cpsMono", "link" : "monographs"},
    { "label" : "global-nav.medDevices", "link" : "medicalDevices"},
    { "label" : "global-nav.genericIndex", "link" : "genericIndex"},
    { "label" : "global-nav.clinInfo", "link" : "clinInfo"},
    { "label" : "global-nav.ifp", "link" : "patientMedInfo"}
  ];

  public toolMenu = [
    { "label" : "global-nav.pillid", "link" : "pillid"},
    { "label" : "global-nav.drugAdminFood", "link" : "drugAdminFood"},
    { "label" : "global-nav.drugAdminGrape", "link" : "drugAdminGrape"},
    { "label" : "global-nav.renalCalc", "link" : "renalfunction"},
    { "label" : "global-nav.bodyCalc", "link" : "bodysurface"},
    { "label" : "global-nav.lexiNoHyphen", "link" : "lexi"}
  ];

  public resourceMenu = [
    { "label" : "global-nav.hcAdvisories", "link" : "hcAlert"},
    { "label" : "global-nav.hcReport", "link" : "hcReport"},
    { "label" : "global-nav.medAbbrev", "link" : "abbrevs"},
    { "label" : "global-nav.microAbbrev", "link" : "microOrgs"},
    { "label" : "global-nav.poisonControl", "link" : "poisonControl"},
    { "label" : "global-nav.manuDir", "link" : "manufacturers"},
    { "label" : "global-nav.healthOrg", "link" : "healthOrgs"},
    { "label" : "global-nav.publicDrugPrograms", "link" : "publicDrugProg"}
  ];

  public isFullAccess: boolean;


  constructor(public translationService: TranslateService, private datalayerService: DatalayerService,
    private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    //Empty
    this.authService.getUserProfileDetails().subscribe(UserProfileState => {
      // Validate the token here?
      if (UserProfileState.userProfile != null && UserProfileState.userProfile.access_token != null && UserProfileState.userProfile.active_Subscription != null && UserProfileState.userProfile.active_Subscription.toLocaleLowerCase() == 'ecps') {
       this.isFullAccess = false;
      }else {
        this.isFullAccess = true;
      }
    });
  }

  onLexiClicked(): void {
    this.datalayerService.globalNavCategoryClickedEvent('lexi', 'lexi');
  }

  onSubcategoryClicked(categoryName: string, subcategoryName: string): void {
    this.datalayerService.globalNavSubcategoryClickedEvent(categoryName, subcategoryName);
  }

  @ViewChild('drugsConditionsDropdown') drugsConditionsDropdown: NgbDropdown;
  @ViewChild('toolDropdown') toolDropdown: NgbDropdown;
  @ViewChild('resourcesDropdown') resourcesDropdown: NgbDropdown;
  
  declare timeoutDrugConditions: ReturnType<typeof setTimeout>;
  declare timeoutTool: ReturnType<typeof setTimeout>;
  declare timeoutResources: ReturnType<typeof setTimeout>;

  public hoverOnDropdown(hoverType: string): void {
    switch (hoverType) {
      case 'drugs':
        this.timeoutDrugConditions = setTimeout(() => {
          this.drugsConditionsDropdown.open()
        }, AppConstants.MENU_DELAY);
        this.resourcesDropdown.close();
        this.toolDropdown.close();
        break;
      case 'tool':
        this.timeoutTool = setTimeout(() => {
          this.toolDropdown.open();
        }, AppConstants.MENU_DELAY);
        this.resourcesDropdown.close();
        this.drugsConditionsDropdown.close();
        break;
      case 'resources':
        this.timeoutResources = setTimeout(() => {
          this.resourcesDropdown.open();
        }, AppConstants.MENU_DELAY);
        this.drugsConditionsDropdown.close();
        this.toolDropdown.close();
        break;
      default:
        break;
    }
  }

  public leaveNavBar(): void {
    this.drugsConditionsDropdown.close();
    this.resourcesDropdown.close();
    this.toolDropdown.close();
    this.clearAllTimeouts();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    // This is to close all opened dropdowns when user return from switching tabs 
    // otherwise, the dropdowns will sometimes stay open and it's weird
    this.leaveNavBar();
  }
  goToHomePage(): void {
    this.router.navigate(['/home']);
  }

  clearAllTimeouts(): void {
    clearTimeout(this.timeoutDrugConditions);
    clearTimeout(this.timeoutResources);
    clearTimeout(this.timeoutTool);
  }

  ngOnDestroy(): void {
    this.clearAllTimeouts();
  }
  
}
