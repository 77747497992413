import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Shortages } from '../CPSMessages.interface';
import { Router } from '@angular/router';
import { DatalayerService } from 'src/services/datalayer.service';
import { DocumentService } from 'src/services/document.service';
import { AppConstants } from 'src/utils/app.constants';
import { DocumentItem } from 'src/app/document/document-models/document-item';

@Component({
  selector: 'app-cps-shortages-panel',
  templateUrl: './cps-shortages-panel.component.html',
  styleUrls: ['./cps-shortages-panel.component.scss']
})
export class CpsShortagesPanelComponent implements OnInit {

  @Input() drugShortages: Shortages[];

  drugShortageLink = 'https://www.drugshortagescanada.ca/shortage/';

  constructor(public translationService: TranslateService, private router: Router, private datalayerService: DatalayerService,
              private documentService: DocumentService) {

  }

  ngOnInit(): void {
    this.datalayerService.bannerImpressionsEvent('Drug Shortages', 'monograph_message_centre');
  }

  clickEvent(linkUrl: string): void {
    this.datalayerService.bannerClickEvent('Drug Shortages', 'monograph_message_centre', linkUrl);
  }

  navigateToMonograph(docId: string, linkUrl: string): void {
    this.clickEvent(linkUrl);
    this.documentService.setDocumentAsCurrent(AppConstants.MONOGRAPH_TYPE, docId, this.documentService.getCurrentlyViewedDocument().lang);
  }

  public locatorToPath(locator: string): string[] {
    return DocumentItem.locatorToPath(locator);
  }
}
