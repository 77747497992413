
 <div class="hcAlertContainer">
    <div class="messageHeading">{{ 'MessageCenter.hcalerts.heading' | translate }}</div>
    <div class="hcAlertList">
        <ul>
            <li class="hcAlertItem" *ngFor="let item of advisories">
                <span [innerHTML]="item.message"></span>
                <span class="hcAlertDate"> ({{item.dateStr}}) </span>
                <span class="moreLink"><u><a [href]="item.url" target="_blank" (click)="clickEvent(item.url)">{{ 'MessageCenter.hcalerts.moreLabel' | translate }}</a></u></span>
            </li>
        </ul>
    </div>
</div>