<div class="row searchbar">
    <div class="col-6 btn-group search-input-group">
        <span class="searchIcon"></span>
        <input type="text" id="search-input" name="search-input" (keyup)="onSearchTextChanged()"
            [(ngModel)]="searchText" placeholder="{{ 'ToolBar.Placeholder' | translate }}"
            class="form-control search-input" />
    </div>
    <div class="col-auto">
        <button type="button" class="btn btn-outline-secondary search-btn up-chevron" (click)="onSearchIndexChange(-1)"
            [disabled]="!hasSearchBeenMade">
        </button>
        <button type="button" class="btn btn-outline-secondary search-btn down-chevron" (click)="onSearchIndexChange(1)"
            [disabled]="!hasSearchBeenMade">
        </button>
    </div>
    <div class="col-auto searchMatch">
        <span *ngIf="hasSearchBeenMade && totalMatchesNum > 0" class="search-results-text">
            {{ currentFocusIndex + 1 }} {{ 'ToolBar.of' | translate}} {{ totalMatchesNum }} {{ 'ToolBar.Matches' |
            translate}}
        </span>
        <span *ngIf="hasSearchBeenMade && totalMatchesNum == 0" class="search-results-text">
            {{ 'ToolBar.noMatches' | translate}}
        </span>
    </div>
</div>