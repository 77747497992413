<div [ngClass]="{'mask': hamHideShow}">
<div class="globalNavHam-container" id="globalNavHam-container-id">
    <button (click)="hamburgerMenuHideShow()" class="globalNavHam-button"><span class="globalNavHam-icon"></span></button>

    <div *ngIf="hamHideShow" class="globalNavHam-menu" >
        <div class="globalNavHam-menu-header">
            <span class="globalNavHam-site-icon site-logo-big" *ngIf="sessionActive"></span><button class="globalNavHam-close" (click)="hamburgerMenuHideShow()"><span class="globalNavHam-close-icon"></span></button>
        </div>
        <div class="globalNavHam-menu-list">
            <ul class="nav flex-column">
                <li class="nav-item nav-dropdown">
                    <a class="globalNavHam-menu-item" (click)="drugsCollapse = !drugsCollapse;">{{ 'global-nav.drugsConditions' | translate }} 
                        <img [hidden]="!drugsCollapse" class="globalNavHam-menu-arrow down-solid-arrow">
                        <img [hidden]="drugsCollapse" class="globalNavHam-menu-arrow up-solid-arrow">
                    </a>
                    <div class="globalNavHam-drugMenuContainer" [ngbCollapse]="drugsCollapse">
                        <ul class="globalNavHam-menuList">
                            <li *ngFor="let item of drugsMenu" class="globalNavHam-listItem">
                                <a [routerLink]="[ item.link | routerPipe ]" (click)="onSubcategoryClicked('drugsConditions', item.link)" class="globalNavHam-menu-subitem">{{ item.label | translate }}</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item nav-dropdown">
                    <a class="globalNavHam-menu-item" (click)="toolsCollapse = !toolsCollapse;">{{ 'global-nav.tool' | translate }} 
                        <img [hidden]="!toolsCollapse" class="globalNavHam-menu-arrow down-solid-arrow">
                        <img [hidden]="toolsCollapse" class="globalNavHam-menu-arrow up-solid-arrow">
                    </a>
                    <div class="globalNavHam-toolsMenuContainer" [ngbCollapse]="toolsCollapse">
                        <ul class="globalNavHam-menuList">
                            <li *ngFor="let item of toolMenu" class="globalNavHam-listItem">
                                <a [routerLink]="[ item.link | routerPipe ]" (click)="onSubcategoryClicked('tool', item.link)" class="globalNavHam-menu-subitem">{{ item.label | translate }}</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item nav-dropdown">
                    <a class="globalNavHam-menu-item" (click)="resourcesCollapse = !resourcesCollapse;">{{ 'global-nav.resources' | translate }} 
                        <span [hidden]="!resourcesCollapse" class="globalNavHam-menu-arrow down-arrow-cyan-icon"></span>
                        <span [hidden]="resourcesCollapse" class="globalNavHam-menu-arrow up-solid-arrow"></span>
                    </a>
                    <div class="globalNavHam-resourcesMenuContainer" [ngbCollapse]="resourcesCollapse">
                        <ul class="globalNavHam-menuList">
                            <li *ngFor="let item of resourceMenu" class="globalNavHam-listItem">
                                <a [routerLink]="[ item.link | routerPipe ]" (click)="onSubcategoryClicked('resources', item.link)" class="globalNavHam-menu-subitem">{{ item.label | translate }}</a>
                            </li>
                        </ul> 
                    </div>
                </li>
                <li class="nav-item">
                    <a [routerLink]="[ 'lexi' | routerPipe ]" (click)="hamburgerMenuHideShow(false); onCategoryClicked('lexi', 'lexi');" class="globalNavHam-menu-item">{{ 'global-nav.lexi' | translate }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
</div>
