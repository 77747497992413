import { Action } from '@ngrx/store';
import { userProfile } from '../utility/Objects/userProfile';

export enum UserProfileType {
  LoadUserProfile = '[UserProfile] Load UserProfile',
  RefreshUserToken = '[UserProfile] Refresh User Token',
  RemoveUserProfile = '[UserProfile] Remove UserProfile',
  LoadUserCredentials = '[UserProfile] Load UserCredentials',
  LoadActiveSubscription = '[UserProfile] Load ActiveSubscription',
  LoadIPAuthenticatedUser = '[UserProfile] Load IP Authenticated User',
  LoadAutoLoginByParam = '[UserProfile] Load AutoLogin',
  NoAction = '[UserProfile] No action'
}

export class LoadUserProfile implements Action {
  readonly type = UserProfileType.LoadUserProfile;
  constructor(public userProfile: userProfile) { }
}

export class RemoveUserProfile implements Action {
  readonly type = UserProfileType.RemoveUserProfile;
  constructor(public err: any = null) { }
}

export class LoadUserCredentials implements Action {
  readonly type = UserProfileType.LoadUserCredentials;
  constructor(public username: string, public password: string) { }
}

export class RefreshUserToken implements Action {
  readonly type = UserProfileType.RefreshUserToken;
  constructor(public userProfile: userProfile) { }
}

export class LoadActiveSubscription implements Action {
  readonly type = UserProfileType.LoadActiveSubscription;
  constructor(public selectedSub: string) { }
}

export class LoadIPAuthenticatedUser implements Action {
  readonly type = UserProfileType.LoadIPAuthenticatedUser;
  constructor() { }
}

export class LoadAutoLoginByParam implements Action {
  readonly type = UserProfileType.LoadAutoLoginByParam;
  constructor(public pParam: string) { }
}

export class NoAction implements Action {
  readonly type = UserProfileType.NoAction;
  constructor() { }
}


export type UserProfileActions = LoadUserProfile | RemoveUserProfile | LoadUserCredentials | RefreshUserToken | LoadActiveSubscription
                                                  | LoadIPAuthenticatedUser | LoadAutoLoginByParam | NoAction;