import { Action } from '@ngrx/store';

export enum HomeAlertsActionTypes {
    PopulateHomeAlerts = '[HomeAlerts] Populate Home Alerts',
    LoadHomeAlerts = '[HomeAlerts] Load Home Alerts',
    ClearHomeAlerts = '[HomeAlerts] Clear Home Alerts'
}

export class PopulateHomeAlerts implements Action {
    readonly type = HomeAlertsActionTypes.PopulateHomeAlerts;

    constructor() {
        // Empty
    }
}

export class LoadHomeAlerts implements Action {
    readonly type = HomeAlertsActionTypes.LoadHomeAlerts;

    constructor(public homeAlert: Element[]) {
        // Empty
    }
}

export class ClearHomeAlerts implements Action {
    readonly type = HomeAlertsActionTypes.ClearHomeAlerts;

    constructor() {
        // Empty
    }
}

export type HomeAlertsActions = LoadHomeAlerts | PopulateHomeAlerts | ClearHomeAlerts;
