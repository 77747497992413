import { Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WhatsNewTC } from 'src/app/home/cps-home-sidebar/whatsnew-tc.interface';
import { WhatsNew } from 'src/app/home/cps-home-sidebar/whatsnew.interface';
import { HomeService } from 'src/services/home.service';
import { DocumentItem } from '../document-models/document-item';
import { DocumentComponent } from '../document-models/documentComponent';
import { DocumentService } from 'src/services/document.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cps-whats-new',
  templateUrl: './cps-whats-new.component.html',
  styleUrls: ['./cps-whats-new.component.scss']
})
export class CpsWhatsNewComponent implements OnInit, DocumentComponent, OnDestroy {

  private whatsNew: WhatsNew[];

  public today: number = Date.now();

  public whatsNewTC: WhatsNewTC[];

  public categories = [];
  public contentLoaded = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;

  constructor(public translationService: TranslateService, public homeService: HomeService,
    private documentService: DocumentService) {
    // Empty
  }

  filterBy(catg: string) {
    return this.whatsNew.filter(item => item.category === catg);
  }

  ngOnInit(): void {
    this.homeService.getWhatsNew().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      let catgMap = new Map<string, any>();
      this.whatsNew = value.whatsNew;
      this.whatsNew.sort((a, b) => {
        return a.brandName.localeCompare(b.brandName);
      });
      this.whatsNew.forEach(item => {
        if (item.filename !== null && item.filename !== 'none') { 
          item.id = item.filename.substring(0,item.filename.length - 7);
          catgMap.set(item.category, null);
        }
      });

      this.categories = Array.from(catgMap.keys());
      this.categories.sort();
    });

    this.homeService.getWhatsNewTC().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.whatsNewTC = value.whatsNewTC;
    });

    this.checkContentLoaded();
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  
}
