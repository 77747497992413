import { Pipe, PipeTransform } from '@angular/core';
import routesENJSON from '../../assets/routes.en.json';
import routesFRJSON from '../../assets/routes.fr.json';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/utils/app.constants';

@Pipe({name: 'routerPipe'})
export class RouterPipe implements PipeTransform {

    constructor(public translationService: TranslateService) {
    }

    transform(value: string): string {

        if (this.translationService.currentLang === AppConstants.ENGLISH_CODE) {
            return routesENJSON[value];
        }

        return routesFRJSON[value];
    }
}
