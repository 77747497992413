import { Action } from '@ngrx/store';
import { GenericList } from '../document/cps-generic-index/genericList.interface';

export enum GenericListActionTypes {
  LoadGenericList = '[DocumentData] Load Generic List',
  AddGenericList = '[DocumentData] Add Generic List',
  ClearGenericList = '[DocumentData] Clear Generic List'
}

export class LoadGenericList implements Action {
  readonly type = GenericListActionTypes.LoadGenericList;
  constructor() {
    // Empty
  }
}

export class AddGenericList implements Action {
  readonly type = GenericListActionTypes.AddGenericList;
  constructor(public genericList: GenericList) {
    // Empty
  }
}

export class ClearGenericList implements Action {
  readonly type = GenericListActionTypes.ClearGenericList;
  constructor() {
    // Empty
  }
}

export type GenericListActions = LoadGenericList | AddGenericList | ClearGenericList;
