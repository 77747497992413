import { DocumentDataActions, DocumentDataActionTypes } from '../actions/document-data.actions';

export const DocumentDataFeatureKey = 'documentData';

export interface MenuDocumentDataItem {
    id: string;
    title: string;
    docType: string;
    lang: string;
    locator: string;
    bodySystemDescriptorTextLst: string[];
    itemType: string;
}

export class MenuDocumentData {
    resultsList: MenuDocumentDataItem[];
    titlesList: string[];
    hasBodyParts: boolean;
    // bodypartMap: Map<string, any>;
    bodypartKeys: string[];
}

export interface DocumentDataState {
    documentDataMap: Map<string, MenuDocumentData>;
}

export const initialState: DocumentDataState = {
    documentDataMap: new Map<string, MenuDocumentData>()
};

export function documentDataReducer(state = initialState, action: DocumentDataActions): DocumentDataState {
    switch (action.type) {
        case DocumentDataActionTypes.AddMenuDocument:
            state.documentDataMap[action.menuType] = action.menuDocument;
            return {...state};
        case DocumentDataActionTypes.LoadMenuDocument:
            return state;
        case DocumentDataActionTypes.ClearMenuDocument:
            return initialState;
        default:
            return state;
    }
}
