import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cps-license-taken-modal',
  templateUrl: './cps-license-taken-modal.component.html',
  styleUrls: ['./cps-license-taken-modal.component.scss']
})
export class CpsLicenseTakenModalComponent implements OnInit {

  constructor(private cd: ChangeDetectorRef, public bsModalRef: BsModalRef, private router: Router) { }

  ngOnInit(): void {
    // Empty
  }

  detectChanges(): void {
    this.cd.detectChanges();
  }

  buttonClick(): void {
    this.bsModalRef.hide();
    this.router.navigate(['/login']);
  }

}
