<div *ngIf="!isSearchHidden" class="sticky-search-panel" [ngClass]="{'searchTermFilled': isSearchTermFilled}">
<div id="searchFormID" #searchPanel>
                <form [formGroup]="searchForm">

                    <div class="searchBar" ngbDropdown role="group">

                        <input id="SearchTxt" (keydown)='keyActivity($event)' (keyup)='keyupActivity($event)'
                            formControlName="searchField"
                            placeholder="{{ 'Search.placeholder' | translate }}" 
							(focus)="onSearchFocused()" (focusout)="onSearchFocusOut($event)"
                            class="form-control searchInput searchItems searchTermFilled" 
							[ngClass]="{'focused': isSearchInputFocused, 'searchTermFilled': isSearchTermFilled}" 
                            type="text" autocomplete="off" #searchInput>

                        <div class="search-icon-container">
                            <span *ngIf="searchQuery" class="crossButton" 
                            [ngClass]="{'searchTermFilled': isSearchTermFilled, 'focused': isSearchInputFocused}"
                            (mousedown)="clearSearchText()"><span class="x-close-icon"></span></span>
                            <span class="magnifyingGlass" (mousedown)="searchCall()" [ngClass]="{'focused': isSearchInputFocused, 'searchTermFilled': isSearchTermFilled}"></span>
                        </div>
                       
                        <span><button *ngIf="mobileView && isSearchTermFilled && hasResults" (click)="toggleDisplay()" class="threedots-button"
                           ><img [src]="'/assets/images/three-dots.svg'"></button></span>

                        <div *ngIf="statusDropdown" class="suggestionPanel" [ngClass]="{'condensedAndSearchTermFilled': (isHeaderCondensed && isSearchTermFilled), 'searchTermFilled': (isSearchTermFilled && !isHeaderCondensed)}">
                            <app-cps-search-suggestions></app-cps-search-suggestions>
                        </div>
 
                        <div [ngClass]="{'searchTermFilled': isSearchTermFilled, 'focused': isSearchInputFocused}"
                            *ngIf="searchTerm && showBuckets && (!isSearchInputFocused  || !isTabletView)" class="search-result-switch-wrapper">
                            <app-cps-search-result-switches [isHeaderCondensed]="isHeaderCondensed" [isSearchTermFilled]="isSearchTermFilled" [isSeachInputFocused]="isSeachInputFocused"></app-cps-search-result-switches>
                        </div>
                    </div>
                </form>
</div>
</div>
