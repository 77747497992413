<div class="header-root">
    <div class="header-container row no-gutters">
        <div class="global-Nav d-xl-block d-lg-block d-md-block d-block col-xl-12 col-lg-12 col-md-12 ml-auto">
            <div class="pkgselectiontitle">
                <a *ngIf="subscriptionInfo.length === 1" (click)="redirectToHome()"
                    class="redirectButton d-none d-md-block">{{ 'subcription-Page.returnToCPS' | translate }}</a>
            </div>
        </div>
    </div>
</div>
<div class="subMainPanel" [ngClass]="{'subscriptionFrPanel':currentLanguage==='fr'}">
    <div class="sub-select-wrapper">
        <div class="subsciptionPanel">
            <div class="redirectDiv">
                <a *ngIf="subscriptionInfo.length === 1" (click)="redirectToHome()"
                    class="redirectBtnMobile d-md-none">{{ 'subcription-Page.returnToCPS' | translate }}</a>
            </div>
            <div class="title">{{ 'subcription-Page.title' | translate }}</div>
            <div *ngIf="subscriptionInfo.length>1">{{ 'subcription-Page.selectSub' | translate }}</div>
        </div>

        <div class="login-error row justify-content-center" *ngIf="this.loginErrorMessage$.getValue() != '' ">
            <div *ngIf="this.loginErrorMessage$.getValue() != '' "[innerHTML]="'error-message.' + this.loginErrorMessage$.getValue() | translate"></div>
        </div>

        <!--ECPS- SINGLE SUBSCRIPTIONS -->
        <div *ngIf="subscriptionInfo.length === 1 && authorities[0]==='ecps'" class="subsciptionPanel singleSubscription">
            <div class="selection-block row no-gutters">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="drug-info-well infoSpecialPanel">
                        <h5 class="infotitle">
                            <span class="activemarker"><img src="assets/images/checkmark.png"></span>
                            {{ 'subcription-Page.drugInfoTitle' | translate }}
                            <span class="infoPanel activemarker">({{ 'subcription-Page.activeSubscription' | translate
                                }})</span>
                        </h5>
                        <span class="infobody">{{ 'subcription-Page.drugInfoBody' | translate }}</span>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="full-access-well infoSpecialPanel inactive">
                        <h5 class="infotitle">{{ 'subcription-Page.fullAccessTitle' | translate }}</h5>
                        <span class="infobody">{{ 'subcription-Page.fullAccessBody' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!--ETPC- SINGLE SUBSCRIPTIONS-->
        <div *ngIf="subscriptionInfo.length === 1 && authorities[0] === 'etpc'" class="subsciptionPanel singleSubscription">
            <div class="selection-block row no-gutters">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="drug-info-well infoSpecialPanel inactive">
                        <h5 class="infotitle">{{ 'subcription-Page.drugInfoTitle' | translate }}</h5>
                        <span class="infobody">{{ 'subcription-Page.drugInfoBody' | translate }}</span>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="full-access-well infoSpecialPanel">
                        <h5 class="infotitle">
                            <span class="activemarker"><img src="assets/images/checkmark.png"></span>
                            {{ 'subcription-Page.fullAccessTitle' | translate }}
                            <span class="infoPanel activemarker">({{ 'subcription-Page.activeSubscription' | translate
                                }})</span>
                        </h5>
                        <span class="infobody">{{ 'subcription-Page.fullAccessBody' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- MULTIPLE SUBSCRIPTIONS -->
        <div *ngIf="subscriptionInfo!==null && subscriptionInfo.length > 1" class="subsciptionPanel multipleSubscription">
            <div class="selection-block row no-gutters">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="drug-info-well infoSpecialPanel" (click)="selectSubscription('ecps')">
                        <h5 class="infotitle">
                            <ng-template [ngIf]="activeSubscription==='ecps'">
                                <span class="activemarker"><img src="assets/images/checkmark.png"></span>
                            </ng-template>
                            {{ 'subcription-Page.drugInfoTitle' | translate }}
                            <ng-template [ngIf]="activeSubscription==='ecps'">
                                <span class="infoPanel activemarker">({{ 'subcription-Page.activeSubscription' |
                                    translate }})</span>
                            </ng-template>
                        </h5>
                        <span class="infobody">{{ 'subcription-Page.drugInfoBody' | translate }}</span>
                        <button class="mybuttonoverlap btn btn-info">
                            <img src="assets/images/checkmark-white.png">{{ 'subcription-Page.selectsubscription' |
                            translate }}
                        </button>
                        <button class="sub-selectMobile btn-mobile btn-info">
                            <img src="assets/images/checkmark-white.png">{{ 'subcription-Page.selectsubscription' |
                            translate }}
                        </button>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="full-access-well infoSpecialPanel" (click)="selectSubscription('etpc')">
                        <h5 class="infotitle">
                            <ng-template [ngIf]="activeSubscription==='etpc'">
                                <span class="activemarker"><img src="assets/images/checkmark.png"></span>
                            </ng-template>
                            {{ 'subcription-Page.fullAccessTitle' | translate }}
                            <ng-template [ngIf]="activeSubscription==='etpc'">
                                <span class="infoPanel activemarker">({{ 'subcription-Page.activeSubscription' |
                                    translate }})</span>
                            </ng-template>
                        </h5>
                        <span class="infobody">{{ 'subcription-Page.fullAccessBody' | translate }}</span>
                        <button class="mybuttonoverlap btn btn-info">
                            <img src="assets/images/checkmark-white.png">{{ 'subcription-Page.selectsubscription' |
                            translate }}
                        </button>
                        <button class="sub-selectMobile btn-mobile btn-info">
                            <img src="assets/images/checkmark-white.png">{{ 'subcription-Page.selectsubscription' |
                            translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="subscriptionInfo.length > 1" class="block subsciptionPanelFooter">
            <span class="whytext">
                <img class="home-alert-icon" src="assets/images/alert-icon.png">
                {{ 'subcription-Page.whySelect' | translate }}
            </span>
            <span class="whytext">{{ 'subcription-Page.whySelectAnswer' | translate }}</span>
        </div>

        <div *ngIf="subscriptionInfo.length===1 && authorities[0]==='etpc'" class="block subsciptionPanelFooter">
            <span class="whytext">
                <img class="home-alert-icon" src="assets/images/alert-icon.png">
                {{ 'subcription-Page.fullAccesssubscription' | translate }}
            </span>
        </div>

        <div class="tokenToolTip" style="visibility: hidden">{{ 'subcription-Page.copied' | translate }}</div>
        <div class="tokenArrowDown" style="visibility: hidden"></div>
        <div class="pParam-container">
            <img class="token-img" src="assets/images/tokenIcon.png" (click)="togglePPDisplay()"><span class="pParam" *ngIf="showPP">{{pParam}} 
                <img class="token-img" (click)="copyToken()" src="assets/images/tokenOpenIcon.png"></span>
        </div>

        <div *ngIf="subscriptionInfo.length===1 && authorities[0]=='ecps'" class="block subsciptionPanelFooter"
            style="text-align: center;">
            <a class="changeSubBtn" href="{{ 'contactUs' | routerPipe }}"
                target="_blank">{{ 'subcription-Page.contactUs' | translate }}</a>
        </div>
    </div>
</div>