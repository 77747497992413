export class DocumentSectionElement {
    public element: HTMLElement;

    public sectionName: string;

    public sectionTitleElement: HTMLElement;

    public offsetTop: number;

    public height: number;

    public isHidden: boolean;

    public sectionIndex: number;
}