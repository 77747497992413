import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DocumentItem } from '../document-models/document-item';
import { DocumentComponent } from '../document-models/documentComponent';

@Component({
  selector: 'app-not-found-document',
  templateUrl: './not-found-document.component.html',
  styleUrls: ['./not-found-document.component.scss']
})
export class NotFoundDocumentComponent implements OnInit, DocumentComponent {

  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;

  public doc: DocumentItem;

  constructor() { }

  ngOnInit(): void {
  }

}
