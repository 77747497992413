import { HomeAlertsActions, HomeAlertsActionTypes } from '../actions/home-alerts.action';

export const HomeAlertsFeatureKey = 'homealertsKey';

export interface HomeAlertState {
    homeAlert: Element[];
}

export const initialState: HomeAlertState = {
    homeAlert: null
};

export function homeAlertsReducer(state = initialState, action: HomeAlertsActions): HomeAlertState {
    switch (action.type) {
        case HomeAlertsActionTypes.LoadHomeAlerts:
            state.homeAlert = action.homeAlert;
            return {...state};
        case HomeAlertsActionTypes.PopulateHomeAlerts:
            return state;
        case HomeAlertsActionTypes.ClearHomeAlerts:
            return initialState;
        default:
            return state;
    }
}

