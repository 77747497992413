import { Component, OnInit, Input } from '@angular/core';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { DatalayerService } from 'src/services/datalayer.service';
import { ContactManufacturer } from '../CPSMessages.interface';

@Component({
  selector: 'app-cps-contact-manufacturer',
  templateUrl: './cps-contact-manufacturer.component.html',
  styleUrls: ['./cps-contact-manufacturer.component.scss']
})
export class CpsContactManufacturerComponent implements OnInit {
  
  @Input() currentDocument: DocumentItem;
  @Input() contactManufacturer: ContactManufacturer;

  constructor(
    private datalayerService: DatalayerService){};  
  
  ngOnInit(): void {
    // EMPTY
  }

  onManufacturerClick(): void {
    this.datalayerService.manufacturerInfoClickFeature(this.currentDocument.name, this.currentDocument.manufacturerId, this.contactManufacturer.url, 'Feature');
  }
}
