import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocalStorage {

  private static readonly PREFERRED_LANG: string = 'pref_lang';
  private static readonly TEMP_TOKEN: string = 'temp_token';
  private static readonly INDUSTRY_CODE: string = 'industry_code';
  private static readonly USER_NAME: string = 'user_name';
  private static readonly OPEN_DOCS: string = 'open_docs';
  private static readonly SAVED_SCROLL_POSITION: string = 'saved_scroll_pos';
  private static readonly LOGGEDIN: string = 'is_logged_in'; //DEMO only
  private static readonly USER_BOOKMARKS: string = 'user_bookmarks';
 
  public preferredLang: BehaviorSubject<string>;
  public tempToken: BehaviorSubject<string>;
  public industryCode: BehaviorSubject<string>;
  public userName: BehaviorSubject<string>;
  public openDocs: BehaviorSubject<string>;
  public savedScrollPosition: BehaviorSubject<string>;
  public userBookmarks: BehaviorSubject<string>;

  public isLoggedIn: BehaviorSubject<string>; //DEMO only

  constructor() {
    this.preferredLang = new BehaviorSubject(localStorage.getItem(LocalStorage.PREFERRED_LANG));
    this.tempToken = new BehaviorSubject(localStorage.getItem(LocalStorage.TEMP_TOKEN));
    this.industryCode = new BehaviorSubject(localStorage.getItem(LocalStorage.INDUSTRY_CODE));
    this.userName = new BehaviorSubject(localStorage.getItem(LocalStorage.USER_NAME));
    this.openDocs = new BehaviorSubject(localStorage.getItem(LocalStorage.OPEN_DOCS));
    this.savedScrollPosition = new BehaviorSubject(localStorage.getItem(LocalStorage.SAVED_SCROLL_POSITION));
    this.isLoggedIn = new BehaviorSubject(localStorage.getItem(LocalStorage.LOGGEDIN)); //DEMO only
    this.userBookmarks = new BehaviorSubject(localStorage.getItem(LocalStorage.USER_BOOKMARKS));
  }

  public static getPreferredLang() : string {
    return localStorage.getItem(LocalStorage.PREFERRED_LANG);
  }

  public static getTempToken() : string {
    return localStorage.getItem(LocalStorage.TEMP_TOKEN);
  }

  public static getIndustryCode() : string {
    return localStorage.getItem(LocalStorage.INDUSTRY_CODE);
  }

  public static getUserName() : string {
    return localStorage.getItem(LocalStorage.USER_NAME);
  }

  public static getLoggedIn() : string {
    return localStorage.getItem(LocalStorage.LOGGEDIN);
  }

  public static getUserBookmarks(): any {
    return JSON.parse(localStorage.getItem(LocalStorage.USER_BOOKMARKS));
  }

  //DEMO only
  setLoggedIn(loggedIn: string): void {
//    localStorage.setItem(LocalStorage.LOGGEDIN, loggedIn);

    this.isLoggedIn.next(loggedIn);
  }

  setPreferredLang(lang: string): void {
    localStorage.setItem(LocalStorage.PREFERRED_LANG, lang);

    this.preferredLang.next(lang);
  }

  setTempToken(token: string): void {
    localStorage.setItem(LocalStorage.TEMP_TOKEN, token);

    this.preferredLang.next(token);
  }

  setIndustryCode(code: string): void {
    localStorage.setItem(LocalStorage.INDUSTRY_CODE, code);

    this.industryCode.next(code);
  }

  setUserName(userName: string): void {
    localStorage.setItem(LocalStorage.USER_NAME, userName);

    this.userName.next(userName);
  }
  
  setUserBookmarks(bookmarks: any) {
    const bookmarksString = JSON.stringify(bookmarks);
    localStorage.setItem(LocalStorage.USER_BOOKMARKS, bookmarksString);

    this.userBookmarks.next(bookmarksString);
  }
}
