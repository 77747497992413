<div id="{{data.id}}" class="bucketHeaderTOC" *ngIf="data.results.length>0">
<div class="d-flex align-items-center justify-content-between bucketContainer row no-gutters" *ngIf="data.results.length>0"
        [ngClass]="{'groupSpacing': toggleFlag}" (click)="toggleBucket()">
        <span class="col-11 m-0 bucketHeader p-0"><img *ngIf="isNpd" src="assets/images/alert-icon.svg" class="alt-mono-alert-img">{{ "ResultsPage."+data.id |
                translate }}</span>
        <button class="col-1 btn btn-link p-0 bucketSwitch" [ngClass]="{'collapsed': toggleFlag}"><span class="bucketOpener"></span></button>
</div>
<div *ngIf="isNpd" class="messageDisplay">{{'ResultsPage.npdOptions' | translate }}</div>
<div *ngIf="!toggleFlag">
        <div class="no-gutters hoverRow" *ngFor="let result of data.results; first as isFirst" [ngClass]="{'rowBorder': !isFirst, 'firstRow': isFirst}" (click)="onSelect(result)">
                <div class="resultRow">
                        <div class="row-title">
                          <span class="icon {{result.documentQualifier === 'G' ? 'GENERIC' : result.type}}"></span>
                          <span class="document-title">{{result.documentTitle }}</span>
                        </div>

                        <div *ngIf="result.documentQualifier === 'G'; else documentOthers">
                          <div class="document-Chunk">
                            <span class="document-title-bold">{{ result.title }}</span>
                          </div>
                          <div class="document-Chunk"> {{ manufacturerListFilter(result.documentChunk) }}</div>
                        </div>

                        <ng-template #documentOthers>
                          <div class="document-Chunk" [innerHTML]="result.documentChunk"></div>
                        </ng-template>
                </div>
        </div>
        <div class="buttonDiv" *ngIf="(maxResults === undefined && data.results.length > 2 && categoryCount>data.results.length) || data.results.length < maxResults"> 
                <button class="showMoreButton" (click)="showMoreResults(data.type, data.qualifier, data.results.length)">{{
                        'ResultsPage.ShowMoreResults' | translate }}</button>
        </div>
        <div class="buttonDiv" *ngIf="data.results.length>=maxResults && (!!maxResults && maxResults > 5)">
                <button class="showMoreButton" (click)="showLessResults(data.type, data.qualifier)">{{
                        'ResultsPage.ShowLessResults' | translate }}</button>
        </div>
</div>
</div>
