import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatalayerService } from 'src/services/datalayer.service';
import { SafePipe } from 'src/app/shared/safe.pipe';

@Component({
  selector: 'app-cps-manufacturer-modal',
  templateUrl: './cps-manufacturer-modal.component.html',
  styleUrls: ['./cps-manufacturer-modal.component.scss']
})
export class CpsManufacturerModalComponent implements OnInit {

  public manuDetail?: any;
  public title?: string;

  constructor(public translationService: TranslateService,
              public bsModalRef: BsModalRef, private cd: ChangeDetectorRef, private datalayerService: DatalayerService) {
    this.manuDetail = null;
    this.title = 'Manufacturer';
  }

  ngOnInit(): void {
    this.datalayerService.userEvent(
      { 'event': 'trackEvent-modalClick',
        'event_name': 'modal_click',
        'modal_type': 'manufacturer_info'
      }
    );
  }

  detectChanges() {
    this.cd.detectChanges();
  }

  typeOf(value) {
    if (typeof (value) === 'object' && Array.isArray(value)) {
      return 'array';
    }
    return typeof (value); // object array string
  }

  hide(): void {
    this.datalayerService.setModal(false)
    this.bsModalRef.hide()  
  }
}
