import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DoSearch, SearchResultActionTypes, LoadSearchResults, DoMoreSearch, DoLessSearch, addMoreResults, addLessResults } from '../actions/search-result.actions';
import { SearchService } from '../search/search.service';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap, map, catchError } from 'rxjs/operators';
import { Result, SearchResult } from '../search/SearchResult.interface';
import { TranslateService } from '@ngx-translate/core';



@Injectable()
export class SearchResultEffects {

  @Effect()
  loadCurrentResult$: Observable<any> = this.actions$.pipe(
    ofType(SearchResultActionTypes.DoSearch),
    switchMap(action =>
      this.searchService.updateResultsWrapper(action.searchText, action.lang).pipe(
        map(data => {
          if (data.totalCount === 0) {
            const nonParticipantResult: Result[] = []; 
            
            this.populateNpdData(data, nonParticipantResult);

            const resultCount: number = nonParticipantResult ?  nonParticipantResult.length : 0;
            this.populateNavigationPath(action, data);
            
            return new LoadSearchResults(resultCount, action.searchText, data.didYouMean, [], [], [], nonParticipantResult, 0, 0, 0, resultCount, null);
          } else {

              const nonParticipantResult: Result[] = [];
              this.populateNpdData(data, nonParticipantResult);

              if (nonParticipantResult.length > 0) {

              const resultCount: number = nonParticipantResult ? nonParticipantResult.length : 0;

              this.populateNavigationPath(action, data);
              return new LoadSearchResults(resultCount, action.searchText, data.didYouMean, [], [], [], nonParticipantResult, 0, 0, 0, resultCount, null);

            } else {
                const resultArray: Result[] = data.results;
                const monographsBucket: Result[] = resultArray.filter(record => record.type === "MONOGRAPH");
                const conditionsBucket: Result[] = resultArray.filter(record => record.type === "CHAPTER" ||
                    record.type === "MA_CHAPTER" || record.type === "CLININFO" || record.type === "PMA");
                const patientInfoBucket: Result[] = resultArray.filter(record => record.type === "IFP" || record.type === "MA_PI");
                const monographCount: number = data.monographCount;
                const conditionsCount: number = data.conditionsCount;
                const patientinfoCount: number = data.patientinfoCount;
                const bucketSwitch: string = data.scope;
                const searchResultData = { ...data };

                if (this.translationService.currentLang === action.lang) { // Don't navigate if the search request is due to a language change
                  this.searchService.adjustNavigation(monographsBucket, conditionsBucket, patientInfoBucket, bucketSwitch,
                  action.searchText, action.searchType, searchResultData);
                } else {
                  this.translationService.use(action.lang);
                }

                const map = new Map(Object.entries(data.subcounts));
                return new LoadSearchResults(resultArray.length, action.searchText, data.didYouMean,
                                         monographsBucket, conditionsBucket, patientInfoBucket, [],
                  monographCount, conditionsCount, patientinfoCount, 0, data.results[0], map);
          }
        }
        }),
        catchError(err => {
          this.searchService.adjustNavigation([], [], [], 'noresult', action.searchText, action.searchType, null);
          return of(new LoadSearchResults(0, action.searchText, '', [], [], [],[], 0, 0, 0, null, 0));
        })
      )
    )
  );

  @Effect()
  loadMoreSerachResult$: Observable<any> = this.actionsMore$.pipe(
    ofType(SearchResultActionTypes.DoMoreSearch),
    switchMap(action =>
      this.searchService.getMoreSerachResults(action.searchText, 
                                              action.start, action.limit, 
                                              action.searchType, action.qualifier).pipe(
        map(data => {
          if (data.totalCount === 0) { 
            this.searchService.adjustNavigation([], [], [], 'noresult', action.searchText, action.searchType, data);
            return new addMoreResults([], [], [],);
          } else {
            const resultArray: Result[] = data.results;
            const monographsBucket: Result[] = resultArray.filter(record => record.type === "MONOGRAPH");
            const conditionsBucket: Result[] = resultArray.filter(record => record.type === "CHAPTER" ||
             record.type === "MA_CHAPTER" || record.type === "CLININFO" || record.type === "PMA");
            const patientInfoBucket: Result[] = resultArray.filter(record => record.type === "IFP" || record.type === "MA_PI");
            const bucketSwitch: string = data.scope;
            this.searchService.adjustNavigation(monographsBucket, conditionsBucket, patientInfoBucket,
              bucketSwitch, action.searchText, action.searchType, data, false);
            return new addMoreResults(monographsBucket, conditionsBucket, patientInfoBucket, action.groupId, data.totalCount);
          }
        }),
        catchError(err => {
          this.searchService.adjustNavigation([], [], [], 'noresult', action.searchText, action.searchType, null);
          return of(new addMoreResults( [], [], []));
        })
      )
    )
  );

  @Effect()
  loadLessSerachResult$: Observable<any> = this.actionsLess$.pipe(
    ofType(SearchResultActionTypes.DoLessSearch),
    switchMap(action =>
      this.searchService.getMoreSerachResults(action.searchText, 
                                              action.start, action.limit, 
                                              action.searchType, action.qualifier).pipe(
        map(data => {
          if (data.totalCount === 0) { 
            this.searchService.adjustNavigation([], [], [], 'noresult', action.searchText, action.searchType, data);
            return new addLessResults([], [], [],);
          } else {
            const resultArray: Result[] = data.results;
            const monographsBucket: Result[] = resultArray.filter(record => record.type === "MONOGRAPH");
            const conditionsBucket: Result[] = resultArray.filter(record => record.type === "CHAPTER" || record.type === "MA_CHAPTER" || record.type === "CLININFO" || record.type === "PMA");
            const patientInfoBucket: Result[] = resultArray.filter(record => record.type === "IFP" || record.type === "MA_PI");
            const bucketSwitch: string = data.scope;
            this.searchService.adjustNavigation(monographsBucket, conditionsBucket, patientInfoBucket,
              bucketSwitch, action.searchText, action.searchType, data, false);

            return new addLessResults(monographsBucket, conditionsBucket, patientInfoBucket);
          }
        }),
        catchError(err => {
          this.searchService.adjustNavigation([], [], [], 'noresult', action.searchText, action.searchType, null);
          return of(new addLessResults( [], [], []));
        })
      )
    )
  );


  constructor(private actions$: Actions<DoSearch>, private searchService: SearchService, private translationService: TranslateService,
              private actionsMore$: Actions<DoMoreSearch>, private actionsLess$: Actions<DoLessSearch>) { }

  populateNavigationPath(action: DoSearch, data: SearchResult) {
    if (this.translationService.currentLang === action.lang) {
      this.searchService.adjustNavigation([], [], [], "noresult", action.searchText, action.searchType, data, true);
    } else {
      this.translationService.use(action.lang);
    }
  }

  populateNpdData(data: SearchResult, nonParticipantResult: Result[]) {
    data.results
      .filter(record => record.type === "NON_PARTICIPANT_DRUG")
      .forEach(result => {
        result.npdLst.forEach(npd => nonParticipantResult.push(npd));
      });
  }
}
