import { Type } from '@angular/core';
import { CpsClininfoDocumentComponent } from 'src/app/document/cps-clininfo-document/cps-clininfo-document.component';
import { CpsConditionDocumentComponent } from 'src/app/document/cps-condition-document/cps-condition-document.component';
import { CpsDirectoryDocumentComponent } from './cps-directory-document/cps-directory-document.component';
import { CpsMenuDocumentComponent } from 'src/app/document/cps-menu-document/cps-menu-document.component';
import { CpsMonographDocumentComponent } from 'src/app/document/cps-monograph-document/cps-monograph-document.component';
import { CpsResourceDocumentComponent } from 'src/app/document/cps-resource-document/cps-resource-document.component';
import { CpsBodyWeightCalculatorComponent } from './cps-body-weight-calculator/cps-body-weight-calculator.component';
import { CpsGenericIndexComponent } from './cps-generic-index/cps-generic-index.component';
import { CpsPatientInfoDocumentComponent } from './cps-patient-info-document/cps-patient-info-document.component';
import { CpsPillIdentifierToolComponent } from './cps-pill-identifier-tool/cps-pill-identifier-tool.component';
import { CpsRenalFunctionCalculatorComponent } from './cps-renal-function-calculator/cps-renal-function-calculator.component';
import { DocumentComponent } from './document-models/documentComponent';
import { NotFoundDocumentComponent } from './not-found-document/not-found-document.component';
import { CpsClininfoToolsComponent } from './cps-clininfo-tools/cps-clininfo-tools.component';
import { CpsLexiPageComponent } from './cps-lexi-page/cps-lexi-page.component';
import { CpsWhatsNewComponent } from './cps-whats-new/cps-whats-new.component';
import { AppConstants } from 'src/utils/app.constants';

export default class DocumentUtils {
    static getDocumentComponentTypeFromString(typeString: string, docID: string): Type<DocumentComponent> {
        switch (typeString) {
            case 'MONOGRAPH':
                return CpsMonographDocumentComponent;
            case 'CHAPTER':
            case 'MA_CHAPTER':
                return CpsConditionDocumentComponent;
            case 'IFP':
            case 'MA_PI':
                return CpsPatientInfoDocumentComponent;
            case 'CLININFO':
                return CpsClininfoDocumentComponent;
            case 'CLININFOTOOL':
                return CpsClininfoToolsComponent;
            case 'DIRECTORY':
                return CpsDirectoryDocumentComponent;
            case 'WHATSNEW':
                return CpsWhatsNewComponent;
            case 'PMA':
            case 'RESOURCE':
                return CpsResourceDocumentComponent;
            case 'MENU':
                return CpsMenuDocumentComponent;
            case 'TOOL':
                if (docID === 'pillid') {
                    return CpsPillIdentifierToolComponent;
                }
                else if (docID === 'bodysurface') {
                    return CpsBodyWeightCalculatorComponent;
                }
                else if (docID === 'renalfunction') {
                    return CpsRenalFunctionCalculatorComponent;
                }
                else if (docID === 'lexi-interact') {
                    return CpsLexiPageComponent;
                }
                return NotFoundDocumentComponent;
            case 'GENERIC':
                return CpsGenericIndexComponent;
            default:
                return NotFoundDocumentComponent;
        }
    }

    static getNotFoundDocumentComponent(): Type<DocumentComponent> {
        return NotFoundDocumentComponent;
    }

    static getDocumentComponentTitleFromID(toolID: string): string {
        switch (toolID) {
            case 'pillid':
                return "Pill Identification";
            default:
                return "Tool title";
        }
    }
    
    static isDocumentTypeRequireHtmlDownload(docType: string): boolean {
        return docType !== AppConstants.DIRECTORY && docType !== AppConstants.MENU_TYPE && docType !== AppConstants.CLININFO_TOOLS_TYPE
            && docType !== AppConstants.NOT_FOUND_TYPE && docType !== AppConstants.TOOLS_TYPE && docType !== AppConstants.GENERIC_TYPE;
    }
}
