<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="true" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML">
    </app-cps-tool-bar>
    <div class="tool-wrapper">
        <div class="row pidTool" #docWrapper>
            <!-- Skeleton-loader for desktop view -->
            <div class="skeleton-wrapper" *ngIf="!contentLoaded && !mobileView">
                <div>
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '280px', height: '450px', 'border-radius': '10px' }">
                    </ngx-skeleton-loader>
                </div>
                <div class="PID-Search-Inner-Container">

                    <div class="skeleton-wrapper-items" *ngIf="!mobileView">
                        <div *ngIf="!contentLoaded" class="pidMainTitle">
                            <ngx-skeleton-loader
                                [theme]="{width: '30%', height: '35px', 'border-radius': '10px', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                        </div>
                        <div *ngIf="!contentLoaded" class="intro">
                            <ngx-skeleton-loader count="2" [theme]="{width: '100%', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                        </div>
                        <div *ngIf="!contentLoaded" class="intro">
                            <ngx-skeleton-loader appearance="circle" count="2"
                                [theme]="{width: '80px', height: '80px', 'margin-left':'10px'}"></ngx-skeleton-loader>
                        </div>
                        <div *ngIf="!contentLoaded" class="intro">
                            <ngx-skeleton-loader [theme]="{width: '25%', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                            <ngx-skeleton-loader count="3" [theme]="{width: '100%', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Skeleton-loader for mobile devices -->
            <div *ngIf="!contentLoaded && mobileView">
                <div>
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '280px', height: '450px', 'border-radius': '10px' }">
                    </ngx-skeleton-loader>
                </div>
                <div class="PID-Search-Inner-Container">

                    <div class="skeleton-wrapper-border-mobile" *ngIf="mobileView">
                        <div *ngIf="!contentLoaded" class="pidMainTitle">
                            <ngx-skeleton-loader
                                [theme]="{width: '30%', height: '35px', 'border-radius': '10px', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                        </div>
                        <div *ngIf="!contentLoaded" class="intro">
                            <ngx-skeleton-loader count="2" [theme]="{width: '100%', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                        </div>
                        <div *ngIf="!contentLoaded" class="intro">
                            <ngx-skeleton-loader appearance="circle" count="2"
                                [theme]="{width: '80px', height: '80px', 'margin-left':'10px'}"></ngx-skeleton-loader>
                        </div>
                        <div *ngIf="!contentLoaded" class="intro">
                            <ngx-skeleton-loader [theme]="{width: '25%', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                            <ngx-skeleton-loader count="3" [theme]="{width: '100%', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="contentLoaded" class="col-sm-12 col-md-12 col-lg-3 col-xl-3 PID-Search-Container"
                [ngClass]="{'enableFilter': switchScreen}" #filterPanel>
                <div class="PID-Search-Inner-Container">
                    <div class="row">
                        <div class="col filterHeading">
                            <div class="searchFilterHeading">{{'PID.searchFilters' | translate}}</div>
                            <button class="clearButton" (click)="clearAll()">{{'PID.clearAll' | translate}}</button>
                        </div>
                    </div>
                    <form [formGroup]="pidForm">
                        <div class="row">

                            <!-- BRAND NAME -->
                            <div class="col-12">
                                <div class="filterField">
                                    <input id="brandName" formControlName="brandNameField" type="text"
                                        class="form-control placeholder" placeholder="{{'PID.brandName' | translate}}"
                                        autocomplete="off">
                                    <span *ngIf="brandNameFieldClearButton" class="crossButton"
                                        (click)="clearText('brandNameField')"><span class="x-close-icon"></span></span>
                                </div>
                            </div>
                            <!-- ENTER IMPRINT -->
                            <div class="col-12">
                                <div class="filterField">
                                    <input id="imprint" formControlName="imprintField" type="text"
                                        class="form-control placeholder"
                                        placeholder="{{'PID.enterImprint' | translate}}" autocomplete="off">
                                    <span *ngIf="imprintFieldClearButton" class="crossButton"
                                        (click)="clearText('imprintField')"><span class="x-close-icon"></span></span>
                                </div>
                            </div>
                            <!-- DOSAGE FORM -->
                            <div class="col-12">
                                <div ngbDropdown class="d-inline-block dropDownField">
                                    <button type="button" class="btn filterFieldDrpDwn" id="dosageFormDropdown"
                                        ngbDropdownToggle>
                                        <div class="dosageFormValue">
                                            <span
                                                [ngClass]="{'hideText': selectedFormType === null}">{{'PID.'+selectedFormType
                                                |
                                                translate}}</span>
                                            <span class="labelOverflow"
                                                [ngClass]="{'hideText': selectedFormType != null}">{{'PID.selectDosageForm'
                                                |
                                                translate}}</span>
                                        </div>
                                        <div class="downArrow">
                                            <span class="pidDropdwnArrow"></span>
                                        </div>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dosageFormDropdown">
                                        <button ngbDropdownItem (click)="selectDosageForm('none')">{{'PID.noneSelected'
                                            |
                                            translate}}</button>
                                        <button ngbDropdownItem (click)="selectDosageForm('capsule')">{{'PID.capsule' |
                                            translate}}</button>
                                        <button ngbDropdownItem (click)="selectDosageForm('tablet')">{{'PID.tablet' |
                                            translate}}</button>
                                    </div>
                                </div>
                            </div>

                            <!-- SELECT SHAPE -->
                            <div class="col-12">
                                <div ngbDropdown class="d-inline-block dropDownField">
                                    <button type="button" class="btn filterFieldDrpDwn" id="shapeDropdown"
                                        ngbDropdownToggle #shapedrpdn [disabled]="selectedFormType === 'capsule'">
                                        <div class="shapeContainer">
                                            <span [ngClass]="{'hideText': shapeSelected === null}"
                                                class="{{shapeSelected}} selectedShapeImage"></span>
                                            <span [ngClass]="{'hideText': shapeSelected === null}"
                                                class="selectedShapeText">{{'PID.'+shapeSelected | translate}}</span>
                                            <span class="shapeLabel"
                                                [ngClass]="{'hideText': shapeSelected != null}">{{'PID.selectShape' |
                                                translate}}</span>
                                        </div>
                                        <div class="downArrow">
                                            <span class="pidDropdwnArrow"></span>
                                        </div>
                                    </button>
                                    <div ngbDropdownMenu class="shapeDropdown" aria-labelledby="dropdownBasic2">

                                        <button #deselectShape class="dropdown-item-shape-noneSelected"
                                            id="deselectShape" ngbDropdownItem
                                            (click)="selectShape(this.deselectShape.id)"><span
                                                class="noColorSelected">{{'PID.noneSelected' |
                                                translate}}</span></button>

                                        <button *ngFor="let shape of shapesList" ngbDropdownItem
                                            (click)="selectShape(shape)"
                                            [ngClass]="{'highlightColour': shapeSelected===shape}">
                                            <div class="shapeImg {{shape}}"></div>
                                            <div class="shapeText">{{'PID.'+shape | translate}}</div>
                                        </button>

                                    </div>
                                </div>
                            </div>

                            <!-- SELECT COLOUR -->
                            <div class="col-12">
                                <div ngbDropdown class="d-inline-block dropDownField">

                                    <button type="button" class="btn filterFieldDrpDwn colourArea" id="colourDropdown"
                                        ngbDropdownToggle #clrdrpdn>
                                        <div class="dosageFormValue" aria-label="colourDropdown">
                                            <span
                                                [ngClass]="{'hideText': selectedColorsArray.length>0}">{{'PID.selectColour'
                                                |
                                                translate}}</span>
                                            <div *ngFor="let color of selectedColorsArray | slice:0:7"
                                                class="colourPreview" [ngClass]=color></div>
                                        </div>
                                        <div class="downArrow">
                                            <span class="pidDropdwnArrow"></span>
                                        </div>
                                    </button>

                                    <div ngbDropdownMenu class="colourDropdown" aria-labelledby="dropdownBasic2">

                                        <button #deselectColor class="dropdown-item-color-noneSelected"
                                            id="deselectColor" ngbDropdownItem
                                            (click)="selectColour(this.deselectColor.id)"><span
                                                class="noColorSelected">{{'PID.clearSelection' |
                                                translate}}</span></button>
                                        <div class="colorHeading">{{'PID.selectAll' | translate}}</div>

                                        <button *ngFor="let color of colorsAvailable" class="colorField" ngbDropdownItem
                                            (click)="selectColour(color)"
                                            [ngClass]="{'highlightColour': selectedColorsArray.indexOf(color)>=0}">
                                            <span class="colourImage {{color}}"></span><span
                                                class="colourText">{{'PID.'+color |
                                                translate}}</span>
                                        </button>

                                    </div>
                                </div>
                            </div>

                            <!-- SCORED -->
                            <div class="col-12">
                                <div ngbDropdown class="d-inline-block dropDownField">
                                    <button type="button" class="btn filterFieldDrpDwn" id="scoredDropdown"
                                        ngbDropdownToggle [disabled]="selectedFormType === 'capsule'">
                                        <div class="dosageFormValue">
                                            <span [ngClass]="{'hideText': scoredOrNot === null}">{{'PID.'+scoredOrNot |
                                                translate}}</span>
                                            <span [ngClass]="{'hideText': scoredOrNot != null}">{{'PID.scored' |
                                                translate}}</span>
                                        </div>

                                        <div class="downArrow">
                                            <span class="pidDropdwnArrow"></span>
                                        </div>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="scoredDropdown">
                                        <button ngbDropdownItem (click)="isScored('none')">{{'PID.noneSelected' |
                                            translate}}</button>
                                        <button ngbDropdownItem (click)="isScored('yes')">{{'PID.yes' |
                                            translate}}</button>
                                        <button ngbDropdownItem (click)="isScored('no')">{{'PID.no' |
                                            translate}}</button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="centerPosition">
                                  <button class="applyButton"
                                        [ngClass]="[isActive ? 'isActive activeApplyButton' : 'inactiveApplyButton']"
                                        (disabled)="!isActive" (click)="searchDrug(true)">
                                        <div class="applyLabel">{{ 'PID.apply' | translate }}</div>
                                  </button>
                                </div>

                                <!--  <div class="applyButton" [ngClass]="{'isActive': isActive}" (disabled)="!isActive" (click)="searchDrug(true)">
                                <div class="applyLabel">Apply</div>
                            </div> -->
                            </div>

                            <div class="col-12" *ngIf="resultList!=null && mobileView">
                                <button class="resultButton" (click)="switchScreens()">
                                    <div class="viewResultLabel">View {{resultList.totalCount}} results</div>
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9 resultPanel">

                <div class="col-12 stickyClass" *ngIf="mobileView && switchScreen">
                    <div class="filterButton" (click)="switchScreens()">
                        <div class="filterLabel">Search Filters ({{activeFilterCount}})</div>
                    </div>
                </div>
                <h3 *ngIf="contentLoaded" id="pidTitle" class="pidMainTitle">{{'PID.title' | translate}}</h3>

                <!-- DEFAULT PID INTRO SCREEN -->
                <ng-template [ngIf]="resultList==null">
                    <div class="toolIntro" *ngIf='activeFilterCount==0 && contentLoaded'>
                        <div class="intro">{{'PID.intro' | translate}}</div>
                        <div class="imageExampleq"><img class="pidImage" aria-label="pidImage" src="assets/images/pills.png"></div>
                        <div class="bestResults">{{'PID.bestResults' | translate}}</div>
                        <div class="hint">
                            <ol>
                                <li>{{'PID.hint1' | translate}}</li>
                                <li [innerHTML]="'PID.hint2' | translate"></li>
                                <li>{{'PID.hint3' | translate}}</li>
                            </ol>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngIf]="resultList!=null">
                    <app-cps-pid-item [trackEventPIDRequestData]="pidRequest" [pidItem]="pillData"
                        *ngFor="let pillData of resultList.results" class="pidBlock"></app-cps-pid-item>
                    <div class="buttonDiv">
                        <button class="showMoreButton" (click)="showMoreResults()"  *ngIf='resultList.results.length < resultList.totalCount' >
                          {{'ResultsPage.ShowMoreResults' |
                            translate }}</button>
                    </div>
                    <div class="messageDiv" *ngIf='searchPerformed && resultList.totalCount==0'>
                      <p>{{'PID.error-noResult' | translate}}</p>
                    </div>
                </ng-template>

                <ng-template [ngIf]="searchPerformed && resultList==null">
                    <div class="messageDiv">
                        <p>{{'PID.error-noResult' | translate}}</p>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
