import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpsMonographMessagesComponent } from './cps-monograph-messages/cps-monograph-messages.component';
import { CpsAdvisoriesPanelComponent } from './cps-advisories-panel/cps-advisories-panel.component';
import { CpsNotificationsPanelComponent } from './cps-notifications-panel/cps-notifications-panel.component';
import { CpsShortagesPanelComponent } from './cps-shortages-panel/cps-shortages-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { CpsImportantMessagesComponent } from './cps-important-messages/cps-important-messages.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CpsContactManufacturerComponent } from './cps-contact-manufacturer/cps-contact-manufacturer.component';



@NgModule({
  declarations: [
    CpsMonographMessagesComponent, 
    CpsAdvisoriesPanelComponent, 
    CpsNotificationsPanelComponent, 
    CpsShortagesPanelComponent, 
    CpsImportantMessagesComponent,
    CpsContactManufacturerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule
  ],
  exports: [
    CpsMonographMessagesComponent, 
    CpsAdvisoriesPanelComponent, 
    CpsNotificationsPanelComponent, 
    CpsShortagesPanelComponent, 
    CpsImportantMessagesComponent,
    CpsContactManufacturerComponent
  ]
})
export class MonographModule { }
