<div class="print-wrapper">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="printPageCpsLogo"><img [src]="'/assets/images/cps-logo.svg'"></div>
        <div class="skipPrinting menuItemsPanel {{data.doc.type}}">
            <div *ngIf="menuItemsList.length > 0" class="itemsChecklist">
                <div class="menuPanelHeading">{{ 'Print.itemsToPrint' | translate}}</div>
                <div class="printOptions">
                    <label class="item-container">{{ 'Print.selectAll' | translate}}
                        <input type="checkbox" (change)="setCheckboxes($event)" checked="checked">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="printMenu">
                    <div class="printMenuItems" *ngFor="let menuItem of menuItemsList">
                        <label class="item-container">{{menuItem.text}}
                            <input type="checkbox" [value]="menuItem.href" (change)="onCheckboxChange($event)" checked="checked">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="printElement">
                <div class="printButton" (click)="printDocument()">{{ 'Print.label' | translate}}</div>
            </div>
        </div>
        <div [innerHTML]="htmlElement.innerHTML | safe: 'html'"></div>

        <div class="printFootnote">
            <div>
                <span *ngIf="data.doc.type === 'MONOGRAPH'">{{ 'disclaimers.MONOGRAPH_'+ data.docQualifier | translate}}</span>
                <span *ngIf="data.doc.type !== 'MONOGRAPH'">{{ 'disclaimers.'+data.doc.type | translate}}</span>
                <span>[{{ 'Print.footerPrintedon' | translate}} {{today | date:'MM/dd/yy hh:mm a'}}]</span>
            </div>
            <div>{{ 'Print.cpha.name.rxtx' | translate}}<ng-template [ngIf]="printName">: {{ 'Print.cpha.name.'+data.doc.type | translate}}</ng-template>
              {{'Print.cpha.copyright1' | translate}}, {{today | date:'y'}}. {{ 'Print.cpha.copyright2' | translate}}
            </div>
        </div>
    </div>
</div>
