import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { SearchService } from 'src/app/search/search.service';
import { AppConstants } from '../utils/app.constants';
import { LocalStorage } from '../utils/local-storage';
import { DatalayerService } from './datalayer.service';
import { DocumentService } from './document.service';

@Injectable()
export class HeaderService {

  private loggedIn: BehaviorSubject<boolean>;
  private searchBarCondensedObservable$: BehaviorSubject<boolean>;

  constructor(private localStorage: LocalStorage, private documentService: DocumentService,
              private searchService: SearchService, public router: Router) {
    if (LocalStorage.getLoggedIn() == 'true') {
      this.loggedIn = new BehaviorSubject<boolean>(true);
    } else {
      this.loggedIn = new BehaviorSubject<boolean>(false);
    }
    this.searchBarCondensedObservable$ = new BehaviorSubject<boolean>(false);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/login')) {
          this.loggedIn.next(false);
        } else {
          this.loggedIn.next(true);
        }
      }
    });
  }

  getLogin(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  public changeLang(translationService: TranslateService, currentLanguage: string, localStorage: LocalStorage, storedScrollPercentage: number=null): string {

    const newLang = currentLanguage === AppConstants.ENGLISH_CODE ? AppConstants.FRENCH_CODE : AppConstants.ENGLISH_CODE;
    localStorage.setPreferredLang(newLang);

    translationService.use(newLang);

    this.searchService.clearSearchTermAndResults();
    return this.getLanguageText(newLang);
  }

  public setLang(translationService: TranslateService, currentLanguage: string, localStorage: LocalStorage): string {
    translationService.use(currentLanguage === AppConstants.ENGLISH_CODE
      ? AppConstants.ENGLISH_CODE : AppConstants.FRENCH_CODE);

    currentLanguage = translationService.currentLang;
    localStorage.setPreferredLang(currentLanguage);

    return this.getLanguageText(currentLanguage);
  }

  getLanguageText(currentLanguage: string) : string {
    return currentLanguage === AppConstants.ENGLISH_CODE
    ? AppConstants.FRENCH_CODE.toUpperCase() : AppConstants.ENGLISH_CODE.toUpperCase();
  }

  public setSearchBarCondensed(isSearchCondensed: boolean): void {
    this.searchBarCondensedObservable$.next(isSearchCondensed);
  }

  public getsearchBarCondensedObservable$(): Observable<boolean> {
    return this.searchBarCondensedObservable$.asObservable();
  }

  public navigateToHomeIfUserInSearchResult(): void {
    if (this.loggedIn.value && this.router.url.indexOf('/search/') > -1) {
      this.searchService.reloadHome();
    }
  }
}
