import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatalayerService } from 'src/services/datalayer.service';
import { HomeService } from 'src/services/home.service';
import { AppConstants } from 'src/utils/app.constants';
import { Feature, ToolsArray } from './feature.interface';

@Component({
  selector: 'app-cps-home-feature',
  templateUrl: './cps-home-feature.component.html',
  styleUrls: ['./cps-home-feature.component.scss']
})
export class CpsHomeFeatureComponent implements OnInit {

  @Input()
  private feature: Feature;

  private currentLanguage: string;

  public img: string;
  public title: string;
  public text: string;
  public link: string;
  public route: string;
  public linkID: string;
  public toolsArray: ToolsArray[];

  constructor(public translationService: TranslateService, public homeService: HomeService, private datalayerService: DatalayerService) {

    this.currentLanguage = this.translationService.currentLang;
  }

  ngOnInit(): void {

    if (this.currentLanguage === AppConstants.ENGLISH_CODE) {

      this.title = this.feature.en.title;
      this.img = this.feature.en.img;
      this.text = this.feature.en.text;
      this.link = this.feature.en.linkText;
      this.route = this.feature.en.link;
      this.linkID = this.feature.en.linkID;
      this.toolsArray = this.feature.en.toolsArray;

    } else {

      this.title = this.feature.fr.title;
      this.img = this.feature.fr.img;
      this.text = this.feature.fr.text;
      this.link = this.feature.fr.linkText;
      this.route = this.feature.fr.link;
      this.linkID = this.feature.fr.linkID;
      this.toolsArray = this.feature.fr.toolsArray;
    }
  }

  homePageClickEvent(value: string): void {
    let [category, subcategory] = value.split('_');
    this.datalayerService.homePageClickEvent(category, subcategory)
  }
}
