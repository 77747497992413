import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, Observable } from "rxjs";
import { DocumentService } from "src/services/document.service";
import { AddGenericList, GenericListActionTypes, LoadGenericList } from "../actions/generic-list.actions";
import { GenericList } from "../document/cps-generic-index/genericList.interface";
import { FrenchAccentNormalizer } from "../utility/Objects/frenchNormalizationMap";

@Injectable()
export class GenericListEffects {

    // eslint-disable-next-line no-control-regex
    private english = /[^\u0000-\u007f]/;

    private fan: FrenchAccentNormalizer;

    constructor(private actions$: Actions<LoadGenericList>, private documentService: DocumentService) {
        this.fan = new FrenchAccentNormalizer();
    }

    @Effect()
    populateMenuDocuments$: Observable<any> = this.actions$.pipe(
        ofType(GenericListActionTypes.LoadGenericList),
            mergeMap(() =>
                this.documentService.getGenerics().pipe(
                    map(value => {
                        
                        return new AddGenericList(this.sortList(value));
                    }),
                    catchError(() => EMPTY)
                )
            )
    );

    sortList(value: GenericList): GenericList {

        const genericList = value.results;

        // The following sorts the numeric entries to the back of the list by splitting the list in two, sorting each part and then recombining, alpha first numbers second
        const numberList = [];
        const alphaList = [];

        for (let j = 0; j < genericList.length; j++) {

            const genericTitle = genericList[j].title;

            const firstLetter = genericTitle.substring(0, 1);

            if ((Number(firstLetter) >= 0 && Number(firstLetter) <= 9) || this.english.test(this.normalizeTitle(firstLetter))) { // If non-Latin character (i.e. Greek) put it in with the numbers
                numberList.push(genericList[j]);
            } else {
                alphaList.push(genericList[j]);
            }
        }

        numberList.sort((a, b) => {
            const t = a.title.toLowerCase();
            const v = b.title.toLowerCase();

            if (t < v) {return -1; }
            if (t > v) {return 1; }
            return 0;
        });

        alphaList.sort((a, b) => {
            const o = this.normalizeTitle(a.title.toLowerCase());
            const m = this.normalizeTitle(b.title.toLowerCase());

            if (o < m) { return -1; }
            if (o > m) { return 1; }
            return 0;
        });

        const sortedList = [];

        for (let x = 0; x < alphaList.length; x++) {
            sortedList.push(alphaList[x]);
        }

        for (let y = 0; y < numberList.length; y++) {
            sortedList.push(numberList[y]);
        }

        value.results = sortedList;

        return value;
    }

    normalizeTitle(title: string): string {

        return this.fan.frenchAccentNormalizer(title);

    }

}