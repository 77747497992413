import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { DocumentComponent } from 'src/app/document/document-models/documentComponent';

@Component({
  selector: 'app-cps-content-page',
  templateUrl: './cps-content-page.component.html',
  styleUrls: ['./cps-content-page.component.scss']
})
export class CpsContentPageComponent implements OnInit, DocumentComponent {
  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;

  public doc: DocumentItem;

  constructor(public translationService: TranslateService) {

  }

  ngOnInit(): void {
  }

}
