import { SearchResultActions, SearchResultActionTypes } from '../actions/search-result.actions';
import { Result } from '../search/SearchResult.interface';


export const searchResultFeatureKey = 'searchResultKey';

export interface SearchResultState {
  resultCount: number;
  searchTerm: string;
  lastSearchterm: String;
  didYouMean: string;
  monographs: Result[];
  conditions: Result[];
  patientInfo: Result[];
  npd: Result[];
  monographCount: number;
  conditionsCount: number;
  patientinfoCount: number;
  npdCount: number;
  groupMaxResultCounts: any;
  subcounts: Map<string, number>;
  mostRelevantResult: any;
}

export const initialState: SearchResultState = {
  resultCount: 0,
  searchTerm: "",
  lastSearchterm: "",
  didYouMean: "",
  monographs: [],
  conditions: [],
  patientInfo: [],
  npd: [],
  monographCount: 0,
  conditionsCount: 0,
  patientinfoCount: 0,
  groupMaxResultCounts: {},
  npdCount: 0,
  subcounts: new Map(),
  mostRelevantResult: {}
};

export function searchResultReducer(state = initialState, action: SearchResultActions): SearchResultState {
  switch (action.type) {
    case SearchResultActionTypes.LoadSearchResults:
      return { ...state, resultCount: action.resultCount, searchTerm: action.searchTerm,lastSearchterm: action.searchTerm, didYouMean: action.didYouMean, 
                         monographs: action.monographs, conditions: action.conditions, patientInfo: action.patientInfo,
                         monographCount: action.monographCount, conditionsCount: action.conditionsCount,
                         patientinfoCount: action.patientinfoCount, npd : action.npd, npdCount: action.npdCount,
                         subcounts: action.subcounts, mostRelevantResult: action.mostRelevantResult};
    case SearchResultActionTypes.RemoveSearchResults:
      return { ...state, resultCount: 0, searchTerm: "", monographs: [], conditions: [], patientInfo: [],
                         monographCount: 0, conditionsCount: 0, patientinfoCount: 0 };
    case SearchResultActionTypes.DoSearch:
      return state;
    case SearchResultActionTypes.DoMoreSearch:
      return state;
    case SearchResultActionTypes.addMoreResults:
      if (!!action.groupId && action.groupId !== '') {
        state.groupMaxResultCounts[action.groupId] = action.maxResults;
      }
      if(action.monographs.length > 0) {
              return {...state, monographs: [...state.monographs, ...action.monographs]};
      } else if(action.conditions.length > 0) {
              return {...state, conditions: [...state.conditions, ...action.conditions]};
      } else if(action.patientInfo.length > 0) {
              return {...state, patientInfo: [...state.patientInfo, ...action.patientInfo]};
      }
      return {...state};
    case SearchResultActionTypes.addLessResults:
      if(action.monographs.length > 0) {
              const qual = action.monographs[0].documentQualifier;
              const filteredMonographs = state.monographs.filter((mono) => mono.documentQualifier !=qual);
              return {...state, monographs: [...filteredMonographs, ...action.monographs]};
      } else if(action.conditions.length > 0) {
              const scope = action.conditions[0].type;
              const filteredConditions = state.conditions.filter((cond) => cond.type != scope);
              return {...state, conditions: [...filteredConditions, ...action.conditions]};
      } else if(action.patientInfo.length > 0) {
              const scope = action.patientInfo[0].type;
              const filteredPIs = state.patientInfo.filter((pi) => pi.type != scope)
              return {...state, patientInfo: [...filteredPIs, ...action.patientInfo]};
      }
      break;
    case SearchResultActionTypes.setMaxCountForGroup:
      state.groupMaxResultCounts[action.groupId] = action.maxResults;
      return {...state};
    case SearchResultActionTypes.clearMaxResultCountMap:
      state.groupMaxResultCounts = {};
      return {...state};
    default:
      return state;
  }
}

export function reducer(state: SearchResultState, action: SearchResultActions): SearchResultState {
  return searchResultReducer(state, action);
}
