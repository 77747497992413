import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, Observable } from 'rxjs';
import { HeaderService } from 'src/services/header.service';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from 'src/services/auth-services/auth.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

    constructor(private headerService: HeaderService,
                private authService: AuthService,
                private router: Router) {
        // Empty
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
            return next.handle(req).pipe(tap((response: HttpResponse<any>) => {

                // Empty. Not sure what we're actually doing here.
            }));
        }
}
