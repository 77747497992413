import { Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { PIDRequest, PIDResult } from './PIDResult.interface';
import { DocumentComponent } from '../document-models/documentComponent';
import { debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { PidService } from './pid.service';
import { DocumentItem } from '../document-models/document-item';
import { DatalayerService } from 'src/services/datalayer.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { AddResults, ClearResults, SetResults } from 'src/app/actions/pill-id-result.actions';
import { DocumentService } from 'src/services/document.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConstants } from 'src/utils/app.constants';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-cps-pill-identifier-tool',
  templateUrl: './cps-pill-identifier-tool.component.html',
  styleUrls: ['./cps-pill-identifier-tool.component.scss']
})
export class CpsPillIdentifierToolComponent implements OnInit, OnDestroy, DocumentComponent {

  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;
  public contentLoaded = false;

  @ViewChild('filterPanel') filterPanell: ElementRef;
  
  pidForm: FormGroup;
  brandNameField: FormControl;
  imprintField: FormControl;

  dosageFormDefault = 'Select Dosage Form';
  selectedFormType = null;
  scoredOrNot = null;
  shapeSelected = null;
  colorPlaceHolder = 'Select Colour';
  brand: string = null;
  imprint: string = null;
  isActive = false;
  brandNameFieldClearButton = false;
  imprintFieldClearButton = false;
  
  selectedColorsArray: string[] = [];
  colorsAvailable = ['speckl', 'white', 'offWhite', 'blue', 'pink', 'purple', 'yellow', 'orange', 'brown', 'tan', 'red', 'redbrown', 'green', 'teal', 'turquoise', 'peach', 'grey', 'black', 'clear'];
  shapesList = ['round', 'oblong', 'oval', 'square', 'rectangle', 'threesided', 'fivesided', 'sixsided', 'eightsided', 'teardrop', 'doublecircle', 'dshaped', 'diamond', 'gear', 'shield', 'character'];
  sidedStringMap = {threesided: '3sided', fivesided: '5sided', sixsided: '6sided', eightsided: '8sided'};
  activeFilterCount = 0;
  limit = 10;
  start = 0;
  page = 1;
  end: number = this.limit;
  pidRequest: PIDRequest = null;

  mobileView = false;
  switchScreen = false;

  resultList: PIDResult = null;
  hasUserInteractedWithTool: boolean;

  public searchPerformed: boolean = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private checkContentLoadedTimeout: any;

  disableDefaultComparator() {
    return 0;
  }

  constructor(public translationService: TranslateService,
              private formBuilder: FormBuilder,
              private elem: ElementRef,
              private pidService: PidService,
              private datalayerService: DatalayerService,
              private documentService: DocumentService,
              private store: Store<State>) {
    fromEvent(window, 'resize').pipe(debounceTime(75)).pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.onResize();
    });
  }

  ngOnInit(): void {
    this.detectScreenSize();

    this.hasUserInteractedWithTool = false;
    this.brandNameField = new FormControl();
    this.imprintField = new FormControl();

    this.pidForm = this.formBuilder.group(
      {
        'brandNameField': this.brandNameField,
        'imprintField': this.imprintField
      }
    );

    this.pidForm.get('brandNameField').valueChanges
    .pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null && data.length > 0) {
        this.brand = data;
        this.brandNameFieldClearButton = true;
      } else {
        this.brand = null;
        this.brandNameFieldClearButton = false;
      }
      this.isActive = true;
    });

    this.pidForm.get('imprintField').valueChanges
    .pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null && data.length > 0) {
        this.imprint = data;
        this.imprintFieldClearButton = true;
      } else {
        this.imprint = null;
        this.imprintFieldClearButton = false;
      }
      this.isActive = true;
    });

    this.store.select(state => state.pillIdResultKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe(action => {
        this.resultList = action.pidResult;

        this.pidForm.get('brandNameField').setValue(action.brandName);
        this.pidForm.get('imprintField').setValue(action.imprint);

        this.selectedFormType = action.selectedFormType;
        this.shapeSelected = action.shapeSelected;
        this.selectedColorsArray = action.selectedColorsArray;
        this.scoredOrNot = action.scoredOrNot;

        this.limit = action.limit;
        this.start = action.start;
        this.page = action.page;
      });
    
      this.checkContentLoadedTimeout = setTimeout(() => {
        this.checkContentLoaded();
      }, 200); 
  }

  clearText(formFieldName: string) {
      this.pidForm.get(formFieldName).setValue('');
      if(formFieldName === 'brandNameField'){
        this.brandNameFieldClearButton = false;
      }else if(formFieldName === 'imprintField'){
        this.imprintFieldClearButton = false;
      }
  }

  private onResize(): void {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    if (window.innerWidth < 420) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  switchScreens() {
    this.switchScreen = !this.switchScreen;
  }

  selectDosageForm(dosageForm: string) {
    if (dosageForm !== 'none') {
      this.selectedFormType = dosageForm;
      if (dosageForm === 'capsule') {
        this.shapeSelected = null;
        this.scoredOrNot = null;
      }
    } else {
      this.selectedFormType = null;
    }
    this.searchDrug(true);
  }

  isScored(scored: string) {
    if (scored !== 'none') {
      this.scoredOrNot = scored;
    } else {
      this.scoredOrNot = null;
    }
    this.searchDrug(true);
  }

  selectShape(shapeID) {
    this.shapeSelected = shapeID;
    if (shapeID !== 'deselectShape') {
      this.shapeSelected = shapeID;
    } else {
      this.shapeSelected = null;
    }
    this.searchDrug(true);

  }

  selectColour(colour) {
    if (colour === 'deselectColor') {
      this.selectedColorsArray = [];
    } else if (this.selectedColorsArray.indexOf(colour) < 0) {
      this.selectedColorsArray.push(colour);
    } else {
      this.selectedColorsArray = this.selectedColorsArray.filter(clr => clr !== colour);
    }
    this.searchDrug(true);
  }

  showMoreResults(): void {
    this.end += this.limit;
    // implementing rxtx logic
    this.start += this.limit;
    this.searchDrug(false);
    this.datalayerService.pidShowMoreEvent();
  }

  searchDrug(newSearch: boolean) {
    this.searchPerformed = true;
    this.onToolInteractionEvent();
    if (newSearch) {
      this.limit = 10;
      this.start = 0;
      this.page = 1;
    }

    let shapeString = this.shapeSelected;
    if (!!this.shapeSelected && this.shapeSelected.indexOf('sided') > -1) {
      shapeString = this.sidedStringMap[this.shapeSelected];
    }

    let pidSearchRequest = {
      "request": {
        "brandValue": this.brand,
        "imprintValue": this.imprint,
        "dosageForm": this.selectedFormType,
        "shape": shapeString,
        "colours": this.selectedColors(this.selectedColorsArray),
        "score": this.scoredOrNot,
        "limit": this.limit,
        "start": this.start,
        "page": this.page,
      }
    };
    this.pidRequest = pidSearchRequest.request;
    this.isActive = false;
    this.activeFilterCount = 0;

    Object.keys(pidSearchRequest.request).forEach(key => {
      if(key!="limit" && key!="start" && key!="page" && pidSearchRequest.request[key]!=null){
        this.activeFilterCount++;
      }
    })

    if (this.activeFilterCount == 0) {
      this.resultList = null;
    } else {
      this.pidService.getPIDSearchResults(pidSearchRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          if (!newSearch) {
            this.store.dispatch(new AddResults(data.results, this.limit, this.start, this.page));
            
          } else {
            this.store.dispatch(new SetResults(data, this.brand, this.imprint, this.selectedFormType, this.shapeSelected,
              this.selectedColorsArray, this.scoredOrNot, this.limit, this.start, this.page));
          }
        }, error => {
            if((error.httpStatus == AppConstants.HTTP_STATUS_NOT_FOUND && error.message === AppConstants.RECORD_NOT_FOUND) || (error.error.httpStatus == AppConstants.HTTP_STATUS_NOT_FOUND && error.error.message === AppConstants.RECORD_NOT_FOUND)) {
                this.resultList = null;
            }
        });
    }

  }

  selectedColors(colorsArray: string[]) {
    this.onToolInteractionEvent();
    if (colorsArray != null && colorsArray.length > 0) {
      return colorsArray.toString();
    } else {
      return null;
    }
  }

  clearAll() {
    this.pidForm.get('brandNameField').setValue('');
    this.pidForm.get('imprintField').setValue('');
    this.selectedFormType = null;
    this.shapeSelected = null;
    this.selectedColorsArray = [];
    this.scoredOrNot = null;
    this.activeFilterCount = 0;
    this.resultList = null;
    this.isActive = false;
    this.store.dispatch(new ClearResults());
    this.searchPerformed=false;
  }

  onToolInteractionEvent(): void {
    if (!this.hasUserInteractedWithTool) {
      this.datalayerService.toolInteractionEvent('pidtool');
      this.hasUserInteractedWithTool = true;
    }
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    clearTimeout(this.checkContentLoadedTimeout);
  }

}
