import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CpsSearchPanelComponent } from './cps-search-panel/cps-search-panel.component';
import { CpsSearchResultSwitchesComponent } from './cps-search-result-switches/cps-search-result-switches.component';
import { CpsSearchSuggestionsComponent } from './cps-search-suggestions/cps-search-suggestions.component';
import { CpsResultsGroupComponent } from './cps-results-group/cps-results-group.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

/* const routes: Routes = [
  { path: 'searchswitches', component: CpsSearchResultSwitchesComponent }
];
 */
@NgModule({
  declarations: [CpsSearchPanelComponent, CpsSearchResultSwitchesComponent, CpsSearchSuggestionsComponent, CpsResultsGroupComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    NgbModule
  ],
  exports: [
    CpsSearchPanelComponent,
    CpsSearchResultSwitchesComponent,
    CpsSearchSuggestionsComponent,
    CpsResultsGroupComponent
  ]
})
export class SearchModule { }
