/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { interval } from 'rxjs';
import { AuthService } from 'src/services/auth-services/auth.service';

@Component({
  selector: 'app-cps-lost-connection-modal',
  templateUrl: './cps-lost-connection-modal.component.html',
  styleUrls: ['./cps-lost-connection-modal.component.scss']
})

// tslint:disable: max-line-length
export class CpsLostConnectionModalComponent implements OnInit {

  public desiredRoute;
  public isAuthenticated;
  public countDown = 10;
  public attemps = 1;
  public reconnected = false;

  constructor(public translationService: TranslateService, public bsModalRef: BsModalRef, private cd: ChangeDetectorRef, private authService: AuthService, private router: Router) {
    this.desiredRoute = '';
    this.isAuthenticated = false;
  }

  ngOnInit(): void {

    interval(1000).subscribe(() => {

      if (this.countDown === 0) {
        this.countDown = -1;
        this.checkConnectivity();
      } else if (this.countDown > 0) {
        --this.countDown;
      }
    });
  }

  detectChanges(): void {
    this.cd.detectChanges();
  }

  // tslint:disable-next-line: typedef
  async checkConnectivity() {

    await this.authService.checkConnection().then( value => {
      if (value == null) {
        ++ this.attemps;
        this.countDown = 10;
      } else {
        this.bsModalRef.hide();

        if (this.isAuthenticated) {
          this.router.navigate([this.desiredRoute]);
        } else {
          this.router.navigate(['/login']);
        }
      }
    });
  }
}
