import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentComponent } from 'src/app/document/document-models/documentComponent';
import { DocumentItem } from '../document-models/document-item';
import { ReplaySubject, Subscription, fromEvent } from 'rxjs';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SearchService } from 'src/app/search/search.service';

@Component({
  selector: 'app-cps-clininfo-document',
  templateUrl: './cps-clininfo-document.component.html',
  styleUrls: ['./cps-clininfo-document.component.scss']
})
export class CpsClininfoDocumentComponent implements OnInit, DocumentComponent {
  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;

  isBucketExpandedMobile: boolean;
  private showBuckets: Subscription;
  public doc: DocumentItem;
  mobileView = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService, private searchService: SearchService) {

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
      this.isBucketExpandedMobile = !this.mobileView;
    });

  }

  ngOnInit(): void {

    this.showBuckets = this.searchService.getIsBucketExpandedMobile()
      .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
        this.isBucketExpandedMobile = isBucketExpandedMobile;
      });
  }

}
