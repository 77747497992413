import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PIDRequest, Result } from 'src/app/document/cps-pill-identifier-tool/PIDResult.interface';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { RouterPipe } from 'src/app/shared/router.pipe';
import { environment } from 'src/environments/environment';
import { DatalayerService } from 'src/services/datalayer.service';

@Component({
  selector: 'app-cps-pid-item',
  templateUrl: './cps-pid-item.component.html',
  styleUrls: ['./cps-pid-item.component.scss']
})
export class CpsPidItemComponent implements OnInit {

  @Input() pidItem: Result;
  @Output() selectedFlag = new EventEmitter<boolean>();
  isSelected = false;
  @Input() trackEventPIDRequestData: PIDRequest;

  constructor(public translationService: TranslateService,
              private datalayerService: DatalayerService,
              private routerPipe: RouterPipe,
              private router: Router) {

  }
  

  ngOnInit(): void {
  }

  collapse(){
    this.isSelected = false;
  }

  getDetail(){
    this.isSelected = true;
    this.datalayerService.productIdentificationDetailsEvent(this.trackEventPIDRequestData, this.pidItem);
  }

  getFullImgUrl(relativeUrl: string): string {
    return environment.API_SERVICE_ENDPOINT + '/' + relativeUrl;
  }

  public locatorToPath(locator: string): string[] {
    return DocumentItem.locatorToPath(locator);
  }

  public navigateToPath(locator: string): void {
    this.router.navigate(DocumentItem.locatorToPath(locator));
  }

}
