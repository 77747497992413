import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cps-pid-search',
  templateUrl: './cps-pid-search.component.html',
  styleUrls: ['./cps-pid-search.component.scss']
})
export class CpsPidSearchComponent implements OnInit {

  constructor(public translationService: TranslateService) {

  }
  

  ngOnInit(): void {
  }

}
