import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationEnd, NavigationError, NavigationStart, GuardsCheckStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { CpsPermissionDeniedModalComponent } from 'src/app/modals/cps-permission-denied-modal/cps-permission-denied-modal.component';
import { RouterPipe } from 'src/app/shared/router.pipe';
import { userProfile } from 'src/app/utility/Objects/userProfile';
import { SessionService } from '../session.service';
import { take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {

  currentRole = null;
  private userProfile: userProfile;

  constructor(private router: Router, 
              private sessionService: SessionService,
              private translationService: TranslateService,
              private routerPipe: RouterPipe,
              private modalService: BsModalService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  
    let isAuthorised = false;

    this.userProfile = this.sessionService.userProfile;
    if (!!this.userProfile) {
        if (this.userProfile.authorities != null && this.userProfile.authorities.length == 1) {
          this.currentRole = this.userProfile.authorities[0];
        }
    }

    const expectedRole = route.data.expectedRole;
    if (expectedRole != null && expectedRole != undefined) {
      if (expectedRole === this.currentRole) {
        isAuthorised = true;
      } else {
        this.translationService.get('subscriptionAlert.message').pipe(take(1)).subscribe(messageText => {
          const sessionexpiredModal = this.modalService.show(CpsPermissionDeniedModalComponent, {backdrop : 'static', keyboard : false});
          sessionexpiredModal.content.detectChanges();
        });

        if (document.referrer == "" || document.referrer == null || document.referrer == undefined) {
          this.router.navigate([this.routerPipe.transform('home')]);  
        }
        isAuthorised = false;
      }
    }
    return isAuthorised;
  }

}
