import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { SearchResult, Result } from './SearchResult.interface';
import { Autocomplete } from './Autocomplete.interface';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { SearchTermState } from '../reducers/search-term.reducer';
import { LoadSearchTerm, RemoveSearchTerm } from '../actions/search-term.actions';
import { clearMaxResultCountMap, DoSearch, RemoveSearchResults } from '../actions/search-result.actions';
import { SearchResultState } from '../reducers/search-result.reducer';
import { DatalayerService } from 'src/services/datalayer.service';
import { environment } from 'src/environments/environment';
import { DocumentItem } from '../document/document-models/document-item';
import { DocumentService } from 'src/services/document.service';
import { SessionService } from 'src/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/utils/app.constants';
import { AuthService } from 'src/services/auth-services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public searchResultGroupStates: Map<string, boolean>;

  public scrollPositions: Map<string, number>;

  private showSuggestion: BehaviorSubject<boolean>;
  textToUpdateSearchBox: BehaviorSubject<string>;
  private bucketStatus: BehaviorSubject<boolean>;
  private isBucketExpandedMobile: BehaviorSubject<boolean>;
  private lastSearchTerm: String;
  

  constructor(private http: HttpClient,
              private router: Router,
              private datalayerService: DatalayerService,
              private documentService: DocumentService,
              private translationService: TranslateService,
              private sessionService: SessionService,
              private store: Store<State>) {
      this.showSuggestion = new BehaviorSubject<boolean>(true);
      this.bucketStatus = new BehaviorSubject<boolean>(true);
      this.isBucketExpandedMobile = new BehaviorSubject<boolean>(false);
      this.textToUpdateSearchBox = new BehaviorSubject<string>('');
      this.searchResultGroupStates = new Map<string, boolean>();
      this.scrollPositions = new Map<string,number>([
                                    ['monographs', 0],
                                    ['conditions', 0],
                                    ['patientinfo', 0]
      ]);


    this.store.select(state => state.searchResultKey)
      .subscribe(action => {
        this.lastSearchTerm = action.lastSearchterm;
      });

  }

  getSearchTermFromResultWrapperQuery(): Observable<string>{
    return this.textToUpdateSearchBox;
  }

  getSearchTermObservable(): Observable<SearchTermState> {
    return this.store.select(state => state.searchTermKey);
  }

  populateSearchTerm(searchterm: string): void {
    this.textToUpdateSearchBox.next(searchterm);
    this.store.dispatch(new LoadSearchTerm(searchterm));
  }

  setBucketStatus(showBuckets : boolean) {
    this.bucketStatus.next(showBuckets);
  }

  getBucketStatus():  Observable<boolean> {
    return this.bucketStatus.asObservable();
  }

  getIsBucketExpandedMobile(): Observable<boolean> {
    return this.isBucketExpandedMobile.asObservable();
  }

  setIsBucketExpandedMobile(expandBuckets: boolean): void {
    this.isBucketExpandedMobile.next(expandBuckets);
  }

  setSearchTerm(searchterm: string): void {
    this.searchResultGroupStates = new Map<string, boolean>();
    this.scrollPositions = new Map<string, number>([
                                  ['monographs', 0],
                                  ['conditions', 0],
                                  ['patientinfo', 0]
    ]);
    this.store.dispatch(new LoadSearchTerm(searchterm));
  }

  clearSearchTerm(): void {
    this.textToUpdateSearchBox.next('');
    this.store.dispatch(new RemoveSearchTerm());
  }

  clearSearch(): void {
    this.store.dispatch(new RemoveSearchTerm());
    this.store.dispatch(new RemoveSearchResults());
  }

  clearSearchTermAndResults(): void {
    this.clearSearchTerm();
    this.clearSearch();
  }

  getSearchResultObservable(): Observable<SearchResultState> {
    return this.store.select(state => state.searchResultKey);
  }

  reloadHome(): void {
    this.router.navigate(['/home']).then(() => {
      window.location.reload();
    });
  }

  changeSearchResultLanguage(lang: string, translationService: TranslateService, searchType?: string): void {
    this.store.dispatch(new clearMaxResultCountMap());
    if (this.textToUpdateSearchBox.value == "") {
        translationService.use(lang);
    }
    else {
      this.store.dispatch(new DoSearch(this.textToUpdateSearchBox.value, lang, searchType));
    }
    this.showSuggestion.next(false);
  }

  getResultsWrapper(searchterm: string, searchType?: string): void {
    this.textToUpdateSearchBox.next(searchterm);
    this.store.dispatch(new clearMaxResultCountMap());
    this.store.dispatch(new DoSearch(searchterm, this.translationService.currentLang, searchType));
    this.showSuggestion.next(false);
  }
  
  showSuggestionDropdown(): Observable<boolean> {
    this.showSuggestion.next(true);
    return this.showSuggestion.asObservable();
  }

  getAutocompleteSuggestions(textEntered: string): Observable<Autocomplete[]> {
    let url = environment.API_SERVICE_ENDPOINT + '/search/autocomplete?lang=' + this.translationService.currentLang + '&query=' 
                  + textEntered + "&cursorPosition=" + textEntered.length;
    if (environment.mockup) {
      url = 'assets/mockups/search/autocomplete.json';
    }
    return this.http.get<Autocomplete[]>(url).pipe(switchMap(suggest => of(suggest)), catchError(this.handleError));
  }

  updateResultsWrapper(searchterm: string, lang: string): Observable<SearchResult> {

    const url: string = environment.API_SERVICE_ENDPOINT + '/search?lang=' + lang + '&sId=' + AuthService.sID + '&userId=' + AuthService.userProfileState.userProfile.subscriberId + '&limit=3&query=' + encodeURIComponent(searchterm);

    return (this.http.get<SearchResult>(url).pipe(catchError(this.handleError)));
  }

  getMoreSerachResults(searchterm: string, start: number, end: number, searchType: string, qualifier: string): Observable<SearchResult> {

    let url: string = environment.API_SERVICE_ENDPOINT + '/search?lang=' + this.translationService.currentLang + '&query=' + searchterm + '&limit=' + end + '&start=' + start 
                                                                      + '&qualifier='+qualifier + '&publications=' + searchType;
    if (environment.mockup) {
      url = 'assets/mockups/search/' + searchterm + '.json';
    }
    return (this.http.get<SearchResult>(url).pipe(catchError(this.handleError)));
  }

  adjustNavigation(monographs: Result[], conditions: Result[], patientInfo: Result[], bucketSwitch: string,
                   searchText: string, searchType: string, searchData: SearchResult, isNavigating = true): void {
      if (!!searchData) {
      const monographCount: number = searchData.monographCount;
      const conditionsCount: number = searchData.conditionsCount;
      const patientinfoCount: number = searchData.patientinfoCount;

      this.datalayerService.searchTermEvent(searchText, searchType, monographCount, conditionsCount, patientinfoCount);
    } else {
      this.datalayerService.searchTermEvent(searchText, searchType, monographs.length, conditions.length, patientInfo.length);
    }

      switch (bucketSwitch) {

      case 'MONOGRAPH':
        if (monographs.length > 0) {
          if (monographs.length === 1 && isNavigating) {
            if (this.documentService.getDocumentList().length > 0 && this.router.url !== '/home'
              && this.documentService.isDocumentInOpenedDocList(monographs[0].type, monographs[0].id, monographs[0].lang)) {
              this.documentService.setDocumentAsCurrent(monographs[0].type, monographs[0].id, monographs[0].lang);
            } else {
              this.documentService.setDocumentAsCurrent(monographs[0].type, monographs[0].id, monographs[0].lang);
              this.navigateTo(monographs[0]);
            }
          } else {
            this.reloadCurrentRoute('monographs');
          }
        }
        break;
        case 'CLININFO':
      case 'CHAPTER':
      case 'MA_CHAPTER':
      case 'PMA':
        if (conditions.length > 0) {
          if (conditions.length == 1 && isNavigating) {
            this.navigateTo(conditions[0]);
            this.documentService.setDocumentAsCurrent(conditions[0].type, conditions[0].id, conditions[0].lang);
          } else {
            this.reloadCurrentRoute('conditions');
          }
        }
        break;
      case 'IFP':
      case 'MA_PI':
        if (patientInfo.length > 0) {
          if (patientInfo.length == 1 && isNavigating) {
            this.navigateTo(patientInfo[0]);
          } else {
            this.reloadCurrentRoute('patientinfo');
          }
        }
        break;
      case 'noresult':
        this.router.navigate(['search', 'noresult']);
    }
  }
  reloadCurrentRoute(bucketSwitch: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['search', bucketSwitch]);
    });
  }

  /* using this function we can resolve a path for the Result object using central functions */
  public navigateTo(result: Result): void {
    if (result.type === AppConstants.MA_PI_TYPE){
      this.router.navigate(['document', DocumentItem.getPathFromType(result.type), result.parentId, result.id]);
    } else {
      this.router.navigate(['document', DocumentItem.getPathFromType(result.type), result.id]);
    }
  }

  public navigateToDocument(suggestedDocument: Autocomplete, searchType: string): void {
    this.textToUpdateSearchBox.next(suggestedDocument.item.replace(/(<([^>]+)>)/gi, ""));
    const documentLocator = DocumentItem.locatorToPath(suggestedDocument.locator);
    this.router.navigate(documentLocator);
    this.documentService.setDocumentAsCurrent(suggestedDocument.type, suggestedDocument.docId, this.translationService.currentLang);
    let monographCount = 0;
    let conditionsCount = 0;
    let patientinfoCount = 0;
    switch (suggestedDocument.type) {
      case 'MONOGRAPH':
        monographCount = 1;
        break;
      case 'CHAPTER':
      case 'MA_CHAPTER':
      case 'PMA':
        conditionsCount = 1;
        break;
      case 'IFP':
      case 'MA_PI':
        patientinfoCount = 1;
        break;
      default:
        monographCount = 0;
        conditionsCount = 0;
        patientinfoCount = 0;
    }
    this.datalayerService.searchTermEvent(suggestedDocument.item.replace(/(<([^>]+)>)/gi, ""), searchType, monographCount, conditionsCount, patientinfoCount);
    this.showSuggestion.next(false);
  }

  private handleError(error: HttpErrorResponse) {
    let errorMsg: string;
    if (error.error instanceof ErrorEvent) {
      errorMsg = 'An error occured: ' + error.error.message;
    } else {
      errorMsg = `Backend returned code ${error.status}, body was: ${error.error}`;
    }
    console.error(errorMsg);
    return throwError('Please try again later');
  }

  public updateSearchResultGroupState(id: string, state: boolean): void {
    this.searchResultGroupStates.set(id, state);
  }

  public getSearchResultGroupState(id: string): boolean {
    return this.searchResultGroupStates.has(id) ? this.searchResultGroupStates.get(id) : false;
  }
}
