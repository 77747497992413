<div class="searchDropdown" *ngIf="suggestionsArray.length > 0 || documentsArray.length > 0">
  <div class="searchContainer">
      <div class="searchContainerScrollBar">
    <span class="dropdown-header dropdown-label"
          *ngIf="suggestionsArray.length > 0">{{ 'Search.suggestions.label' | translate }}</span>
      <button class="dropdown-item dropdownElement searchItems" (keyup)='moveFocus(suggIndex+1, $event)'
              (click)="selectedSuggestion(suggestion.item, 'suggestion_click')"
              *ngFor="let suggestion of suggestionsArray; let suggIndex = index" [attr.tabindex]="suggIndex+1"
              [innerHTML]="suggestion.item"></button>
      <span class="dropdown-header dropdown-label"
            *ngIf="documentsArray.length > 0">{{ 'Search.documents.label' | translate }}</span>
      <button class="dropdown-item dropdownElement searchItems"
              (keyup)='moveFocus(suggestionsArray.length+docuIndex+1, $event)'
              (click)="selectedDocument(document, 'document_click')"
              *ngFor="let document of documentsArray; let docuIndex = index"
              [attr.tabindex]="suggestionsArray.length+docuIndex+1"><span class="icon {{document.type}} {{document.qualifier}}"></span>
        <span [innerHTML]="document.item"></span>
        <span *ngIf="!!document.manufacturers && document.manufacturers.length>0;then content"></span>
        <ng-template #content> (<span [innerHTML]="document.manufacturers"></span>)</ng-template>
      </button>
    </div>
  </div>
</div>
