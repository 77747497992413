import { Action } from '@ngrx/store';
import { MenuDocumentData } from '../reducers/document-data.reducer';

export enum DocumentDataActionTypes {
  LoadMenuDocument = '[DocumentData] Load Menu Document',
  AddMenuDocument = '[DocumentData] Add Menu Document',
  ClearMenuDocument = '[DocumentData] Clear Menu Document'
}

export class LoadMenuDocument implements Action {
  readonly type = DocumentDataActionTypes.LoadMenuDocument;
  constructor(public menuType: string, public lang: string) {
    // Empty
  }
}

export class ClearMenuDocument implements Action {
  readonly type = DocumentDataActionTypes.ClearMenuDocument;
  constructor() {
    // Empty
  }
}

export class AddMenuDocument implements Action {
  readonly type = DocumentDataActionTypes.AddMenuDocument;
  constructor(public menuDocument: MenuDocumentData, public menuType: string) {
    // Empty
  }
}

export type DocumentDataActions = LoadMenuDocument | AddMenuDocument | ClearMenuDocument;
