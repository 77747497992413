<div class="toolbar-wrapper" [ngClass]="{ 'justify-end': isSearchBoxHidden, 'isBucketsDisplayed': isBucketExpandedMobile && isMobile}">
    <div class="row toolbar-row no-gutters">
            <div class="col-8 toolbar-spacing">
                <div class="search-input-group" [ngClass]="{ 'hidden': isSearchDisabled }">
                    <input type="text" name="search-input" autocomplete="off" (keyup)="onSearchTextChanged($event.target.value)"
                    (focus)="setDisplayFullDocumentHTML(true)" (focusout)="setDisplayFullDocumentHTML(false)"
                    [(ngModel)]="searchText" placeholder="{{ 'ToolBar.Placeholder' | translate }}" class="form-control search-input"/>
                    <button *ngIf="searchText" type="button" aria-label="Close" (click)="clearSearch()"  class="btn bg-transparent clear-search-button">
                        <i class="times-icon"></i>
                    </button>
                </div>
                <div class="arrow-button-wrapper" [ngClass]="{ 'hidden': isSearchDisabled }">
                    <button type="button" aria-label="Previous" class="btn btn-outline-secondary search-btn up-chevron" (click)="onSearchIndexChange(false, true)"
                        [disabled]="!hasSearchBeenMade || (hasSearchBeenMade && totalMatchesNum == 0)">
                    </button>
                    <button type="button" aria-label="Next" class="btn btn-outline-secondary search-btn down-chevron" (click)="onSearchIndexChange(true, true)"
                        [disabled]="!hasSearchBeenMade || (hasSearchBeenMade && totalMatchesNum == 0)">
                    </button>
                </div>
                <div *ngIf="!isMobile" class="d-xs-none d-md-inline-block">
                    <span [ngClass]="{ 'hidden': !(hasSearchBeenMade && !isSearchDisabled) }" class="search-results-text">
                        {{ currentFocusIndex + 1 }} {{ 'ToolBar.of' | translate}} {{ totalMatchesNum }} {{ 'ToolBar.Matches' | translate}}
                    </span>
                </div>
            </div>
            <div class="col-4 action-buttons float-right-padding mobile-bookmark-position">
                <div class="bookmark-group" (click)="toggleBookmark()">
                    <span class="bookmark-unselected-icon" [ngClass]="[isMobile ? 'bookmark-icon-mobile' : 'bookmark-icon', (isMobile && isSearchDisabled) ? 'bookmark-icon-position' : '']" *ngIf="!isCurrentDocBookmarked"></span>
                    <span class="bookmark-selected-icon" [ngClass]="[isMobile ? 'bookmark-icon-mobile' : 'bookmark-icon']" *ngIf="isCurrentDocBookmarked"></span>
                    
                    <span *ngIf="isSearchDisabled && isMobile" class="d-sm-block mobile-label-position">{{ 'ToolBar.bookmark' | translate }}</span> 
                    <span *ngIf="!isMobile" class="d-none d-sm-block">{{ 'ToolBar.bookmark' | translate }}</span>
                </div>
                <div class="print-group" (click)="redirectToPrintPage()" *ngIf="!isTabletSize && !hidePrintButton">
                    <span class="print-icon"></span>
                    <span>{{ 'ToolBar.print' | translate }}</span>
                </div>
            </div>
    </div>
    <div *ngIf="isTabletSize" class="mobile-search-result-text">
        <span [ngClass]="{ 'hidden': !(hasSearchBeenMade && !isSearchDisabled) }"
            class="col-sm-3 col-md-3 search-results-text">
            {{ currentFocusIndex + 1 }} {{ 'ToolBar.of' | translate}} {{ totalMatchesNum }} {{ 'ToolBar.Matches' |
            translate}}
        </span>
    </div>
    <div class="row hr"></div>
</div>