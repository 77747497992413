import { AuthStateActionTypes, AuthStateActions } from "../actions/auth-state.action";

export const authStateKey = 'authStateKey';

export interface AuthState {
    timeSinceLastAutloginCheck: Date;
    currentBrowserIP: string;
}

export const initialState: AuthState = {
    timeSinceLastAutloginCheck: null,
    currentBrowserIP: null
};

export function authStateReducer(state = initialState, action: AuthStateActions): AuthState {
    switch (action.type) {
        case AuthStateActionTypes.SetLastAutologinDate:
            state.timeSinceLastAutloginCheck = action.currentDate;
            return { ...state, timeSinceLastAutloginCheck: action.currentDate };
        case AuthStateActionTypes.SetCurrentBrowserIP:
            state.currentBrowserIP = action.currentIP;
            return { ...state };
        default:
            return state;
    }
}