import { Injectable } from '@angular/core';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectFavoritesList } from 'src/app/reducers';
import { AddFavorite, RemoveFavorite } from 'src/app/actions/home-history.actions';
import { SessionService } from './session.service';

@Injectable()
export class UserService {
    private bookmarks: Array<DocumentItem>;
    public currentKeywordSearchText: string;

    constructor(public httpClient: HttpClient,
                private store: Store,
                private sessionService: SessionService) {
        store.select(selectFavoritesList).subscribe((favorites) => this.bookmarks = favorites);
    }

    public getBookmarks(): Array<any> {
        return this.bookmarks;
    }

    addFavorite(doc: DocumentItem): void {
        this.store.dispatch(new AddFavorite(doc));
    }

    removeFavorite(doc: DocumentItem): void {
        this.store.dispatch(new RemoveFavorite(doc));
    }

    public isCurrentDocumentBookmarked(doc: DocumentItem): boolean {
        let bookmarked: boolean = false;
        if (!!this.sessionService.userProfile && !!this.sessionService.userProfile.userId) {
            this.bookmarks.forEach(bookmark => {
                if (bookmark.id === doc.id && bookmark.type === doc.type && bookmark.lang === doc.lang
                    && bookmark.userid === this.sessionService.userProfile.userId) {
                    bookmarked = true;
                }
            })
        }
        return bookmarked;
    }

}