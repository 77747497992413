import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cps-modal',
  templateUrl: './cps-modal.component.html',
  styleUrls: ['./cps-modal.component.scss']
})
export class CpsModalComponent implements OnInit {

  constructor(public translationService: TranslateService) {

  }

  ngOnInit(): void {
  }

}
