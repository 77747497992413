import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HomeService } from 'src/services/home.service';
import { CustomizerActionTypes, LoadCustomizer, PopulateCustomizer } from '../actions/customizer.actions';

@Injectable()
export class CustomizerEffects {

  @Effect()
  fetchCustomizer$: Observable<any> = this.actions$.pipe(
    ofType(CustomizerActionTypes.PopulateCustomizer),
    mergeMap(action =>
        
        this.homeService.getCustomizer(action.lang, action.userProfile).pipe(map(value => {
            return new LoadCustomizer(value);
        }),
        catchError(() => EMPTY))
      )
    );

    constructor(private actions$: Actions<PopulateCustomizer>, private homeService: HomeService, public translationService: TranslateService) {
      // Empty
    }
}
