import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectAccessToken, State } from '../../app/reducers';
import { UserProfileState } from '../../app/reducers/user-profile.reducer';
import { LoadActiveSubscription, LoadUserCredentials, RefreshUserToken, LoadIPAuthenticatedUser, LoadAutoLoginByParam } from '../../app/actions/user-profile.actions';
import { userProfile } from 'src/app/utility/Objects/userProfile';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/utils/app.constants';
import { AuthResponse } from "./auth-response.interface";
import { RemoveSearchResults } from "src/app/actions/search-result.actions";
import { RemoveSearchTerm } from "src/app/actions/search-term.actions";
import { ClearAllDocuments } from "src/app/actions/document-list.actions";
import { ClearMenuDocument } from "src/app/actions/document-data.actions";
import { AuthState } from "src/app/reducers/auth-state.reducer";
import { SetLastAutologinDate } from "src/app/actions/auth-state.action";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static userProfileState: UserProfileState;

  public static authState: AuthState;

  public static userProfileDetailsObservable$: Observable<UserProfileState>;

  public static newLogin = false;

  public static sID;

  public loginErrorMessage$: BehaviorSubject<string>;
  public isLoginAuthenticated: BehaviorSubject<boolean>;
  public ipCheckMessage$: BehaviorSubject<string>;

  private requestCompletionSubject$ = new BehaviorSubject<boolean>(false);
  requestCompletion$: Observable<boolean> = this.requestCompletionSubject$.asObservable();

  public static AUTOLOGIN_DELAY_TIME = 2;

  constructor(private httpClient: HttpClient,
              private store: Store<State>) {
    this.loginErrorMessage$ = new BehaviorSubject<string>('');
    this.ipCheckMessage$ = new BehaviorSubject<string>(null);
    this.isLoginAuthenticated = new BehaviorSubject<boolean>(false);

    AuthService.userProfileDetailsObservable$ = this.getUserProfileDetails();

    AuthService.sID = Math.floor(100000000 + Math.random() * 900000000); // Session ID for use tracking

    AuthService.userProfileDetailsObservable$.subscribe(profile => { AuthService.userProfileState = profile; });
    this.getAuthState().subscribe(auth => { AuthService.authState = auth })
  }

  getAccessTokenData(user: string, pass: string): Observable<AuthResponse> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(user+':'+pass)   
        });
        const params = new HttpParams()
                          .set("grant_type", AppConstants.AUTH_SERVICE_PASSWORD_GRANT);
        const url = environment.AUTH_SERVICE_ENDPOINT + "/oauth/token/" + AppConstants.AUTH_SERVICE_CLIENT_ID;
        return this.httpClient.post<AuthResponse>(url, null, {params:params, headers:headers});
    }

    checkProxyVersion(): Observable<any> {
      const url = environment.PROXY_SERVICE_ENDPOINT + '/version';
      return this.httpClient.get(url);
    }

    checkAPIVersion(): Observable<any> {
      const url = environment.API_SERVICE_ENDPOINT + '/version';
      return this.httpClient.get(url);
    }

    checkAuthVersion(): Observable<any> {
      const url = environment.AUTH_SERVICE_ENDPOINT + '/version';
      return this.httpClient.get(url);
    }

    checkConnection(): Promise<any> {
      const url = environment.AUTH_SERVICE_ENDPOINT + '/version';
      return  this.httpClient.get(url).pipe(
        timeout(2000),
        catchError(() => {

          return of(null);
        })
      ).toPromise();
    }

    getRefreshToken(refreshToken: string): Observable<AuthResponse> {
      return this.oauthTokenRequest(refreshToken, AppConstants.AUTH_SERVICE_REFRESHTOKEN_GRANT);
    }

    removeAccessTokenData(token: string): Observable<any> {
      if (token != '') {
        const headers = new HttpHeaders({
            'Content-Type': 'application/text',
            'Authorization': 'Basic ' + token
        });
        const params = new HttpParams()
          .set('subid', AuthService.userProfileState.userProfile.subscriberId);
        const url = environment.AUTH_SERVICE_ENDPOINT + "/logout";

        return this.httpClient.post(url, null, {params:params, headers:headers});
      }
    }

    getpParam(token: string): Observable<any> {
      if (token != '') {
        const headers = new HttpHeaders({
            'Content-Type': 'application/text',
            'Authorization': 'Basic ' + token
        });
        const params = new HttpParams();
        const url = environment.AUTH_SERVICE_ENDPOINT + "/pParam";
        console.log(headers);
        return this.httpClient.post(url, null, {params:params, headers:headers});
      }
    }

    checkAccessTokenValidity(token: string): Observable<any> {
      if (token != '') {
        const headers = new HttpHeaders({
          'Content-Type': 'application/text',
          'Authorization': 'Basic ' + token
        });
        const params = new HttpParams();
        const url = environment.AUTH_SERVICE_ENDPOINT + "/tokenCheck";

        return this.httpClient.post(url, null, { params: params, headers: headers });
      }
    }

    setUserCredentials( username: string, password: string) : void{
      this.store.dispatch(new LoadUserCredentials(username, password));
    }

    refreshAuthToken(userLocalProfile: userProfile): Observable<string> {
      this.store.dispatch(new RefreshUserToken(userLocalProfile));
      return this.store.select(selectAccessToken);
    }

    setUserProfileDetails(selectedSub: string): void {
      AuthService.newLogin = false;
      this.store.dispatch(new LoadActiveSubscription(selectedSub));
    }

    setFirstTimeUserProfileDetails(selectedSub: string): void {
      AuthService.newLogin = true;
      this.store.dispatch(new LoadActiveSubscription(selectedSub));
    }

    setIpAuthenticatedUserDetails(): void {
      if (!!AuthService.authState.timeSinceLastAutloginCheck) {
        const currentDate = new Date();
        const autologinDate = AuthService.authState.timeSinceLastAutloginCheck;
        autologinDate.setHours(autologinDate.getHours() + AuthService.AUTOLOGIN_DELAY_TIME);
        if (autologinDate < currentDate) {
          this.store.dispatch(new LoadIPAuthenticatedUser());
        }
      } else {
        this.store.dispatch(new LoadIPAuthenticatedUser());
      }
    }

    getIpAuthenticatedUserDetails(): Observable<UserProfileState> {
      return  this.store.select(state => state.userProfileKey);
    }

    getUserProfileDetails(): Observable<UserProfileState> {
      return this.store.select(state => state.userProfileKey);
    }

    getAuthState(): Observable<AuthState> {
      return this.store.select(state => state.authStateKey);
    }

    setIpAndAutologinAttemptTime(): void {
      this.store.dispatch(new SetLastAutologinDate(new Date()));
    }

    getNewAcessToken(selectedSubscription: string): Observable<AuthResponse> {
      let url: string = environment.AUTH_SERVICE_ENDPOINT + '/oauth/switch_role?package=' + selectedSubscription + '&isLogin=false';

      if (AuthService.newLogin) {
        url = environment.AUTH_SERVICE_ENDPOINT + '/oauth/switch_role?package=' + selectedSubscription + '&isLogin=true';
      }

      const headers = new HttpHeaders({'Content-Type': 'application/json'});
      const params = new HttpParams();

      return this.httpClient.get<AuthResponse>(url, {params:params, headers:headers});
    }

    isAuthenticated(): boolean {
      let isAuthenticated = false;

      if (AuthService.userProfileState.userProfile != null && AuthService.userProfileState.userProfile.access_token != null) {
        isAuthenticated = true;
        this.isLoginAuthenticated.next(isAuthenticated);

      }
      return isAuthenticated;
    }

    getIsUserAuthenticated(): BehaviorSubject<boolean> {
      return this.isLoginAuthenticated;
    }

    setIsUserAuthenticated(isAuthenticated: boolean): void {
      this.isLoginAuthenticated.next(isAuthenticated);
    }

    getIpAutheticatedUserData(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        const params = new HttpParams();      
        const url = environment.AUTH_SERVICE_ENDPOINT + "/ipcheck";
        return this.httpClient.get(url, {params:params, headers:headers});       
    }

    setLoginErrorMessage(errMessage: string): void {
      this.loginErrorMessage$.next(errMessage);
    }

    getLoginErrorMessage(): BehaviorSubject<string> {
      return this.loginErrorMessage$;
    }

    setIpCheckMessage(message: string): void {
      this.ipCheckMessage$.next(message);
    }

    getIpCheckMessage(): BehaviorSubject<string> {
      return this.ipCheckMessage$;
    }

    clearLoginErrorMessage() : void {
      this.loginErrorMessage$.next('');
    }

    clearSearchResult(): void {
      this.store.dispatch(new RemoveSearchResults());
      this.store.dispatch(new RemoveSearchTerm());
      this.store.dispatch(new ClearAllDocuments());
      this.store.dispatch(new ClearMenuDocument());
    }

    setAutoLoginByParam(pParam: string): void {
      this.store.dispatch(new LoadAutoLoginByParam(pParam));
    }

    getAutoLoginByParam(pParam: string): Observable<AuthResponse> {
      return this.oauthTokenRequest(pParam, AppConstants.AUTH_SERVICE_AUTOLOGIN_GRANT);
    }

    private oauthTokenRequest(token: string, grantType: string): Observable<AuthResponse> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + token
      });

      let params = null;

      // if they are using autologin, they don't have a profile set so we need to send the
      // bare minimum so the backend can fetch that info
      if (grantType === AppConstants.AUTH_SERVICE_REFRESHTOKEN_GRANT && AuthService.userProfileState.userProfile === null) {
        params = new HttpParams()
          .set("grant_type", grantType);
      }
      else if (grantType === AppConstants.AUTH_SERVICE_AUTOLOGIN_GRANT) {
        params = new HttpParams()
          .set("grant_type", grantType);
      } else {
        const activeSubCode = AuthService.userProfileState.userProfile.active_Subscription;
        const activeSub = AuthService.userProfileState.userProfile.subscriptionInfo
          .filter(s => s.packageCode == activeSubCode)[0];
        params = new HttpParams()
          .set("grant_type", grantType)
          .append("uid", AuthService.userProfileState.userProfile.userId)
          .append("pkg", activeSubCode)
          .append("subs", activeSub.maxlicenses)
          .append("subid", AuthService.userProfileState.userProfile.subscriberId);
      }
        
      const url = environment.AUTH_SERVICE_ENDPOINT + "/oauth/token/" + AppConstants.AUTH_SERVICE_CLIENT_ID;
      return this.httpClient.post<AuthResponse>(url, null, { params: params, headers: headers });
    }

    setRequestCompletionState(completed: boolean): void {
      this.requestCompletionSubject$.next(completed);
    }
}
