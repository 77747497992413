import { Action } from '@ngrx/store';

export enum AuthStateActionTypes {
    SetLastAutologinDate = '[AuthState] Set last autologin attempt date',
    SetCurrentBrowserIP = '[AuthState] Set IP for current browser'
}

export class SetLastAutologinDate implements Action {
    readonly type = AuthStateActionTypes.SetLastAutologinDate;

    constructor(public currentDate: Date) { }
}

export class SetCurrentIp implements Action {
    readonly type = AuthStateActionTypes.SetCurrentBrowserIP;

    constructor(public currentIP: string) { }
}

export type AuthStateActions = SetLastAutologinDate | SetCurrentIp;
