import { InjectionToken } from "@angular/core";
import { DocumentItem } from "./document-models/document-item";

export interface PrintoutData {
    htmlElement: Element;
    doc: DocumentItem;
    docQualifier: string;
}

export const PRINTOUT_DOCUMENT_DATA = new InjectionToken<PrintoutData>('PRINTOUT_DOCUMENT_DATA');

export enum PrintoutModalName {
    patientinfo = 'PATIENT_INFO_DOCUMENT'
}

export let PRINTOUT_MODALS = {

};