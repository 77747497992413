import { Action } from '@ngrx/store';
import { WhatsNew } from '../home/cps-home-sidebar/whatsnew.interface';

export enum WhatsNewActionTypes {
    PopulateWhatsNew = '[WhatsNew] Populate WhatsNew',
    LoadWhatsNew = '[WhatsNew] Load WhatsNew',
    ClearWhatsNew = '[WhatsNew] Claer WhatsNew'
}

export class PopulateWhatsNew implements Action {
    readonly type = WhatsNewActionTypes.PopulateWhatsNew;

    constructor() {
        // Empty
    }
}

export class LoadWhatsNew implements Action {
    readonly type = WhatsNewActionTypes.LoadWhatsNew;

    constructor(public whatsNew: WhatsNew[]) {
        // Empty
    }
}

export class ClearWhatsNew implements Action {
    readonly type = WhatsNewActionTypes.ClearWhatsNew;

    constructor() {
        // Empty
    }
}

export type WhatsNewActions = PopulateWhatsNew | LoadWhatsNew | ClearWhatsNew;
