<div class="utilNav-container">
    <div class="cpsLogoDivMobile d-xl-none d-lg-none" *ngIf="loggedInObservable$ | async as sessionActive" [hidden]="!sessionActive">
    <div class="cpsLogoDiv">
        <span (click)="goToHomePage()" class="cpsLogo site-logo-header">
        </span>
    </div>
    </div>
    <div class="utilNav-menu" (mouseleave)="onUtilNavMouseLeave()">
        <ul class="nav nav-pills">
            <li class="utilNav-menuItem" *ngIf="loggedInObservable$ | async as sessionActive" [hidden]="!sessionActive"
            ngbDropdown placement="bottom-left bottom-center" #userDropdown="ngbDropdown"
            (mouseenter)="hoverOnUser()" (mouseleave)="leaveUserDropdown()">
                <ng-container *ngIf="sessionActive">
                    <a ngbDropdownToggle tabindex="0" (focus)="userDropdown.open()" (click)="userDropdown.open()"
                        class="userdropdown-toggle">
                        <span class="menu-text" *ngIf="userProfileDetailsObservable$ | async as userProfileDetails">
                            <div *ngIf="!!userProfileDetails.userProfile" class="nav-username">
                                {{userProfileDetails.userProfile.userFirstName + ' ' + userProfileDetails.userProfile.userLastName}}
                            </div>
                        </span>
                        <span class="menu-icon profile-icon"></span><span class="down-arrow down-solid-arrow"></span>
                    </a>
                    <ul class="utilNav-menuList" ngbDropdownMenu (mouseleave)="leaveUserDropdown()">
                        <li ngbDropdownItem>
                            <a [routerLink]="[ 'pkgswitch' | routerPipe ]" class="utilNav-menu-subitem">{{ 'util-Nav.subInfo' | translate }}</a>
                        </li>
                        <li ngbDropdownItem>
                            <a (click)="logout($event)" class="utilNav-menu-subitem">{{ 'util-Nav.logout' | translate }}</a>
                        </li>
                    </ul>
                </ng-container>
            </li>
            <li class="utilNav-menuItem langChange">
                <div style="cursor: pointer;" (click)="changeLang()"><span>{{ languageChangeText }}</span></div>
            </li>
        </ul>
    </div>
</div>
