import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { HomeService } from 'src/services/home.service';
import { WhatsNewActionTypes, PopulateWhatsNew, LoadWhatsNew } from '../actions/whatsnew.actions';
import { WhatsNew } from '../home/cps-home-sidebar/whatsnew.interface';

@Injectable()
export class WhatsNewEffects {

  @Effect()
  loadWhatsNew$: Observable<any> = this.actions$.pipe(
    ofType(WhatsNewActionTypes.PopulateWhatsNew),
    mergeMap(() =>
      this.homeService.getDrugUpdates().pipe(
        map(whatsNew => {
          const result = this.parseWhatsNew(whatsNew);
          return new LoadWhatsNew(result);
        }),
        )
      )
    );

    constructor(private actions$: Actions<PopulateWhatsNew>, private homeService: HomeService, public translationService: TranslateService) {
      // Empty
    }

    parseWhatsNew(xml: string): WhatsNew[] {

      const result = [];

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, 'text/xml');

      const products = xmlDoc.getElementsByTagName('product');

      for (const item in products) {

          if (!isNaN(+item) && products[item].getAttribute('language').toLocaleLowerCase() === this.translationService.currentLang.toLocaleLowerCase()) {

              const whatsnew: WhatsNew = {brandName: '', classification: '', genericName: '', filename: '', category: '', status: ''};

              if (products[item].getAttribute('status') === 'whats_new') {

                whatsnew.brandName = products[item].getElementsByTagName('brandname')[0].textContent;
                whatsnew.classification = products[item].getElementsByTagName('classification')[0].textContent;
                whatsnew.genericName = products[item].getElementsByTagName('genericname')[0].textContent;
                whatsnew.filename = products[item].getAttribute('filename');
                whatsnew.category = products[item].getAttribute('category');
                whatsnew.status = products[item].getAttribute('status');

                result.push(whatsnew);
              }
          }
      }

      return result;
    }
}
