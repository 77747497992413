<div class="home-container container-fluid">
    
    <div class="home-row row">
        <div class="home-section col-xxl-6 col-xl-6 col-lg-6 col-md-12 home-customizer-padding">
            <app-cps-home-customizer></app-cps-home-customizer>
        </div>
        <div class="home-section col-xl-6 col-xl-6 col-lg-6 col-md-12 d-lg-block home-sidebar-padding">
            <app-cps-home-sidebar></app-cps-home-sidebar>
        </div>
    </div>

    <div class="home-row row">
        <div *ngIf="feature1" class="home-section col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 home-feature1-padding">
            <app-cps-home-feature [feature]="feature1"></app-cps-home-feature>
        </div>
        <div *ngIf="feature2" class="home-section col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 home-feature2-padding">
            <app-cps-home-feature [feature]="feature2"></app-cps-home-feature>
        </div>
        <div *ngIf="feature3" class="home-section col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 home-feature3-padding">
            <app-cps-home-feature [feature]="feature3"></app-cps-home-feature>
        </div>
        <div class="home-section col-xxl-3 col-xl-3 col-lg-3 d-lg-block d-none home-history-padding">
            <app-cps-home-history></app-cps-home-history>
        </div>
    </div>

    <div class="home-row row">
        <div class="home-section d-xl-none d-lg-none col-md-12 home-history-bottom-padding">
            <app-cps-home-history></app-cps-home-history>
        </div>
    </div>
    
</div>

