import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DirectoryService } from 'src/services/directory.service';
import { DocumentItem } from '../document-models/document-item';

@Component({
  selector: 'app-cps-keyword-search-bar',
  templateUrl: './cps-keyword-search-bar.component.html',
  styleUrls: ['./cps-keyword-search-bar.component.scss']
})
export class CpsKeywordSearchBarComponent implements OnInit {

  @Input() public doc: DocumentItem;

  public highlightList = [];
  public searchText: string;
  public hasSearchBeenMade: boolean;
  public currentFocusIndex: number;
  public totalMatchesNum: number;

  constructor(public viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0,0]);

    if (DirectoryService.keywordSearchTerm.get(this.doc.id) === undefined) {
      this.searchText = '';
    } else {
      this.searchText = DirectoryService.keywordSearchTerm.get(this.doc.id);
      this.onSearchTextChanged();
    }
  }
  
  onSearchTextChanged(): void {
    if (this.searchText !== null && this.searchText !== undefined && this.searchText !== '' && this.searchText.length > 2) {

      this.highlightList = [];
      this.hasSearchBeenMade = true;

      DirectoryService.keywordSearchTerm.set(this.doc.id, this.searchText);

      DirectoryService[this.doc.id].titleList.map(title => {
        if (title.toLowerCase().includes(this.searchText.toLowerCase())) {
          this.highlightList.push(title);
        }
        this.totalMatchesNum = this.highlightList.length;
        this.currentFocusIndex = 0;

        if(this.highlightList.length > 0 ){
          this.scrollToHighlightedText(this.highlightList[this.currentFocusIndex]);
        }
      });
    } else if (this.searchText === undefined || this.searchText === '') {
      this.clearSearch();
    }
  }

  clearSearch(): void {
    this.highlightList = [];
    this.searchText = '';
    this.currentFocusIndex = -1;
    this.totalMatchesNum = 0;
    this.hasSearchBeenMade = false;
    this.viewportScroller.scrollToPosition([0,0]);
    DirectoryService.keywordSearchTerm.set(this.doc.id, '');
  }

  onSearchIndexChange(increment: number): void {

    this.currentFocusIndex = this.currentFocusIndex + increment;

    if (this.currentFocusIndex < 0) {
      this.currentFocusIndex = 0;
    }

    if (this.currentFocusIndex > this.highlightList.length - 1) {
      this.currentFocusIndex = 0;
    }

    this.scrollToHighlightedText(this.highlightList[this.currentFocusIndex]);

  }

  scrollToHighlightedText(id: string): void {

    this.viewportScroller.setOffset([0, 500]);

    this.viewportScroller.scrollToAnchor(id);
  }

}
