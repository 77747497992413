<ng-template #componentTab let-document let-openedDocuments="list">
  <span class="doc-icon" [ngClass]="[!isActive(document)?'unselected-icon':'', isGeneric(document)?'generic-icon':document.type+'-icon']"></span>
  <span class="doc-title" [ngClass]="{ 'selected': isActive(document), 'tab-loading': document.name=='' }" [innerHTML]="document.nameHTML"></span>
  <span class="close-img x-close-icon" (click)="closeDocument(document)" *ngIf="openedDocuments.length > 1"></span>
</ng-template>
<ng-template #docTab let-document let-openedDocuments="list">
  <span class="doc-icon" [ngClass]="[!isActive(document)?'unselected-icon':'', isGeneric(document)?'generic-icon':document.type+'-icon']"></span>
  <span class="doc-title" [ngClass]="{ 'selected' : isActive(document), 'tab-loading' : document.name=='' }">{{'TabBar.'+document.id | translate}}</span>
  <span class="close-img x-close-icon" (click)="closeDocument(document)" *ngIf="openedDocuments.length > 1"></span>
</ng-template>
<div class="toc-tab-bar" [ngClass]="{ 'isBucketsDisplayed': isBucketExpandedMobile && mobileView}" *ngIf="openDocs$ | async as openedDocuments">
  <div class="container-fluid">
    <div class="tab-bar-container row">
      <div class="col-sm-1 col-xl-1 col-lg-1 col-md-1 col-3 recent-label">
        <span class="tab-bar-label">{{ 'ResultsPage.recent' | translate }}</span>
      </div>

      <div *ngFor="let document of openedDocuments.documents | slice:0:tabNumber" class="document-tab col-md-3 d-xl-flex d-lg-flex d-md-flex d-sm-none d-none"
           (click)="openDocument(document)" [ngClass]="{ 'active-tab' : isActive(document) }">
        <ng-container *ngTemplateOutlet="!ComponentType.includes(document.type)? componentTab : docTab; context: { $implicit: document, list: openedDocuments.documents }"></ng-container>
      </div>
      <div class="col-1 d-xl-block d-lg-block d-md-block d-none dropdown-wrapper" ngbDropdown *ngIf="openedDocuments.documents.length > tabNumber"
        placement="start" display="static">
        <div class="tab-bar-menu" [ngClass]="{ 'active-dropdown': isActiveDocInTab }" ngbDropdownToggle>
          <span class="tab-bar-arrow down-chevron"></span>
          <ul class="tab-menu-dropdown" ngbDropdownMenu>
            <li ngbDropdownItem>
              <div class="tab-menu-item" (click)="clearAllTabs()">
                <span class="doc-title clearAll">{{ 'ResultsPage.clearAll' | translate }}</span>
              </div>
            </li>
            <li ngbDropdownItem *ngFor="let document of openedDocuments.documents | slice:tabNumber" [ngClass]="{ 'active-dropdown-tab' : isActive(document) }">
              <div class="tab-menu-item" (click)="openDocument(document)">
                <ng-container *ngTemplateOutlet="!ComponentType.includes(document.type)? componentTab : docTab; context: { $implicit: document, list: openedDocuments.documents }"></ng-container>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="document-tab col-12 col-sm-11 d-xl-none d-lg-none d-md-none d-flex" (click)="openDocument(getActive())"
           *ngIf="getActive() as document">
        <ng-container *ngTemplateOutlet="!ComponentType.includes(document.type)? componentTab : docTab; context: { $implicit: document, list: openedDocuments.documents }"></ng-container>
      </div>
      <div class="col-1 d-xl-none d-lg-none d-md-none d-sm-block dropdown-wrapper" ngbDropdown *ngIf="openedDocuments.documents.length > 1">

        <div *ngIf="openedDocuments.documents.length > 1" class="tab-bar-menu" ngbDropdownToggle>
          <span class="tab-bar-arrow down-chevron"></span>
          <ul class="tab-menu-dropdown" ngbDropdownMenu>
            <li ngbDropdownItem>
              <div class="tab-menu-item" (click)="clearAllTabs()">
                <span class="doc-title clearAll">{{ 'ResultsPage.clearAll' | translate }}</span>
              </div>
            </li>
            <li ngbDropdownItem *ngFor="let document of openedDocuments.documents">
              <div class="tab-menu-item" (click)="openDocument(document)">
                <ng-container *ngTemplateOutlet="!ComponentType.includes(document.type)? componentTab : docTab; context: { $implicit: document, list: openedDocuments.documents }"></ng-container>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
