import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { DocumentItem } from '../document-models/document-item';
import { DocumentComponent } from '../document-models/documentComponent';
import { FrenchAccentNormalizer } from 'src/app/utility/Objects/frenchNormalizationMap';
import { DirectoryService } from 'src/services/directory.service';
import { DocumentService } from 'src/services/document.service';
import { ReplaySubject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CpsAlphaBarComponent } from '../cps-alpha-bar/cps-alpha-bar.component';
import { DetectMobileView } from 'src/utils/detect-mobile-view';

@Component({
  selector: 'app-cps-directory-document',
  templateUrl: './cps-directory-document.component.html',
  styleUrls: ['./cps-directory-document.component.scss']
})
export class CpsDirectoryDocumentComponent implements OnInit, DocumentComponent, OnDestroy {
  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  @ViewChild('alphaBar') alphaBar: CpsAlphaBarComponent;

  public doc: DocumentItem;
  public document: Document;

  public list;
  public identifier;
  public fan: FrenchAccentNormalizer;
  public currentLetter = 'a';
  public showID: string;
  public directoryDetails: string;
  public directorySet: Map<string, string>;
  public alphabarSelectedChar = 'a';

  private headingIdPrefix: string;
  public contentLoaded = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  // Search variables
  public searchText: string;
  public hasSearchBeenMade: boolean;
  public currentFocusIndex: number;
  public totalMatchesNum: number;
  public highlightList = [];
  public titlesList = [];

  constructor(public directoryService: DirectoryService, private documentService: DocumentService,
    public viewportScroller: ViewportScroller) {
    this.fan = new FrenchAccentNormalizer();
    this.directorySet = new Map<string, string>();
    this.directoryDetails = '';

  }

  ngOnInit(): void {
    this.headingIdPrefix = '#' + this.doc.id;
    this.viewportScroller.scrollToPosition([0, 0]);

    this.onSearchTextChanged(this.doc.searchText);
    this.onSearchIndexChange(0);
    this.searchText = this.doc.searchText;

    switch (this.doc.id) {
      case 'manufacturers':
        this.identifier = 'manufacturers';

        if (DirectoryService.manufacturers === undefined) {
          this.directoryService.getManufacturers()
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {

            DirectoryService.manufacturers = value;
            this.list = value.organizationinfo;
            this.titlesList = value.organizationinfo;

            DirectoryService.manufacturers.titleList = DirectoryService.manufacturers.organizationinfo.map(info => info.title);

            DirectoryService.keywordSearchList.set(this.doc.id, this.list);

            this.excludeNonParticipating();
          });
        } else {
          this.list = DirectoryService.manufacturers.organizationinfo;
        }
        break;
      case 'healthorgs':
        this.identifier = 'healthorgs';
        if (DirectoryService.healthorgs === undefined) {
          this.directoryService.getHealthOrgs().pipe(takeUntil(this.destroy$)).subscribe((value) => {

            DirectoryService.healthorgs = value;
            this.list = value.organizationinfo;

            DirectoryService.healthorgs.titleList = DirectoryService.healthorgs.organizationinfo.map(info => info.title);

            DirectoryService.keywordSearchList.set(this.doc.id, this.list);

          });
        } else {
          this.list = DirectoryService.healthorgs.organizationinfo;
        }
        break;
    }

    setTimeout(() => {
      this.checkContentLoaded();
    }, 200);
  }

  isANumber(letter: string): string {
    if (isNaN(Number(letter))) {
      return letter;
    }
    return '#';
  }

  normalizeTitle(title: string): string {
    return this.fan.frenchAccentNormalizer(title);
  }

  newHeading(letter: string): boolean {
    const l = this.isANumber(letter);

    if (this.alphabarSelectedChar.toLowerCase() === l.toLowerCase()) {
      Promise.resolve().then(() => {
        this.alphabarSelectedChar = '';
      });
      // setTimeout(()=>{
      //   this.alphabarSelectedChar = '';
      // })
      return true;
    }

    if (this.currentLetter !== l) {
      this.currentLetter = l;
      return true;
    }
    return false;
  }

  showItem(id: string): void {
    if (this.showID === id) {
      this.showID = '';
    } else {
      this.showID = id;
    }
  }

  onSelectedAlphaChange(selectedLetter: string): void {
    this.currentLetter = selectedLetter;
    this.alphabarSelectedChar = selectedLetter;
    const headerId = this.headingIdPrefix + selectedLetter;
    const headerElement = document.querySelector(headerId);
    // the scroll position is not accounted for in boundingClientRect().top
    const headerPosition = headerElement.getBoundingClientRect().top + document.documentElement.scrollTop;
    let scrollOffsetForHeader = 0;
    if(window.innerWidth < 400) {
        scrollOffsetForHeader = 270 ;
    } else if (window.innerWidth >= 400 && window.innerWidth <= 500) {
         scrollOffsetForHeader = 360;
    }  else if (window.innerWidth > 500 && window.innerWidth <= 768) {
         scrollOffsetForHeader = 320;
    } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        scrollOffsetForHeader = 300;
     } else {
         scrollOffsetForHeader = 260;
     }
    window.scrollTo(0, headerPosition - scrollOffsetForHeader);
  }

  getDirectoryDetail(id: string): void {

    this.directoryDetails = '';

    if (this.directorySet.has(id)) {
      this.directoryDetails = this.directorySet.get(id);
    } else {
      this.directoryService.getDirectoryDetail(id, this.identifier)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {

        if (value == null || value === '') {
          this.directoryDetails = '';
        } else {
          this.directorySet.set(id, value);
          this.directoryDetails = value;
        }
      },
      (error) => {
        this.directoryDetails = '';
      });
    }
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.doc.searchText = this.searchText;
  }


  onSearchTextChanged = (text: string): number => {
    this.searchText = text;
    if (this.searchText !== null && this.searchText !== undefined && this.searchText !== '' && this.searchText.length > 2) {

      this.highlightList = [];
      this.hasSearchBeenMade = true;

      DirectoryService.keywordSearchTerm.set(this.doc.id, this.searchText);

      DirectoryService[this.doc.id].titleList.map(title => {
        if (title.toLowerCase().includes(this.searchText.toLowerCase())) {
          this.highlightList.push(title);
        }
        this.totalMatchesNum = this.highlightList.length;
        this.currentFocusIndex = 0;
      });
    } else if (this.searchText === undefined || this.searchText === '') {
      this.clearSearch();
    }
    return this.totalMatchesNum;
  }

  clearSearch(): void {
    this.highlightList = [];
    this.searchText = '';
    this.currentFocusIndex = -1;
    this.totalMatchesNum = 0;
    this.hasSearchBeenMade = false;
    this.viewportScroller.scrollToPosition([0, 0]);
    DirectoryService.keywordSearchTerm.set(this.doc.id, '');
  }

  onSearchIndexChange = (increment: number): number => {

    this.currentFocusIndex = increment;

    if (this.currentFocusIndex < 0) {
      this.currentFocusIndex = 0;
    }

    if (this.currentFocusIndex > this.highlightList.length - 1) {
      this.currentFocusIndex = 0;
    }

    if (this.highlightList.length > 0) {
      this.setSelectedLetter(this.highlightList[this.currentFocusIndex][0]);
      this.scrollToHighlightedText(this.highlightList[this.currentFocusIndex]);
    }
    return this.currentFocusIndex;
  }

  scrollToHighlightedText(id: string): void {
    this.viewportScroller.setOffset([0, 500]);
    this.viewportScroller.scrollToAnchor(id);
  }

  setSelectedLetter(selectedLetter: string): void {
    this.currentLetter = selectedLetter;
    this.alphabarSelectedChar = selectedLetter;
    this.alphaBar.alphaSelected(selectedLetter);
  }

  nextChar(c: string): string {
    return c === 'z' ? null : c === 'Z' ? null : String.fromCharCode(c.charCodeAt(0) + 1);
  }

  excludeNonParticipating(): any {

    let newList = [];
    for (let i = 0; i < this.list.length; i++) {

      const item = this.list[i];
      if (item.participating !== 'no') {
        newList.push(item);
      }
    }
    this.list = newList;
  }
}
