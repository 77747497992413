import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { State } from 'src/app/reducers';
import { DocumentService } from 'src/services/document.service';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { DocumentItem } from '../document-models/document-item';
import { DocumentComponent } from '../document-models/documentComponent';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from 'src/app/search/search.service';

@Component({
  selector: 'app-cps-patient-info-document',
  templateUrl: './cps-patient-info-document.component.html',
  styleUrls: ['./cps-patient-info-document.component.scss']
})
export class CpsPatientInfoDocumentComponent implements OnInit, DocumentComponent, OnDestroy {

  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;
  public htmlElement: HTMLElement;
  public showTOC = true;
  public contentLoaded = false;
  mobileView = false;
  isBucketExpandedMobile: boolean;
  private showBuckets: Subscription;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService, private documentService: DocumentService, private searchService : SearchService) {

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75))
    .pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
      this.isBucketExpandedMobile = !this.mobileView;
    });
  }

  ngOnInit(): void {
    if(this.doc.type === 'MA_PI'){
      this.showTOC = false;
    }
    this.checkContentLoaded();

    this.showBuckets = this.searchService.getIsBucketExpandedMobile()
      .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
        this.isBucketExpandedMobile = isBucketExpandedMobile;
      });
  }
  
  ifpCountChangedHandler(noOfIFP: number) {
    if (noOfIFP <= 1) {
      this.showTOC = false;
    }
    if (noOfIFP > 1) {
      this.showTOC = true;
    }
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


}