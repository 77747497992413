<ng-template #IPchecking>{{ 'login-Page.checking' | translate }}</ng-template>
<div class="login-container">

    <div class="login-panel">

        <div class="row justify-content-center"><span class="login-img site-logo site-logo-big"></span></div>
        <div class="login-error row justify-content-center" *ngIf="loginErrorMessage$ | async as message">
            <div *ngIf="message != ''" [innerHTML]="'error-message.' + message | translate"></div>                
        </div>
        <form [formGroup]="loginForm" enctype="multipart/form-data" (ngSubmit)="login()"role="form" name=loginForm autocomplete="off">
            <div class="row justify-content-center">    
                <div class="field">
                    <input type="text" name="username" id="username" placeholder="Username" formControlName="username" (keyup.enter)="tabUser()" required>
                    <label for="username">{{ 'login-Page.username' | translate }}</label>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="field">
                    <input type="password" name="password" id="password" placeholder="Password" formControlName="password" (keyup.enter)="login()" required>
                    <label for="password">{{ 'login-Page.password' | translate }}</label>
                </div>

                <!-- Test for the Login Error message -->
                <!-- <button type="button" class="btn btn-primary form-control login-button" (click)="login()">{{ 'login-Page.login' | translate }}</button> -->
            </div>

            <div class="row justify-content-center">
                <button type="submit" class="btn btn-primary form-control login-button" [disabled]="isButtonDisabled" >
                    <span class="login-text">{{ 'login-Page.login' | translate }} </span>
                    <span class="button-spinner" *ngIf="isButtonDisabled"></span>
                </button>
            </div>                
        </form>
        <div class="login-info">
            <a target="_blank" href="{{ 'forgotPassword' | routerPipe }}" class="row justify-content-center">{{ 'login-Page.forgotPW' | translate }}</a>
            <h4 class="row justify-content-center login-notSubbed">{{ 'login-Page.notSubbed' | translate }}</h4>
            <a target="_blank" href="{{ 'learnMore' | routerPipe }}" class="row justify-content-center login-learnMore">{{ 'login-Page.learnMore' | translate }}</a>
            <p class="row justify-content-center login-contact">{{ 'login-Page.haveTrouble' | translate }}</p>
            <p class="row justify-content-center login-contact">{{ 'login-Page.email' | translate }}</p>
            <p class="row justify-content-center login-contact">{{ 'login-Page.tel' | translate }}</p>
            <p class="row justify-content-center login-contact">
                {{ 'login-Page.ip' | translate }}
                <span *ngIf="userIp$ | async as userIp; else IPchecking" class="nowidth">{{ userIp }}</span>
            </p>
        </div>
    </div>
</div>
