import { GenericListActions, GenericListActionTypes } from '../actions/generic-list.actions';
import { GenericList } from '../document/cps-generic-index/genericList.interface';

export const GenericListKey = 'genericListKey';

export interface GenericListState {
    genericList: GenericList;
}

export const initialState: GenericListState = {
    genericList: null
}

export function genericListReducer(state = initialState, action: GenericListActions): GenericListState {
    switch (action.type) {
        case GenericListActionTypes.AddGenericList:
            state.genericList = action.genericList;
            return {...state};
        case GenericListActionTypes.LoadGenericList:
            return state;
        case GenericListActionTypes.ClearGenericList:
            return initialState;
        default:
            return state;
    }
}
