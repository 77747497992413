import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpsHomeAlertsComponent } from './cps-home-alerts/cps-home-alerts.component';
import { CpsHomeCustomizerComponent } from './cps-home-customizer/cps-home-customizer.component';
import { CpsHomeSidebarComponent } from './cps-home-sidebar/cps-home-sidebar.component';
import { CpsHomeFeatureComponent } from './cps-home-feature/cps-home-feature.component';
import { CpsHomeHistoryComponent } from './cps-home-history/cps-home-history.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    CpsHomeAlertsComponent, 
    CpsHomeCustomizerComponent, 
    CpsHomeSidebarComponent, 
    CpsHomeFeatureComponent, 
    CpsHomeHistoryComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    CpsHomeAlertsComponent, 
    CpsHomeCustomizerComponent, 
    CpsHomeSidebarComponent, 
    CpsHomeFeatureComponent, 
    CpsHomeHistoryComponent
  ]
})
export class HomeModule { }
