<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="true" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>

    <div class="doc-content-wrapper">        
        
        
        <!-- Skeleton-loader for desktop view -->
        <div class="skeleton-first-wrapper" *ngIf="!contentLoaded && !mobileView">
            <div>
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '230px', height: '700px', 'border-radius': '10px' }">
                </ngx-skeleton-loader>
            </div>
            <div class="skeleton-first-wrapper-items">
                <ngx-skeleton-loader [theme]="{width: '400px', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '300px', 'margin-left': '660px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <div class="skeleton-text-line">
                    <ngx-skeleton-loader [theme]="{width: '100%', 'border-radius': '10px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{width: '100%', 'border-radius': '10px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{width: '25%', 'border-radius': '10px', 'margin-bottom': '5px'}"></ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '100%', 'border-radius': '30px', height: '100px', 'margin-bottom': '5px'}">
                    </ngx-skeleton-loader>
                </div>                
                
                <div>
                    <ngx-skeleton-loader
                    [theme]="{width: '100%', 'border-radius': '10px', height: '35px', 'margin-left': '10px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                </div>
                <div class="skeleton-text-line">
                    <ngx-skeleton-loader count="5" [theme]="{width: '100%',  height: '60px','border-radius': '10px', 'margin-left': '5px','margin-bottom': '0px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>  
        
        <div class="skeleton-second-wrapper" *ngIf="!contentLoaded && !mobileView">
            <div>
                <ngx-skeleton-loader [theme]="{width: '230px', 'margin-left': '5px', display: 'block', 'border-radius': '10px'}"
                    count="2"></ngx-skeleton-loader>
            </div>        
        </div>
        
        <!-- Skeleton-loader for mobile devices -->
        <div *ngIf="!contentLoaded && mobileView">
            <div>
                <ngx-skeleton-loader [theme]="{width: '80%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '50%', 'margin-left': '50%', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="4" [theme]="{width: '100%', 'border-radius': '10px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '45%', 'border-radius': '10px', 'margin-bottom': '5px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '100%', 'border-radius': '30px', height: '100px', 'margin-bottom': '5px', 'margin-left': '0px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '100%', 'border-radius': '10px', height: '35px', 'margin-left': '0px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                <div class="skeleton-text-line">
                    <ngx-skeleton-loader count="5" [theme]="{width: '100%',  height: '60px','border-radius': '10px', 'margin-left': '0px','margin-bottom': '0px'}"></ngx-skeleton-loader>
                </div>    
            </div>
        </div>

        <div *ngIf="contentLoaded" #docWrapper class="clininfo-wrapper">
            <div [innerHTML]="htmlElement.innerHTML | safe: 'html'">
            </div>
        </div>
    </div>
</div>