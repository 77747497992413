import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cps-action-bar',
  templateUrl: './cps-action-bar.component.html',
  styleUrls: ['./cps-action-bar.component.scss']
})
export class CpsActionBarComponent implements OnInit {

  constructor(public translationService: TranslateService) {

  }
  
  ngOnInit(): void {
  }

}
