import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpsPidSearchComponent } from './cps-pid-search/cps-pid-search.component';
import { CpsPidItemComponent } from './cps-pid-item/cps-pid-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CpsToolBarComponent } from '../document/cps-tool-bar/cps-tool-bar.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [CpsPidSearchComponent, CpsPidItemComponent, CpsToolBarComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    CpsPidSearchComponent,
    CpsPidItemComponent,
    CpsToolBarComponent
  ]
})
export class UtilityModule { }
