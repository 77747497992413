import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HomeService } from 'src/services/home.service';
import { HomeAlertsActionTypes, LoadHomeAlerts } from '../actions/home-alerts.action';

@Injectable()
export class HomeAlertEffects {

    @Effect()
    loadWhatsNew$: Observable<any> = this.actions$.pipe(
        ofType(HomeAlertsActionTypes.PopulateHomeAlerts),
            mergeMap(() =>
                this.homeService.getHomeAlerts().pipe(
                    map(xml => {
                        const result = this.parseHomeAlerts(xml);
                        return new LoadHomeAlerts(result);
                    }),
                    catchError(() => EMPTY)
                )
        )
    );

    constructor(private actions$: Actions<LoadHomeAlerts>, private homeService: HomeService, private translationService: TranslateService) {
        // Empty
    }

    parseHomeAlerts(xml: string): Element[] {
        let alerts = null;

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'text/xml');

        const messages = xmlDoc.getElementsByTagName('message');

        if (messages.length > 0) {
            alerts = [];

            for (const item in messages) {

                if (!isNaN(+item) &&
                    messages[item].getAttribute('language').toLocaleLowerCase() === this.translationService.currentLang.toLocaleLowerCase()) {
                    alerts.push(messages[item]);
                }
            }
        }

        return alerts;
    }

}
