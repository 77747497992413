import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { userProfile } from 'src/app/utility/Objects/userProfile';
import { SessionService } from '../session.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { JwtTokenService } from '../jwt-token.service';
import { CpsSessionExpiredModalComponent } from 'src/app/modals/cps-session-expired-modal/cps-session-expired-modal.component';
import { DatalayerService } from '../datalayer.service';
import { AuthResponse } from './auth-response.interface';
import { Store } from '@ngrx/store';
import { RemoveUserProfile } from 'src/app/actions/user-profile.actions';
import { ClearAllDocuments } from 'src/app/actions/document-list.actions';
import { ClearMenuDocument } from 'src/app/actions/document-data.actions';
import { ClearGenericList } from 'src/app/actions/generic-list.actions';
import { RemoveSearchResults } from 'src/app/actions/search-result.actions';
import { RemoveSearchTerm } from 'src/app/actions/search-term.actions';
import { ClearWhatsNew } from 'src/app/actions/whatsnew.actions';
import { ClearWhatsNewTC } from 'src/app/actions/whatsnew-tc.actions';
import { ClearMessages } from 'src/app/monograph/messages.actions';
import { ClearCustomizer } from 'src/app/actions/customizer.actions';
import { DocumentService } from '../document.service';
import { CpsLicenseTakenModalComponent } from 'src/app/modals/cps-license-taken-modal/cps-license-taken-modal.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public static originalRoute = '';

  private refreshTokenAttempts = 0;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private hasError = false;

  constructor(public authService: AuthService,
              private router: Router,
              private sessionService: SessionService,
              private jwtTokenService: JwtTokenService,
              private datalayerService: DatalayerService,
              private store: Store,
              private documentService: DocumentService,
              private modalService: BsModalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let accessToken = null;
    let currentUserProfile: userProfile = null;
    let errorMessage: string;

    currentUserProfile = this.sessionService.userProfile;
    if (!!currentUserProfile && !!currentUserProfile.userId) {
        this.authService.clearLoginErrorMessage();
        accessToken = currentUserProfile.access_token;
    } else {
        if (window.location.pathname.indexOf('login') === -1
            && window.location.pathname.indexOf('pkgselection') === -1
            && window.location.pathname.indexOf('package_switch') === -1) {
          AuthInterceptor.originalRoute = window.location.pathname;
        }
        this.router.navigate(['/login']);
    }
    let authReq = request;

    if (request.url.includes('logout')) {
      if (window.location.pathname.indexOf('login') === -1) {
        AuthInterceptor.originalRoute = '/home';
      }
      AuthService.sID = Math.floor(100000000 + Math.random() * 900000000); // Reset session ID for use tracking
      this.router.navigate(['/login']);
    }

    if (currentUserProfile != null && accessToken != null
        && currentUserProfile.subscriptionInfo != null
        && currentUserProfile.subscriptionInfo.length > 1
        && currentUserProfile.active_Subscription == null) {

      this.router.navigate(['/pkgselection']);
    }

    if (!!accessToken
        && !request.url.includes('oauth/token/cps')
        && !request.url.includes('version')
        && !request.url.includes('login')
        && !request.url.includes('pParam')
        && !request.url.includes('logout') ) {

        if (this.jwtTokenService.isExpired(currentUserProfile.access_token)) {
           return this.getRefreshToken(request, next, currentUserProfile);
        }
        authReq = this.addTokenHeader(request, accessToken);
    }

    return next.handle(authReq).pipe(catchError((error: HttpErrorResponse) => {

      const err = JSON.stringify(error);
      errorMessage = JSON.parse(err).error;

      switch (error.status) {

      case 403:

        return this.getRefreshToken(request, next, currentUserProfile);

      case 400:
      case 401: {

        let removeSubscribe: Subscription;

        const token = this.sessionService.userProfile.refresh_token;

        errorMessage = JSON.parse(err).error.error_description;
        this.authService.setLoginErrorMessage(errorMessage);
          if (!!removeSubscribe) {
            removeSubscribe.unsubscribe();
          }
        if (!!token) {
          if (!!AuthService.userProfileState.userProfile) {
            removeSubscribe = this.authService.removeAccessTokenData(token).subscribe();
          }
          this.clearStore();
        }
        
        return throwError(errorMessage);
      }
      default:
        return throwError(errorMessage);

      }
      
    }),
      finalize(() => {
        this.authService.setRequestCompletionState(true);
      })    
    );
  }

  clearStore(): void {
    this.store.dispatch(new RemoveUserProfile());
    this.store.dispatch(new ClearAllDocuments());
    this.store.dispatch(new ClearMenuDocument());
    this.store.dispatch(new ClearGenericList());
    this.store.dispatch(new RemoveSearchResults());
    this.store.dispatch(new RemoveSearchTerm());
    this.store.dispatch(new ClearWhatsNew());
    this.store.dispatch(new ClearWhatsNewTC());
    this.store.dispatch(new ClearMessages());
    this.store.dispatch(new ClearCustomizer());
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }

  private getRefreshToken(request: HttpRequest<any>, next: HttpHandler, currentUserProfile: userProfile) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenAttempts++;
      this.refreshTokenSubject.next(null);

      return this.authService.getRefreshToken(currentUserProfile.refresh_token).pipe(
        switchMap((token: AuthResponse) => {
          this.isRefreshing = false;
          this.hasError = false;
          this.refreshTokenSubject.next(token.access_token);
          this.sessionService.refreshProfile(token);
          this.authService.clearLoginErrorMessage();
          return next.handle(this.addTokenHeader(request, token.access_token));
        }),
        catchError(err => {

          if (err.status !== 500) {
            this.isRefreshing = false;

            if (!this.hasError) {
              if (!!err.indexOf && (err.indexOf('code.5') !== -1 || err.indexOf('code.11') !== -1)) { // Temporary change to allow for correct error messaging when user license consumed elsewhere
                const sessionexpiredModal = this.modalService.show(CpsLicenseTakenModalComponent, {backdrop : 'static', keyboard : false});
                sessionexpiredModal.content.detectChanges();
              } else {
                // tslint:disable-next-line: max-line-length
                const sessionexpiredModal = this.modalService.show(CpsSessionExpiredModalComponent, {backdrop : 'static', keyboard : false});
                sessionexpiredModal.content.detectChanges();
              }
              this.hasError = true;
            }
            this.datalayerService.loginFailureEvent(1);
            this.sessionService.refreshProfile(null);
            this.documentService.resetDocumentVariables();
            this.store.dispatch(new ClearAllDocuments());
            this.store.dispatch(new ClearMessages());
          } else {
            console.log('unexpected 500 error: ' + err);
          }
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addTokenHeader(request, jwt));
        })
      );
    }
  }

}
