import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription, Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DocumentComponent } from 'src/app/document/document-models/documentComponent';
import { DocumentService } from 'src/services/document.service';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { DocumentItem } from '../document-models/document-item';
import { ReplaySubject } from 'rxjs';
import { DocumentSectionElement } from '../document-models/document-section-element';
import { ScrollToSection } from 'src/app/actions/document-list.actions';
import { SearchService } from 'src/app/search/search.service';
import { Action } from '@ngrx/store';

@Component({
  selector: 'app-cps-monograph-document',
  templateUrl: './cps-monograph-document.component.html',
  styleUrls: ['./cps-monograph-document.component.scss']
})
export class CpsMonographDocumentComponent implements OnInit, DocumentComponent, OnDestroy {
  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;

  public displayHTML: HTMLElement;

  public scrollPosition: number;
  @HostListener('window:scroll', ['$event'])
  scrollEvent(event) {
    this.scrollPosition = document.body.scrollTop;
    if (this.scrollPosition < 3000) {
      for (let i = 0; i < this.monographSections.length-1 && i < 3; i++) {
        this.monographSections[i].isHidden = false;
      }
    }
  }

  public isManufacturerModalInitialized: boolean;
  public contentLoaded = false;
  public isDisplayFullHTML = false;
  mobileView = false;
  isBucketExpandedMobile: boolean;
  private showBuckets: Subscription;

  public monographSections: Array<DocumentSectionElement>;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  private scrollActionListener: Observable<Action>;

  public emptyTOC = false;

constructor(public translationService: TranslateService, private router: Router, private documentService: DocumentService, private searchService: SearchService) {
   this.mobileView = DetectMobileView.detectScreenSize();
    this.monographSections = new Array<DocumentSectionElement>();
    
    fromEvent(window, 'resize').pipe(debounceTime(75)).pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
      this.isBucketExpandedMobile = !this.mobileView;
    });

    this.documentService.getDisplayFullHTML().pipe(takeUntil(this.destroy$)).subscribe(isDisplayEverything => {
      this.isDisplayFullHTML = isDisplayEverything;
    });
  }
  
  ngOnInit(): void {
    
    this.calculateVisibleSections();
    this.checkContentLoaded();

    this.showBuckets = this.searchService.getIsBucketExpandedMobile()
      .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
        this.isBucketExpandedMobile = isBucketExpandedMobile;
      });
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;

      if (isLoaded) {
        const newHtmlParser = new DOMParser();
        thizz.displayHTML = newHtmlParser.parseFromString(thizz.htmlElement.innerHTML, 'text/html').documentElement;
        const numOfDomElements = thizz.htmlElement.getElementsByTagName('*').length;

        this.emptyTOC = thizz.displayHTML.getElementsByClassName('emptyTOC').length > 0;
        if (numOfDomElements > 20000) { 

          // seperate the section headers and store them
          let sectionheaders = thizz.displayHTML.querySelectorAll<HTMLElement>('.nested1.collapsablesection');

          // add each element to the sections array, then if it's put in the array remove it from the dom
          // to avoid duplicates
          for (let i = 0; i < sectionheaders.length; i++) {
            const newDocSectionElement = new DocumentSectionElement();
            newDocSectionElement.element = sectionheaders[i];
            newDocSectionElement.offsetTop = i;
            newDocSectionElement.sectionIndex = i;
            const sectionHeader = sectionheaders[i].querySelector('.sectiontitle2') as HTMLElement;
            const sectionAnchor = sectionheaders[i].querySelector('.section-head').firstElementChild;
            newDocSectionElement.sectionTitleElement = sectionHeader as HTMLElement;
            newDocSectionElement.height = sectionHeader.children.length;
            newDocSectionElement.sectionName = sectionAnchor.getAttribute('name');
            if (i < 3)
              newDocSectionElement.isHidden = false;
            this.monographSections.push(newDocSectionElement);
            sectionheaders[i].remove();
          }
          this.calculateVisibleSections();
        }

        if (this.scrollActionListener == null) {
          this.scrollActionListener = this.documentService.scrollActionListenerObservable();
          this.scrollActionListener.pipe(takeUntil(this.destroy$)).subscribe((action: ScrollToSection) => {
            if (this.doc.isActive) {
              const monographSection = this.monographSections.find(m => m.sectionName.toLowerCase() == action.sectionName.toLowerCase());
              if (!!monographSection) {
          
                for (const section of this.monographSections) {
                  section.isHidden = true;
                }
                monographSection.isHidden = false;

                const activeDoc = document.querySelector('.active-document');
                let section = activeDoc.querySelector('#' + monographSection.sectionName);
                if (section == null) {
                  section = activeDoc.querySelector('a[name="' + monographSection.sectionName + '"');
                }

                section.scrollIntoView({block: 'center'});
                
              }
            }
          });
        }
      }
    });
  }

  calculateVisibleSections() {
    for (const docSection of this.monographSections) {
      if (this.scrollPosition > docSection.offsetTop) {
        docSection.isHidden = false;
      }
    }
  }

  // Function called when a certain section becomes visible on the viewport
  onSectionView(value: any, index: number) {
    if (value === true) {
      if (index > 1) {
        for (let i = 0; i < index - 3; i++) {
          this.monographSections[i].isHidden = true;
        }
      }
      
      // Show the sections before and after the one currently being viewed
      if (index - 1 > -1)
        this.monographSections[index - 1].isHidden = false;
      // this shouldn't be neccessary, the element is already visible
      this.monographSections[index].isHidden = false;

      if (index + 1 < this.monographSections.length)
        this.monographSections[index + 1].isHidden = false;

      for (let i = index + 2; i < this.monographSections.length; i++) {
        this.monographSections[i].isHidden = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    //this.htmlElement = null;
  }
}
