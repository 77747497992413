<div *ngIf="switchesRequired" [ngClass]="{'condensed': isHeaderCondensed, 'searchTermFilled': isSearchTermFilled }">
    <div class="switches-Nav">
        <ul class="nav nav-pills">
            <li>
                <a class="nav-link"[ngClass]="{'switchDisabled':monographCount===0, 'active' : isActive('monographs')}" 
                    (click)="reloadCurrentRoute('monographs')">
                    <div [ngClass]="{'truncate-string': isHeaderCondensed || isSearchTermFilled }">{{'Search.monographSwitch' |translate }}</div>
                    <span class="result-count" [ngClass]="{'condensed': isHeaderCondensed, 'searchTermFilled': isSearchTermFilled }">{{monographCount}}</span>
                </a>
            </li>
            <li > 
                <a class="nav-link" [ngClass]="{'switchDisabled':conditionsCount===0, 'active' : isActive('conditions')}"
                    (click)="reloadCurrentRoute('conditions')">
                    <div [ngClass]="{'truncate-string': isHeaderCondensed || isSearchTermFilled }">{{'Search.conditionsSwitch' | translate }}</div>
                    <span class="result-count" [ngClass]="{'condensed': isHeaderCondensed, 'searchTermFilled': isSearchTermFilled }">{{conditionsCount}}</span>
                </a>
            </li>
            <li>
                <a class="nav-link" [ngClass]="{'switchDisabled':patientInfoCount===0, 'active' : isActive('patientinfo')}"
                    (click)="reloadCurrentRoute('patientinfo')">
                    <div [ngClass]="{'truncate-string': isHeaderCondensed || isSearchTermFilled }">{{'Search.patientInformation' | translate}}</div>
                    <span class="result-count" [ngClass]="{'condensed': isHeaderCondensed, 'searchTermFilled': isSearchTermFilled }">{{patientInfoCount}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>