import { Action, ActionReducer, ActionReducerMap } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { DocumentListState, DOCUMENT_LIST_FEATURE_KEY, reducer as documentListReducer} from '../reducers/document-list.reducer';
import { searchTermFeatureKey } from './search-term.reducer';
import { searchResultFeatureKey } from './search-result.reducer';
import { MessagesFeatureKey } from '../monograph/messages.reducer';
import { DocumentDataFeatureKey } from './document-data.reducer';
import { userProfileFeatureKey } from './user-profile.reducer';
import { HomeHistoryKey } from './home-history.reducer';
import { WhatsNewFeatureKey } from './whatsnew.reducer';
import { WhatsNewTCFeatureKey } from './whatsnew-tc.reducer';
import { PillIdResultFeatureKey } from './pill-id-result-reducer';
import { GenericListKey } from './Generic-list.reducer';
import { authStateKey } from './auth-state.reducer';


export const localStorageSyncReducerFeatureKey = 'localStorageSyncReducer';

export interface State {

}

export const initialState: State = {

};

// set the component refs to null, we don't want to store these since we need to reinitialize them anyways
export function documentListStateToJson(docState: DocumentListState): Record<string, any> {
  return docState;
}

export function localStorageSyncReducer(actionReducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [DOCUMENT_LIST_FEATURE_KEY, HomeHistoryKey, searchTermFeatureKey, searchResultFeatureKey,
           MessagesFeatureKey, DocumentDataFeatureKey, userProfileFeatureKey, WhatsNewFeatureKey,
           WhatsNewTCFeatureKey, PillIdResultFeatureKey, GenericListKey, authStateKey],
    rehydrate: true
  })(actionReducer);
}

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {

    default:
      return state;
  }
}
