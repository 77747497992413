import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FrenchAccentNormalizer } from 'src/app/utility/Objects/frenchNormalizationMap';
import { DocumentService } from 'src/services/document.service';
import { DocumentItem } from '../document-models/document-item';
import { DocumentComponent } from '../document-models/documentComponent';
import { MenuDocumentData, MenuDocumentDataItem } from 'src/app/reducers/document-data.reducer';
import { Observable, Subscription } from 'rxjs';
import { CpsAlphaBarComponent } from '../cps-alpha-bar/cps-alpha-bar.component';
import { SessionService } from 'src/services/session.service'
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cps-menu-document',
  templateUrl: './cps-menu-document.component.html',
  styleUrls: ['./cps-menu-document.component.scss']
})
export class CpsMenuDocumentComponent implements OnInit, OnDestroy, DocumentComponent {

  @Input() htmlElement: HTMLElement;

  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  @ViewChild('alphaBar') alphaBar: CpsAlphaBarComponent;
  @Input() public doc: DocumentItem;
  public document: Document;

  public alphaType = 'alpha';
  public bodyType = 'body';

  public titlesList = [];
  public highlightList = [];

  private fan: FrenchAccentNormalizer;
  public currentLetter = '';
  private displayedLettersList = [];
  private alphabarSelectedChar = 'a';
  public sortType: string;

  private maxListSize = 300;

  specificMenuData$: Observable<MenuDocumentData>;
  allData: MenuDocumentDataItem[];
  filteredData: MenuDocumentDataItem[];

  public searchText: string;
  public hasSearchBeenMade: boolean;
  public currentFocusIndex: number;
  public totalMatchesNum: number;

  private subMenuData: Subscription;
  public contentLoaded = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private checkContentLoadedTimeout: any;
  private scrollToTextTimeout: any;

  constructor(public translationService: TranslateService, 
              public documentService: DocumentService,
              public viewportScroller: ViewportScroller,
              private cdRef: ChangeDetectorRef,
              private sessionService: SessionService,
              private router: Router) {

    this.fan = new FrenchAccentNormalizer();
    this.sortType = this.alphaType;

    this.currentFocusIndex = 0;
    this.totalMatchesNum = 0;
    this.hasSearchBeenMade = false;
  }

  ngOnInit(): void {
    
    this.currentLetter = 'a';
    
    const l = this.documentService.getSelectedAlpha(this.doc.id);

    this.onSearchTextChanged(this.doc.searchText);
    this.onSearchIndexChange(0);
    this.searchText = this.doc.searchText;

    if (l !== undefined && l !== '') {
      this.currentLetter = l;
    }
    
    this.displayedLettersList = [this.currentLetter];
    
    this.specificMenuData$ = this.documentService.getSpecificMenuDocument(this.doc.id);
    this.subMenuData = this.specificMenuData$.subscribe(data => {
      if (!!data) {
        this.titlesList = data.titlesList;
        this.allData = data.resultsList;
        this.updateFilteredList();
      }
    });

    this.checkContentLoadedTimeout = setTimeout(() => {
      this.checkContentLoaded();
    }, 200);    
  }
  
  isANumber(letter: string): string {
    if (isNaN(Number(letter))) {
      return letter;
    }
    return '#';
  }
  
  normalizeTitle(title: string): string {
    return this.fan.frenchAccentNormalizer(title);
  }
  
  filterBy(resultsList, filter): MenuDocumentDataItem[] {
    return resultsList.filter(item => item.bodySystemDescriptorTextLst[0] 
      && item.bodySystemDescriptorTextLst[0] == filter);
    }
    
    newHeading(letter: string): boolean {
      if (!isNaN(Number(letter))) {
        letter = '#';
    }
    if (this.alphabarSelectedChar.toLowerCase() === letter.toLowerCase()) {
      this.alphabarSelectedChar = '';
      return true;
    }
    if (this.currentLetter.toLowerCase() !== letter.toLowerCase()) {
      this.currentLetter = letter;
      return true;
    }
    return false;
  }
  
  resultOrderType(type: string): void {
    
    this.sortType = type;
    this.viewportScroller.scrollToPosition([0, 0]);
  }
  
  onSearchTextChanged = (text: string): number => {
    this.searchText = text;
    if (this.searchText !== null && this.searchText !== undefined && this.searchText !== '' && this.searchText.length > 2) {
      this.highlightList = [];
      this.hasSearchBeenMade = true;
      for (const index in this.titlesList) {
        if (index !== undefined) {
          const title = this.titlesList[index];
          if (title.toLowerCase().includes(this.searchText.toLowerCase())) {
            this.highlightList.push(title);
          }
        }
      }
      this.totalMatchesNum = this.highlightList.length;
      this.currentFocusIndex = 0;

    } else if (this.searchText === '') {
      this.highlightList = [];
      this.hasSearchBeenMade = false;
      this.clearSearch();
    }
    return this.totalMatchesNum;
  }

  clearSearch(): void {
    this.searchText = '';
    this.currentFocusIndex = -1;
    this.totalMatchesNum = 0;
    this.hasSearchBeenMade = false;
  }

  onSearchIndexChange = (increment: number): number => {
    this.currentFocusIndex = increment;
    if (this.currentFocusIndex < 0) {
      this.currentFocusIndex = 0;
    }
    if (this.currentFocusIndex > this.highlightList.length - 1) {
      this.currentFocusIndex = 0;
    }
    
    if (this.highlightList.length > 0) {
      if (this.titlesList.indexOf(this.highlightList[this.currentFocusIndex][0].toLowerCase()) < 0) {
        this.setSelectedLetter(this.highlightList[this.currentFocusIndex][0]); 
        this.currentLetter = this.highlightList[this.currentFocusIndex][0]; 
        this.updateFilteredList();
        const thizz = this;
        this.scrollToTextTimeout = setTimeout(() => {
          thizz.scrollToHighlightedText(thizz.doc.id + thizz.highlightList[thizz.currentFocusIndex]);
        }, 200);
      } else {
        this.scrollToHighlightedText(this.doc.id + this.highlightList[this.currentFocusIndex]);
      }
    }
    return this.currentFocusIndex;
  }

  setSelectedLetter(selectedLetter: string): void {
    this.currentLetter = selectedLetter;
    this.alphabarSelectedChar = selectedLetter;
    this.displayedLettersList = [this.currentLetter.toLowerCase()];
    this.alphaBar.alphaSelected(selectedLetter);
  }

  onSelectedAlphaChange(selectedLetter: string): void {
    this.currentLetter = selectedLetter;
    this.alphabarSelectedChar = selectedLetter;
    this.displayedLettersList = [this.currentLetter.toLowerCase()];
    this.documentService.setSelectedAlpha(this.doc.id, selectedLetter);
    this.updateFilteredList();
  }

  updateFilteredList(): void {
    if (this.isANumber(this.currentLetter) === '#') {
      this.filteredData = this.allData.filter(d => !d.title[0].toLowerCase().match(/[a-z]/i));
    } else {
      this.filteredData = this.allData.filter(d => d.title.length > 0 && this.displayedLettersList.indexOf(d.title[0].toLowerCase()) > -1);
      this.displayedLettersList = [this.currentLetter.toLowerCase()];
      let nextLetter = '';
      while (this.filteredData.length < this.maxListSize && nextLetter !== null) {
        nextLetter = this.nextChar(this.displayedLettersList[this.displayedLettersList.length - 1].toLowerCase());
        this.displayedLettersList.push(nextLetter);
        this.filteredData = this.allData.filter(d => d.title.length > 0
          && this.displayedLettersList.indexOf(d.title[0].toLowerCase()) > -1);
      }
    }
    this.cdRef.detectChanges();
    this.sessionService.isScrollLocked = true;
    window.scrollTo(0,0);
  }

  scrollToHighlightedText(id: string): void {
    this.viewportScroller.setOffset([0, 500]);
    this.viewportScroller.scrollToAnchor(id);
  }

  public getPathFromType(type: string): string {
    return DocumentItem.getPathFromType(type);
  }

  public locatorToPath(locator: string): string[] {
    return DocumentItem.locatorToPath(locator);
  }

  public navigateToPath(locator: string): void {
    this.router.navigate(DocumentItem.locatorToPath(locator));
  }

  public isFirstOfList(item, list): boolean {
    return list.indexOf(item) === 0;
  }

  nextChar(c: string): string {
    return c === 'z' ? null : c === 'Z' ? null : String.fromCharCode(c.charCodeAt(0) + 1);
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    if (this.subMenuData) this.subMenuData.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
    this.doc.searchText = this.searchText;
    clearTimeout(this.checkContentLoadedTimeout);
    clearTimeout(this.scrollToTextTimeout);
  }
}
