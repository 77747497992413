import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../session.service';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService,
              private sessionService: SessionService,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (window.location.pathname.indexOf('login') === -1 
        && window.location.pathname.indexOf('pkgselection') === -1 
        && window.location.pathname.indexOf('package_switch') === -1
        && window.location.pathname.indexOf('search') === -1
        && window.location.pathname.indexOf('updates') === -1){
      AuthInterceptor.originalRoute = window.location.pathname;
    }
    const isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated){
      const params = new Proxy(new URLSearchParams(window.location.search),
      { get: (searchParams, prop) => searchParams.get(prop as string), }
      );
      
      const rParam = (params as any).r;
      if (window.location.pathname.includes('/home') && rParam) {
        this.sessionService.setAutoLoginByWelcomeAction(rParam);
        return this.router.navigate(['/home']);
      }
      return this.router.navigate(['/login'], { queryParams: { p: route.queryParams.p }, queryParamsHandling: "merge" });
    }    
    return isAuthenticated;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.canActivate(childRoute, state);
  }
}
