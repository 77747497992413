<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }"  *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="true" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>
    
    <!-- Skeleton-loader for desktop view -->
    <div *ngIf="!contentLoaded && !mobileView">
        <div class="skeleton-wrapper">
            <div class="skeleton-second-wrapper">
                <div class="skeleton-wrapper-title">
                    <ngx-skeleton-loader
                        [theme]="{width: '400px', height: '35px', 'border-radius': '10px', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                </div>
        
                <div class="skeleton-wrapper-calculator">
                    <div>
                        <ngx-skeleton-loader count="5" [theme]="{width: '150px', 'margin-top': '10px', 'margin-bottom':'20px'}"
                            style="display: grid; align-items: center;"></ngx-skeleton-loader>
                    </div>
                    <div>
                        <ngx-skeleton-loader count='5' appearance="circle" style="display: grid; align-items: center;"
                            [theme]="{ width: '250px', height: '40px', 'border-radius': '10px', 'margin-left': '100px', 'margin-top': '5px'}"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        
            <div class="skeleton-wrapper-calculated-results">
                <ngx-skeleton-loader appearance="circle"
                    [theme]="{ width: '500px', height: '400px', 'border-radius': '10px', 'margin-left': '150px', 'margin-top': '0px'}">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class="skeleton-text-line">
            <ngx-skeleton-loader [theme]="{width: '50%', 'margin-top': '0px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '50%', height: '40px', 'margin-top': '0px'}" style="display: grid;"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '30%', 'margin-top': '0px'}" style="display: grid;"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '60%', 'margin-top': '0px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '20%', 'margin-top': '0px'}" style="display: grid;"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '60%', 'margin-top': '0px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '30%', 'margin-top': '0px'}" style="display: grid;"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="3" [theme]="{width: '60%', 'margin-top': '0px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '50%', 'margin-top': '0px', 'margin-bottom': '30px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="3" [theme]="{width: '85%', 'margin-top': '0px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>
        </div>
    </div>

    <!-- Skeleton-loader for mobile devices -->
    <div *ngIf="!contentLoaded && mobileView">
        <ngx-skeleton-loader [theme]="{width: '80%', height: '35px', 'border-radius': '10px', 'margin-top': '20px'}"></ngx-skeleton-loader>
        <div class="skeleton-wrapper-calculator-mobile">
            <div>
                <ngx-skeleton-loader count="5" [theme]="{width: '90px', 'margin-top': '10px', 'margin-bottom':'20px'}" style="display: grid; align-items: center;"></ngx-skeleton-loader>
            </div>
            <div>
                <ngx-skeleton-loader count='5' appearance="circle" style="display: grid; align-items: center;" [theme]="{ width: '150px', height: '40px', 'border-radius': '10px', 'margin-left': '100px', 'margin-top': '5px'}"></ngx-skeleton-loader>
            </div>
        </div>
        <ngx-skeleton-loader appearance="circle" [theme]="{ width: '100%', height: '400px', 'border-radius': '10px', 'margin-left': '0px', 'margin-top': '20px'}">
        </ngx-skeleton-loader>
    </div>

    <div class="renalCalculator doc-content-wrapper"  #docWrapper>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 RENAL-Container">
                <div class="row">
                    <div class="col-12 spacingAdjustment" *ngIf="contentLoaded">
                        <div class="renal-title">{{ 'RenalCalculator.title' | translate }}</div>
                        <div class="RENAL-Inner-Container">
                            <form [formGroup]="RENALForm">
                                <div class="row">
                                    <div class="col-12 fieldSpacing">
                                        <div class="calLabel labelWidth">{{ 'RenalCalculator.sex' | translate }}</div>
                                        <div class="genderArea">
                                            <div class="diplayPosition">
                                                <button type="button"  class="genderButtonMale genderButton" [ngClass]="{'active': gender==='male'}" (click)="selectGender('male')">
                                                    <span class="genderText">{{ 'RenalCalculator.male' | translate }}</span>
                                                </button>
                                            </div>
                                            <div class="diplayPosition">
                                                <button type="button"  class="genderButtonFemale genderButton" [ngClass]="{'active': gender==='female'}" (click)="selectGender('female')">
                                                    <span class="genderText">{{ 'RenalCalculator.female' | translate }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Race section removed as per XMLT-11 -->
                                    <!-- <div class="col-12 fieldSpacing">
                                        <div class="calLabel labelWidth">{{ 'RenalCalculator.race' | translate }}</div>
                                        <div class="raceArea">
                                            <div class="diplayPosition">
                                                <button type="button" class="raceButtonNB genderButton" [ngClass]="{'active': race==='nonblack'}" (click)="selectRace('nonblack')">
                                                    <span class="genderText">{{ 'RenalCalculator.nonblack' | translate }}</span> 
                                                </button>
                                            </div>
                                            <div class="diplayPosition">
                                                <button type="button" class="raceButtonB genderButton" [ngClass]="{'active': race==='black'}" (click)="selectRace('black')">
                                                    <span class="genderText">{{ 'RenalCalculator.black' | translate }}</span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-12 fieldSpacing">
                                        <div class="calLabel labelWidth">{{ 'RenalCalculator.age' | translate }}</div>
                                        <div class="ageArea" id="ageArea">
                                            <div class="wtTextField"><input id="ageField" aria-labelledby="ageArea" formControlName="ageField"
                                                    autocomplete="off" type="number" class="ageFieldClass"></div>
                                            <div class="ageLabel">
                                                {{ 'RenalCalculator.yearsold' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 fieldSpacing">
                                        <div class="calLabel labelWidth">{{ 'RenalCalculator.weight' | translate }}</div>
                                        <div class="wtArea" id="weightArea">
                                            <div class="wtTextField"><input id="wtFieldRenal" aria-labelledby="weightArea" formControlName="weightFieldRenal"
                                                    autocomplete="off" type="number" class="wtFieldClass"></div>
                                            <div class="wtDropDown">
                                                <div ngbDropdown class="d-inline-block dropDownField">
                                                    <button type="button" class="btn unitDrpDwn" id="weightDropdownRenal" ngbDropdownToggle>
                                                        <div class="selectedUnitValue">
                                                            <span>{{'RenalCalculator.'+selectedWeightUnit | translate}}</span>
                                                        </div>
                                                        <div class="downArrow">
                                                            <span class="DropdwnArrow"></span>
                                                        </div>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="unitDropdown">
                                                        <button ngbDropdownItem class="firstButton"
                                                            (click)="selectWeightUnit('kg')">{{'RenalCalculator.kg' |
                                                            translate}}</button>
                                                        <button ngbDropdownItem class="lastButton"
                                                            (click)="selectWeightUnit('lb')">{{'RenalCalculator.lb' |
                                                            translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 fieldSpacing">
                                        <div class="calLabel labelWidth">{{ 'RenalCalculator.creatinine' | translate }}</div>
                                        <div class="ctArea" id="creatinineArea">
                                            <div class="ctTextField"><input id="ctField" aria-labelledby="creatinineArea" formControlName="creatinineField"
                                                    autocomplete="off" type="number" class="ctFieldClass"></div>
                                            <div class="ctDropDown">
                                                <div ngbDropdown class="d-inline-block dropDownField">
                                                    <button type="button" class="btn unitDrpDwn" id="creatinineDropdown" ngbDropdownToggle>
                                                        <div class="selectedUnitValue">
                                                            <span>{{'RenalCalculator.'+selectedCreatinineUnit | translate}}</span>
                                                        </div>
                                                        <div class="downArrow"><span class="DropdwnArrow"></span></div>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="unitDropdown">
                                                        <button ngbDropdownItem class="firstButton"
                                                            (click)="selectCreatinineUnit('micro')">{{'RenalCalculator.micro' |
                                                            translate}}</button>
                                                        <button ngbDropdownItem class="lastButton"
                                                            (click)="selectCreatinineUnit('mg')">{{'RenalCalculator.mg' |
                                                            translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
    
                    <div class="col-12" class="desktopViewClass"  *ngIf="contentLoaded">
                        <ng-template [ngTemplateOutlet]="FormulaRef"></ng-template>
                    </div>
                </div>
            </div>
    
            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 outerSpacing">              
              <div class="documentTitleTextDownload">
                  <a class="link-text" (click)="getPdfItem( environment.API_SERVICE_ENDPOINT+'/'+this.translationService.currentLang+this.fileName, $event)">
                    {{ 'Calculations_and_Dosing_Tools.statement' | translate }}<span class="new-window-icon-blue"></span>
                  </a>
                <div *ngIf="contentLoaded" class="resultPanel">
                    <div class="resultHeading">{{ 'RenalCalculator.calculatedResults' | translate }}</div>
    
                    <div class="cockcroftResult">
                        <div class="label">{{ 'RenalCalculator.cockcroft' | translate }}</div>
                        <div class="result">{{cicrValue}} <span class="unitsize">mL/min</span></div>
                    </div>
                    <div class="mdrdResult">
                        <div class="label">{{ 'RenalCalculator.mdrd' | translate }}</div>
                        <div class="result">{{mdrdValue}} <span class="unitsize">{{ 'RenalCalculator.equationunit' | translate }}<sup>2</sup></span></div>
                    </div>
                    <div class="ckdResult">
                        <div class="label">{{ 'RenalCalculator.ckdepi' | translate }}</div>
                        <div class="result">{{ckdepiValue}} <span class="unitsize">{{ 'RenalCalculator.equationunit' | translate }}<sup>2</sup></span></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="contentLoaded" class="row mobileViewClass">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 outerSpacing">
                <div class="formulaTopBorder"></div>
                <ng-template [ngTemplateOutlet]="FormulaRef"></ng-template>
            </div>
        </div>
        <div *ngIf="contentLoaded" class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 footer">
                <div class="footerText" [innerHTML]=" 'RenalCalculator.footer' | translate "></div>
                <div class="footerText" [innerHTML]=" 'RenalCalculator.footerb' | translate "></div>
                <div class="abbreviation" [innerHTML]=" 'RenalCalculator.abbreviation-label' | translate "></div>

                
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 references">
            <div class="label" [innerHTML]=" 'RenalCalculator.references' | translate "></div>
            <div class="list">
                <ol>
                    <li class="footerText">{{ 'RenalCalculator.ref1' | translate }}</li>
                    <li class="footerText">{{ 'RenalCalculator.ref2' | translate }}</li>
                    <li class="footerText">{{ 'RenalCalculator.ref3' | translate }}</li>
                    <li class="footerText">{{ 'RenalCalculator.ref4' | translate }}</li>
                    <li class="footerText">{{ 'RenalCalculator.ref5' | translate }}</li>
                </ol>
            </div>
        </div>
                
    </div>
    
    <ng-template #FormulaRef> 
        <div class="formula" *ngIf="contentLoaded">
            <div class="formulaDiv">
                <div class="renal-formula-body" [innerHTML]=" 'RenalCalculator.cockcroftlabel' | translate "></div>
                
                <div class="renal-formula-body cockcroftDiv spacing-top">
                    <div class="cockcroftLeftPart">{{ 'RenalCalculator.cockcroft.formula.part1' | translate }}</div>
                    <div class="cockcroftRightPart">
                        <div class="cockcroftpart2">{{ 'RenalCalculator.cockcroft.formula.part2' | translate }}</div>
                        <div class="cockcroftpart3" [innerHTML]=" 'RenalCalculator.cockcroft.formula.part3' | translate "></div>
                    </div>
                    <div class="cockcroftpart4">{{ 'RenalCalculator.cockcroft.formula.part4' | translate }}</div>
                </div>
                
            </div>
        </div>

        <div class="formula" *ngIf="contentLoaded">
            <div class="formulaDiv">
                <div class="renal-formula-body" [innerHTML]=" 'RenalCalculator.mdrdlabel' | translate "></div>
                <div class="renal-formula-body spacing-top" [innerHTML]=" 'RenalCalculator.mdrd.formula' | translate "></div>
            </div>
        </div>
        <div class="formula" *ngIf="contentLoaded">
            <div class="formulaDiv">
                <div class="renal-formula-body" [innerHTML]=" 'RenalCalculator.ckdepilabel' | translate "></div>                
                <div class="renal-formula-body spacing-top" [innerHTML]=" 'RenalCalculator.ckdepi.formula' | translate "></div>
            </div>
        </div>
        <!-- Removed as per XTML-11 -->
        <!-- <div class="formula renal-formula-body" *ngIf="contentLoaded">
			<b>{{ 'RenalCalculator.notice' | translate }}</b>
		</div> -->
    </ng-template>
</div>
