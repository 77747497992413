
export class DetectMobileView {
    
    static detectScreenSize(): boolean {
        return window.innerWidth <= 576;
    }

    static isScreenSizeTabletOrSmaller(): boolean {
        return window.innerWidth <= 767;
    }

    static isScreenSizeTablet(): boolean {
        return window.innerWidth <= 767 && window.innerWidth > 576;
    }
}