import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DocumentDataFeatureKey, DocumentDataState } from './document-data.reducer';

const docDataFeatureState = createFeatureSelector<Partial<DocumentDataState>>(
    DocumentDataFeatureKey
);

export const selectDocumentData = createSelector(
    docDataFeatureState,
    state => state.documentDataMap
);

export const selectSpecificDocumentData = (id: string) => createSelector(
    docDataFeatureState,
    state => state.documentDataMap[id]
);
