import { NavigationModule } from './../navigation/navigation.module';

import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CpsLoginPageComponent } from './cps-login-page/cps-login-page.component';
import { CpsHomePageComponent } from './cps-home-page/cps-home-page.component';
import { CpsContentPageComponent } from './cps-content-page/cps-content-page.component';
import { CpsResultsPageComponent } from './cps-results-page/cps-results-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeModule } from '../home/home.module';
import { SearchModule } from '../search/search.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CpsPageNotFoundComponent } from '../navigation/cps-page-not-found/cps-page-not-found.component';
import { CpsSubscriptionSelectionComponent } from './cps-subscription-selection/cps-subscription-selection.component';
import { DocumentModule } from '../document/document.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [CpsLoginPageComponent, CpsHomePageComponent, CpsContentPageComponent, CpsResultsPageComponent, CpsPageNotFoundComponent, CpsSubscriptionSelectionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    NavigationModule,
    FormsModule,
    ReactiveFormsModule,
    HomeModule,
    SearchModule,
    NgbModule,
    BrowserModule,
    HttpClientModule, 
    DocumentModule
  ],
  exports: []
})
export class PagesModule { }
