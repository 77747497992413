import { CustomizerActions, CustomizerActionTypes } from '../actions/customizer.actions';

export const CustomizerFeatureKey = 'customizerKey';

export interface CustomizerState {
    customizer: string;
}

export const initialState: CustomizerState = {
    customizer: '<div></div>'
};

export function customizerReducer(state = initialState, action: CustomizerActions): CustomizerState {
    switch (action.type) {
        case CustomizerActionTypes.LoadCustomizer:
            return {...state, customizer: action.customizer };
        case CustomizerActionTypes.PopulateCustomizer:
            return state;
        case CustomizerActionTypes.ClearCustomizer:
            return initialState;
        default:
            return state;
    }
}

export function reducer(state: CustomizerState, action: CustomizerActions): CustomizerState {
    return customizerReducer(state, action);
}

