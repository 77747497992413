import { Action } from '@ngrx/store';
import { DocumentItem } from '../document/document-models/document-item';
import { CPSMessages } from './CPSMessages.interface';

export enum MessagesActionTypes {
  LoadMessagesSuccess = '[Messages] Load Messages Success',
  LoadMessagesFailure = '[Messages] Load Messages Failure',
  ToggleOpenedState = '[Messages] Toggle is opened state',
  ClearMessages = '[Messages] Clear Messages',
  LoadMessageForChangedLanguage = '[Messages] Load Mesasge For New Langauge'
}

export class LoadMessagesSuccess implements Action {
  readonly type = MessagesActionTypes.LoadMessagesSuccess;
  constructor(public monographid: string, public messages: CPSMessages) { }
}

export class LoadMessagesFailure implements Action {
  readonly type = MessagesActionTypes.LoadMessagesFailure;
  constructor(public monographid: string, public error: any) { }
}

export class ToggleOpenedState implements Action {
  readonly type = MessagesActionTypes.ToggleOpenedState;
  constructor(public monographid: string, public isCollapsed: boolean) {}
}

export class ClearMessages implements Action {
  readonly type = MessagesActionTypes.ClearMessages;
  constructor() {
    // Empty
  }
}

export class LoadMessageForChangedLanguage implements Action {
  readonly type = MessagesActionTypes.LoadMessageForChangedLanguage;
  constructor(public document: DocumentItem) { }
}

export type MessagesActions = LoadMessagesSuccess | LoadMessagesFailure | ToggleOpenedState | ClearMessages | LoadMessageForChangedLanguage;

