import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { HomeService } from 'src/services/home.service';
import { AppConstants } from 'src/utils/app.constants';
import { LoadWhatsNewTC, PopulateWhatsNewTC, WhatsNewTCActionTypes } from '../actions/whatsnew-tc.actions';
import { WhatsNewTC } from '../home/cps-home-sidebar/whatsnew-tc.interface';

@Injectable()
export class WhatsNewTCEffects {

  @Effect()
  loadWhatsNewTC$: Observable<any> = this.actions$.pipe(
    ofType(WhatsNewTCActionTypes.PopulateWhatsNewTC),
    mergeMap(() =>
      this.homeService.getTCUpdates().pipe(
        map(whatsNewTC => {
          const result = this.parseWhatsNewTC(whatsNewTC);
          return new LoadWhatsNewTC(result);
        })
        )
      )
    );

    constructor(private actions$: Actions<PopulateWhatsNewTC>, private homeService: HomeService, public translationService: TranslateService) {
      // Empty
    }

    parseWhatsNewTC(xml: string): WhatsNewTC[] {

        const parserTC = new DOMParser();
        const xmlDocTC = parserTC.parseFromString(xml, 'text/xml');

        const productsTC = xmlDocTC.getElementsByTagName('highlight');

        const result = [];

        for (const tcItem in productsTC) {

            if (!isNaN(+tcItem) && productsTC[tcItem].getElementsByTagName('description')[0].getAttribute('lang') === this.translationService.currentLang.toLocaleLowerCase()) {

                const whatsnewTC: WhatsNewTC = {title: '', description: '', filename: '', date: ''};

                whatsnewTC.description = productsTC[tcItem].getElementsByTagName('description')[0].textContent;
                whatsnewTC.title = productsTC[tcItem].getAttribute('title');
                whatsnewTC.filename = productsTC[tcItem].getAttribute('file');
                whatsnewTC.date = productsTC[tcItem].getAttribute('date');

                if (productsTC[tcItem].getAttribute('title') == null) {
                  whatsnewTC.title = whatsnewTC.filename.split('.')[0];
                } else {
                  whatsnewTC.title = productsTC[tcItem].getAttribute('title');
                }

                result.push(whatsnewTC);
            }

        }

        return result;
    }
}
