<div class="container-fluid footer-container-wrapper">
    <div class="footer-container row ">
        
        <div class="footer-menu col-xl-9 col-lg-9 col-md-12 ">
            <ul class="nav nav-pills">
                <li>
                    <a target="_blank" href="{{ 'about' | routerPipe }}" (click)="onNavClicked('about')">{{ 'footer.about' | translate }}</a>
                </li>
                <li>
                    <a target="_blank" href="{{ 'contactUs' | routerPipe }}" (click)="onNavClicked('contactUs')">{{ 'footer.contactUs' | translate }}</a>
                </li>
                <li>
                    <a target="_blank" href="#" (click)="onNavClicked('feedback')">{{ 'footer.feedback' | translate}}</a>
                </li>
                <li>
                    <a target="_blank" href="{{ 'errata' | routerPipe }}" (click)="onNavClicked('errata')">{{ 'footer.errata' | translate }}</a>
                </li>
                <!-- <li>
                    <a target="_blank" href="{{ 'feedback' | routerPipe }}" (click)="onNavClicked('feedback')">{{ 'footer.feedback' | translate }}</a>
                </li> -->
                <li>
                    <a target="_blank" href="{{ 'policyPermission' | routerPipe }}" (click)="onNavClicked('policyPermission')">{{ 'footer.policyPermission' | translate }}</a>
                </li>
                <li>
                    <a target="_blank" href="{{ 'privacy' | routerPipe }}" (click)="onNavClicked('privacy')">{{ 'footer.privacy' | translate }}</a>
                </li>
                <!-- <li>
                    <a target="_blank" href="{{ 'stats' | routerPipe }}" (click)="onNavClicked('stats')">{{ 'footer.stats' | translate }}</a>
                </li> -->
            </ul>
        </div>
        <div class="footer-logo-container col-xl-3 col-lg-3 d-xl-block d-lg-block d-none">
            <a target="_blank" href="https://www.pharmacists.ca/."><span class="footer-logo cpha-logo"></span></a>
        </div>
    </div>
    <div class="footer-container row justify-content-center">
        <div class="footer-copyright-div col-12 ">
            <span class="footer-copyright-span">{{'Print.cpha.copyright1' | translate}}, {{today | date:'y'}}. {{ 'Print.cpha.copyright2' | translate}}</span>
        </div>
    </div>
    <div class="footer-container row justify-content-center">
        <div class="footer-logo-container d-xl-none d-lg-none d-block col-12 d-sm-block">
            <span class="footer-logo cpha-logo"></span>
        </div>
    </div>
</div>
