import { AppConstants } from 'src/utils/app.constants';
import { DocumentListActions, DocumentListActionTypes } from '../actions/document-list.actions';
import { DocumentItem } from '../document/document-models/document-item';

export const DOCUMENT_LIST_FEATURE_KEY = 'documentList';

export interface DocumentListState {
  documents: Array<DocumentItem>;
}

export const initialState: DocumentListState = {
  documents: []
};

export function documentListReducer(state = initialState, action: DocumentListActions): DocumentListState {
  switch (action.type) {
    case DocumentListActionTypes.AddDocument:
      const existingDocIndex = state.documents
        .findIndex(d => d.type === action.document.type && d.id === action.document.id);
      state.documents.forEach(doc => {
        doc.isActive = (doc == action.document);
      });

      if (existingDocIndex === -1) {
        return {...state, documents: [action.document, ...state.documents]};
      } else {
        state.documents[existingDocIndex].lang = action.lang;
      }

      return {...state, documents: [...state.documents]};

    case DocumentListActionTypes.RemoveDocument:
      // the document's being removed from the list, but removing the component as well in the future may increase performance
      return {...state, documents: state.documents
        .filter(doc => !(doc.id === action.id && doc.type === action.docType && doc.lang === action.lang))};

    case DocumentListActionTypes.ClearTabs:
      return { ...state, 
        documents: state.documents
        .filter(doc => (doc.id === action.activeDoc.id && doc.type === action.activeDoc.type && doc.lang === action.activeDoc.lang))}

    case DocumentListActionTypes.ClearAllDocuments:
      return initialState;

    case DocumentListActionTypes.ShowDocumentSuccess:
      return { ...state};

    case DocumentListActionTypes.ShowDocument:
      return {...state, documents: state.documents.map(doc => {
        doc.isActive = (doc == action.document);
        return doc;
      })}

    case DocumentListActionTypes.LoadDocumentFailed:
      // change type of action? if we change the type, we can force the component type
      // but we won't be able to fix by reloading (if it was just a communication error)
      // If the backend services fail, it will return an error message that can specify 
      // the title?
      return { ...state };
   
    case DocumentListActionTypes.ChangeDocumentListLanguage:
      state.documents.forEach(doc => {
        doc.lang = action.selectedLanguage;
        if (doc.type === AppConstants.NOT_FOUND_TYPE && doc.originalType !== undefined) {
          doc.type = doc.originalType;
        }
      });
      return { ...state};

 
    case DocumentListActionTypes.UnsetAllActiveFlags:
      return {
        ...state, documents: state.documents.map(doc => {
          doc.isActive = false;
          return doc;
        })
      };

    case DocumentListActionTypes.ReloadTableOfContents:
      return {...state};
    case DocumentListActionTypes.UnhideMonographSectionElements:
      return state;
    case DocumentListActionTypes.ScrollToSection:
      return state;
    default:
      return state;
  }
}

export function reducer(state: DocumentListState, action: DocumentListActions): DocumentListState {
  return documentListReducer(state, action);
}
