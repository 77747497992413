import { CpsPillIdentifierToolComponent } from './document/cps-pill-identifier-tool/cps-pill-identifier-tool.component';
import { CpsClininfoDocumentComponent } from './document/cps-clininfo-document/cps-clininfo-document.component';
import { CpsPatientInfoDocumentComponent } from './document/cps-patient-info-document/cps-patient-info-document.component';
import { CpsConditionDocumentComponent } from './document/cps-condition-document/cps-condition-document.component';
import { CpsMonographDocumentComponent } from './document/cps-monograph-document/cps-monograph-document.component';
import { CpsMenuDocumentComponent } from './document/cps-menu-document/cps-menu-document.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CpsLoginPageComponent } from './pages/cps-login-page/cps-login-page.component';
import { CpsHomePageComponent } from './pages/cps-home-page/cps-home-page.component';
import { CpsBodyWeightCalculatorComponent } from './document/cps-body-weight-calculator/cps-body-weight-calculator.component';
import { CpsRenalFunctionCalculatorComponent } from './document/cps-renal-function-calculator/cps-renal-function-calculator.component';
import { CpsDirectoryDocumentComponent } from './document/cps-directory-document/cps-directory-document.component';
import { CpsDocumentComponent } from './document/cps-document/cps-document.component';
import { CpsResourceDocumentComponent } from './document/cps-resource-document/cps-resource-document.component';
import { CpsResultsPageComponent } from './pages/cps-results-page/cps-results-page.component';
import { NotFoundDocumentComponent } from './document/not-found-document/not-found-document.component';
import { AppConstants } from 'src/utils/app.constants';
import { CpsGenericIndexComponent } from './document/cps-generic-index/cps-generic-index.component';
import { CpsPrintDocumentComponent } from './document/cps-print-document/cps-print-document.component';
import { CpsClininfoToolsComponent } from './document/cps-clininfo-tools/cps-clininfo-tools.component';
import { CpsPageNotFoundComponent } from './navigation/cps-page-not-found/cps-page-not-found.component';
import { CpsSubscriptionSelectionComponent } from './pages/cps-subscription-selection/cps-subscription-selection.component';
import { CpsLexiPageComponent } from './document/cps-lexi-page/cps-lexi-page.component';
import { CpsWhatsNewComponent } from './document/cps-whats-new/cps-whats-new.component';
import { AuthGuard } from 'src/services/auth-services/auth.guard';
import { AccessGuard } from 'src/services/auth-services/access.guard';

const routes: Routes = [
  {
    path: '', component: CpsHomePageComponent,
    canActivate: [AuthGuard]
  },
  { path: 'login', component: CpsLoginPageComponent },
  { path: 'pkgselection', component: CpsSubscriptionSelectionComponent },
  { path: 'package_switch', component: CpsSubscriptionSelectionComponent },
  {
    path: 'home', component: CpsHomePageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'search/:switch', component: CpsResultsPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'document',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'monograph/:docid',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.MONOGRAPH_TYPE,
          componentType: CpsMonographDocumentComponent
        }
      },
      {
        path: 'minorailments/:docid',
        component: CpsDocumentComponent,
        canActivate: [AccessGuard],
        data: {
          docType: AppConstants.MA_CONDITIONS_TYPE,
          componentType: CpsConditionDocumentComponent,
          expectedRole: 'etpc'
        }
      },
      {
        path: 'therapeuticchoices/:docid',
        component: CpsDocumentComponent,
        canActivate: [AccessGuard],
        data: {
          docType: AppConstants.CONDITIONS_TYPE,
          componentType: CpsConditionDocumentComponent,
          expectedRole: 'etpc'
        }
      },
      {
        path: 'patientinfo/:docid',
        component: CpsDocumentComponent,
        data: {
          docType: 'IFP',
          componentType: CpsPatientInfoDocumentComponent
        }
      },
      {
        path: 'needtoknow/:parentDocId/:docid',
        component: CpsDocumentComponent,
        canActivate: [AccessGuard],
        data: {
          docType: 'MA_PI',
          componentType: CpsPatientInfoDocumentComponent,
          expectedRole: 'etpc'
        }
      },
      {
        path: 'clininfo/:docid',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.CLININFO_TYPE,
          componentType: CpsClininfoDocumentComponent
        }
      },
      {
        path: 'products/:docid',
        component: CpsDocumentComponent,
        canActivate: [AccessGuard],
        data: {
          docType: 'PMA',
          componentType: CpsResourceDocumentComponent,
          expectedRole: 'etpc'
        }
      },
      { path: 'print/:doctype/:docid',
        component: CpsPrintDocumentComponent,
        data: {
          docType: AppConstants.MONOGRAPH_TYPE,
          componentType: CpsPrintDocumentComponent
        }
      },
      { path: 'tool/bodysurfacecalc', component: CpsBodyWeightCalculatorComponent },
      { path: 'tool/renalfunctioncalc', component: CpsRenalFunctionCalculatorComponent }
    ]
  },
  {
    path: 'tool',
    canActivateChild: [AuthGuard],
    children: [
      { path: 'lexi-interact',
        component: CpsDocumentComponent,
        data: {
          docType: 'TOOL',
          componentType: CpsLexiPageComponent
        }
      },
      {
        path: 'pillid',
        component: CpsDocumentComponent,
        data: {
          docType: 'TOOL',
          componentType: CpsPillIdentifierToolComponent
        }
      },
      {
        path: 'bodysurface',
        component: CpsDocumentComponent,
        data: {
          docType: 'TOOL',
          componentType: CpsBodyWeightCalculatorComponent
        }
      },
      {
        path: 'renalfunction',
        component: CpsDocumentComponent,
        data: {
          docType: 'TOOL',
          componentType: CpsRenalFunctionCalculatorComponent
        }
      },
      {
        path: 'drugadminandfood',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.CLININFO_TOOLS_TYPE,
          componentType: CpsClininfoToolsComponent
        }
      },
      {
        path: 'grapefruit',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.CLININFO_TOOLS_TYPE,
          componentType: CpsClininfoToolsComponent
        }
      }
    ]
  },
  {
    path: 'directory',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'healthorgs',
        component: CpsDocumentComponent,
        data: {
          docType: 'DIRECTORY',
          componentType: CpsDirectoryDocumentComponent
        }
      },
      {
        path: 'manufacturers',
        component: CpsDocumentComponent,
        data: {
          docType: 'DIRECTORY',
          componentType: CpsDirectoryDocumentComponent
        }
      }
    ]
  },
  {
    path: 'organization',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'healthorgs',
        component: CpsDocumentComponent,
        data: {
          docType: 'DIRECTORY',
          componentType: CpsDirectoryDocumentComponent
        }
      },
      {
        path: 'manufacturers',
        component: CpsDocumentComponent,
        data: {
          docType: 'DIRECTORY',
          componentType: CpsDirectoryDocumentComponent
        }
      }
    ]
  },
  {
    path: 'resource',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'poisoncontrol',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.RESOURCE_TYPE,
          componentType: CpsResourceDocumentComponent
        }
      },
      {
        path: 'microorganisms',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.RESOURCE_TYPE,
          componentType: CpsResourceDocumentComponent
        }
      },
      {
        path: 'hcalerts',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.RESOURCE_TYPE,
          componentType: CpsResourceDocumentComponent
        }
      },
      {
        path: 'hcreporting',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.RESOURCE_TYPE,
          componentType: CpsResourceDocumentComponent
        }
      },
      {
        path: 'abbrevs',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.RESOURCE_TYPE,
          componentType: CpsResourceDocumentComponent
        }
      },
      {
        path: 'publicdrugprograms',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.RESOURCE_TYPE,
          componentType: CpsResourceDocumentComponent
        }
      },
      {
        path: 'whatsnew',
        component: CpsDocumentComponent,
        data: {
          docType: 'WHATSNEW',
          componentType: CpsWhatsNewComponent
        }
      },
      {
        path: 'updates',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.RESOURCE_TYPE,
          componentType: CpsResourceDocumentComponent
        }
      },
      {
        path: ':docid',
        component: CpsDocumentComponent,
        data: {
          docType: AppConstants.RESOURCE_TYPE,
          componentType: CpsResourceDocumentComponent
        }
      }
    ]
  },
  {
    path: 'index',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'generic',
        component: CpsDocumentComponent,
        data: {
          docType: 'GENERIC',
          componentType: CpsGenericIndexComponent
        }
      },
      {
        path: 'monographs',
        component: CpsDocumentComponent,
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent
        }
      },
      {
        path: 'medicalDevices',
        component: CpsDocumentComponent,
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent
        }
      },
      {
        path: 'clininfos',
        component: CpsDocumentComponent,
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent
        }
      },
      {
        path: 'products',
        component: CpsDocumentComponent,
        canActivate: [AccessGuard],
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent,
          expectedRole: 'etpc'
        }
      },
      {
        path: 'therapeuticchoices',
        component: CpsDocumentComponent,
        canActivate: [AccessGuard],
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent,
          expectedRole: 'etpc'
        }
      },
      {
        path: 'minorailments',
        component: CpsDocumentComponent,
        canActivate: [AccessGuard],
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent,
          expectedRole: 'etpc'
        }
      },
      {
        path: 'patientinfo',
        component: CpsDocumentComponent,
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent
        }
      },
      {
        path: 'needtoknow',
        component: CpsDocumentComponent,
        canActivate: [AccessGuard],
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent,
          expectedRole: 'etpc'
        }
      },
      {
        path: ':docid',
        component: CpsDocumentComponent,
        data: {
          docType: 'MENU',
          componentType: CpsMenuDocumentComponent
        }
      },
    ]},
  {path: '404', component: CpsPageNotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
