import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentComponent } from '../document-models/documentComponent';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DocumentItem } from '../document-models/document-item';
import { DatalayerService } from 'src/services/datalayer.service';
import { DocumentService } from 'src/services/document.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import {EMPTY, fromEvent, Observable, Subscription} from 'rxjs';
import {catchError, debounceTime} from 'rxjs/operators';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-cps-renal-function-calculator',
  templateUrl: './cps-renal-function-calculator.component.html',
  styleUrls: ['./cps-renal-function-calculator.component.scss']
})
export class CpsRenalFunctionCalculatorComponent implements OnInit, DocumentComponent {

  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;

  RENALForm: FormGroup;
  ageField: FormControl;
  weightFieldRenal: FormControl;
  creatinineField: FormControl;

  gender = 'male';
  race = 'nonblack';
  selectedWeightUnit = 'kg';
  selectedCreatinineUnit = 'micro';

  ageValue: number = null;
  wtValue: number = null;
  ctValue: number = null;
  weightValue: number = null;
  creatinineMolValue: number = null;
  creatinineMgValue: number = null;

  cicrValue = 0;
  mdrdValue = 0;
  ckdepiValue = 0;

  hasUserInteractedWithTool: boolean;
  public contentLoaded = false;
  mobileView = false;
  fileName = '/static/Calculations_and_Dosing_Tools.pdf';


  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService,
              public formBuilder: FormBuilder,
              public datalayerService: DatalayerService,
              private documentService: DocumentService) {

      this.mobileView = DetectMobileView.detectScreenSize();

      fromEvent(window, 'resize').pipe(debounceTime(75))
      .pipe(takeUntil(this.destroy$)).subscribe(event => {
        this.mobileView = DetectMobileView.detectScreenSize();
      });
  }

  ngOnInit(): void {

    this.hasUserInteractedWithTool = false;
    this.ageField = new FormControl();
    this.weightFieldRenal = new FormControl();
    this.creatinineField = new FormControl();
    this.RENALForm = this.formBuilder.group(
      {
        'ageField': this.ageField,
        'weightFieldRenal': this.weightFieldRenal,
        'creatinineField': this.creatinineField
      }
    );

    this.ageField.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if(data === '') {
        this.ageValue = null;
      } else {
        this.ageValue = data;
      }
      this.calculateResults();
    })
    this.weightFieldRenal.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data === '') {
        this.wtValue = null;
      } else {
        this.wtValue = data;
      }
      this.calculateResults();
    })
    this.creatinineField.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data === '') {
        this.ctValue = null;
      } else {
        this.ctValue = data;
      }
      this.calculateResults();
    })
    this.creatinineField.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data === '') {
        this.ctValue = null;
      } else {
        this.ctValue = data;
      }
      this.calculateResults();
    })

    setTimeout(() => {
      this.checkContentLoaded();
    }, 200);
  }
  getPdfItem(urlForPdf, event: MouseEvent): void {
    const element = event.target as HTMLElement;
    const rect = element.getBoundingClientRect();
    const position = rect.top + window.scrollY;
    const fileExtension = urlForPdf.split('.').pop();

    this.documentService.getPdfForDocumentItem(urlForPdf).subscribe(res => {
      const fileURL: string = window.URL.createObjectURL(res);
      const tab: Window = window.open();
      tab.location.href = fileURL;
      this.datalayerService.fileDownloadEvent(fileExtension, this.fileName, position);
    });
  }

  selectGender(genderType: string): void {
    this.gender = genderType;
    this.calculateResults();
  }

  selectRace(raceType: string): void {
    this.race = raceType;
    this.calculateResults();
  }

  weightConversion(unitValue: number, convertTo: string): number {
    // 1 KG = 2.20462 LBS
    let requiredResult = null;
    if (convertTo === 'kg') {
      requiredResult = unitValue / 2.20462;
    } else {
      requiredResult = unitValue * 2.20462;
    }
    if (requiredResult % 1 !== 0) {
      return parseFloat((Math.floor(requiredResult * Math.pow(10, 3)) / Math.pow(10, 3)).toFixed(3));
    } else {
      return Math.trunc(requiredResult);
    }
  }

  selectWeightUnit(weightType: string): void {
    if (this.selectedWeightUnit !== weightType) {
      this.onToolInteractionEvent();
      this.selectedWeightUnit = weightType;
      if(this.wtValue !== null){
        this.RENALForm.get('weightFieldRenal').setValue(this.weightConversion(this.wtValue, weightType));
      }
    }
  }

  selectCreatinineUnit(creatinineUnit: string): void {
    if (this.selectedCreatinineUnit !== creatinineUnit) {
      this.onToolInteractionEvent();
      this.selectedCreatinineUnit = creatinineUnit;
      this.RENALForm.get('creatinineField').setValue('');
    }
  }

  resetResult(): void {
    this.cicrValue = 0;
    this.mdrdValue = 0;
    this.ckdepiValue = 0;
  }

  calculateResults(): void {
    this.onToolInteractionEvent();
    if(this.ageValue !== null && this.wtValue !== null && this.ctValue !== null){
      this.weightValue = this.wtValue;
      if (this.selectedWeightUnit === 'lb') {
        this.weightValue = this.wtValue / 2.20462;
      }

      this.creatinineMolValue = this.ctValue;
      this.creatinineMgValue = this.ctValue;
      if (this.selectedCreatinineUnit === 'mg') {
        this.creatinineMolValue = this.ctValue * 88.4;
      }

      this.cicrValue = (1.23 * (140-this.ageValue) * this.weightValue)/this.creatinineMolValue;
      if(this.gender!=='male'){
        this.cicrValue = this.cicrValue *0.85
      }

      if(this.selectedCreatinineUnit !== 'mg'){
        this.creatinineMgValue = this.ctValue / 88.4;
      }

      this.mdrdValue = 175*(Math.pow(this.creatinineMolValue/88.4,-1.154))*(Math.pow(this.ageValue,-0.203));
      let k = 0.9;
      let alpha = -0.302;
      let ckdepiEndVariable = 1;

      if(this.gender!=='male'){
        this.mdrdValue = this.mdrdValue * 0.742;
        k = 0.7;
        alpha = -0.241;
        ckdepiEndVariable *= 1.012;
      }

      // Removed as per XMLT-11
      // if(this.race === 'black'){
      //   this.mdrdValue = this.mdrdValue * 1.212;
      //   ckdepiEndVariable *= 1.159;
      // }

      // Old Formula. Changed per XMLT-11
      // this.ckdepiValue = 141 * Math.pow(Math.min(this.creatinineMgValue/k, 1), alpha) * Math.pow(Math.max(this.creatinineMgValue/k, 1), -1.209) * Math.pow(0.993, this.ageValue);
      this.ckdepiValue = 142 * Math.pow(Math.min(this.creatinineMgValue/k, 1), alpha) * Math.pow(Math.max(this.creatinineMgValue/k, 1), -1.200) * Math.pow(0.994, this.ageValue);

      this.cicrValue = parseFloat(this.cicrValue.toFixed(1));
      this.mdrdValue = parseFloat(this.mdrdValue.toFixed(1));
      this.ckdepiValue = parseFloat(this.ckdepiValue.toFixed(1));
    } else {
      this.resetResult();
    }
  }


  onToolInteractionEvent(): void {
    if (!this.hasUserInteractedWithTool) {
      this.datalayerService.toolInteractionEvent('renalfunction');
      this.hasUserInteractedWithTool = true;
    }
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  protected readonly environment = environment;


}

