import { Action } from '@ngrx/store';
import { DocumentItem } from '../document/document-models/document-item';

export enum DocumentListActionTypes {
  LoadDocumentLists = '[DocumentList] Load DocumentLists',
  AddDocument = '[DocumentList] Add Document',
  LoadDocumentFailed =  '[DocumentList] Load Document Failed',
  RemoveDocument = '[DocumentList] Remove Document',
  ClearTabs = '[DocumentList] Clear Tabs',
  ClearAllDocuments = '[DocumentList] Clear All Documents',
  ShowDocument = '[DocumentList] Show Document',
  ShowDocumentPreload = '[DocumentList] Preloading content to show document',
  ShowDocumentSuccess = '[DocumentList] Show Document Success',
  ChangeDocumentListLanguage = '[DocumentList] Toggle the language on all documents',
  UnsetAllActiveFlags = '[DocumentList] Unset active on all documents',
  ReloadTableOfContents = '[DocumentList] Reload current table of contents',
  UnhideMonographSectionElements = '[DocumentList] Show all monograph sections',
  ScrollToSection = '[DocumentList] Scroll to section'
}

export class AddDocument implements Action {
  readonly type = DocumentListActionTypes.AddDocument;

  constructor(public document: DocumentItem, public lang: string) {}
}

export class RemoveDocument implements Action {
  readonly type = DocumentListActionTypes.RemoveDocument;

  constructor(public docType: string, public id: string, public lang: string) {}
}

export class ClearTabs implements Action {
  readonly type = DocumentListActionTypes.ClearTabs;

  constructor(public activeDoc: DocumentItem) {}
}

export class ClearAllDocuments implements Action {
  readonly type = DocumentListActionTypes.ClearAllDocuments;

  constructor() {}
}

export class ShowDocumentSuccess implements Action {
  readonly type = DocumentListActionTypes.ShowDocumentSuccess;

  constructor(public document: DocumentItem, public html: string) {}
}

export class ShowDocumentPreload implements Action {
  readonly type = DocumentListActionTypes.ShowDocumentPreload;

  constructor(public document: DocumentItem, public activate: boolean = true) {}
}

export class ShowDocument implements Action {
  readonly type = DocumentListActionTypes.ShowDocument;

  constructor(public document: DocumentItem) {}
}

export class ChangeDocumentListLanguage implements Action {
  readonly type = DocumentListActionTypes.ChangeDocumentListLanguage;

  constructor(public selectedLanguage: string) {}
}

export class LoadDocumentFailed implements Action {
  readonly type = DocumentListActionTypes.LoadDocumentFailed;

  constructor(public document: DocumentItem, public error: any) {}
}

export class UnsetAllActiveFlags implements Action {
  readonly type = DocumentListActionTypes.UnsetAllActiveFlags;

  constructor() { }
}

export class ReloadTableOfContents implements Action {
  readonly type = DocumentListActionTypes.ReloadTableOfContents;

  constructor() {}
}

export class UnhideMonographSectionElements implements Action {
  readonly type = DocumentListActionTypes.UnhideMonographSectionElements;

  constructor() {}
}

export class ScrollToSection implements Action {
  readonly type = DocumentListActionTypes.ScrollToSection;

  constructor(public sectionName: String) {}
}

export type DocumentListActions = AddDocument | RemoveDocument | ClearTabs | ClearAllDocuments | ShowDocument | ShowDocumentSuccess |
  ShowDocumentPreload | ChangeDocumentListLanguage | LoadDocumentFailed | UnsetAllActiveFlags | ReloadTableOfContents |
  UnhideMonographSectionElements | ScrollToSection;
