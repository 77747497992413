import {Directive, Input, IterableChangeRecord, IterableDiffer, IterableDiffers, TemplateRef, ViewContainerRef, ViewRef } from '@angular/core';

@Directive({
    selector: '[ngxtFor]'
})
export class NgxtForDirective {
    private items: any[] = [];
    private viewRefsMap: Map<any, ViewRef> = new Map<any, ViewRef>();
    private difference: IterableDiffer<any> | undefined;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private differs: IterableDiffers) {
    }

    @Input('ngxtForOf')
    public set ngxtForOf(items: any) {
        this.items = items;
        if (items) {
            this.difference = this.differs.find(items).create();
        }
        //Clear any existing items
        this.viewContainer.clear();
    }

    @Input('ngxtForItemsAtOnce')
    public itemsAtOnce: number = 50;

    @Input('ngxtForIntervalLength')
    public intervalLength: number = 20;

    // checks if the list has been updated, and appends/removes from the current list if so
    public ngDoCheck(): void {
        if (this.difference) {
            const changes = this.difference.diff(this.items);
            if (changes) {
                changes.forEachRemovedItem(item => {
                    const mapView = this.viewRefsMap.get(item.item) as ViewRef;
                    const viewIndex = this.viewContainer.indexOf(mapView);
                    this.viewContainer.remove(viewIndex);
                    this.viewRefsMap.delete(item.item);
                });

                const itemsAdded: any[] = [];
                changes.forEachAddedItem(item => {
                    itemsAdded.push(item);
                });

                this.progressiveRender(itemsAdded);
            }
        }
    }

    private progressiveRender(items: IterableChangeRecord<any>[]): void {
        let interval: any;
        let start = 0;
        let end = start + this.itemsAtOnce;
        if (end > items.length) {
            end = items.length;
        }
        this.renderItems(items, start, end);

        interval = setInterval(() => {
            start = end;
            end = start + this.itemsAtOnce;
            if (end > items.length) {
                end = items.length;
            }
            this.renderItems(items, start, end);
            if (start >= items.length) {
                clearInterval(interval);
            }
        }, this.intervalLength);
    }

    private renderItems(items: IterableChangeRecord<any>[], start: number, end: number): void {
        items.slice(start, end).forEach(item => {
            const embeddedView = this.viewContainer.createEmbeddedView(
                this.templateRef,
                {
                    $implicit: item.item
                },
                item.currentIndex || 0
            );
            embeddedView.detectChanges();
            this.viewRefsMap.set(item.item, embeddedView);
        });
    }
}
