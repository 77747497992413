import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { RouterPipe } from '../shared/router.pipe';
import { NgxtForDirective } from '../directives/ngxt-for.directive';

@NgModule({
  declarations: [
    SafePipe,
    RouterPipe,
    NgxtForDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    RouterPipe,
    NgxtForDirective
  ],
  providers: [
    RouterPipe
  ]
})
export class SharedModule { }
