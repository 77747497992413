<!-- <app-cps-search-panel></app-cps-search-panel> -->
<app-cps-tab-bar></app-cps-tab-bar>
<div class="no-resultPanel container-fluid" *ngIf="resultCount===0">
    <div class="no-result-found">{{ 'NoResults.header' | translate }}</div>
    <div class="yourSearchText"><span>{{ 'NoResults.tryAnotherSearch1' | translate }}"</span><span><b>{{searchTerm}}</b></span><span>"{{ 'NoResults.tryAnotherSearch2' | translate }}</span></div>
    <div *ngIf="didYouMean">
        <h4 class="did-you-mean">
            <span>{{ 'NoResults.didYouMean' | translate }}'</span>
            <a class="link-text no-margins" (click)="searchAgain(didYouMean)" [innerHTML]="didYouMean"></a>
            <span>'?</span>
        </h4>
    </div>
    <div class="trying-browsing-drug">
        <div>{{ 'NoResults.beforeShearchingAgain' | translate }}</div>
        <div>
            <ul>
                <li class="browsingOption">{{ 'NoResults.addOrRemoveKeyword' | translate }}</li>
                <li class="browsingOption">{{ 'NoResults.addToSomeWords' | translate }}</li>
                <li class="browsingOption">{{ 'NoResults.removeQuotes' | translate }}</li>
                <li class="browsingOption">{{ 'NoResults.clearSearchFilters' | translate }}</li>
                <li class="browsingOption">{{ 'NoResults.browsingContent' | translate }}
                    <ul>
                        <li class="browsingOption"><a class="drug-monographs" href='index/monographs'>{{ 'global-nav.cpsMono' | translate }}</a></li>
                        <li class="browsingOption" *ngIf="!limitedSubscription"><a class="drug-monographs" href='index/therapeuticchoices'>{{ 'global-nav.tc' | translate }}</a></li>
                        <li class="browsingOption" *ngIf="!limitedSubscription"><a class="drug-monographs" href='index/minorailments'>{{ 'global-nav.tcma' | translate }}</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="row resultPanel container-fluid" [ngClass]="{ 'isBucketsDisplayed': isBucketExpandedMobile && mobileView}">
    <div class="toc-wrapper col-sm-12 col-md-2 col-lg-2 active-toc" *ngIf="resultCount>0" [ngClass]="{ 'isBucketsDisplayed': isBucketExpandedMobile && mobileView}">
        <app-cps-table-of-contents [currentDocument]="null"></app-cps-table-of-contents>
    </div>
    <div class="col-md-10 col-lg-10" *ngIf="resultCount>0">
        <div *ngIf="!isNpd" class="resultCount">{{currentBucketLength}} {{ 'ResultsPage.results' | translate }}
            <span class="resultText">{{searchTerm}}</span> {{ 'ResultsPage.under_'+ pageSwitch | translate }} 
        </div>
        <div *ngIf="isNpd" class="resultCount">
            <span class="resultText">{{searchTerm}}</span> {{ 'ResultsPage.under_'+ pageSwitch | translate }}
        </div>
        <app-cps-results-group [data]="group" *ngFor="let group of resultGroups"></app-cps-results-group>
    </div>
</div>

