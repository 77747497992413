import { Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DocumentHostDirective } from 'src/app/document/document-host.directive';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { DocumentService } from 'src/services/document.service';
import { AppConstants } from 'src/utils/app.constants';
import DocumentUtils from '../document-utils';
import { CpsManufacturerModalComponent } from 'src/app/modals/cps-manufacturer-modal/cps-manufacturer-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DirectoryService } from 'src/services/directory.service';
import { SessionService } from 'src/services/session.service';
import { ReplaySubject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, takeUntil, take } from 'rxjs/operators';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { SearchService } from 'src/app/search/search.service';
import { NonMarketedUtils } from 'src/services/nonMarketedUtils';

// the documentComponent hook is declared so the DocumentTool.js can access the ngZone
// eslint-disable-next-line no-var, @typescript-eslint/no-unused-vars
declare var documentComponent: unknown;

@Component({
  selector: 'app-cps-document',
  templateUrl: './cps-document.component.html',
  styleUrls: ['./cps-document.component.scss']
})
export class CpsDocumentComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DocumentHostDirective, { static: true }) documentHost: DocumentHostDirective;
  @ViewChild('docWrapper') docWrapper: ElementRef;
  public doc: DocumentItem;
  private offset = 210;
  public isTocSticky: boolean;
  public distanceFromBottom: number=null;

  public userId: string;
  isBucketExpandedMobile: boolean;
  private showBuckets: Subscription;
  mobileView = false;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  
  constructor(public translationService: TranslateService,
    public documentService: DocumentService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private directoryService: DirectoryService,
    private sessionService: SessionService,
    private searchService: SearchService, private nonMarketedUtils: NonMarketedUtils) {
    this.isTocSticky = false;
    this.userId = sessionService.userProfile.userId;

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
      this.isBucketExpandedMobile = !this.mobileView;
    });
  }

  ngOnInit(): void {
    this.documentService.checkDocumentLanguages();

    // const previouslyOpenedDocs = this.documentService.getDocumentList();
    // if (previouslyOpenedDocs.length > 0) {
    //   //TODO: Go through list of store documents in document.service

    //   for (const doc of previouslyOpenedDocs) {
    //     // create a new copy of the componentRef

    //     // destroy the old one
    //   }
    // }
    
    this.documentService.docWrapperViewContainerRef = this.documentHost.viewContainerRef;
    // recreate each component ref if we are reopening the browser
    this.createComponentsRefFromDocs(false);
    documentComponent = this; // this is to bind to documentTool.js to the ngZone
    this.loadRequestedDocument();

    this.showBuckets = this.searchService.getIsBucketExpandedMobile()
      .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
        this.isBucketExpandedMobile = isBucketExpandedMobile;
      });  

      window.addEventListener('scroll', this.onWindowScroll.bind(this));

  }

  onWindowScroll(): void{
    this.updateDistanceFromBottom();
    this.checkTocNearPageBottom();
  }


  loadRequestedDocument(): void {
    let docId = this.route.snapshot.paramMap.get('docid');
    if (docId == null) { docId = this.route.snapshot.routeConfig.path; }
    const docType = this.route.snapshot.data[AppConstants.ROUTE_DOCUMENT_TYPE];

    const currentDoc = this.documentService.getDocument(docType, docId, this.translationService.currentLang);

    if (currentDoc == null) {
      const newDocItem = new DocumentItem(docType, docId, this.translationService.currentLang, this.userId, null);
      if (docType === AppConstants.MA_PI_TYPE) {
        newDocItem.parentDocumentID = this.route.snapshot.paramMap.get('parentDocId');
      }

      const componentName = DocumentUtils.getDocumentComponentTypeFromString(newDocItem.type, newDocItem.id);
      this.documentService.createComponentRefForDocument(newDocItem, componentName);

      if (AppConstants.STATIC_COMPONENT_TYPE_LIST.includes(docType)) {
        this.translationService.get('TabBar.' + docId)
          .pipe(take(1))
          .subscribe(name => newDocItem.name = name);
      }

      if (!!this.sessionService.userProfile && !!this.sessionService.userProfile.access_token) {
        this.documentService.addDocumentToStore(newDocItem);
      }
    } else {
      this.documentService.showDocument(currentDoc);
    }
  }

  ngAfterViewInit(): void {
    this.nonMarketedUtils.addNonmarketedMsg();
    this.documentService.stickyTableSections();
    addEventListener('resize', () => {
      this.documentService.stickyTableSections();
    });
  }

  // function to reinitialize the list of previously open documents
  // if the page is refreshed or browser is reopened
  createComponentsRefFromDocs(reset: boolean): void {
    const docSaved = this.documentService.getDocumentList();
    for (const doc of docSaved) {
      const docType = DocumentUtils.getDocumentComponentTypeFromString(doc.type, doc.id);
      const component = this.documentService.getComponentRef(doc.type, doc.id, doc.lang);
      doc.isActive = false;
      if (reset || !component) {
        doc.isInitialized = false;
        this.documentService.createComponentRefForDocument(doc, docType);
      } else {
        doc.isInitialized = true;
        this.documentService.copyComponentRefForDocument(doc, docType, this.documentHost.viewContainerRef);
      }
    }
  }

  openManufacturerModal(id: string): void {
    // use 6923 for now since we don't have actual manufacturer data, the id passed in here is the real id though
    this.directoryService.getDirectoryDetail(id, 'manufacturers')
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        const manuModalRef = this.modalService.show(CpsManufacturerModalComponent, {});
        manuModalRef.content.manuDetail = res;

        // dirwrapper
        const parser = new DOMParser();
        const manuDoc = parser.parseFromString(res, 'text/html');

        const titles = manuDoc.getElementsByClassName('dirwrapper');
        manuModalRef.content.title = titles[0].getElementsByTagName('h2')[0].innerHTML;
        manuModalRef.content.detectChanges();
    });
  }

  onTocSticky(event: any): void {
    this.isTocSticky = event.isSticky;
  }

  private updateDistanceFromBottom(): void {
    const totalHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    this.distanceFromBottom = totalHeight - scrollPosition;
  }
  private checkTocNearPageBottom(): void {
    const tocContainer = document.getElementById('toc-container');
    if (tocContainer){
      if (this.distanceFromBottom!=null && this.distanceFromBottom <= 125) {
        tocContainer.classList.add('nearPageBottom');
      } else {
        tocContainer.classList.remove('nearPageBottom');
      }
  }
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onWindowScroll.bind(this));
    this.destroy$.next(true);
    this.destroy$.complete();
    this.documentService.destroyAllComponentRefs();
  }

}
