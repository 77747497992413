/**
 * Created by avohra on 7/6/2017.
 */

import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import enTranslation from './assets//i18n/en.json';
import frTranslation from './assets/i18n/fr.json';

export class JsonTranslationLoader implements TranslateLoader {

  constructor(private prefix: string = 'assets/i18n/',
              private suffix: string = '.json') {}

  public getTranslation(lang: string): Observable<any> {
    if (lang === 'fr') {

      return of(frTranslation);
    }

    return of(enTranslation);
  }

  // Method used to concat two objects
  // NOTE: Make sure there are no duplicate keys in different resource files
  private extend(obj, src) {
    for (const key in src) {
      if (src.hasOwnProperty(key)) { obj[key] = src[key]; }
    }
    return obj;
  }

}