<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="false" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>

    <div class="doc-content-wrapper">
        <div class="toc-wrapper col-sm-12 col-md-3 col-lg-3 active-toc" [ngClass]="{'isBucketsDisplayed': isBucketExpandedMobile && mobileView , 'isSearchEnabled' : !isSearchDisabled}">
            <app-cps-table-of-contents [currentDocument]="doc"></app-cps-table-of-contents>
        </div>

        <div #docWrapper class="clininfo-wrapper col-sm-12 col-md-9 col-lg-9">
            <div [innerHTML]="htmlElement.innerHTML | safe: 'html'">
            </div>
        </div>
    </div>
</div>