import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DocumentComponent } from '../document-models/documentComponent';
import { DocumentItem } from '../document-models/document-item';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { DocumentService } from 'src/services/document.service';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { ReplaySubject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

declare var initializeSelector: any;

@Component({
  templateUrl: './cps-clininfo-tools.component.html',
  styleUrls: ['./cps-clininfo-tools.component.scss']
})
export class CpsClininfoToolsComponent implements OnInit, DocumentComponent, OnDestroy {
  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;

  public activeDocumentFlag = null;
  public contentLoaded = false;

  mobileView = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService, private actionListener: ActionsSubject, 
    private documentService: DocumentService) {

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75), takeUntil(this.destroy$)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
    });
  }

  ngOnInit(): void {
    initializeSelector();
    if(this.doc.isActive){
      if (this.doc.id === 'drugadminandfood'){
        this.activeDocumentFlag = 'searchTextareaDrugFoodAdmin';
      } else if (this.doc.id === 'grapefruit') {
        this.activeDocumentFlag = 'searchTextareaGrapefruit';
      }
    }
    this.checkContentLoaded();
  }
  
  ngAfterViewInit(): void {
    addEventListener('load', () => {
      this.breakPlaceholder();
    })
    addEventListener('resize', () => {
      this.breakPlaceholder();
    })
  }

breakPlaceholder(): void {
    const searchTextarea = document.querySelector('input.searchTextarea');
    if (searchTextarea) {
        // Find the closest .searchbarcontainer
        const wrapper = searchTextarea.closest(".searchbarcontainer");
      if (searchTextarea.clientWidth < 445){
        searchTextarea.setAttribute('placeholder', "Enter drug name and select from list.")
        // Create the pseudo-placeholder
        if (document.querySelector('.input-placeholder')==null){
            const placeholder = document.createElement('p');
            placeholder.className = 'input-placeholder';
            placeholder.innerHTML = "Repeat to add multiple drugs.";
            if (wrapper) {
                wrapper.appendChild(placeholder);
            }
          }
        }
      else { 
        searchTextarea.setAttribute('placeholder', "Enter drug name and select from list. Repeat to add multiple drugs.") 
        const placeholder=document.querySelector('.input-placeholder');
        if (placeholder)
        {placeholder.remove();}
      }
    }
}

  checkContentLoaded(): void {
    this.documentService.getContentLoaded()
    .pipe(takeUntil(this.destroy$))
    .subscribe(isLoaded => {
      this.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
