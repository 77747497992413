<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="false" [isMyOwnSearch]="true" [onNavegate]="onSearchIndexChange" [onSearch]="onSearchTextChanged" [currentDocument]="doc" [hidePrintButton]="true"></app-cps-tool-bar>
    <div #docWrapper class="doc-content-wrapper">
        <div id="genericBody" class="genericTopicPage">
                
            <div *ngIf="!contentLoaded">
                <ngx-skeleton-loader [theme]="{width: '25%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '50%', 'border-radius': '10px', 'margin-bottom': '20px'}" style="display: grid;"></ngx-skeleton-loader>       
                <ngx-skeleton-loader [theme]="{width: '100%', height: '40px', 'border-radius': '10px', 'margin-bottom': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '60%', height: '40px', 'border-radius': '10px', 'margin-bottom': '10px', 'margin-bottom': '30px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{width: '40px', height: '40px', 'margin-bottom': '10px'}" style="display: grid;"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="10" [theme]="{width: '100%'}"></ngx-skeleton-loader>
            </div>
            
            <div class="genericHeaderContainer" *ngIf="contentLoaded">
                
                <h1 class="genericHeading">{{'Directories.generic.header' | translate}}</h1>
                
                <p id="genericSumText">{{'Directories.generic.summary' | translate}}</p>
                
                <app-cps-alpha-bar [identifier]="genericIdentifier" [itemList]="genericList" [document]="document" #alphaBar
                (selectedAlphaEvent)="onSelectedAlphaChange($event)"></app-cps-alpha-bar>

                
            </div>

            <div id="genericList" *ngIf="contentLoaded">
                <div *ngFor="let generic of filteredGenericList" class="alpha_jumpbar_item" class="genericLetterHeadingTarget">
                    <h3 *ngIf="newHeading(normalizeTitle(generic.title).charAt(0))" class="genericLetterHeading" id="generic{{isANumber(generic.title.charAt(0))}}">{{isANumber(generic.title.charAt(0))}}</h3>
                    <div class="genericItem" [ngClass]="{'genericSelected' : showMono==generic.title}">
                        <div [ngClass]="{'rowHighlight' : this.highlightList.indexOf(generic.title) >= 0}">
                            <a class="genericItemAnchor" (click)="getMonos(generic.title); showMonos(generic.title)" id="{{generic.title}}"><img [src]="showMono==generic.title ? 'assets/images/collapse-icon.svg' : 'assets/images/expand-icon.svg'">
                                <span id="{{doc.id}}{{generic.title}}" class="genericSpan" [ngClass]="{'highlight' : this.highlightList.indexOf(generic.title) >= 0}">{{generic.title}}</span>
                            </a>
                        </div>
                        <div class="genericDocs" *ngIf="showMono==generic.title">
                            <p class="genericSubheader" *ngIf="genericMono.length == 0 && !docError">{{'Directories.generic.loading' | translate}}</p>
                            <p class="genericSubheader" *ngIf="docError">{{'Directories.generic.docError' | translate}}</p>
                            <p class="genericSubheader" *ngIf="genericMono.length > 0">{{'Directories.mono' | translate}} ({{genericMono.length}})</p>
                            <p class="genericDocItem" *ngFor="let mono of genericMono">
                                <a [routerLink]="getPath(mono.id)">
                                    {{mono.title}}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


