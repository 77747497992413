import {
  ActionReducerMap,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { documentListReducer, DocumentListState } from './document-list.reducer';
import { localStorageSyncReducer } from './local-storage-sync-reducer.reducer';
import { SearchTermState, searchTermReducer } from './search-term.reducer';
import { SearchResultState, searchResultReducer } from './search-result.reducer';
import { messagesReducer, MessagesState } from '../monograph/messages.reducer';
import { whatsNewReducer, WhatsNewState } from './whatsnew.reducer';
import { whatsNewReducerTC, WhatsNewTCState } from './whatsnew-tc.reducer';
import { DocumentDataState, documentDataReducer } from './document-data.reducer';
import { userProfileReducer, UserProfileState } from './user-profile.reducer';
import { homeAlertsReducer, HomeAlertState } from './home-alerts.reducer';
import { homeHistoryReducer, HomeHistoryState } from './home-history.reducer';
import { pillIdResultReducer, PillIdResultState } from './pill-id-result-reducer';
import { genericListReducer, GenericListState } from './Generic-list.reducer';
import { customizerReducer, CustomizerState } from './customizer.reducer';
import { AuthState, authStateReducer } from './auth-state.reducer';

export interface State {
  documentList: DocumentListState;
  homeHistory: HomeHistoryState;
  documentData: DocumentDataState;
  messages: MessagesState;
  searchTermKey: SearchTermState;
  searchResultKey: SearchResultState;
  whatsnewKey: WhatsNewState;
  whatsnewtcKey: WhatsNewTCState;
  userProfileKey: UserProfileState;
  homealertsKey: HomeAlertState;
  pillIdResultKey: PillIdResultState;
  genericListKey: GenericListState;
  customizerKey: CustomizerState;
  authStateKey: AuthState;
}

export const reducers: ActionReducerMap<State> = {
  documentList: documentListReducer,
  documentData: documentDataReducer,
  homeHistory: homeHistoryReducer,
  messages: messagesReducer,
  searchTermKey: searchTermReducer,
  searchResultKey: searchResultReducer,
  whatsnewKey: whatsNewReducer,
  whatsnewtcKey: whatsNewReducerTC,
  userProfileKey: userProfileReducer,
  homealertsKey: homeAlertsReducer,
  pillIdResultKey: pillIdResultReducer,
  genericListKey: genericListReducer,
  customizerKey: customizerReducer,
  authStateKey: authStateReducer
};

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer];

export const selectMessagesState = (state: State) => state.messages;

export const selectMessagesList = createSelector(selectMessagesState, (state: MessagesState) => state.messages);

export const selectHomeHistory = (state: State) => state.homeHistory;
export const selectRecentsList = createSelector(selectHomeHistory, (state: HomeHistoryState) => state.recents);
export const selectFavoritesList = createSelector(selectHomeHistory, (state: HomeHistoryState) => state.favorites);
export const selectActive = createSelector(selectHomeHistory, (state: HomeHistoryState) => state.active);

export const selectUserProfile = (state: State) =>  state.userProfileKey;
export const selectAccessToken = createSelector(selectUserProfile, (state: UserProfileState) => state.userProfile.access_token);
export const selectAuthState = (state: State) => state.authStateKey;

