import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatalayerService } from 'src/services/datalayer.service';

declare const openFeedbackForm: Function;
@Component({
  selector: 'app-cps-footer',
  templateUrl: './cps-footer.component.html',
  styleUrls: ['./cps-footer.component.scss']
})

export class CpsFooterComponent implements OnInit {

  
  public today: number = Date.now();
  
  constructor(public translationService: TranslateService, private datalayerService: DatalayerService) {

  }

  ngOnInit(): void {
    //Empty
  }

  onNavClicked(navLinkString: string): void {
    if (navLinkString == 'feedback') {
      openFeedbackForm();
    } else {
      this.datalayerService.footerNavClickedEvent(navLinkString);
    }
  }

}
