import { TocItem } from "../cps-table-of-contents/cps-table-of-contents.component";

export class DocumentItem {
  public type: string;  // related to the datatype MONOGRAPH, CHAPTER, IFP, MENU, etc
  public id: string;    // unique identifier of the document in docRepo
  public lang: string;  // either en or fr
  public name: string;  // the title of the document from the HTML/head/title. In the case of MENU/TOOLs it matches the id
  public index: number; // ??
  public isActive: boolean;  // identifies if this is the actively viewed document in the list
  public isInitialized: boolean;  // identifies if content has been loaded from the API for viewing yet
  public tocModel: TocItem;  // contains the preloaded TOC content for this document
  public sectionHeaderInfoList: TocItem[];   // a list of headings from the preloaded tocModel
  public userid: string; // subscriber_id of user that opened the document item. Used for filtering favorites/recents
  public originalType: string; // only used when the document type changes and needed to be changed back (404 not found for one language)
  public manufacturerName: string; // the manufacturer of the drug, set by document-list.effects.ts when it parses the html
  public manufacturerId: string; // the id of the manufacturer, also set by document-list.effects.ts when it parses the html
  public jsonModel: string; // (Optional): the associated json model for the document, if any
  public parentDocumentID: string; //in case document is MA_PI and then we need the parentDocument id as well
  public scrollPosition: number; // current scroll position in document
  public bodySystemDescriptor: string; // Primarily for PMA. Paired with title to complete full title.
  public searchText: string; //related to the find keyword field
  public currentFocusIndex: number; //related to the find keyword field
  public nameHTML: string; //for names with superscript and need <sup>
  public logEn = false;
  public logFr = false;

    constructor(type: string, id: string, lang: string, userid: string, parentDocumentID: string) {
    this.type = type;
    this.originalType = type;
    this.id = id;
    this.lang = lang;
    this.name = '';
    this.userid = userid;
    this.parentDocumentID = parentDocumentID;
    this.scrollPosition = 1;
    this.bodySystemDescriptor = '';
    this.searchText = '';
    this.currentFocusIndex = -1;
    this.nameHTML = '';
  }

  getLocator(): string {
    return this.type + "/" + this.lang + "/" + this.id;
  }

  public getPath(): string {
    return DocumentItem.getPathFromType(this.type) + "/" + this.id;
  }

  static getPathFromType(type: string): string {
        switch (type) {
            case 'MONOGRAPH':
                return "monograph";
            case 'CHAPTER':
                return "therapeuticchoices"
            case 'MA_CHAPTER':
                return "minorailments";
            case 'IFP':
                return "patientinfo";
            case 'MA_PI':
                return "needtoknow";
            case 'CLININFO':
                return "clininfo";
            case 'DIRECTORY':
                return "directory";
            case 'PMA':
                return "products";
            case 'MENU':
            case 'GENERIC':
                return "index";
            case 'CLININFOTOOL':
            case 'TOOL':
                return "tool";
            default:
                return "resource";
        }
    }

    static locatorToPath(locator: string): string[] {
        const locatorArray = locator.split('/');
        locatorArray[0] = DocumentItem.getPathFromType(locatorArray[0]);
        locatorArray.splice(1, 1);
        const requiredLocator = '/document,' + locatorArray.toString();
        return requiredLocator.split(',');
    }
}
