import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LoadUserProfile, UserProfileType, LoadUserCredentials, RemoveUserProfile, 
                              LoadActiveSubscription, LoadIPAuthenticatedUser, LoadAutoLoginByParam, NoAction } from '../actions/user-profile.actions';
import { Observable, of } from 'rxjs';
import { mergeMap, map, switchMap , catchError } from 'rxjs/operators';
import { userProfile } from '../utility/Objects/userProfile';
import { JwtTokenService } from 'src/services/jwt-token.service';
import { AuthService } from 'src/services/auth-services/auth.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { AuthInterceptor } from 'src/services/auth-services/auth.interceptor';
import { Router } from '@angular/router';
import { AuthResponse } from 'src/services/auth-services/auth-response.interface';

@Injectable()
export class UserProfileEffects {
  public userProfile: userProfile = null;

@Effect()
  loadUserProfileFromAuthToken$: Observable<any> = this.actions$.pipe(
    ofType(UserProfileType.LoadUserCredentials),
    mergeMap(action =>
      this.authService.getAccessTokenData(action.username, action.password).pipe(
        map(userProfileDetails => {
          if(userProfileDetails != null) {
            if(userProfileDetails.access_token != null) {
              this.setUserProfile(userProfileDetails);
              this.authService.clearLoginErrorMessage();
              this.datalayerService.loginSuccessEvent(0, this.userProfile);

              this.setRoute();
              
              return new LoadUserProfile(this.userProfile);
            } else {
              this.datalayerService.loginFailureEvent(1);
              //handle exception when access_token is null
              //return to login page
            }
          } else {            
            this.datalayerService.loginFailureEvent(1);            
            //handle exception when server does not repond
            //retun to login page
          }
        }),
        catchError(err => {
          this.datalayerService.loginFailureEvent(1);
          return of(new RemoveUserProfile(err));
        })
      )
    )
  );

  /* MULTIPLE SUBSCRIPTION */
  @Effect()
  loadNewUserProfileFromAuthToken$: Observable<any> = this.actionSub.pipe(
    ofType(UserProfileType.LoadActiveSubscription),
    mergeMap(action =>
      this.authService.getNewAcessToken(action.selectedSub).pipe(
        map(userProfileDetails => {
          if(userProfileDetails != null) {
            if(userProfileDetails.access_token != null) {

              this.jwtTokenService.setToken(userProfileDetails.access_token);
              this.userProfile = new userProfile();
              
              this.userProfile = this.jwtTokenService.getUserInfo();
              this.userProfile.access_token = userProfileDetails.access_token;
              this.userProfile.refresh_token = userProfileDetails.refresh_token;
              
              const updatedAuthorities = this.jwtTokenService.getAuthorities();//userProfileDetails.authorities;
              this.userProfile.authorities = updatedAuthorities;
              const subscriptionInfoList = this.userProfile.subscriptionInfo;
              if(subscriptionInfoList!=null && subscriptionInfoList.length>1 
                          && updatedAuthorities != null && updatedAuthorities.length==1){
                this.userProfile.active_Subscription = updatedAuthorities[0];
              } else {
                this.userProfile.active_Subscription = null;
              }

              this.setRoute();

              // should log a subscription switch in the datalayer? Do we have an event for that yet?
              //  this.datalayerService.loginSuccessEvent(0);
              return new LoadUserProfile(this.userProfile);
            }
          }
        }),
        catchError(err => {
          return of(new RemoveUserProfile(err));
        })
      )
    )
  );

  @Effect()
  loadUserProfileForAutoLogin$: Observable<any> = this.actionAutoLogin.pipe(
    ofType(UserProfileType.LoadAutoLoginByParam),
    switchMap(action => 
      this.authService.getAutoLoginByParam(action.pParam).pipe(
        map(userProfileDetails => {
          if (userProfileDetails != null) {
            if (userProfileDetails.access_token != null) {
              this.setUserProfile(userProfileDetails);

              this.authService.clearLoginErrorMessage();
              this.datalayerService.loginSuccessEvent(0, this.userProfile);
              
              this.setRoute();

              return new LoadUserProfile(this.userProfile);
            
            } else {
              this.datalayerService.loginFailureEvent(1);
            }
          } else {
            this.datalayerService.loginFailureEvent(1);
          }
        }),
        catchError(err => {
          this.datalayerService.loginFailureEvent(1);
          return of(new RemoveUserProfile(err));
        })
      )
    )
  );

  @Effect()
  loadUserProfileForIpAuthenticated$: Observable<any> = this.actionIp.pipe(
    ofType(UserProfileType.LoadIPAuthenticatedUser),
    switchMap(action =>
      this.authService.getIpAutheticatedUserData().pipe(
        map(userProfileDetails => {
          if(!!userProfileDetails) {
            if(userProfileDetails.access_token != null) {

              this.setUserProfile(userProfileDetails);
              
              this.authService.clearLoginErrorMessage();
              this.datalayerService.loginSuccessEvent(0, this.userProfile);

              this.setRoute();

              return new LoadUserProfile(this.userProfile);
            }
            if (userProfileDetails.ip != null) {
              // If we just get an IP back, it means the user cannot autologin by ip
              // Store their ip and attempt date so we don't try again in the future
              this.authService.setIpAndAutologinAttemptTime();
              this.authService.setIpCheckMessage(userProfileDetails.ip);
              return new NoAction(); // The dispatch requires something to be returned even if it's 'nothing'. It can't be undefined
            }
          } else {
            // In the case autologin fails, we need to prevent the browser from attempting again
            this.authService.setIpAndAutologinAttemptTime();

            return new NoAction();
          }
        })
      )
    )
  );
  
  private setRoute(){
    if(!this.userProfile.active_Subscription){
      this.router.navigate(['pkgselection']);
    }else if (AuthInterceptor.originalRoute !== '') {
      this.router.navigate([AuthInterceptor.originalRoute]);
    } else {
      this.router.navigate(['home']);
    }
  }

  private setUserProfile(userProfileDetails: AuthResponse){
    this.jwtTokenService.setToken(userProfileDetails.access_token);
    this.userProfile = new userProfile();
    this.userProfile = this.jwtTokenService.getUserInfo();

    this.userProfile.access_token = userProfileDetails.access_token;
    this.userProfile.refresh_token = userProfileDetails.refresh_token;

    this.userProfile.authorities = this.jwtTokenService.getAuthorities();
    const subscriptionInfoList = this.userProfile.subscriptionInfo;
    if (subscriptionInfoList != null && subscriptionInfoList.length === 1
      && this.userProfile.authorities != null && this.userProfile.authorities.length == 1) {
      this.userProfile.active_Subscription = this.userProfile.authorities[0];
    } else {
      this.userProfile.active_Subscription = null;
    }
  }
  
  constructor(private actions$: Actions<LoadUserCredentials>, 
              private actionSub: Actions<LoadActiveSubscription>, 
              private actionIp: Actions<LoadIPAuthenticatedUser>,
              private actionAutoLogin: Actions<LoadAutoLoginByParam>,
              private authService: AuthService,
              private router: Router,
              private jwtTokenService: JwtTokenService,
              private datalayerService: DatalayerService ) { }

}