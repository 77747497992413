import { Action } from '@ngrx/store';
import { WhatsNewTC } from '../home/cps-home-sidebar/whatsnew-tc.interface';

export enum WhatsNewTCActionTypes {
    PopulateWhatsNewTC = '[WhatsNewTC] Populate WhatsNewTC',
    LoadWhatsNewTC = '[WhatsNewTC] Load WhatsNewTC',
    ClearWhatsNewTC = '[WhatsNewTC] Clear WhatsNewTC'
}

export class PopulateWhatsNewTC implements Action {
    readonly type = WhatsNewTCActionTypes.PopulateWhatsNewTC;

    constructor() {
        // Empty
    }
}

export class LoadWhatsNewTC implements Action {
    readonly type = WhatsNewTCActionTypes.LoadWhatsNewTC;

    constructor(public whatsNewTC: WhatsNewTC[]) {
        // Empty
    }
}

export class ClearWhatsNewTC implements Action {
    readonly type = WhatsNewTCActionTypes.ClearWhatsNewTC;

    constructor() {
        // Empty
    }
}

export type WhatsNewTCActions = PopulateWhatsNewTC | LoadWhatsNewTC | ClearWhatsNewTC;
