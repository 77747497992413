import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatalayerService } from 'src/services/datalayer.service';
import { Hcalert } from '../CPSMessages.interface';

@Component({
  selector: 'app-cps-advisories-panel',
  templateUrl: './cps-advisories-panel.component.html',
  styleUrls: ['./cps-advisories-panel.component.scss']
})
export class CpsAdvisoriesPanelComponent implements OnInit {

  @Input() advisories: Hcalert[];
  
  constructor(public translationService: TranslateService, private datalayerService: DatalayerService) {

  }

  ngOnInit(): void {
    this.datalayerService.bannerImpressionsEvent('Health Canada Advisories', 'monograph_message_centre');
  }

  clickEvent(linkUrl: string): void {
    this.datalayerService.bannerClickEvent('Health Canada Advisories', 'monograph_message_centre', linkUrl);
  }

}
