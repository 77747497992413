<div class="menu-wrapper">
<ng-template #loading>
    <h2 class="loading"></h2>
</ng-template>
<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" #docWrapper *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="false" [isMyOwnSearch]="true" [onNavegate]="onSearchIndexChange" [onSearch]="onSearchTextChanged" [currentDocument]="doc" [hidePrintButton]="true"></app-cps-tool-bar>
    <div *ngIf="specificMenuData$ | async as specificMenuData;else loading" class="doc-content-wrapper">
    <div class="menu-document-body" *ngIf="specificMenuData">
        <div class="menu-document-header">
            <div *ngIf="!contentLoaded" >
                <ngx-skeleton-loader [theme]="{width: '50%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '60%', 'margin-top': '0px'}"></ngx-skeleton-loader>
                <div *ngIf="specificMenuData.hasBodyParts">
                    <ngx-skeleton-loader [theme]="{width: '40%', height: '40px', 'margin-top': '0px'}"></ngx-skeleton-loader>
                </div>
                <ngx-skeleton-loader [theme]="{width: '100%', height: '40px', 'margin-top': '0px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '60%', height: '40px', 'margin-top': '0px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{width: '40px', height: '40px', 'margin-bottom': '10px'}" style="display: grid;"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="5" [theme]="{width: '100%', 'margin-top': '0px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{width: '40px', height: '40px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="5" [theme]="{width: '100%', 'margin-top': '0px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{width: '40px', height: '40px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="5" [theme]="{width: '100%', 'margin-top': '0px'}"></ngx-skeleton-loader>                
            </div>

            <h1 *ngIf="contentLoaded" class="menu-document-heading">{{'Directories.' + doc.id + '.header' | translate}}</h1>
            <p id="menu-document-summary" *ngIf="contentLoaded">{{'Directories.' + doc.id + '.summary' | translate}}</p>

            <div *ngIf="specificMenuData.hasBodyParts && contentLoaded" class="buttonContainer">
                <button class="alphabet" (click)="resultOrderType(alphaType)" [ngClass]="{'inactive': sortType == bodyType, 'small-text': translationService.currentLang == 'fr'}">{{'Directories.alphaOrder' | translate}}</button>
                <button class="body" (click)="resultOrderType(bodyType)" [ngClass]="{'inactive': sortType == alphaType, 'small-text': translationService.currentLang == 'fr'}">{{'Directories.bodySystem' | translate}}</button>
            </div>
            
            <app-cps-alpha-bar *ngIf="sortType == alphaType && contentLoaded" [identifier]="doc.id" [document]="document" [itemList]="specificMenuData.resultsList"
                (selectedAlphaEvent)="onSelectedAlphaChange($event)" #alphaBar>
            </app-cps-alpha-bar>
        </div>

        <div id="resultsList" *ngIf="sortType == alphaType && contentLoaded">
            <div *ngFor="let item of filteredData" class="letterHeadingTarget">
                <h3 *ngIf="newHeading(normalizeTitle(item.title).charAt(0)) || isFirstOfList(item, filteredData)" class="letterHeading" id="{{doc.id}}{{isANumber(item.title.charAt(0))}}">{{isANumber(item.title.charAt(0))}}</h3>
                <div class="menu-document-item" [ngClass]="{'rowHighlight' : this.highlightList[currentFocusIndex] == item.title}">
                    <span [ngClass]="{'highlight' : this.highlightList.indexOf(item.title) >= 0}"><a class="itemAnchor"
                           (click)="navigateToPath(item.locator)"><span class="itemSpan"
                                id="{{doc.id}}{{item.title}}">{{item.title}}</span></a><span class="itemType"> {{item.itemType}}</span></span>
                </div>
            </div>
        </div>

        <div id="resultsList" *ngIf="sortType == bodyType && contentLoaded">
            <div *ngFor="let bodypart of specificMenuData.bodypartKeys" class="letterHeadingTarget">
                <h3 *ngIf="newHeading(normalizeTitle(bodypart))" class="letterHeading">{{bodypart}}</h3>
                <div *ngFor="let item of filterBy(specificMenuData.resultsList, bodypart)" class="menu-document-item" [ngClass]="{'rowHighlight' : this.highlightList[currentFocusIndex] == item.title}">
                    <span [ngClass]="{'highlight' : this.highlightList.indexOf(item.title) >= 0}"><a class="itemAnchor" (click)="navigateToPath(item.locator)"><span class="itemSpan" id="{{doc.id}}{{item.title}}">{{item.title}}</span></a></span>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

</div>