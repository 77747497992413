<div class="home-feature-container row">
    <div class="img col-lg-12 col-md-4 col-sm-5 col-xs-6">
        <img class="home-feature-image" alt="{{ title }}" src="assets/features/images/{{ img }}">
    </div>
    <div class="home-feature-text col-lg-12 col-md-8 col-sm-7 col-xs-6">
        <p class="home-feature-title">{{ title }}</p>
        <div class="home-feature-content" *ngIf="text!= null && text!=''" [innerHTML]="text | safe: 'html'"></div>
        <div class="home-feature-content" *ngIf="toolsArray!=null && toolsArray!=undefined && toolsArray.length>0">
            <ul>
                <li *ngFor="let tool of toolsArray;">
                    <a id="{{tool.id}}" [routerLink]="[tool.route]" [innerHTML]="tool.title" (click)="homePageClickEvent(tool.id)"></a>
                </li>
            </ul>
        </div>
        <a *ngIf="link" id='{{linkID}}' [routerLink]="[route]" (click)="homePageClickEvent(linkID)"><span class="double-chevron-right"></span>{{ link }}</a>
    </div>
</div>
