import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpsDocumentComponent } from './cps-document/cps-document.component';
import { CpsMonographDocumentComponent } from './cps-monograph-document/cps-monograph-document.component';
import { CpsConditionDocumentComponent } from './cps-condition-document/cps-condition-document.component';
import { CpsPatientInfoDocumentComponent } from './cps-patient-info-document/cps-patient-info-document.component';
import { CpsClininfoDocumentComponent } from './cps-clininfo-document/cps-clininfo-document.component';
import { CpsPillIdentifierToolComponent } from './cps-pill-identifier-tool/cps-pill-identifier-tool.component';
import { CpsRenalFunctionCalculatorComponent } from './cps-renal-function-calculator/cps-renal-function-calculator.component';
import { CpsMenuDocumentComponent } from './cps-menu-document/cps-menu-document.component';
import { CpsDirectoryDocumentComponent } from './cps-directory-document/cps-directory-document.component';
import { CpsResourceDocumentComponent } from './cps-resource-document/cps-resource-document.component';
import { CpsActionBarComponent } from './cps-action-bar/cps-action-bar.component';
import { CpsTableOfContentsComponent } from './cps-table-of-contents/cps-table-of-contents.component';
import { CpsBodyWeightCalculatorComponent } from './cps-body-weight-calculator/cps-body-weight-calculator.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { SearchModule } from '../search/search.module';
import { NavigationModule } from '../navigation/navigation.module';
import { DocumentHostDirective } from './document-host.directive';
import { MonographModule } from '../monograph/monograph.module';
import { NotFoundDocumentComponent } from './not-found-document/not-found-document.component';
import { CustomRouteReuseStrategy } from 'src/utils/reuse-strategy-impl';
import { NgxStickyfillModule } from 'ngx-stickyfill';
import { EffectsModule } from '@ngrx/effects';
import { DocumentListEffects } from '../effects/document-list-effects.effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilityModule } from '../utility/utility.module';
import { CpsGenericIndexComponent } from './cps-generic-index/cps-generic-index.component';
import { CpsPrintDocumentComponent } from './cps-print-document/cps-print-document.component';
import { CpsAlphaBarComponent } from './cps-alpha-bar/cps-alpha-bar.component';
import { CpsClininfoToolsComponent } from './cps-clininfo-tools/cps-clininfo-tools.component';
import { CpsKeywordSearchBarComponent } from './cps-keyword-search-bar/cps-keyword-search-bar.component';
import { CpsLexiPageComponent } from './cps-lexi-page/cps-lexi-page.component';
import { SharedModule } from '../shared/shared.module';
import { CpsWhatsNewComponent } from './cps-whats-new/cps-whats-new.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InsideViewportDirective } from '../directives/InsideViewport.directive';

@NgModule({
  declarations: [
    CpsDocumentComponent,
    CpsMonographDocumentComponent,
    CpsConditionDocumentComponent,
    CpsPatientInfoDocumentComponent,
    CpsClininfoDocumentComponent,
    CpsClininfoToolsComponent,
    CpsPillIdentifierToolComponent,
    CpsRenalFunctionCalculatorComponent,
    CpsMenuDocumentComponent,
    CpsResourceDocumentComponent,
    CpsActionBarComponent,
    CpsTableOfContentsComponent,
    CpsBodyWeightCalculatorComponent,
    NotFoundDocumentComponent,
    DocumentHostDirective,
    CpsGenericIndexComponent,
    CpsPrintDocumentComponent,
    CpsAlphaBarComponent,
    CpsClininfoToolsComponent,
    CpsKeywordSearchBarComponent,
    CpsLexiPageComponent,
    CpsWhatsNewComponent,
    CpsDirectoryDocumentComponent,
    InsideViewportDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SearchModule,
    NavigationModule,
    MonographModule,
    NgxStickyfillModule,
    FormsModule,
    NgbModule,
    SharedModule,
    UtilityModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule.forRoot(),
    EffectsModule.forFeature([DocumentListEffects])
  ],
  exports: [
    CpsTableOfContentsComponent,
    InsideViewportDirective
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    }
  ]
})
export class DocumentModule { }
