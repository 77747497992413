import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpsHeaderComponent } from './cps-header/cps-header.component';
import { CpsGlobalNavComponent } from './cps-global-nav/cps-global-nav.component';
import { CpsUtilityNavComponent } from './cps-utility-nav/cps-utility-nav.component';
import { CpsFooterComponent } from './cps-footer/cps-footer.component';
import { CpsTabBarComponent } from './cps-tab-bar/cps-tab-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CpsGlobalNavHamburgerComponent } from './cps-global-nav-hamburger/cps-global-nav-hamburger.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SearchModule } from '../search/search.module';


@NgModule({
  declarations: [
    CpsHeaderComponent,
    CpsGlobalNavComponent,
    CpsUtilityNavComponent,
    CpsFooterComponent,
    CpsGlobalNavHamburgerComponent,
    CpsTabBarComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    SearchModule
  ],
  exports: [
    CpsHeaderComponent,
    CpsFooterComponent,
    CpsTabBarComponent
  ],
})
export class NavigationModule { }
