import { Component, ElementRef, Input, OnInit, ViewChild, HostListener, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/services/document.service';
import { DocumentItem } from '../document-models/document-item';
import { DocumentComponent } from '../document-models/documentComponent';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-cps-resource-document',
  templateUrl: './cps-resource-document.component.html',
  styleUrls: ['./cps-resource-document.component.scss']
})
export class CpsResourceDocumentComponent implements OnInit, DocumentComponent {
  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;
  public contentLoaded = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public selectedAlphaEvent = new EventEmitter<string>();
  private headingIdPrefix: string;
  public headingIdSufix: string;
  private _buttonIdPrefix = "button_"
  private _scrollOffsetForHeader = 320;
  private alphaBarButtons: HTMLElement[]
  private currentButton: HTMLElement;
  private lastScrollTop = 0;
  private isLoading = true;
  private isClickAction = false;

  constructor(public translationService: TranslateService,
    private documentService: DocumentService,
    public viewportScroller: ViewportScroller,
    private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.checkContentLoaded();
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      if (isLoaded) {
        const itemsAlphaBar: HTMLElement[] = Array.from(thizz.htmlElement.querySelectorAll('.contains-alphabar'));
        thizz.alphaBarButtons = Array.from(thizz.htmlElement.querySelectorAll('.call-function-button'));
        const availableAlphas = itemsAlphaBar.map(element => element.getAttribute("value"));
        const headingIdPrefixElement = thizz.htmlElement.querySelector('.heading-id-prefix') as HTMLInputElement;
        const headingIdSufixElement = thizz.htmlElement.querySelector('.heading-id-sufix') as HTMLInputElement;
        if (headingIdPrefixElement && headingIdSufixElement) {
          thizz.headingIdPrefix = headingIdPrefixElement.value;
          thizz.headingIdSufix = headingIdSufixElement.value;
        }

        thizz.unselectAllButtons();
        let isFirstButton = true;
        thizz.alphaBarButtons.forEach(button => {
          const value = button.getAttribute("value");
          button.setAttribute("id", thizz._buttonIdPrefix + value)
          if (!availableAlphas.includes(value)) {
            button.classList.add("glossaryAlphaInactive");
          } else if (isFirstButton) {
            thizz.selectAlphaBarButton(button);
            isFirstButton = false;
          }
        })
      }
      thizz.contentLoaded = isLoaded;
    });
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  onclick(targetElement: HTMLElement) {
    if (targetElement.classList.contains('call-function-button')) {
      this.onSelectedAlphaChange(targetElement);
    }
    if (targetElement.parentElement.classList.contains('call-function-button')) {
      this.onSelectedAlphaChange(targetElement.parentElement);
    }
  }

  @HostListener('document:scroll', ['$event.target'])
  onscroll() {
    if (this.contentLoaded && !this.isClickAction) {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrolling = -1; //scrolling up
      if (currentScrollTop > this.lastScrollTop && !this.isLoading) {
        scrolling = 1; //scrolling down
      }
      this.isLoading = false;
      this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
      this.selectButtonOnScroll(scrolling);
    }
    this.isClickAction = false;
  }

  selectButtonOnScroll(scrolling: number): void {
    const headerElements: any[] = Array.from(this.elementRef.nativeElement.querySelectorAll('.letterHeadingMedGlossary'));
    headerElements.forEach((el) => {
      const rect = el.getBoundingClientRect();
      // Check if the element is within the viewport
      const visibleCondition = scrolling < 0 ? rect.top <= this._scrollOffsetForHeader : rect.top < window.innerHeight && rect.bottom >= this._scrollOffsetForHeader;
      if (visibleCondition) {
        const value = el.getAttribute("id").replace(this.headingIdPrefix, "").replace(this.headingIdSufix, "");
        let currentLetter = value == '0' ? "#" : value;
        currentLetter = currentLetter == ' ' ? "*" : currentLetter;
        this.selectAlphaBarButtonById(this._buttonIdPrefix + currentLetter)       
      }
    })
  }

  onSelectedAlphaChange(targetElement: HTMLElement): void {
    let selectedLetter = targetElement.getAttribute("value");
    selectedLetter = this.specialCharacter(selectedLetter);
    const headerId = this.headingIdPrefix + selectedLetter + this.headingIdSufix;
    const headerElement = document.querySelector(`[id="${headerId}"]`);
    if (headerElement) {
      const headerPosition = headerElement.getBoundingClientRect().top + document.documentElement.scrollTop;
      window.scrollTo(0, headerPosition - this._scrollOffsetForHeader);
      this.selectAlphaBarButtonById(targetElement.getAttribute("id")); 
      this.isClickAction = true;   
    }
  }

  unselectAllButtons(): void {
    this.alphaBarButtons.forEach(button => {
      button.classList.remove("glossaries-alphaSelected");
    })
  }

  selectAlphaBarButtonById(idButton: string): void {
    const newButton = this.alphaBarButtons.find(e => e.getAttribute("id") == idButton);
    this.selectAlphaBarButton(newButton);
  }

  selectAlphaBarButton(targetElement: HTMLElement): void {
    if (!targetElement || targetElement.getAttribute("id") === this.currentButton?.getAttribute("id")) {
      return;
    }
    this.currentButton?.classList.remove("glossaries-alphaSelected")
    targetElement.classList.add("glossaries-alphaSelected");
    this.currentButton = targetElement;   
  }

  specialCharacter(selectedLetter: string): string {
    selectedLetter = selectedLetter == '#' ? "0" : selectedLetter;
    selectedLetter = selectedLetter == '*' ? " " : selectedLetter;
    return selectedLetter;
  }
}