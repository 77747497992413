import { WhatsNewActions, WhatsNewActionTypes } from '../actions/whatsnew.actions';
import { WhatsNew } from '../home/cps-home-sidebar/whatsnew.interface';

export const WhatsNewFeatureKey = 'whatsnewKey';

export interface WhatsNewState {
    whatsNew: WhatsNew[];
}

export const initialState: WhatsNewState = {
    whatsNew: []
};

export function whatsNewReducer(state = initialState, action: WhatsNewActions): WhatsNewState {
    switch (action.type) {
        case WhatsNewActionTypes.LoadWhatsNew:
            return {...state, whatsNew: action.whatsNew };
        case WhatsNewActionTypes.PopulateWhatsNew:
            return state;
        case WhatsNewActionTypes.ClearWhatsNew:
            return initialState;
        default:
            return state;
    }
}

export function reducer(state: WhatsNewState, action: WhatsNewActions): WhatsNewState {
    return whatsNewReducer(state, action);
}

