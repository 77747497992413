<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="true" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>

    <!-- Skeleton-loader for desktop view -->
    <div *ngIf="!contentLoaded && !mobileView">
        <div class="skeleton-wrapper">
            <div class="skeleton-second-wrapper">
                <div class="skeleton-wrapper-title">
                    <ngx-skeleton-loader
                        [theme]="{width: '600px', height: '35px', 'border-radius': '10px', 'margin-bottom':'0px'}"></ngx-skeleton-loader>
                </div>

                <div class="skeleton-wrapper-calculator">
                    <div>
                        <ngx-skeleton-loader count="3" [theme]="{width: '150px', 'margin-top': '10px', 'margin-bottom':'20px'}"
                            style="display: grid; align-items: center;"></ngx-skeleton-loader>
                    </div>
                    <div>
                        <ngx-skeleton-loader count='3' appearance="circle" style="display: grid; align-items: center;"
                        [theme]="{ width: '350px', height: '40px', 'border-radius': '10px', 'margin-left': '100px', 'margin-top': '5px'}"></ngx-skeleton-loader>
                    </div>
                </div>

                <div class="skeleton-wrapper-calculator-formula">
                    <ngx-skeleton-loader appearance="circle"
                        [theme]="{ width: '600px', height: '350px', 'border-radius': '10px', 'margin-top': '15px'}"></ngx-skeleton-loader>
                </div>
            </div>

            <div class="skeleton-wrapper-calculated-results">
                <ngx-skeleton-loader appearance="circle"
                    [theme]="{ width: '450px', height: '600px', 'border-radius': '10px', 'margin-left': '100px', 'margin-top': '0px'}">
                </ngx-skeleton-loader>
            </div>
        </div>

        <div class="skeleton-text-line">
            <ngx-skeleton-loader [theme]="{width: '30%', 'margin-top': '0px', 'margin-bottom': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="4"
                [theme]="{width: '100%', 'margin-top': '0px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>
        </div>
    </div>

    <!-- Skeleton-loader for mobile devices -->
    <div *ngIf="!contentLoaded && mobileView">
        <ngx-skeleton-loader [theme]="{width: '80%', height: '35px', 'border-radius': '10px', 'margin-top':'20px'}"></ngx-skeleton-loader>
        <div class="skeleton-wrapper-calculator-mobile">
            <ngx-skeleton-loader count="3" [theme]="{width: '100px', 'margin-top': '10px', 'margin-bottom':'20px'}"
                    style="display: grid; align-items: center;"></ngx-skeleton-loader>
            <ngx-skeleton-loader count='3' appearance="circle" style="display: grid; align-items: center;"
                    [theme]="{ width: '200px', height: '40px', 'border-radius': '10px', 'margin-left': '50px', 'margin-top': '5px'}"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader appearance="circle" [theme]="{ width: '100%', height: '450px', 'border-radius': '10px', 'margin-top': '15px', 'margin-left':'0%'}"></ngx-skeleton-loader>
    </div>

    <div class="bsaCalculator doc-content-wrapper"  #docWrapper  *ngIf="contentLoaded">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 BSA-Container">
                <div class="row">
                    <div class="col-12">
                        <div class="bsa-title">{{ 'BSACalculator.title' | translate }}</div>
                        <div class="BSA-Inner-Container">
                            <form [formGroup]="BSAForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="calLabel labelWidth">{{ 'BSACalculator.sex' | translate }}</div>
                                        <div class="genderArea">
                                            <div class="diplayPosition">
                                                <button type="button"  class="genderButtonMale genderButton" [ngClass]="{'active': gender==='male'}" (click)="selectGender('male')">
                                                    <span class="genderText">{{ 'BSACalculator.male' | translate }}</span>
                                                </button>
                                            </div>
                                            <div class="diplayPosition">
                                                <button type="button"  class="genderButtonFemale genderButton" [ngClass]="{'active': gender==='female'}" (click)="selectGender('female')">
                                                    <span class="genderText">{{ 'BSACalculator.female' | translate }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 fieldSpacing ">
                                        <div class="calLabel labelWidth">{{ 'BSACalculator.weight' | translate }}</div>
                                        <div class="wtArea" id="weightArea">
                                            <div class="wtTextField"><input id="wtField" aria-labelledby="weightArea" formControlName="weightField"
                                                    autocomplete="off" type="number" class="wtFieldClass"></div>
                                            <div class="wtDropDown">
                                                <div ngbDropdown class="d-inline-block dropDownField">
                                                    <button type="button" class="btn unitDrpDwn" id="weightDropdown" ngbDropdownToggle>
                                                        <div class="selectedUnitValue">
                                                            <span>{{'BSACalculator.'+selectedWeightUnit | translate}}</span>
                                                        </div>
                                                        <div class="downArrow">
                                                            <span class="DropdwnArrow"></span>
                                                        </div>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="unitDropdown">
                                                        <button ngbDropdownItem class="firstButton"
                                                            (click)="selectWeightUnit('kg')">{{'BSACalculator.kg' |
                                                            translate}}</button>
                                                        <button ngbDropdownItem class="lastButton"
                                                            (click)="selectWeightUnit('lb')">{{'BSACalculator.lb' |
                                                            translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">  <!-- [ngClass]="doc.isActive ? 'active-toc' : 'hidden' -->
                                        <div class="calLabel" [ngClass]="heightUnitFlag ? 'labelWidth' :'htLabelWidth'">{{ 'BSACalculator.height' | translate }}</div>
                                        <div class="htArea" id="heightArea" [ngClass]="{'inchSelected': !heightUnitFlag}">
                                            <ng-template [ngIf]="heightUnitFlag">
                                                <div class="htTextField"><input id="htField" aria-labelledby="heightArea" formControlName="heightFieldCm"
                                                        autocomplete="off" type="number" class="htFieldClass"></div>
                                            </ng-template>
                                            <ng-template [ngIf]="!heightUnitFlag">
                                                <div class="htTextField"><input id="ftField"
                                                        formControlName="heightFieldFeet" autocomplete="off" type="number"
                                                        class="htFieldClass"
                                                        placeholder="{{ 'BSACalculator.feetPlaceholder' | translate }}">
                                                </div>
                                                <div class="htTextField"><input id="inField"
                                                        formControlName="heightFieldInches" autocomplete="off" type="number"
                                                        class="htFieldClass"
                                                        placeholder="{{ 'BSACalculator.inchesPlaceholder' | translate }}">
                                                </div>
                                            </ng-template>
                                            <div class="htDropDown">
                                                <div ngbDropdown class="d-inline-block dropDownField">
                                                    <button type="button" class="btn unitDrpDwn" id="heightDropdown" ngbDropdownToggle>
                                                        <div class="selectedUnitValue">
                                                            <span>{{'BSACalculator.'+selectedHeightUnit | translate}}</span>
                                                        </div>
                                                        <div class="downArrow"><span class="DropdwnArrow"></span></div>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="unitDropdown">
                                                        <button ngbDropdownItem class="firstButton"
                                                            (click)="selectHeightUnit('cm')">{{'BSACalculator.cm' |
                                                            translate}}</button>
                                                        <button ngbDropdownItem class="lastButton"
                                                            (click)="selectHeightUnit('in')">{{'BSACalculator.in' |
                                                            translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-12" class="desktopViewClass">
                        <ng-template [ngTemplateOutlet]="FormulaRef"></ng-template>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 outerSpacing">
              <div class="documentTitleTextDownload">
                <a class="link-text" (click)="getPdfItem( environment.API_SERVICE_ENDPOINT+'/'+this.translationService.currentLang+this.fileName, $event)">
                  {{ 'Calculations_and_Dosing_Tools.statement' | translate }}<span class="new-window-icon-blue"></span>
                </a>
              <div class="resultPanel">
                    <div class="resultHeading">{{ 'BSACalculator.calculatedResults' | translate }}</div>

                    <div class="mostellerResult">
                        <div class="label">{{ 'BSACalculator.bodysurface' | translate }}</div>
                        <div class="formulaType">{{ 'BSACalculator.mosteller' | translate }}</div>
                        <div class="result">{{mostellerValue}} m<span class="m2"><sup>2</sup></span></div>
                    </div>

                    <div class="haycockResult">
                        <div class="label">{{ 'BSACalculator.bodysurface' | translate }}</div>
                        <div class="formulaType">{{ 'BSACalculator.haycock' | translate }}</div>
                        <div class="result">{{haycockValue}} m<span class="m2"><sup>2</sup></span></div>
                    </div>
                    <div class="ibwResult">
                        <div class="label">{{ 'BSACalculator.ibw' | translate }}</div>
                        <div class="result">{{ibwValue}} {{ 'BSACalculator.kg' | translate }}</div>
                    </div>
                    <div class="abwResult">
                        <div class="label">{{ 'BSACalculator.abw' | translate }}</div>
                        <div class="result">{{abwValue}} {{ 'BSACalculator.kg' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="row mobileViewClass">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 outerSpacing">
                <div class="formulaTopBorder"></div>
                <ng-template [ngTemplateOutlet]="FormulaRef"></ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 references">
                <div class="label">{{ 'BSACalculator.references' | translate }}</div>
                <div class="list">
                    <ol>
                        <li class="footerText">{{ 'BSACalculator.ref1' | translate }}</li>
                        <li class="footerText">{{ 'BSACalculator.ref2' | translate }}</li>
                        <li class="footerText">{{ 'BSACalculator.ref3' | translate }}</li>
                        <li class="footerText">{{ 'BSACalculator.ref4' | translate }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>

    <ng-template #FormulaRef>
        <div class="formula">
            <div class="bsa-formula-title" [innerHTML]="'BSACalculator.mostellerLabel' | translate" ></div>
            <div class="formulaDiv mosteller">
                <div class="squareRoot">{{ 'BSACalculator.squareRoot' | translate }}</div>
                <div class="formulaInnerDiv">
                    <div class="upperPart">{{ 'BSACalculator.mostellerFormulaUpperPart' | translate }}</div>
                    <div class="lowerPart">3600</div>
                </div>
            </div>
        </div>
        <div class="formula">
            <div class="bsa-formula-title" [innerHTML]="'BSACalculator.haycockLabel' | translate"></div>
            <div class="formulaDiv">
                <div class="bsa-formula-body">{{ 'BSACalculator.haycockFormula1' | translate }}<sup>{{
                        'BSACalculator.haycockSup1' | translate }}</sup> x {{ 'BSACalculator.haycockFormula2' |
                    translate }}<sup>{{ 'BSACalculator.haycockSup2' | translate }}</sup></div>
            </div>
        </div>
        <div class="formula">
            <div class="bsa-formula-title">{{ 'BSACalculator.ibwLabel' | translate }}</div>
            <div class="formulaDiv">
                <div class="bsa-formula-body" [innerHTML]=" 'BSACalculator.ibwMaleFormula' | translate "></div>
                <div class="bsa-formula-body" [innerHTML]=" 'BSACalculator.ibwFemaleFormula' | translate "></div>
                <hr>
                <div class="bsa-formula-body" [innerHTML]=" 'BSACalculator.note' | translate "></div>
                <hr>
            </div>
        </div>
        <div class="formula">
            <div class="bsa-formula-title">{{ 'BSACalculator.abwLabel' | translate }}</div>
            <div class="formulaDiv">
                <div class="bsa-formula-body">{{ 'BSACalculator.abwFormula' | translate }}</div>
            </div>
            <hr>
            <div class="bsa-formula-body" [innerHTML]=" 'BSACalculator.abwNote' | translate "></div>
            <hr>
        </div>
    </ng-template>
</div>
