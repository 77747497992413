<div class="modal-header">
  <h4 class="modal-title pull-left">{{'PID.productImage' | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="html.innerHTML | safe: 'html'">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default close-button" (click)="bsModalRef.hide()">{{'PID.close' | translate}}</button>
</div>

