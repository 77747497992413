<div class="header" [ngClass]="{'no-height' : subscriptionPage}">
<div class="header-root" *ngIf="!subscriptionPage">
    <div class="header-container container-fluid-header">
        <div class="row no-gutters">
            <div class="global-Nav-Ham d-xl-none d-lg-none d-md-block col-2" *ngIf="sessionActive"><app-cps-global-nav-hamburger></app-cps-global-nav-hamburger></div>
            <div class="global-Nav d-xl-block d-lg-block d-none col-xl-7 col-lg-7 col-md-8 ml-auto" *ngIf="sessionActive"><app-cps-global-nav></app-cps-global-nav></div>
            <div class="loginLogo" *ngIf="!sessionActive"><span class="cpsLogo site-logo-header">
            </span></div>
                <!-- <div class="cpsLogoDiv d-inline-block" *ngIf="!sessionActive">
                    <span class="cpsLogo site-logo-header">
                    </span>
                </div> -->
            <div class="util-Nav col-xl-5 col-lg-5 col-10 ml-auto" *ngIf="sessionActive"><app-cps-utility-nav></app-cps-utility-nav></div>
            <div class="util-Nav d-inline" *ngIf="!sessionActive"><app-cps-utility-nav></app-cps-utility-nav></div>
        </div>
    </div>
    <app-cps-search-panel></app-cps-search-panel>
</div>
</div>