import { SearchTermActionTypes, SearchTermActions } from '../actions/search-term.actions';


export const searchTermFeatureKey = 'searchTermKey';

export interface SearchTermState {
  searchTerm: string;
}

export const initialState: SearchTermState = {
  searchTerm: ''
};

export function searchTermReducer(state = initialState, action: SearchTermActions): SearchTermState {
  switch (action.type) {
    case SearchTermActionTypes.LoadSearchTerm:
      return { ...state, searchTerm: action.searchterm };
    case SearchTermActionTypes.RemoveSearchTerm:
      return { ...state, searchTerm: '' };
    default:
      return state;
  }
}

export function reducer(state: SearchTermState, action: SearchTermActions): SearchTermState {
  return searchTermReducer(state, action);
}
