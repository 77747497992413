import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DocumentComponent } from 'src/app/document/document-models/documentComponent';
import { State } from 'src/app/reducers';
import { DocumentService } from 'src/services/document.service';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { DocumentItem } from '../document-models/document-item';
import { SearchService } from 'src/app/search/search.service';

@Component({
  selector: 'app-cps-condition-document',
  templateUrl: './cps-condition-document.component.html',
  styleUrls: ['./cps-condition-document.component.scss']
})
export class CpsConditionDocumentComponent implements OnInit, AfterViewInit, DocumentComponent, OnDestroy {
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;
  public htmlElement: HTMLElement;

  private offset = 130;
  public contentLoaded = false;
  mobileView = false;
  isBucketExpandedMobile: boolean;
  private showBuckets: Subscription;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService, public documentService: DocumentService, private searchService: SearchService) {

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75), takeUntil(this.destroy$)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
      this.isBucketExpandedMobile = !this.mobileView;
    });
  }

  ngAfterViewInit(): void {
    this.documentService.stickyTableSections(this.doc.id, this.offset);
  }

  ngOnInit(): void {
    this.checkContentLoaded();

    this.showBuckets = this.searchService.getIsBucketExpandedMobile()
      .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
        this.isBucketExpandedMobile = isBucketExpandedMobile;
      });
  }

  checkContentLoaded(): void {
    const thizz = this;
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      thizz.contentLoaded = isLoaded;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
