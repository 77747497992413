<div class="modal-header">
    <h4 class="modal-title">{{'subscriptionAlert.permissionDeniedTitle' | translate}}</h4>
</div>
<div class="permission-denied-body">
    <p>{{'subscriptionAlert.message1' | translate}} {{'subscriptionAlert.message2' | translate}} {{'subscriptionAlert.message3' | translate}} <a href="{{ 'about' | routerPipe }}" target="_blank">{{'subscriptionAlert.link' | translate}}</a></p>
</div>
<div class="permission-denied-footer">
    <button (click)="buttonClick()" class="btn btn-primary form-control">{{'subscriptionAlert.button' | translate}}</button>
</div>

