import { PillIdResultActions, PillIdResultsActionTypes } from "../actions/pill-id-result.actions";
import { PIDResult, Result } from "../document/cps-pill-identifier-tool/PIDResult.interface";

export const PillIdResultFeatureKey = 'pillIdResultKey';

export interface PillIdResultState {
    pidResult: PIDResult;
    brandName: string;
    imprint: string;
    selectedFormType: any;
    shapeSelected: any;
    selectedColorsArray: string[];
    scoredOrNot: any;
    limit: number;
    start: number;
    page: number;
}

export const initialState: PillIdResultState = {
    pidResult: null,
    brandName: null,
    imprint: null,
    selectedFormType: null,
    shapeSelected: null,
    selectedColorsArray: [],
    scoredOrNot: null,
    limit: 10,
    start: 0,
    page: 1
};

export function pillIdResultReducer(state = initialState, action: PillIdResultActions): PillIdResultState {
    switch (action.type) {
        case PillIdResultsActionTypes.SetResults:
            state.pidResult = action.pidResult;
            state.brandName = action.brand;
            state.imprint = action.imprint;
            state.selectedFormType = action.selectedFormType;
            state.shapeSelected = action.shapeSelected;
            state.selectedColorsArray = action.selectedColorsArray;
            state.scoredOrNot = action.scoredOrNot;
            state.limit = action.limit;
            state.start = action.start;
            state.page = action.page;
            return { ...state };
        case PillIdResultsActionTypes.AddResults:
            state.limit = action.limit;
            state.start = action.start;
            state.page = action.page;
            state.pidResult.results = [...state.pidResult.results, ...action.results];
            return { ...state };
        case PillIdResultsActionTypes.ClearResults:
            state.pidResult = null;
            state.brandName = null;
            state.imprint = null;
            state.selectedFormType = null;
            state.shapeSelected = null;
            state.selectedColorsArray = [];
            state.scoredOrNot = null;
            state.limit = 10;
            state.start = 0;
            state.page = 1;
            return { ...state };
        default:
            return state;
    }
}

export function reducer(state: PillIdResultState, action: PillIdResultActions): PillIdResultState {
    return pillIdResultReducer(state, action);
}
