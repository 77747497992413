import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {mergeMap, catchError, map, filter} from 'rxjs/operators';
import { AppConstants } from 'src/utils/app.constants';
import { AddDocument, DocumentListActionTypes } from '../actions/document-list.actions';
import { LoadMessagesFailure, LoadMessagesSuccess, MessagesActionTypes } from './messages.actions';
import { MessagesService } from './messages.service';


@Injectable()
export class MessagesEffects {

    @Effect()
    loadMessages$: Observable<any> = this.actions$.pipe(
        ofType(DocumentListActionTypes.AddDocument, MessagesActionTypes.LoadMessageForChangedLanguage),
        filter(action => action.document.type === AppConstants.MONOGRAPH_TYPE),
        mergeMap(action => 
            this.messageService.getMonographMessagesById(action.document.id, action.document.lang).pipe(
                // If successful, dispatch success action with result
                map(data => new LoadMessagesSuccess(action.document.id, data)),
                // If request fails, dispatch failed action
                catchError((err) => of(new LoadMessagesFailure(action.document.id, err)))
            )
        )
    );

    constructor(private actions$: Actions<AddDocument>, private messageService: MessagesService) {}

}