import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CpsPrintDocumentComponent } from 'src/app/document/cps-print-document/cps-print-document.component';
import { PRINTOUT_DOCUMENT_DATA, PRINTOUT_MODALS } from 'src/app/document/printoutDocument.tokens';
import { take } from 'rxjs/operators'

declare var printingService: any;

@Injectable({
  providedIn: 'root'
})
export class PrintingService {

  styleSheetElement;

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private httpClient: HttpClient,
    private translationService: TranslateService,
    private ngZone: NgZone
  ) {
    printingService = this;
  }

  printContent(contentData, contentID) {
    this.ngZone.run(() => this.openPrintingModal(contentData, contentID));
  }

  openPrintingModal(data, documentID) {

    let windowInstance = this.openNewTab('assets/' + this.translationService.currentLang.toLowerCase() + '/print.html', documentID);
    setTimeout(() => {
      this.createCDKPortal(data, windowInstance);
    }, 1000);
  }

  openNewTab(url, target) {
    let windowReference = window.open('', target, '');
    if (windowReference.location.href === 'about:blank') {
      windowReference.location.href = url;
    }
    return windowReference;
  }

  createCDKPortal(data, windowInstance) {
    if (windowInstance) {
      const outlet = new DomPortalOutlet(windowInstance.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);

      document.querySelectorAll('style').forEach(htmlElement => {
        windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
      });
      this.styleSheetElement = this.getStyleSheetElement();
      windowInstance.document.head.appendChild(this.styleSheetElement);

      this.styleSheetElement.onload = () => {
        windowInstance.document.body.innerText = '';
        const injector = this.createInjector(data);
        let componentInstance;

        if (data.doc != null) {
          if ((data.doc.name === null || data.doc.name === undefined) && data.doc.type === 'TOOL') {
            this.translationService.get('TabBar.' + data.doc.id).pipe(take(1)).subscribe(tabTitle => {
              windowInstance.document.title = tabTitle;
            });
          }else {
            windowInstance.document.title = data.doc.name;
          }
        } else {
          windowInstance.document.title = 'FIGURE';
        }
        componentInstance = this.attachPrintDocumentContainer(outlet, injector);
        if (data.doc != null) {
          PRINTOUT_MODALS[data.doc.id + 'windowInstance'] = windowInstance;
        } else {
          PRINTOUT_MODALS['figure' + 'windowInstance'] = windowInstance;
        }
        PRINTOUT_MODALS['windowInstance'] = windowInstance;
        PRINTOUT_MODALS['outlet'] = outlet;
        PRINTOUT_MODALS['componentInstance'] = componentInstance;
      };
      if (navigator.userAgent.includes("Firefox")) {
        window.scrollBy({ top: 1 }); // This is to fix DIG-3078
        }
    }
  }

  attachPrintDocumentContainer(outlet, injector) {
    const containerPortal = new ComponentPortal(CpsPrintDocumentComponent, null, injector);
    const containerRef: ComponentRef<CpsPrintDocumentComponent> = outlet.attach(containerPortal);
    return containerRef.instance;
  }

  createInjector(data): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(PRINTOUT_DOCUMENT_DATA, data);
    return new PortalInjector(this.injector, injectionTokens);
  }

// Copy styles from parent window
  getStyleSheetElement() {
    const styleSheetElement = document.createElement('link');
    document.querySelectorAll('link').forEach(htmlElement => {
      if (htmlElement.rel === 'stylesheet') {
        const absoluteUrl = new URL(htmlElement.href).href;
        styleSheetElement.rel = 'stylesheet';
        styleSheetElement.href = absoluteUrl;
      }
    });
    return styleSheetElement;
  }


}
