import { Component, OnDestroy, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from '../search.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CpsSearchResultSwitchesComponent } from '../cps-search-result-switches/cps-search-result-switches.component';
import { DOCUMENT } from '@angular/common';
import { HeaderService } from 'src/services/header.service';
import { SessionService } from 'src/services/session.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { DocumentService } from 'src/services/document.service';

@Component({
  selector: 'app-cps-search-panel',
  templateUrl: './cps-search-panel.component.html',
  styleUrls: ['./cps-search-panel.component.scss']
})
export class CpsSearchPanelComponent implements OnInit, OnDestroy {
  @ViewChild(CpsSearchResultSwitchesComponent, { read: ElementRef })
  searchResultSwitch: CpsSearchResultSwitchesComponent;

  @ViewChild('searchPanel') searchPanel: ElementRef;
  @ViewChild('topHeaderSpacerDiv') topHeaderSpacerDiv: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  private subResultTerm: Subscription;
  private subSearchTerm: Subscription;
  private subSuggestions: Subscription;
  private subSearchField: Subscription;
  private isCondensedSubscription: Subscription;
  private showBuckets = true;
  
  searchForm: FormGroup;
  searchQuery = "";
  searchValueFromResultWrapper = "";
  searchTerm = "";
  statusDropdown = false;
  mobileView = false;

  isSearchInputFocused = false;
  isHeaderCondensed = false;
  isSearchHidden = false;
  isSearchTermFilled = false;

  hasResults = false;
  isTabletView = false;
  isSafari = false;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private clearSearchTimeout: any;
  private searchFocusTimeout: any;

  constructor(private formBuilder: FormBuilder,
              private searchService: SearchService,
              private headerService: HeaderService,
              private sessionService: SessionService,
              private router: Router,
              public translationService: TranslateService,
              public render: Renderer2,
              private documentService: DocumentService,
              private activatedRoute: ActivatedRoute,
            
              @Inject(DOCUMENT) private document: Document) {
    this.searchForm = formBuilder.group({
      searchField: ['', [Validators.minLength(1),
      Validators.required]]
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {

       if (event instanceof NavigationEnd) {

          if (!!event.url && (event.url.startsWith('/login')
                              || event.url.startsWith('/package_switch')
                              || event.url.startsWith('/pkgselection'))) {   
            this.isSearchHidden = true; 

          } else if (event.url.startsWith('/home')) {
            this.isSearchHidden = false;
            this.isHeaderCondensed = false;
          }
          else {
           this.isSearchHidden = false;
          } 
        } 
    });


    this.isCondensedSubscription = this.headerService.getsearchBarCondensedObservable$().subscribe(isSearchCondensed => {
      this.isHeaderCondensed = isSearchCondensed;
    });

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
      this.isTabletView = DetectMobileView.isScreenSizeTablet();      
      this.showBuckets= !this.mobileView;
      if (this.showBuckets){
        this.searchService.setIsBucketExpandedMobile(true);
      }
    });

  }

  ngOnInit(): void {

    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if (this.statusDropdown && (event.key == "ArrowDown" || event.key == "ArrowUp")) {
        event.preventDefault();
        event.stopPropagation();
      }
    });

    this.searchService.getSearchResultObservable().subscribe(searchResultState => {
      if (!!searchResultState) {
        if (searchResultState.resultCount === 0 && this.activatedRoute.snapshot.params.switch!="noresult") {
          this.hasResults = false;
        } else {
          this.hasResults = true;
          this.isSearchInputFocused = false;
        }
      }
    });

    document.addEventListener('click', (event) => {
      const scope = event.target as HTMLElement;
      if (scope.closest('.searchBar') === null) {
        this.statusDropdown = false;
      }
    });

    this.subResultTerm = this.searchService.getSearchTermFromResultWrapperQuery()
    .pipe(takeUntil(this.destroy$)).subscribe(searchValue =>{
      this.searchValueFromResultWrapper = searchValue;
      this.searchForm.get('searchField').setValue(searchValue);
    });

    this.subSearchTerm = this.searchService.getSearchTermObservable()
    .pipe(takeUntil(this.destroy$)).subscribe(searchTermState => {
      this.searchTerm = searchTermState.searchTerm;
      this.searchQuery = searchTermState.searchTerm;
    });

    this.searchService.populateSearchTerm(this.searchTerm);

    this.subSuggestions = this.searchService.showSuggestionDropdown()
    .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.statusDropdown = false;
      if (this.searchTerm == '') {
        this.isSearchTermFilled = false;
      } else {
        this.isSearchTermFilled = true;
      }
    });

    this.subSearchField = this.searchForm.get('searchField').valueChanges
    .pipe(distinctUntilChanged())
    .subscribe(data => {
      this.searchQuery = data;
      if (this.searchQuery != null && this.searchQuery.length > 0) {
        this.searchService.setSearchTerm(this.searchQuery);
        this.statusDropdown = true;
      } else {
        this.statusDropdown = false;
      }
    });
    if (this.mobileView){
      this.showBuckets=false;
      this.searchService.setIsBucketExpandedMobile(false);
    }
  }

  clearSearchText(): void {
    this.statusDropdown = false;
    this.isSearchTermFilled = false;
    this.searchService.clearSearchTerm();
    this.showBuckets=false;
    this.searchService.setBucketStatus(false);
    this.searchService.setIsBucketExpandedMobile(false);
    this.clearSearchTimeout = setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0)
    
  }

  searchCall():void{
      if (this.searchQuery.length > 0) {
        this.statusDropdown = false;
        this.searchService.getResultsWrapper(this.searchQuery, 'direct');
        this.documentService.setScrollPosition(window.scrollY);
      if (this.searchTerm == '')  {
        this.isSearchTermFilled = false;
      } else {
        this.isSearchTermFilled = true;
      }
    }
  }

  keyActivity(e: KeyboardEvent): void {

    if (e.key === "Enter" && this.searchQuery.length > 0) {
      this.statusDropdown = false;
      this.documentService.setScrollPosition(window.scrollY);
      this.searchService.getResultsWrapper(this.searchQuery, 'direct');
      this.isSearchTermFilled = true;
      this.isSearchInputFocused = false;
      this.showBuckets = true;
    } else if (e.key === "ArrowDown") {
      (document.getElementsByClassName('searchItems')[1] as HTMLElement).focus();
      e.stopPropagation();
      e.preventDefault();
    } else if (this.searchQuery.length > 0) {
      this.isSearchTermFilled = true;
    } else {
      this.isSearchTermFilled = false;
      const key = e.key;

      if (key == 'Backspace' || key == 'Delete') {
        this.showBuckets = false;
        this.searchService.clearSearchTerm();
        this.searchService.setBucketStatus(false);
        this.searchService.setIsBucketExpandedMobile(false);
      }
    }
  }

  keyupActivity(e: KeyboardEvent) {
    if (this.searchQuery.length > 0) {
      this.isSearchTermFilled = true;
    }
  }

  onSearchFocused(): void {
    this.isSearchInputFocused = true;
    this.isTabletView = DetectMobileView.isScreenSizeTablet();
    this.mobileView = DetectMobileView.detectScreenSize();
  }



  onSearchFocusOut(event: Event): void {
    const ev = event;
    ev.stopPropagation();

    this.searchFocusTimeout = setTimeout(() => {
      const altTarget = document.activeElement;
      if (altTarget.getAttribute('id') !== 'SearchTxt') {
        this.isSearchInputFocused = false;
      } else {
        this.isSearchInputFocused = true;
      }
    }, 1);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (!this.sessionService.isScrollLocked) {
      if (this.router.url.indexOf('/home') < 0 && this.router.url != "/") {
        this.isHeaderCondensed = (window.scrollY > 0) ? true : false;
      } else {
        this.isHeaderCondensed = false;
      }
    }

    const wrapper = this.document.querySelectorAll(".resultPanel.container-fluid,"
      + ".active-document .tool-wrapper,"
      + ".active-document .doc-content-wrapper");
    if (this.isHeaderCondensed) {
      wrapper?.forEach(div => {
        if (this.sessionService.isScrollLocked === false) {
        } else if (div.getAttribute("class").indexOf("tool-wrapper") != -1) {
          div.classList.add("margin-top-40")
        } else {
          div.classList.add("margin-top-60")
        }
      });
    } else {
      wrapper?.forEach(div => div.classList.remove("margin-top-40","margin-top-60"));
    }
    this.sessionService.isScrollLocked = false;
    this.headerService.setSearchBarCondensed(this.isHeaderCondensed);
  }

  toggleDisplay() {
    this.showBuckets = !this.showBuckets;
    this.searchService.setIsBucketExpandedMobile(this.showBuckets);
  }

  ngOnDestroy(): void {
    if (!!this.subResultTerm) this.subResultTerm.unsubscribe();
    if (!!this.subSearchTerm) this.subSearchTerm.unsubscribe();
    if (!!this.subSuggestions) this.subSuggestions.unsubscribe();
    if (!!this.subSearchField) this.subSearchField.unsubscribe();
    if (!!this.isCondensedSubscription) this.isCondensedSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    clearTimeout(this.clearSearchTimeout);
    clearTimeout(this.searchFocusTimeout);
  }
}
