import { WhatsNewTCActions, WhatsNewTCActionTypes } from '../actions/whatsnew-tc.actions';
import { WhatsNewTC } from '../home/cps-home-sidebar/whatsnew-tc.interface';

export const WhatsNewTCFeatureKey = 'whatsnewtcKey';

export interface WhatsNewTCState {
    whatsNewTC: WhatsNewTC[];
}

export const initialState: WhatsNewTCState = {
    whatsNewTC: []
};

export function whatsNewReducerTC(state = initialState, action: WhatsNewTCActions): WhatsNewTCState {
    switch (action.type) {
        case WhatsNewTCActionTypes.LoadWhatsNewTC:
            return {...state, whatsNewTC: action.whatsNewTC };
        case WhatsNewTCActionTypes.PopulateWhatsNewTC:
            return state;
        case WhatsNewTCActionTypes.ClearWhatsNewTC:
            return initialState;
        default:
            return state;
    }
}

export function reducer(state: WhatsNewTCState, action: WhatsNewTCActions): WhatsNewTCState {
    return whatsNewReducerTC(state, action);
}
