import { Action } from '@ngrx/store';
import { Result, SearchResult } from '../search/SearchResult.interface';

export enum SearchResultActionTypes {
  LoadSearchResults = '[SearchResult] Load SearchResults',
  RemoveSearchResults = '[SearchResult] Remove SearchResults',
  DoSearch = '[SearchResult] Search DoSearch',
  DoMoreSearch = '[DoMoreSearch] Search DoMoreSearch',
  DoLessSearch = '[DoLessSearch] Search DoLessSearch',
  addMoreResults = '[addMoreResults] Add SearchMoreResults',
  addLessResults = '[addLessResults] Add SearchLessResults',
  setMaxCountForGroup = '[setMaxCountForGroup] Set the result limit for a group',
  clearMaxResultCountMap = '[clearMaxResultCountMap] Clear the result limits'
}

export class LoadSearchResults implements Action {
  readonly type = SearchResultActionTypes.LoadSearchResults;
  constructor(public resultCount: number, public searchTerm: string, public didYouMean: string,
              public monographs: Result[], public conditions: Result[], public patientInfo: Result[], public npd: Result[],
    public monographCount: number, public conditionsCount: number, public patientinfoCount: number, public npdCount: number, public mostRelevantResult: any,
              public subcounts?: Map<string, number>) { }
}

export class RemoveSearchResults implements Action {
  readonly type = SearchResultActionTypes.RemoveSearchResults;
  constructor(public err: any = null) { }
}

export class DoSearch implements Action {
  readonly type = SearchResultActionTypes.DoSearch;
  constructor(public searchText: string, public lang: string, public searchType: string) { }
}

export class DoMoreSearch implements Action {
  readonly type = SearchResultActionTypes.DoMoreSearch;
  constructor(public searchText: string, public start: number, public limit: number, public searchType: string,
              public qualifier: string, public groupId) { }
}

export class DoLessSearch implements Action {
  readonly type = SearchResultActionTypes.DoLessSearch;
  constructor(public searchText: string, public start: number, public limit: number, public searchType: string,
              public qualifier: string) { }
}

export class addMoreResults implements Action {
  readonly type = SearchResultActionTypes.addMoreResults;
  constructor(public monographs: Result[], public conditions: Result[], public patientInfo: Result[],
              public groupId: string = '', public maxResults: number = 0) { }
}

export class addLessResults implements Action {
  readonly type = SearchResultActionTypes.addLessResults;
  constructor(public monographs: Result[], public conditions: Result[], public patientInfo: Result[]) { }
}

export class setMaxCountForGroup implements Action {
  readonly type = SearchResultActionTypes.setMaxCountForGroup;
  constructor(public groupId: string, public maxResults: number) {}
}

export class clearMaxResultCountMap implements Action {
  readonly type = SearchResultActionTypes.clearMaxResultCountMap;
  constructor() {}
}

export type SearchResultActions = LoadSearchResults | RemoveSearchResults | DoSearch | DoMoreSearch |
  DoLessSearch | addMoreResults | addLessResults | setMaxCountForGroup | clearMaxResultCountMap;
