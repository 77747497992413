<!-- RESULT LIST -->

<div class="listedDrug" *ngIf="!isSelected">
    <div class="drugImage"><img [src]="getFullImgUrl(pidItem.imageLink)" alt={{pidItem.title}}></div>
    <div class="drugInformation">
        <div class="drugInfo">{{'PID.brandNameLabel' | translate}}<span class="drugData" [innerHTML]="pidItem.brandName"></span></div>
        <div class="drugInfo">{{'PID.genericName' | translate}}<span class="drugData">{{pidItem.genericName}}</span></div>
        <div class="drugInfo" *ngIf="!!pidItem.imprintValue && pidItem.imprintValue !== ''">
            {{'PID.imprint' | translate}}
            <span class="drugData">{{ pidItem.imprintValue}}</span>
        </div>
        <div class="drugInfo" *ngIf="!!pidItem.din && pidItem.din !== ''">
            {{'PID.DIN' | translate}}<span class="drugData">{{ pidItem.din }}</span>
        </div>
        <div class="drugInfo" *ngIf="pidItem.din == null && !!pidItem.npn">
            {{'PID.NPN' | translate}}<span class="drugData">{{ pidItem.npn }}</span>
        </div>
        <div class="detailLink"><span (click)='getDetail()'>{{'PID.details' | translate}}</span></div>
    </div>
</div>

<div class="selectedDrug"  *ngIf="isSelected">
    <div class="closeDetail" (click)="collapse()"><span class="x-close-icon"></span></div> 
    <div class="selectedDrugImage"><img [src]="getFullImgUrl(pidItem.imageLink)"></div>
    <div class="drugDetail">
        <div class="drugInfo">{{'PID.brandNameLabel' | translate}}<span class="drugData" [innerHTML]="pidItem.brandName"></span></div>
        <div class="drugInfo">{{'PID.genericName' | translate}}<span class="drugData">{{ pidItem.genericName }}</span></div>
        <div class="drugInfo" *ngIf="!!pidItem.imprintValue && pidItem.imprintValue !== ''">{{'PID.imprint' | translate}}
            <span class="drugData">{{ pidItem.imprintValue }}</span>
        </div>
        <div class="drugInfo" *ngIf="!!pidItem.dosageForm && pidItem.dosageForm !== ''">
            {{'PID.dosageForm' | translate}}<span class="drugData">{{ pidItem.dosageForm }}</span>
        </div>
        <div class="drugInfo" *ngIf="!!pidItem.colour && pidItem.colour !== ''">
            {{'PID.color' | translate}}<span class="drugData">{{ pidItem.colour }}</span>
        </div>
        <div class="drugInfo" *ngIf="!!pidItem.shape && pidItem.shape !== ''">
            {{'PID.shape' | translate}}<span class="drugData">{{ pidItem.shape }}</span>
        </div>
        <div class="drugInfo" *ngIf="!!pidItem.score && pidItem.score !== ''">
            {{'PID.scoredLabel' | translate}}<span class="drugData">{{ pidItem.score }}</span>
        </div>
        <div class="drugInfo" *ngIf="!!pidItem.manufacturer && pidItem.manufacturer !== ''">
            {{'PID.manufacturer' | translate}}<span class="drugData">{{ pidItem.manufacturer }}</span>
        </div>
        <div class="drugInfo" *ngIf="!!pidItem.din && pidItem.din !== ''">
            {{'PID.DIN' | translate}}<span class="drugData">{{ pidItem.din }}</span>
        </div>
        <div class="drugInfo" *ngIf="pidItem.din == null && !!pidItem.npn">
            {{'PID.NPN' | translate}}<span class="drugData">{{ pidItem.npn }}</span>
        </div>
        <div *ngIf="pidItem.locator!=null && pidItem.locator.length>0" class="drugInfo monoLink">
            <a (click)="navigateToPath(pidItem.locator)">{{'PID.viewMonograph' | translate}}</a>
        </div>
    </div>
</div>

<hr class="drugDivider">
