import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Result } from 'src/app/search/SearchResult.interface';
import { ResultGroup } from 'src/app/search/cps-results-group/cps-results-group.interface';
import { SearchService } from 'src/app/search/search.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { UnsetAllActiveFlags } from 'src/app/actions/document-list.actions';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Subscription, ReplaySubject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from 'src/services/session.service';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { debounceTime } from 'rxjs/operators';

// tslint:disable: max-line-length
@Component({
  selector: 'app-cps-results-page',
  templateUrl: './cps-results-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./cps-results-page.component.scss']
})
export class CpsResultsPageComponent implements OnInit, OnDestroy {
  resultCount = 0;
  searchTerm = '';
  didYouMean = '';
  monographsBucket: Result[] = [];
  conditionsBucket: Result[] = [];
  patientInfoBucket: Result[] = [];
  npdBucket: Result[] = [];
  currentBucketLength = 0;
  monographCount = 0;
  conditionsCount = 0;
  patientinfoCount = 0;
  npdCount = 0;
  isNpd = false;
  limitedSubscription = false;
  mostRelevantResult: any;

  loggedSearchTerms = [];
  searchStateSubscription: Subscription;

  isBucketExpandedMobile: boolean;
  private showBuckets: Subscription;
  
  pageSwitch: string;
  mobileView = false;
  resultGroups: ResultGroup[] = [];

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private navigationSubscription: Subscription;

  constructor(public translationService: TranslateService,
    private activatedRoute: ActivatedRoute,
    public searchService: SearchService,
    private datalayerService: DatalayerService,
    private sessionService: SessionService,
    private router: Router,
    private store: Store<State>) {
    if (this.navigationSubscription == null) {
      this.navigationSubscription = router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
        if (event instanceof NavigationEnd && event.url.startsWith('/search/')) {
          this.updateResultPage();
          setTimeout(() => { window.scrollTo({ top: this.searchService.scrollPositions.get(activatedRoute.snapshot.params.switch), behavior: 'auto' }); }, 50);
        }
      });
    }

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(event => {
      this.mobileView = DetectMobileView.detectScreenSize();
      this.isBucketExpandedMobile = !this.mobileView;
    });
  }

  ngOnInit(): void {
    this.searchStateSubscription = this.searchService.getSearchResultObservable()
      .pipe(takeUntil(this.destroy$)).subscribe(searchResultState => {
      if (!!searchResultState) {
        this.resultCount = searchResultState.resultCount;
        this.didYouMean = searchResultState.didYouMean;
        this.searchTerm = searchResultState.searchTerm;
        this.monographsBucket = searchResultState.monographs;
        this.conditionsBucket = searchResultState.conditions;
        this.patientInfoBucket = searchResultState.patientInfo;
        this.npdBucket = searchResultState.npd;
        this.monographCount = searchResultState.monographCount;
        this.conditionsCount = searchResultState.conditionsCount;
        this.patientinfoCount = searchResultState.patientinfoCount;
        this.npdCount = searchResultState.npdCount;
        this.isNpd = this.npdCount > 0;
        this.mostRelevantResult = searchResultState.mostRelevantResult;
        this.updateResultPage();
        if (this.loggedSearchTerms.indexOf(this.searchTerm) === -1) {
          this.loggedSearchTerms.push(this.searchTerm);
          this.datalayerService.pageLoadEvent('results', this.pageSwitch);
        }
        if (this.resultCount == 0 && this.sessionService.userProfile.active_Subscription == 'ecps') {
              this.limitedSubscription = true;
        }
      }

      this.showBuckets = this.searchService.getIsBucketExpandedMobile()
        .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
          this.isBucketExpandedMobile = isBucketExpandedMobile;
        });

    });
  }

  updateResultPage(): void {
    const thizz = this;
    this.pageSwitch = this.activatedRoute.snapshot.params.switch;
    this.resultGroups = [];
    let conditionsGroup = [];
    this.currentBucketLength = 0;
    this.store.dispatch(new UnsetAllActiveFlags());
    if (this.pageSwitch === 'monographs') {

      this.resultGroups.push({ id: 'Full_Monograph', results: this.monographsBucket.filter(result => result.documentQualifier === 'M'), type: 'MONOGRAPH', qualifier: 'M'});
      this.resultGroups.push({ id: 'Generic_List_Monograph', results: this.monographsBucket.filter(result => result.documentQualifier === 'G'), type: 'MONOGRAPH', qualifier: 'G'});
      this.resultGroups.push({ id: 'CPhA_Monograph', results: this.monographsBucket.filter(result => result.documentQualifier === 'C'), type: 'MONOGRAPH', qualifier: 'C'});
      this.resultGroups.push({ id: 'Availability_Only', results: this.monographsBucket.filter(result => result.documentQualifier === 'A') , type: 'MONOGRAPH', qualifier: 'A'});
      this.resultGroups.push({ id: 'Discontinued', results: this.monographsBucket.filter(result => result.documentQualifier === 'D') , type: 'MONOGRAPH', qualifier: 'D'});
      this.resultGroups.push({ id: 'NPD', results: this.monographsBucket.filter(result => result.documentQualifier === 'N') , type: 'MONOGRAPH', qualifier: 'N'});
      this.currentBucketLength = this.monographCount;
    } else if (this.pageSwitch === 'conditions') {     
      conditionsGroup.push({ id: 'Therapeutic_Choices', results: this.conditionsBucket.filter(result => result.type === 'CHAPTER'), type: 'CHAPTER', qualifier: ''});
      conditionsGroup.push({ id: 'Minor_Ailments', results: this.conditionsBucket.filter(result => result.type === 'MA_CHAPTER'), type: 'MA_CHAPTER', qualifier: ''});
      conditionsGroup.push({ id: 'Clinical_info', results: this.conditionsBucket.filter(result => result.type === 'CLININFO') , type: 'CLININFO', qualifier: ''});
      conditionsGroup.push({ id: 'Products_Minor_Ailments', results: this.conditionsBucket.filter(result => result.type === 'PMA') , type: 'PMA', qualifier: ''});
      if (!!this.mostRelevantResult) {
        const firstConditionBucket = conditionsGroup.filter(g => g.type === this.mostRelevantResult.type)[0];
        if (!!firstConditionBucket) {
          thizz.resultGroups.push(firstConditionBucket);
          const index = conditionsGroup.indexOf(firstConditionBucket);
          conditionsGroup.splice(index, 1);
        }
      }
      for (let group of conditionsGroup) {
        thizz.resultGroups.push(group);
      }   

      this.currentBucketLength = this.conditionsCount;
    } else if (this.pageSwitch === 'patientinfo') {
      this.resultGroups.push({ id: 'Patient_Medication_Information', results: this.patientInfoBucket.filter(result => result.type === 'IFP'), type: 'IFP', qualifier: ''});
      this.resultGroups.push({ id: 'Patient_Information_Minor_Ailments', results: this.patientInfoBucket.filter(result => result.type === 'MA_PI') , type: 'MA_PI', qualifier: ''});
      this.currentBucketLength = this.patientinfoCount;
    } else if(this.pageSwitch === "noresult" && this.isNpd){
      this.resultGroups.push({ id: 'Alternate_Monographs', results: this.npdBucket, type: 'MONOGRAPH', qualifier: 'M' });
    }


  }

  searchAgain(suggestedKeyword: string): void {
    this.searchService.setSearchTerm(suggestedKeyword.replace(/(<([^>]+)>)/gi, ''));
    this.searchService.getResultsWrapper(suggestedKeyword.replace(/(<([^>]+)>)/gi, ''));
  }

  ngOnDestroy(): void {
    this.searchStateSubscription.unsubscribe();
    if (!!this.showBuckets) this.showBuckets.unsubscribe();
  }

}
