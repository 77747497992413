import { Action } from '@ngrx/store';
import { UserProfileActions, UserProfileType } from '../actions/user-profile.actions';
import { userProfile } from '../utility/Objects/userProfile';

export const userProfileFeatureKey = 'userProfileKey';

export interface UserProfileState {
  userProfile: userProfile;
}

export const initialState: UserProfileState = {
  userProfile: null
};

export function userProfileReducer(state = initialState, action: UserProfileActions): UserProfileState {
  switch (action.type) {
    case UserProfileType.LoadUserProfile:
      return { ...state, userProfile: action.userProfile};
    case UserProfileType.RefreshUserToken:
        return { ...state, userProfile: action.userProfile};
    case UserProfileType.RemoveUserProfile:
      return { ...state, userProfile: null };
    case UserProfileType.LoadUserCredentials:
      return state;
    case UserProfileType.LoadActiveSubscription:
      return state;
    case UserProfileType.LoadIPAuthenticatedUser:
      return state;

    default:
      return state;
  }
}

export function reducer(state: UserProfileState, action: UserProfileActions): UserProfileState {
  return userProfileReducer(state, action);
}
