<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="true" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>
    <div class="skeleton-wrapper" *ngIf="!contentLoaded">
        <ngx-skeleton-loader [theme]="{width: '80%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
        <div>
            <ngx-skeleton-loader
                [theme]="{width: '70%', 'border-radius': '10px', 'margin-bottom': '20px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader
                [theme]="{width: '50%', 'border-radius': '10px', 'margin-bottom': '20px', 'margin-left':'50%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader
                count="10" [theme]="{width: '100%', 'border-radius': '10px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>
        </div>
    </div>
    
    <div #docWrapper class="doc-content-wrapper" *ngIf="contentLoaded">
        <p class="header" *ngIf="translationService.currentLang === 'en'">{{ 'home-page.sidebar.whatsNew' | translate }} {{today | date:'MMM d' : '' : translationService.currentLang}}</p>
        <p class="header" *ngIf="translationService.currentLang === 'fr'">{{ 'home-page.sidebar.whatsNew' | translate }} {{today | date:'d MMM' : '' : translationService.currentLang}}</p>
        <div class="whatsnew-container">
            <div class="whatsnew-monographs">
                <p class="whatsNewSection" *ngIf="whatsNew.length > 0">{{ 'whatsnew.mono' | translate }}</p>
                <p><a [routerLink]="['/resource/updates']">{{ 'whatsnew.monoDescription1' | translate }}</a>{{ 'whatsnew.monoDescription2' | translate }}</p>
        
                <div *ngFor="let catg of categories">
                    <span class="whatsNewSubHeader" [translate]="'home-page.sidebar.' + catg"></span>
        
                    <ul>
                        <li class="home-sidebar-item" *ngFor="let item of filterBy(catg)">
                            <span class="brandName"><span *ngIf="item.filename === 'none' && item.filename !== null ">{{item.brandName}} </span><a
                                *ngIf="item.filename !== 'none'  && item.filename !== null " [routerLink]="['/document/monograph',item.filename.split('.')[0]]">{{item.brandName}} </a> </span><span
                                class="generic">({{item.genericName}})</span> :
                            <span class="classification">{{item.classification}}</span>
                        </li>
                    </ul>
        
                </div>
            </div>
            <div class="whatsnew-tc" *ngIf="whatsNewTC.length > 0">
                <p class="whatsNewSection">{{ 'whatsnew.tc' | translate }}</p>
                <p>{{ 'whatsnew.tcDescription' | translate }}</p>
                <ul>
                    <li class="home-sidebar-item" *ngFor="let item of whatsNewTC">
                        <span class="brandName"><a [routerLink]="['/document/therapeuticchoices',item.filename.split('.')[0]]">{{item.title}}
                            </a> </span><span class="generic">({{item.date}})</span> :
                        <span class="classification">{{item.description}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
