import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { DocumentComponent } from 'src/app/document/document-models/documentComponent';
import { SessionService } from 'src/services/session.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cps-lexi-page',
  templateUrl: './cps-lexi-page.component.html',
  styleUrls: ['./cps-lexi-page.component.scss']
})
export class CpsLexiPageComponent implements DocumentComponent, OnDestroy {

  @Input() htmlElement: HTMLElement;
  @ViewChild('docWrapper') documentWrapperElement: ElementRef;
  public doc: DocumentItem;

  @ViewChild('iframe') iframe: ElementRef;

  public loadError = false;

  public loading = true;

  private baseLexiPath = "/cps-proxy/lexi/lco/action/home";

  public lexiPath = "/cps-proxy/lexi/lco/action/home";

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService, private sessionService: SessionService) {
    this.lexiPath = this.baseLexiPath + "?token=" + this.sessionService.userProfile.access_token;
  }

  ngOnInit(): void {
    this.checkTokenValidity();
  }

  onLoad(): void {
    if (this.verifyLoaded()) {
      this.loading = false;
      const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow.document;
      const head = doc.head || doc.getElementsByTagName('head')[0];

      const style = doc.createElement('style');

      const css = "#home-search.wk-search { visibility:hidden; } #header-top-actions, .wk-nav, .wk-footer-nav, "
      +".wk-footer-copyright, #footer-language-select, #clintools-dropdown, #footer-logo-holder,"
      + "#bluebar.wk-navbar { display:none !important; }"
      + ".header-right .top-right-links, #ui-lang-select {display:none}"
      + ".wk-header:after { display:inline-block; position:absolute; right:0; width:550px; font-style: italic; font-size:10pt; padding-top: 12px; margin-left:-300px; }"
      + "#controls .scroll-fix, #main .scroll-fix { height: calc(100vh - 10rem) !important; }"
      + "#main .min-height-container > div { min-height: calc(100vh - 15.5rem); border-left: 1px solid #dadada; padding: 1em 1.5em; margin-left: 0 !important;}"
      + ".wk-nav.wk-nav-tabs.tabs { display: block !important; } .wk-logo {pointer-events: none;}";

      style.innerHTML = css;

      head.appendChild(style);
    } else {
      this.checkTokenValidity();
    }
  }

  verifyLoaded(): boolean {
    const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow.document;
    const lexiElement = doc.getElementById('msinteract');
    return !!lexiElement;
  }

  checkTokenValidity(): void {
    this.sessionService.checkAccessTokenValidity().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.lexiPath = this.baseLexiPath + "?token=" + this.sessionService.userProfile.access_token;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
