import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { SearchService } from 'src/app/search/search.service';
import { subscriptionInfo } from 'src/app/utility/Objects/subscriptionInfo';
import { userProfile } from 'src/app/utility/Objects/userProfile';
import { AuthService } from 'src/services/auth-services/auth.service';
import { DocumentService } from 'src/services/document.service';
import { HomeService } from 'src/services/home.service';
import { SessionService } from 'src/services/session.service';

@Component({
  templateUrl: './cps-subscription-selection.component.html',
  styleUrls: ['./cps-subscription-selection.component.scss']
})
export class CpsSubscriptionSelectionComponent implements OnInit {

  public loginErrorMessage$: BehaviorSubject<string>;
  authorities: string[];
  activeSubscription = null;
  userLocalProfile: userProfile;
  subscriptionInfo: subscriptionInfo[] = [];
  currentLanguage: string;

  public pParam: string;

  public showPP = false;

  constructor(private router: Router, private authService: AuthService, public homeService: HomeService,
              public translationService: TranslateService, public sessionService: SessionService, public search: SearchService, 
              private documentService: DocumentService) {
      this.currentLanguage = this.translationService.currentLang;
      this.pParam = '-';
  }

  ngOnInit(): void {

    this.homeService.clearCustomizer();
    this.authService.clearLoginErrorMessage();
    this.loginErrorMessage$ = this.authService.getLoginErrorMessage();

    this.userLocalProfile = AuthService.userProfileState.userProfile;
    this.authorities = AuthService.userProfileState.userProfile.authorities;
    this.activeSubscription = AuthService.userProfileState.userProfile.active_Subscription;
    this.subscriptionInfo = AuthService.userProfileState.userProfile.subscriptionInfo;

    const token = this.sessionService.userProfile.refresh_token;
    this.authService.getpParam(token).subscribe(
      (value) => {
       console.log(value.pParam);
       this.pParam = value.pParam;
    },
      (error) => {
        console.log('Failed to get pParam: ' + error);
      });

  }

  togglePPDisplay(): void {
    this.showPP = !this.showPP;
  }

  copyToken(): void {

    if (navigator?.clipboard?.writeText) { // navigator clipboard will only work on localhost or https connections so check for that and ignore if it's not true
      navigator.clipboard.writeText(this.pParam).then(() => {});
    }

    const tokenTip = document.getElementsByClassName('tokenToolTip')[0] as HTMLElement;
    const tokenArrow = document.getElementsByClassName('tokenArrowDown')[0] as HTMLElement;

    tokenTip.style.visibility = 'visible';
    tokenTip.classList.add('elementToFadeInAndOut');

    tokenArrow.style.visibility = 'visible';
    tokenArrow.classList.add('elementToFadeInAndOut');

    setTimeout(() => {
      tokenTip.style.visibility = 'hidden';
      tokenTip.classList.remove('elementToFadeInAndOut');

      tokenArrow.style.visibility = 'hidden';
      tokenArrow.classList.remove('elementToFadeInAndOut');
    }, 4000);
  }

  standardSubSelected(): void {
    this.router.navigate(['home']);
  }

  fullSubSelected(): void {
    this.router.navigate(['home']);
  }

  redirectToHome(): void {
    this.router.navigate(['home']);
  }

  selectSubscription(selectedSubscription: string): void {

    if (this.userLocalProfile != null) {

      if (this.userLocalProfile.active_Subscription === null) {
        this.authService.setFirstTimeUserProfileDetails(selectedSubscription);
      } else if (this.userLocalProfile.active_Subscription !== selectedSubscription) {
        this.authService.setUserProfileDetails(selectedSubscription);
      }

      this.search.textToUpdateSearchBox.next('');
      this.authService.clearSearchResult();
      this.documentService.resetDocumentVariables();
      this.router.navigate(['home']);

      AuthService.userProfileState.userProfile.active_Subscription = selectedSubscription;

    }
  }
}
