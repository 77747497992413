
import { AppConstants } from "src/utils/app.constants";
import { HomeHistoryActions, HomeHistoryType } from "../actions/home-history.actions";
import { DocumentItem } from "../document/document-models/document-item";

export const HomeHistoryKey = 'homeHistory';

export interface HomeHistoryState {
  active: number;
  recents: DocumentItem[];
  favorites: DocumentItem[];
}

export const initialState: HomeHistoryState = {
  active: 2,
  recents: [],
  favorites: []
};

export function homeHistoryReducer(state = initialState, action: HomeHistoryActions): HomeHistoryState {  
  switch (action.type) {
      case HomeHistoryType.AddRecent:
        if (action.doc.type !== AppConstants.NOT_FOUND_TYPE) {
          const newRecents = state.recents.filter(doc => !(action.doc.id === doc.id && action.doc.type === doc.type 
                                                            && action.doc.lang === doc.lang && action.doc.userid === doc.userid));
          return {...state, recents: [action.doc, ...newRecents]};
        } else {
          return {...state};
        }

      case HomeHistoryType.AddFavorite:
        const addFavs = state.favorites.filter(doc => !(action.doc.id === doc.id && action.doc.type === doc.type 
                                                        && action.doc.lang === doc.lang && action.doc.userid === doc.userid));
        return {...state, favorites: [action.doc, ...addFavs] };

    case HomeHistoryType.RemoveFavorite:
      const delFavs = state.favorites.filter(doc => !(action.doc.id === doc.id && action.doc.type === doc.type 
                                                        && action.doc.lang === doc.lang && action.doc.userid === doc.userid));
      return {...state, favorites: delFavs };

    case HomeHistoryType.SetState:
      return {...state, active: action.active };

    default:
          return state;
  }
}

