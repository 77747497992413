
<div class="manufacturer-wrapper">
    <div class="manufacturer-children-wrapper">
        <div *ngIf="contactManufacturer.statement"         
        [ngClass]="{
                    'manufacturer-rightAlign':contactManufacturer.statementAlignment=='right',
                 'manufacturer-centered':contactManufacturer.statementAlignment=='center',
                    'manufacturer-leftAlign':contactManufacturer.statementAlignment=='left'}">
            <statement class="manufacturer-font">{{ contactManufacturer.statement}}</statement>
        </div>
        <div *ngIf="!contactManufacturer.statement" class="child-div">
            <statement class="manufacturer-font">{{ 'ContactManufacturer.statement' | translate }}</statement>
        </div>
    <div class="manufacturer-child-position">
        <button class="btn manufacturer-btn" *ngIf="contactManufacturer.buttonText" (click)="onManufacturerClick()">
            <a class="manufacturer-font manufacturer-font-btn" href={{contactManufacturer.url}} target="_blank">
                {{ contactManufacturer.buttonText}}
            </a>
        </button>
        <button class="btn manufacturer-btn" *ngIf="!contactManufacturer.buttonText">
            <a class="manufacturer-font manufacturer-font-btn" href={{contactManufacturer.url}} target="_blank">
                {{ 'ContactManufacturer.buttonText' | translate }}
            </a>
        </button>
    </div>
    </div>
</div>
<div class="manufacturer-space-btn"></div>