<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="false" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>
    <div class="doc-content-wrapper">
        <div class="skeleton-wrapper" *ngIf="!contentLoaded">
            <div *ngIf="!contentLoaded" class="skeleton-first-wrapper">
                <ngx-skeleton-loader [theme]="{width: '80%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <div>
                    <ngx-skeleton-loader [theme]="{width: '75%', 'border-radius': '10px', 'margin-bottom': '20px'}"></ngx-skeleton-loader>
                </div>
                
                <ngx-skeleton-loader [theme]="{width: '60%', 'border-radius': '10px', 'margin-bottom': '10px'}"></ngx-skeleton-loader>                
                <div class="skeleton-text-line">
                    <ngx-skeleton-loader count="20" [theme]="{width: '100%', 'border-radius': '10px', 'margin-bottom': '0px'}"></ngx-skeleton-loader>                
                </div>
            </div>
        </div>

        <div #docWrapper class="resource-wrapper" *ngIf="contentLoaded" >
            <div [innerHTML]="htmlElement.innerHTML | safe: 'html'">
            </div>
        </div>
    </div>
</div>