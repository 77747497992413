<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" #docWrapper  *ngIf="doc.isActive">
    <div class="lexiDisclaimerContainer">
        {{ 'Lexi.disclaimer' | translate}}
    </div>
    <div [ngClass]="{'hidden': !loading}" class="tab-loading"></div>
    <div class="lexi-container" [ngClass]="{'hidden': loading}">
        <iframe id="diFrame" #iframe
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals"  
            style="max-width: 1400px; visibility: visible; overflow: scroll; height: 950px; width: 100%; padding: 0px; margin: 0px;"
            height="100%" frameborder="0" width="100%" scrolling="yes" 
            title="LEXI DIG" marginwidth="0" marginheight="0"
            (load)="onLoad()"
            [src]="lexiPath | safe: 'url'">
        </iframe>
    </div>

    <div class="lexi-error" *ngIf="loadError">

        <p>{{ 'Lexi.error' | translate }}</p>

    </div>
</div>
