import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentService } from 'src/services/document.service';

@Component({
  selector: 'app-cps-alpha-bar',
  templateUrl: './cps-alpha-bar.component.html',
  styleUrls: ['./cps-alpha-bar.component.scss']
})
export class CpsAlphaBarComponent implements OnInit, OnChanges {

  public alphabets =
  ['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  public availableAlphas: string [];
  public selectedAlpha: string;
  private _scrollOffsetForHeader = 320;
  private lastScrollTop = 0;
  private isClickAction = false;
  public contentLoaded = false;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  @Output() public selectedAlphaEvent = new EventEmitter<string>();
  @Input() document: Document;

  @Input() public identifier: string;
  @Input() public itemList;

  constructor(private documentService: DocumentService) {
    this.availableAlphas = [];
    this.selectedAlpha = '';
  }

  ngOnInit(): void {
    this.documentService.getContentLoaded().pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      this.contentLoaded = isLoaded;
    });
  }

  ngOnChanges(): void {
    if (this.itemList) {
      for (const i in this.itemList) {
        if (Object.prototype.hasOwnProperty.call(this.itemList, i)) {
          if (!isNaN(this.itemList[i].title.charAt(0)) && !this.availableAlphas.includes('#')) {
            this.availableAlphas.push('#');
          }
          if (isNaN(this.itemList[i].title.charAt(0)) && !this.availableAlphas.includes(this.itemList[i].title.charAt(0))) {
            this.availableAlphas.push(this.itemList[i].title.charAt(0));
          }
        }        
      } 
      this.selectedAlpha = this.availableAlphas[0];   
    }
  }

  alphaHasEntry(letter: string): boolean {

    if (this.availableAlphas.includes(letter)) {
      return true;
    }

    return false;
  }

  alphaSelected(letter: string): void {
    if (this.alphaHasEntry(letter)) {
      this.selectedAlpha = letter;
      this.selectedAlphaEvent.emit(letter);
      this.isClickAction = true;
    }
  }

  @HostListener('document:scroll', ['$event.target'])
  onscroll() {  
    if (this.contentLoaded && !this.isClickAction) {
        
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrolling = -1; //scrolling up
      if (currentScrollTop > this.lastScrollTop ) {
        scrolling = 1; //scrolling down
      }
      this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
      this.selectButtonOnScroll(scrolling);
      
    }
    this.isClickAction = false;
  }

  selectButtonOnScroll(scrolling: number): void {
    const headerElements: any[] = this.identifier == 'generic' ? Array.from(document.documentElement.querySelectorAll('.genericLetterHeading')) : Array.from(document.documentElement.querySelectorAll('.letterHeading'));    
    headerElements.forEach((el) => {
      const rect = el.getBoundingClientRect();
      const visibleCondition = scrolling < 0 ? rect.top <= this._scrollOffsetForHeader : rect.top < window.innerHeight && rect.bottom <= window.innerHeight;
      if (visibleCondition) {
        const id = el.getAttribute("id");
        let lastLetter = id.replace(this.identifier, '');
        let currentLetter = lastLetter == '0' ? "#" : lastLetter;
        currentLetter = currentLetter == ' ' ? "*" : currentLetter;
        this.selectAlphaBarButton(currentLetter);
      }
    })
  }  
  
  selectAlphaBarButton(buttonId: string): void {
    if (!buttonId || buttonId === this.selectedAlpha) {
      return;
    }
    this.selectedAlpha = buttonId;
  }

}
