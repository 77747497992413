import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DocumentService } from 'src/services/document.service';
import { AddMenuDocument, DocumentDataActionTypes, LoadMenuDocument } from '../actions/document-data.actions';
import { MenuDocumentData } from '../reducers/document-data.reducer';

@Injectable()
export class DocumentDataEffects {

    @Effect()
    populateMenuDocuments$: Observable<any> = this.actions$.pipe(
        ofType(DocumentDataActionTypes.LoadMenuDocument),
            mergeMap(action =>
                this.documentService.getDocumentDirectoryData(action.menuType, action.lang).pipe(
                    map(value => {
                        const result = this.populateMenuDocuments(value);
                        return new AddMenuDocument(result, action.menuType);
                    }),
                    catchError(() => EMPTY)
                )
            )
    );

    constructor(private actions$: Actions<LoadMenuDocument>, private documentService: DocumentService) {
        // Empty
    }

    populateMenuDocuments(value: string): MenuDocumentData {
        
        const newDocData = new MenuDocumentData();

        newDocData.hasBodyParts = false;
        const bodypartMap = new Map<string, any>();
        const titleMap = new Map<string, any>();

        const j = JSON.parse(value);

        newDocData.resultsList = j.results.sort(function (a, b) {
            const titleA = a.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            const titleB = b.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            if (titleA < titleB) return -1;
            else if (titleA > titleB) return 1;
            else return 0;
        });

        newDocData.resultsList.forEach(node => {
            titleMap.set(node.title, null);

            if (node.bodySystemDescriptorTextLst != null && node.bodySystemDescriptorTextLst[0] !== undefined) {
                const bodySystem = node.bodySystemDescriptorTextLst[0];
                newDocData.hasBodyParts = true;
                bodypartMap.set(bodySystem, null);
            }            
        });

        newDocData.titlesList = Array.from(titleMap.keys());
        newDocData.titlesList.sort();

        if (newDocData.hasBodyParts) {
            newDocData.bodypartKeys = Array.from(bodypartMap.keys());
            newDocData.bodypartKeys.sort();
        }

        return newDocData;
    }
}
