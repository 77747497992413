import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PIDResult } from './PIDResult.interface';

@Injectable({
  providedIn: 'root'
})
export class PidService {

  constructor(public httpClient: HttpClient) { }


  getPIDSearchResults(searchForPID): Observable<PIDResult> {
    const requestParams = searchForPID.request;

    // loop through the object so we only add params that aren't null or undefined
    let params = new HttpParams();
    Object.keys(requestParams).forEach(
      key => !!requestParams[key] && (params = params.append(key, requestParams[key]))
    );

    return this.httpClient.get<PIDResult>(environment.API_SERVICE_ENDPOINT + '/search/pillid', { params });
  }

  getPIDByHTMLElement(html: HTMLElement): Observable<PIDResult> {

    // Pull the DINs out of the HTMLElement and prepare to send them to the API
    const dins = html.querySelectorAll('[data-codetype="din"]');

    const d = [];

    let i = 0;
    for (i = 0; i < dins.length; i++) {
      d.push(dins[i].innerHTML);
    }

    const p = new HttpParams().set('requestData', encodeURIComponent(JSON.stringify(d)));

    return this.httpClient.get<PIDResult>('assets/mockData/mezavant.pid.en.json', {params: p});
  }

}
