import { HeaderService } from 'src/services/header.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/services/auth-services/auth.service';
import { DocumentService } from 'src/services/document.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cps-header',
  templateUrl: './cps-header.component.html',
  styleUrls: ['./cps-header.component.scss']
})
export class CpsHeaderComponent implements OnInit {

  public sessionActive: boolean;
  public subscriptionPage: boolean;

  public isCondensed$: Observable<boolean>;

  constructor(public headerService: HeaderService,
    public documentService: DocumentService,
    private authService: AuthService,
    public translationService: TranslateService,
    private router: Router) {

    this.isCondensed$ = headerService.getsearchBarCondensedObservable$();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/package_switch') || event.url.startsWith('/pkgselection')) {
          this.subscriptionPage = true;
        } else {
          this.subscriptionPage = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.authService.getUserProfileDetails().subscribe(UserProfileState => {
      // Validate the token here?
      if (UserProfileState.userProfile != null && UserProfileState.userProfile.access_token != null) {
        this.sessionActive = true;
      } else {
        this.sessionActive = false;
      }
    });
  }
}
