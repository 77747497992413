import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from '../search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { SessionService } from 'src/services/session.service';
import { DocumentService } from 'src/services/document.service';

@Component({
  selector: 'app-cps-search-result-switches',
  templateUrl: './cps-search-result-switches.component.html',
  styleUrls: ['./cps-search-result-switches.component.scss']
})
export class CpsSearchResultSwitchesComponent implements OnInit {
  @Input() isHeaderCondensed: boolean;
  @Input() isSearchTermFilled : boolean;

  monographCount = 0;
  conditionsCount = 0;
  patientInfoCount = 0;
  switchesRequired: boolean;
  monographRoute = '/search/monographs';
  conditionRoute = '/search/conditions';
  piRoute = '/search/patientinfo';

  constructor(public translationService: TranslateService,
    private activatedRoute: ActivatedRoute,
    public searchService: SearchService,
    private sessionService: SessionService,
    private documentService: DocumentService,
    private router: Router) { }

  ngOnInit(): void {
    

    this.searchService.getSearchResultObservable().subscribe(searchResultState => {
      if (!!searchResultState) {
        if (searchResultState.resultCount === 0 && this.activatedRoute.snapshot.params.switch!="noresult") {
          this.switchesRequired = false;
        } else {
          this.switchesRequired = true;
          this.monographCount = searchResultState.monographCount;
          this.conditionsCount = searchResultState.conditionsCount;
          this.patientInfoCount = searchResultState.patientinfoCount;
          if (this.monographCount === 1) {
            this.monographRoute = '/document/monograph/' + searchResultState.monographs[0].id;
          }
          if (this.conditionsCount === 1) {
            this.conditionRoute = '/document/' + DocumentItem.getPathFromType(searchResultState.conditions[0].type) + '/' + searchResultState.conditions[0].id;
          }
          if (this.patientInfoCount === 1) {
            this.piRoute = '/document/' + DocumentItem.getPathFromType(searchResultState.patientInfo[0].type) + '/' + searchResultState.patientInfo[0].id;
          }
        }
      }
    });
  }

  isActive(switchName: string): boolean {
    return (this.router.url.includes('/search/' + switchName))?true:false;
  }

  reloadCurrentRoute(bucketSwitch: string) {
    this.documentService.setScrollPosition(window.scrollY);
    this.sessionService.isScrollLocked = true;
    this.searchService.reloadCurrentRoute(bucketSwitch);
  }
}
