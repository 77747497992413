import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/services/document.service';
import { HomeService } from 'src/services/home.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { AuthService } from 'src/services/auth-services/auth.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cps-home-customizer',
  templateUrl: './cps-home-customizer.component.html',
  styleUrls: ['./cps-home-customizer.component.scss']
})
export class CpsHomeCustomizerComponent implements OnInit, OnDestroy {

  public customizer: HTMLElement;
  public currentLanguage: string;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService, private homeService: HomeService, private authService: AuthService,
              private documentService: DocumentService, private datalayerService: DatalayerService) {
    this.currentLanguage = this.translationService.currentLang;
    const parser = new DOMParser();
    this.customizer = parser.parseFromString('<div></div>', 'text/html').documentElement;
  }

  ngOnInit(): void {
/*
    if (!HomeService.customizerLoaded && AuthService.userProfileState.userProfile.active_Subscription) {
      HomeService.customizerLoaded = true;
      this.homeService.initializeCustomizer(this.translationService.currentLang, AuthService.userProfileState.userProfile);
    }

    this.homeService.getStoredCustomizer().subscribe((value) => {
      const parser = new DOMParser();
      this.customizer = parser.parseFromString(value.customizer, 'text/html').documentElement;
      this.documentService.imageLoader(this.customizer);
      HomeService.customizerLoaded = false;
    });
    */
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
