<div class="modal-header">
    <h4 class="modal-title">{{'Modals.licenseTakenTitle' | translate}}</h4>
</div>
<div class="session-expired-body">
    <p>{{'Modals.licenseTakenBody' | translate}}</p>
</div>
<div class="session-expired-footer">
    <button (click)="buttonClick()" class="btn btn-primary form-control">{{'Modals.lostSessionButton' | translate}}</button>
</div>

